import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { issuingAPI } from '../../services/Axios';
import { generateOperationID } from '../../utils/Helper';

const PREFIX = '@listOfShops/';

export const RESET_SHOPS = `${PREFIX}RESET_SHOPS`;
export const FETCH_SHOPS = `${PREFIX}FETCH_SHOPS`;
export const FETCH_SHOPS_SUCCESS = `${PREFIX}FETCH_SHOPS_SUCCESS`;
export const FETCH_SHOPS_ERROR = `${PREFIX}FETCH_SHOPS_ERROR`;

export const UPDATE_SHOP_ACCEPTANCE_REQUEST = `${PREFIX}UPDATE_SHOP_ACCEPTANCE_REQUEST`;
export const UPDATE_SHOP_ACCEPTANCE_SUCCESS = `${PREFIX}UPDATE_SHOP_ACCEPTANCE_SUCCESS`;
export const UPDATE_SHOP_ACCEPTANCE_ERROR = `${PREFIX}UPDATE_SHOP_ACCEPTANCE_ERROR`;

export const fetchShops =
  (filters = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_SHOPS });

    const { tagCardBrands } = getState().tag;

    let params = {
      per_page: filters.perPage,
      ...filters,
    };

    try {
      const response = await issuingAPI.get(
        `/card-brand/${tagCardBrands}/referenced-shops`,
        { params },
      );

      dispatch({
        type: FETCH_SHOPS_SUCCESS,
        payload: {
          data: response.data.data,
          meta: response.data.meta,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_SHOPS_ERROR, payload: error });
    }
  };

export const setShopStatus = (id, status) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_SHOP_ACCEPTANCE_REQUEST });

  const {
    tag: { tagCardBrands, tagID },
    listOfShops: {
      shops: { data: shops },
    },
  } = getState();

  const shop = shops.find((item) => item.id === id);

  if (!shop) {
    dispatch({ type: UPDATE_SHOP_ACCEPTANCE_SUCCESS });
    return;
  }

  try {
    await issuingAPI.put(
      `/tag/${tagID}/shop/${shop.id}/card-brand/${tagCardBrands}/status/${shop.status}`,
      {
        status,
      },
      {
        headers: {
          'operation-id': generateOperationID(),
        },
      },
    );

    dispatch({ type: UPDATE_SHOP_ACCEPTANCE_SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_SHOP_ACCEPTANCE_ERROR, payload: error });
  }
};
