import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useShoppingCentres() {
  const {
    tagCardBrands,
    receivedTagList: tags,
    tagID,
  } = useSelector((state) => state.tag);

  const shoppingCentresForBrand = useMemo(
    () =>
      tags.filter((tag) => {
        const [cardBrandId] = tag.card_brand_ids;

        return tagCardBrands.includes(cardBrandId);
      }),
    [tags, tagCardBrands],
  );

  return {
    shoppingCentresForBrand,
    currentShoppingCentreId: tagID,
  };
}

export { useShoppingCentres };
