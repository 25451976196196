import React, { useState } from 'react';

const CopyButton = ({ value, label, copiedLabel }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  return (
    <button
      type="button"
      className="ml-2 px-4 py-2 bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer text-white rounded"
      onClick={copyToClipboard}
    >
      {isCopied ? copiedLabel : label}
    </button>
  );
};

export default CopyButton;
