import React from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_LIGHT_BLUE,
  GF_PERIWINKLE,
} from '../../../../utils/Constants';
import { useSelector } from 'react-redux';

export default function WeeklyChart({ data, chartType, CustomizedTooltip }) {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  const dateTickFormatter = (tickItem) => {
    return t(tickItem);
  };

  const renderColorfulLegendText = (value, entry) => {
    const { color } = entry;

    return (
      <span
        style={{ color }}
      >{`${value === 'amount' ? t('Amounts-loaded') : t('Cards-Sold')}`}</span>
    );
  };
  const MoneyTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g>
        <text
          className="text-sm font-MulishBold text-right"
          x={x}
          y={y}
          dy={5}
          fill={GF_LIGHT_BLUE}
        >
          {parseFloat(payload?.value).toFixed(2)}
        </text>

        <text
          className="text-sm font-MulishBold text-right"
          x={x}
          y={y + 15}
          dy={5}
          fill={GF_LIGHT_BLUE}
        >
          {tagCurrency}
        </text>
      </g>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '400px',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data ?? []}>
          <Tooltip content={CustomizedTooltip} />
          <CartesianGrid stroke={GF_PERIWINKLE} />
          <Legend verticalAlign="top" formatter={renderColorfulLegendText} />
          <XAxis
            dataKey={'day_of_week'}
            tickFormatter={dateTickFormatter}
            stroke={GF_DARK_BLUE}
            minTickGap={-200}
            padding={{ left: 20, right: 20, top: 80, bottom: 20 }}
            tick={{ fontSize: 14 }}
          ></XAxis>
          {['cards-sold', 'both'].includes(chartType) && (
            <YAxis yAxisId="left" dataKey={'operations'}>
              <Label
                className="font-MulishBold"
                angle={-90}
                position="insideLeft"
              >
                {t('Cards')}
              </Label>
            </YAxis>
          )}
          {['amounts-loaded', 'both'].includes(chartType) && (
            <YAxis
              yAxisId="right"
              dataKey="amount"
              orientation="right"
              stroke={GF_DARK_BLUE}
              tick={MoneyTick}
              width={70}
            >
              <Label
                className="font-MulishBold"
                angle={-90}
                position="insideRight"
              >
                {t('Amount')}
              </Label>
            </YAxis>
          )}
          {['cards-sold', 'both'].includes(chartType) && (
            <Line
              yAxisId="left"
              stroke={GF_CORAL}
              strokeWidth={2}
              dataKey={'operations'}
            />
          )}
          {['amounts-loaded', 'both'].includes(chartType) && (
            <Line
              yAxisId="right"
              stroke={GF_DARK_BLUE}
              strokeWidth={2}
              dataKey={'amount'}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
