import React, { useEffect } from 'react';
import SelectField from './SelectField';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDesksList } from '../../actions/DesksListAction';

export default function SelectDeskField({ isDisabled, value, onChange }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loadingDesksList, receivedDesksList } = useSelector(
    (state) => state.desksList,
  );
  const { appCount } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (appCount === 0) {
      dispatch(getDesksList());
    }
  }, []);
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center   py-1">{t('Desk')}: </div>{' '}
      <SelectField
        id="deskSelectDropDown"
        isDisabled={isDisabled || loadingDesksList}
        placeholder={false}
        options={receivedDesksList}
        isSearchable={true}
        value={value}
        getOptionLabel={(option) => `${option.label}`}
        onChange={onChange}
      />
    </div>
  );
}
