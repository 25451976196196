/* eslint-disable import/no-anonymous-default-export */
import {
  RESET_MANAGE_SHOP_LIST,
  LOADING_MANAGE_SHOP_LIST,
  RECEIVED_MANAGE_SHOP_LIST,
  ERROR_MANAGE_SHOP_LIST,
  LOADING_SHOP_SIGN,
  RECEIVED_SHOP_SIGN,
  ERROR_SHOP_SIGN,
  LOADING_SHOP_CATEGORY,
  RECEIVED_SHOP_CATEGORY,
  ERROR_SHOP_CATEGORY,
  UPDATING_SHOP,
  SUCCESS_UPDATING,
  FAIL_UPDATING,
  RESET_UPDATE,
  SAVE_SHOP_OPERATION_ID,
} from '../actions/ManageShopActions';
import { randomString } from '../utils/Helper';

const initialState = {
  loadingShopSign: false,
  receivedShopSign: null,
  errorShopSign: null,

  loadingShopCategory: false,
  receivedShopCategory: null,
  errorShopCategory: null,

  loadingManageShopList: false,
  manageShopList: null,
  errorManageShopList: null,
  manageShopListMeta: null,

  updatingShop: false,
  successUpdate: null,
  failUpdate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_MANAGE_SHOP_LIST:
      return {
        ...state,
        ...initialState,
      };

    case LOADING_SHOP_SIGN:
      return {
        ...state,
        loadingShopSign: true,
        receivedShopSign: null,
        errorShopSign: null,
      };

    case RECEIVED_SHOP_SIGN:
      return {
        ...state,
        loadingShopSign: false,
        receivedShopSign: action.payload,
        errorShopSign: null,
      };

    case ERROR_SHOP_SIGN:
      return {
        ...state,
        loadingShopSign: false,
        receivedShopSign: null,
        errorShopSign: action.payload,
      };

    case LOADING_SHOP_CATEGORY:
      return {
        ...state,
        loadingShopCategory: true,
        receivedShopCategory: null,
        errorShopCategory: null,
      };

    case RECEIVED_SHOP_CATEGORY:
      return {
        ...state,
        loadingShopCategory: false,
        receivedShopCategory: action.payload,
        errorShopCategory: null,
      };

    case ERROR_SHOP_CATEGORY:
      return {
        ...state,
        loadingShopCategory: false,
        receivedShopCategory: null,
        errorShopCategory: action.payload,
      };

    case LOADING_MANAGE_SHOP_LIST:
      return {
        ...state,
        loadingManageShopList: true,
        manageShopList: null,
        errorManageShopList: null,
        manageShopListMeta: null,
      };

    case RECEIVED_MANAGE_SHOP_LIST:
      return {
        ...state,
        loadingManageShopList: false,
        manageShopList: action.payload.data,
        errorManageShopList: null,
        manageShopListMeta: action.payload.meta,
      };

    case ERROR_MANAGE_SHOP_LIST:
      return {
        ...state,
        loadingManageShopList: false,
        manageShopList: null,
        errorManageShopList: null,
        manageShopListMeta: action.payload,
      };

    case SAVE_SHOP_OPERATION_ID:
      return {
        ...state,
        saveShopOperationID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
      };

    case UPDATING_SHOP:
      return {
        ...state,
        updatingShop: true,
        successUpdate: null,
        failUpdate: null,
      };

    case SUCCESS_UPDATING:
      return {
        ...state,
        updatingShop: false,
        successUpdate: action.payload,
        failUpdate: null,
      };

    case FAIL_UPDATING:
      return {
        ...state,
        updatingShop: false,
        successUpdate: null,
        failUpdate: action.payload,
      };

    case RESET_UPDATE:
      return {
        ...state,
        updatingShop: false,
        successUpdate: null,
        failUpdate: null,
      };

    default:
      return state;
  }
}
