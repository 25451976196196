import React from 'react';
import { useTranslation } from 'react-i18next';
import TabItemContainer from '../../../UIComponents/TabItemContainer';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Fees() {
  const { t } = useTranslation();

  return (
    <TabItemContainer
      title={t('reports.finance.fees.title')}
      description={t('reports.finance.fees.description')}
    ></TabItemContainer>
  );
}
