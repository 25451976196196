import React, { useState, Component } from 'react';
import { GF_CORAL, GF_DARK_BLUE, GF_PERIWINKLE } from '../../utils/Constants';
import { components } from 'react-select';
import MySelect from '../../actions/MySelect';
import i18n from 'i18next';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  id: -1,
  name: i18n.t('All'),
};

const ValueContainer = ({ children, ...props }) => {
  //console.log(props);
  let [values, input] = children;
  if (values !== null) {
    if (values.length === props.options.length) {
      values = i18n.t('All');
    } else if (Array.isArray(values)) {
      const plural = values.length === 1 ? '' : 's';
      values = `${values.length} ${i18n.t('item')}${plural} ${i18n.t('selected')}`;
    }
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export default class DailyReportDeskMultiDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: allOption,
    };
  }
  customStyles = (value) => ({
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      fontWeight: '600',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: GF_PERIWINKLE,
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.id === value.id
          ? GF_DARK_BLUE
          : state.isFocused
            ? GF_CORAL
            : 'white',
      color:
        state.isSelected && state.data.id === value.id ? '#FFF' : GF_DARK_BLUE,
      '&:hover': {
        background: GF_CORAL,
        color: '#FFF',
      },
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: '250px',
      color: GF_DARK_BLUE,
    }),

    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
    }),
  });

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
    this.props.onChangeHandler(selected);
  };

  componentDidMount() {
    this.handleChange([...this.props.options]);
  }
  render() {
    return (
      <span
        className="d-inline-block"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet account(s)"
      >
        <MySelect
          id="deskDropDown"
          options={this.props.options}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            ValueContainer,
          }}
          onChange={this.handleChange}
          allowSelectAll={true}
          value={this.state.optionSelected}
          styles={this.customStyles(this.props.value)}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option.id}`}
        />
      </span>
    );
  }
}
