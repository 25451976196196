import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';

export default function DailyCard({ item, totalCardsSold }) {
  const percentage = useMemo(
    () => ((item.operations / totalCardsSold) * 100).toFixed(2),
    [totalCardsSold, item.operations],
  );
  const { t } = useTranslation();
  return (
    <div
      key={item.dayOfWeek}
      className="flex flex-col border rounded text-sm shadow-lg bg-white border-gfDarkSand"
    >
      <div className=" flex flex-col rounded p-2 bg-gfSand">
        <div className="flex 2xl:flex-col 2xl:justify-center items-center py-1">
          <div className="mr-2">{`${t('Total-loaded')}:`}</div>
          <div className="flex justify-end text-gfLightBlue font-MulishBold">
            <ShoppingCenterMoney value={item.amount} />
          </div>
        </div>
        <div className="flex py-1 2xl:flex-col 2xl:justify-center items-center">
          <div className="mr-2">{`${t('Cards-Sold')}:`}</div>
          <div className="font-MulishBold">{item.operations}</div>
        </div>
        <div className="flex py-1 2xl:flex-col 2xl:justify-center items-center">
          <div className="mr-2">{`${t('Sales')} %:`}</div>
          <div className="font-MulishBold">{percentage} %</div>
        </div>
      </div>
      <div className="flex justify-center items-center font-MulishBold py-2 text-gfLightBlue text-lg">
        {t(item.day_of_week)}
      </div>
    </div>
  );
}
