import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSubTitle } from '../../components/Modal';

export default function CardHolderDetails({ cardHolder }) {
  const { t } = useTranslation();

  const { first_name: firstName, last_name: lastName } = cardHolder;

  const fullName = useMemo(
    () => [firstName, lastName].filter(Boolean).join(' '),
    [firstName, lastName],
  );

  return (
    <div>
      <ModalSubTitle>{t('orders.card_holder.title')}</ModalSubTitle>
      <div className={'flex mt-4'} data-testid="cardholderInformation">
        <div className={'font-semibold truncate w-48 flex flex-col'}>
          <span>{t('card_holder.full_name')}:</span>
          <span>{t('card_holder.phone_number')}:</span>
        </div>
        <div className={'flex flex-col'}>
          <p data-testid={'fullName'}>{fullName || '-'}</p>
          <p
            data-testid={'phoneNumber'}
          >{`${cardHolder.phone_number || '-'}`}</p>
        </div>
      </div>
    </div>
  );
}
