/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ActiveIcon from '../../../assets/svg/ActiveIcon';
import DeclineIcon from '../../../assets/svg/DeclineIcon';
import { checkLoggedInUserPermissions } from '../../../utils/Helper';
import CreatePaymentMethod from './CreatePaymentMethod';
import { RESET_APP_COUNT } from '../../../actions/CommonActions';
import useAppConfig, { features } from '../../../hooks/useAppConfig';
import Table from '../../Table';
import useApiHook from '../../../hooks/useAPI';
import reportAPI from '../../../api/reportAPI';
import AlertHandler from '../../Alert/AlertHandler';
import ShoppingCenterMoney from '../../ShoppingCenterMoney';

export default function MVCViewList() {
  const dispatch = useDispatch();
  const { isFeatureEnabled } = useAppConfig();

  const [listMVCPaymentMethodsHandler, getMVCPaymentMethodsFullList] =
    useApiHook(reportAPI.getMVCPaymentMethodsFullList);

  useEffect(() => {
    getMVCPaymentMethodsFullList();
  }, []);

  const { isSuperAdmin } = useSelector((state) => state.auth);
  const [
    createPaymentMethodModalVisibility,
    setCreatePaymentMethodModalVisibility,
  ] = useState(false);
  const { t } = useTranslation();

  const createPaymentMethod = () => {
    setCreatePaymentMethodModalVisibility(true);
  };

  const paymentMethodModalClose = () => {
    setCreatePaymentMethodModalVisibility(false);
    getMVCPaymentMethodsFullList();
  };

  const mvcTokenHandler = () => {
    dispatch({ type: RESET_APP_COUNT });
  };

  function ActiveInactiveIcon({ isActive }) {
    return isActive ? (
      <ActiveIcon w={15} h={16} />
    ) : (
      <DeclineIcon w={15} h={16} />
    );
  }

  function PaymentMethod({ method }) {
    return (
      <Table.Row key={method.id}>
        <Table.Cell>
          <div className="flex justify-center items-center underline text-gfLightBlue">
            {checkLoggedInUserPermissions('card-information') ? (
              <Link
                onClick={mvcTokenHandler}
                className="underline"
                to={`/gift-cards/card-information?token=${method.mvc_token}`}
              >
                {method.mvc_token}
              </Link>
            ) : (
              method.mvc_token
            )}
          </div>
        </Table.Cell>
        <Table.Cell>{method.name}</Table.Cell>
        <Table.Cell>
          <div className="flex justify-center items-center font-MulishBold">
            {method.requires_transhandler ? t('Y') : t('N')}
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="flex justify-center items-center">
            <ActiveInactiveIcon isActive={method.is_promo_loads} />
          </div>
        </Table.Cell>
        {isSuperAdmin && (
          <Table.Cell>
            <div className="flex justify-center items-center">
              <ActiveInactiveIcon isActive={method.is_temp} />
            </div>
          </Table.Cell>
        )}
        {isSuperAdmin && (
          <Table.Cell>
            <div className="flex justify-center items-center">
              <ActiveInactiveIcon isActive={method.is_correction_mvc} />
            </div>
          </Table.Cell>
        )}
        {isSuperAdmin && (
          <Table.Cell>
            <div className="flex justify-center items-center">
              <ActiveInactiveIcon isActive={method.is_b2b} />
            </div>
          </Table.Cell>
        )}
        {isSuperAdmin && isFeatureEnabled(features.showPartnerMvc) && (
          <Table.Cell>
            <div className="flex justify-center items-center">
              <ActiveInactiveIcon isActive={method.is_partner} />
            </div>
          </Table.Cell>
        )}
        <Table.Cell>
          <div className="flex justify-center text-gfLightBlue font-MulishBold">
            <ShoppingCenterMoney value={method.balance} />
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex justify-center items-center underline text-gfLightBlue">
              {checkLoggedInUserPermissions('mvc-list') ? (
                <Link
                  onClick={mvcTokenHandler}
                  className="underline"
                  to={`/mvc-management/transaction-overview/${method.mvc_token}`}
                >
                  {t('View-transactions')}
                </Link>
              ) : (
                '-'
              )}
            </div>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      <div className="">
        {isSuperAdmin && (
          <div className="mb-6 flex justify-start w-full">
            <button
              onClick={createPaymentMethod}
              className="border focus:outline-none py-2 px-8  font-MulishBold bg-gfCoral text-gfDarkBlue hover:bg-opacity-75 rounded"
            >
              {t('Create-a-payment-method')}
            </button>
          </div>
        )}

        <AlertHandler
          handler={listMVCPaymentMethodsHandler}
          messages={{ empty: 'there-are-no-payment-methods-to-be-displayed' }}
        />

        <div>
          {!!listMVCPaymentMethodsHandler?.data?.data?.length &&
            listMVCPaymentMethodsHandler.success && (
              <Table>
                <Table.Head>
                  <tr>
                    <Table.Header>{t('MVC-Token')}</Table.Header>
                    <Table.Header>{t('Payment-method')}</Table.Header>
                    <Table.Header>{t('Payment-on-terminal')}</Table.Header>
                    <Table.Header>{t('Loads-promo-cards')}</Table.Header>
                    {isSuperAdmin && (
                      <Table.Header>{t('Is-Temporary')}</Table.Header>
                    )}
                    {isSuperAdmin && (
                      <Table.Header>{t('Is-Correction-MVC')}</Table.Header>
                    )}
                    {isSuperAdmin && <Table.Header>{t('Is-b2b')}</Table.Header>}
                    {isSuperAdmin &&
                      isFeatureEnabled(features.showPartnerMvc) && (
                        <Table.Header>{t('Is-partner')}</Table.Header>
                      )}
                    <Table.Header>{t('Balance')}</Table.Header>
                    <Table.Header>{t('Actions')}</Table.Header>
                  </tr>
                </Table.Head>
                <Table.Body>
                  {listMVCPaymentMethodsHandler?.data?.data?.map((method) => (
                    <PaymentMethod key={method.id} method={method} />
                  ))}
                </Table.Body>
              </Table>
            )}
        </div>
      </div>

      <div>
        <CreatePaymentMethod
          modalVisibility={createPaymentMethodModalVisibility}
          closeHandler={paymentMethodModalClose}
        />
      </div>
    </>
  );
}
