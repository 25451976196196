import { issuingAPI } from '../services/Axios';
import { APP_COUNT } from './CommonActions';
import { renameKeys } from '../utils/Helper';

export const PREFIX = '@deliveryAddress/';

export const FETCH_DELIVERY_ADDRESSES = `${PREFIX}FETCH_DELIVERY_ADDRESSES`;
export const FETCH_DELIVERY_ADDRESSES_SUCCESS = `${PREFIX}FETCH_DELIVERY_ADDRESSES_SUCCESS`;
export const FETCH_DELIVERY_ADDRESSES_ERROR = `${PREFIX}FETCH_DELIVERY_ADDRESSES_ERROR`;

export const CREATE_DELIVERY_ADDRESS = `${PREFIX}CREATE_DELIVERY_ADDRESS`;
export const CREATE_DELIVERY_ADDRESS_SUCCESS = `${PREFIX}CREATE_DELIVERY_ADDRESS_SUCCESS`;
export const CREATE_DELIVERY_ADDRESS_ERROR = `${PREFIX}CREATE_DELIVERY_ADDRESS_ERROR`;

export const SET_SELECTED_ADDRESS = `${PREFIX}SET_SELECTED_ADDRESS`;

export const UPDATE_DELIVERY_ADDRESS = `${PREFIX}UPDATE_DELIVERY_ADDRESS`;
export const UPDATE_DELIVERY_ADDRESS_SUCCESS = `${PREFIX}UPDATE_DELIVERY_ADDRESS_SUCCESS`;
export const UPDATE_DELIVERY_ADDRESS_ERROR = `${PREFIX}UPDATE_DELIVERY_ADDRESS_ERROR`;

export const SET_DEAFULT_DELIVERY_ADDRESS = `${PREFIX}SET_DEAFULT_DELIVERY_ADDRESS`;
export const SET_DEAFULT_DELIVERY_ADDRESS_SUCCESS = `${PREFIX}SET_DEAFULT_DELIVERY_ADDRESS_SUCCESS`;
export const SET_DEAFULT_DELIVERY_ADDRESS_ERROR = `${PREFIX}SET_DEAFULT_DELIVERY_ADDRESS_ERROR`;

export const DELETE_DELIVERY_ADDRESS = `${PREFIX}DELETE_DELIVERY_ADDRESS`;
export const DELETE_DELIVERY_ADDRESS_SUCCESS = `${PREFIX}DELETE_DELIVERY_ADDRESS_SUCCESS`;
export const DELETE_DELIVERY_ADDRESS_ERROR = `${PREFIX}DELETE_DELIVERY_ADDRESS_ERROR`;

export const REFRESH_ADDRESS_LIST = `${PREFIX}REFRESH_ADDRESS_LIST`;

export const RESET_CREATE = `${PREFIX}RESET_CREATE`;

export const ORDER_CARDS_ADDRESS_LIST = `${PREFIX}ORDER_CARDS_ADDRESS_LIST`;

export const fetchDeliveryAddresses = (PAGE_NAME) => (dispatch, getState) => {
  dispatch({ type: FETCH_DELIVERY_ADDRESSES });

  dispatch({ type: APP_COUNT });

  const { tagID } = getState().tag;

  return issuingAPI
    .get(`tag/${tagID}/delivery-address`)
    .then((success) => {
      let defaultAddress = null;
      defaultAddress = success.data.data.find(
        (address) => address.default === true && address.active === true,
      );
      if (defaultAddress !== null) {
        dispatch({ type: SET_SELECTED_ADDRESS, payload: defaultAddress });
      } else {
        dispatch({ type: SET_SELECTED_ADDRESS, payload: success.data.data[0] });
      }
      dispatch({
        type: FETCH_DELIVERY_ADDRESSES_SUCCESS,
        payload: success.data.data,
      });
      if (PAGE_NAME === 'orderCards') {
        dispatch({
          type: ORDER_CARDS_ADDRESS_LIST,
          payload: success.data.data,
        });
      }

      return success;
    })
    .catch((error) => {
      dispatch({ type: FETCH_DELIVERY_ADDRESSES_ERROR });
      throw error;
    });
};

export const createDeliveryAddress = (data) => (dispatch, getState) => {
  dispatch({ type: CREATE_DELIVERY_ADDRESS });

  const { tagID } = getState().tag;

  return issuingAPI
    .post(`tag/${tagID}/delivery-address`, { ...data })
    .then((success) => {
      dispatch({
        type: CREATE_DELIVERY_ADDRESS_SUCCESS,
        payload: success.data,
      });
      dispatch(refreshDeliveryAddresses());
      return success;
    })
    .catch((error) => {
      dispatch({ type: CREATE_DELIVERY_ADDRESS_ERROR });
      throw error;
    });
};

export const setSelectedAddress = (address) => (dispatch) => {
  dispatch({ type: SET_SELECTED_ADDRESS, payload: address });
};

export const setDefaultAddress =
  (addressID, addressActive, addressDefault) => (dispatch, getState) => {
    dispatch({ type: SET_DEAFULT_DELIVERY_ADDRESS });

    const { tagID } = getState().tag;

    return issuingAPI
      .put(`tag/${tagID}/delivery-address/${addressID}`, {
        active: addressActive,
        default: addressDefault,
      })
      .then((success) => {
        dispatch({
          type: SET_DEAFULT_DELIVERY_ADDRESS_SUCCESS,
          payload: success.data,
        });
        dispatch(refreshDeliveryAddresses());
        return success;
      })
      .catch((error) => {
        dispatch({ type: SET_DEAFULT_DELIVERY_ADDRESS_ERROR });
        throw error;
      });
  };

export const refreshDeliveryAddresses = () => (dispatch, getState) => {
  dispatch({ type: REFRESH_ADDRESS_LIST });

  const { tagID } = getState().tag;

  return issuingAPI
    .get(`tag/${tagID}/delivery-address`)
    .then((success) => {
      let defaultAddress = null;
      defaultAddress = success.data.data.find(
        (address) => address.default === true,
      );
      if (defaultAddress !== null) {
        dispatch({ type: SET_SELECTED_ADDRESS, payload: defaultAddress });
      } else {
        dispatch({ type: SET_SELECTED_ADDRESS, payload: success.data.data[0] });
      }
      dispatch({
        type: FETCH_DELIVERY_ADDRESSES_SUCCESS,
        payload: success.data.data,
      });

      return success;
    })
    .catch((error) => {
      dispatch({ type: FETCH_DELIVERY_ADDRESSES_ERROR });
      throw error;
    });
};

export const deleteDeliveryAddress = (addressID) => (dispatch, getState) => {
  dispatch({ type: DELETE_DELIVERY_ADDRESS });

  const { tagID } = getState().tag;

  return issuingAPI
    .delete(`tag/${tagID}/delivery-address/${addressID}`)
    .then((success) => {
      dispatch(refreshDeliveryAddresses());
      dispatch({
        type: DELETE_DELIVERY_ADDRESS_SUCCESS,
        payload: success.data,
      });
      return success;
    })
    .catch((error) => {
      dispatch({ type: DELETE_DELIVERY_ADDRESS_ERROR });
      throw error;
    });
};

export const updateDeliveryAddress =
  (addressID, address) => (dispatch, getState) => {
    dispatch({ type: UPDATE_DELIVERY_ADDRESS });

    const { tagID } = getState().tag;
    address = renameKeys({ zip_code: 'zipcode' }, address);

    const data = address;

    return issuingAPI
      .put(`tag/${tagID}/delivery-address/${addressID}`, { ...data })
      .then((success) => {
        dispatch(refreshDeliveryAddresses());
        dispatch({
          type: UPDATE_DELIVERY_ADDRESS_SUCCESS,
          payload: success.data,
        });
        return success;
      })
      .catch((error) => {
        dispatch({ type: UPDATE_DELIVERY_ADDRESS_ERROR });
        throw error;
      });
  };
