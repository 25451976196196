import { issuingAPI } from '../../services/Axios';
import { exportExcelFile } from '../../utils/Helper';
import {
  ERROR_CSV_REPORT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
} from '../CommonActions';

export const PREFIX = '@myPortalCardsLoad/';

export const LOADING_MYPORTAL_CARDS_LOAD = `${PREFIX}LOADING_MYPORTAL_CARDS_LOAD`;
export const RECEIVED_MYPORTAL_CARDS_LOAD = `${PREFIX}RECEIVED_MYPORTAL_CARDS_LOAD`;
export const ERROR_MYPORTAL_CARDS_LOAD = `${PREFIX}ERROR_MYPORTAL_CARDS_LOAD`;
export const RESET_MYPORTAL_CARDS_LOAD = `${PREFIX}RESET_MYPORTAL_CARDS_LOAD`;

export const LOADING_MYPORTAL_CARDS_LOAD_SUMMARY = `${PREFIX}LOADING_MYPORTAL_CARDS_LOAD_SUMMARY`;
export const RECEIVED_MYPORTAL_CARDS_LOAD_SUMMARY = `${PREFIX}RECEIVED_MYPORTAL_CARDS_LOAD_SUMMARY`;
export const ERROR_MYPORTAL_CARDS_LOAD_SUMMARY = `${PREFIX}ERROR_MYPORTAL_CARDS_LOAD_SUMMARY`;

export const getMyPortalCardsLoads =
  (fromDate, toDate, page, user, desk) => (dispatch, getState) => {
    const { tagID } = getState().tag;

    const params = {
      from_date: fromDate,
      to_date: toDate,
      page,
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    };

    if (user.id !== null) {
      Object.assign(params, { user_ids: [user.id] });
    }

    if (desk.id !== null) {
      Object.assign(params, { desk_ids: [desk.id] });
    }

    return issuingAPI
      .get(`/tag/${tagID}/card-loads`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_MYPORTAL_CARDS_LOAD, payload: success.data });
        return success;
      })
      .catch((error) => {
        dispatch({ type: ERROR_MYPORTAL_CARDS_LOAD, payload: error });
        return error;
      });
  };

export const getMyPortalCardsLoadsSummary =
  (fromDate, toDate, user, desk) => (dispatch, getState) => {
    dispatch({ type: LOADING_MYPORTAL_CARDS_LOAD_SUMMARY });

    const { tagID } = getState().tag;

    const params = {
      from_date: fromDate,
      to_date: toDate,
    };

    if (user.id !== null) {
      Object.assign(params, { user_ids: [user.id] });
    }

    if (desk.id !== null) {
      Object.assign(params, { desk_ids: [desk.id] });
    }

    return issuingAPI
      .get(`/tag/${tagID}/card-loads/summary`, { params })
      .then((success) => {
        dispatch({
          type: RECEIVED_MYPORTAL_CARDS_LOAD_SUMMARY,
          payload: success.data,
        });
        return success;
      })
      .catch((error) => {
        dispatch({ type: ERROR_MYPORTAL_CARDS_LOAD_SUMMARY, payload: error });
        return error;
      });
  };

export const getMyPortalCardsLoadExcelExport =
  (fromDate, toDate, user, desk) => (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT });

    const { tagID } = getState().tag;
    const params = {
      from_date: fromDate,
      to_date: toDate,
    };

    if (user.id !== null) {
      Object.assign(params, { user_ids: [user.id] });
    }

    if (desk.id !== null) {
      Object.assign(params, { desk_ids: [desk.id] });
    }

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const fileName = `Cards-Load-Report-${fromDate}-${toDate}`;

    return issuingAPI
      .get(`tag/${tagID}/card-loads/stream`, {
        responseType: 'blob',
        headers,
        params,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT });
        exportExcelFile(success.data, fileName);
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error });
        return error;
      });
  };
