import { issuingAPI } from '../services/Axios';
import { APP_COUNT, RESET_APP_COUNT } from './CommonActions';

// CARD INFORMATION
export const LOADING_CARD_INFORMATION = 'LOADING_CARD_INFORMATION';
export const RECEIVED_CARD_INFORMATION = 'RECEIVED_CARD_INFORMATION';
export const ERROR_CARD_INFORMATION = 'ERROR_CARD_INFORMATION';

// CARD TRANSACTIONS
export const LOADING_CARD_TRANSACTIONS = 'LOADING_CARD_TRANSACTIONS';
export const RECEIVED_CARD_TRANSACTION_INFO = 'RECEIVED_CARD_TRANSACTION_INFO';
export const ERROR_CARD_TRANSACTION_INFO = 'ERROR_CARD_TRANSACTION_INFO';

// CARD BLOCK / UNBLOCK
export const LOADING_CARD_BLOCK_UNBLOCK = 'LOADING_CARD_BLOCK_UNBLOCK';
export const SUCCESS_CARD_BLOCK_UNBLOCK = 'SUCCESS_CARD_BLOCK_UNBLOCK';
export const FAIL_CARD_BLOCK_UNBLOCK = 'FAIL_CARD_BLOCK_UNBLOCK';

export const RESET_CARD_INFO = 'RESET_CARD_INFO';
export const SET_FOCUS_SELECTED = 'SET_FOCUS_SELECTED';

export const getCardInformation = (token) => (dispatch, getState) => {
  dispatch({ type: LOADING_CARD_INFORMATION });

  dispatch({ type: APP_COUNT });

  const { tagID } = getState().tag;

  return issuingAPI
    .get(`tag/${tagID}/card/${token}`)
    .then((success) => {
      dispatch({ type: RECEIVED_CARD_INFORMATION, payload: success.data });
      dispatch(getCardTransactionInformation(token, success.data.is_mvc, 1));
    })
    .catch((error) => {
      dispatch({ type: ERROR_CARD_INFORMATION, payload: error });
      dispatch({ type: RESET_APP_COUNT });
      return error;
    });
};

export const getCardTransactionInformation =
  (token, iSMVC, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_TRANSACTIONS });

    const { tagID } = getState().tag;

    const params = {
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
      page,
    };

    issuingAPI
      .get(
        `/tag/${tagID}/card/${token}/${iSMVC ? 'mvc-transfer' : 'transaction'}`,
        { params },
      )
      .then((success) => {
        dispatch({
          type: RECEIVED_CARD_TRANSACTION_INFO,
          payload: success.data,
        });
        document.body.dispatchEvent(
          new Event(SET_FOCUS_SELECTED, { bubbles: true, cancelable: true }),
        );
        dispatch({ type: RESET_APP_COUNT });
      })
      .catch((error) => {
        dispatch({ type: ERROR_CARD_TRANSACTION_INFO, payload: error });
        dispatch({ type: RESET_APP_COUNT });
        return error;
      });
  };

export const blockCardByToken =
  (token, description) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_BLOCK_UNBLOCK });

    const { tagID } = getState().tag;

    const params = {
      description,
    };

    issuingAPI
      .post(`/tag/${tagID}/card/${token}/block`, { ...params })
      .then((success) => {
        dispatch({ type: SUCCESS_CARD_BLOCK_UNBLOCK, payload: true });
        window.location.reload();
      })
      .catch((error) => {
        dispatch({ type: FAIL_CARD_BLOCK_UNBLOCK, payload: error });
        return error;
      });
  };

export const unblockCardByToken =
  (token, description) => (dispatch, getState) => {
    dispatch({ type: LOADING_CARD_BLOCK_UNBLOCK });

    const { tagID } = getState().tag;

    const params = {
      description,
    };

    issuingAPI
      .post(`/tag/${tagID}/card/${token}/unblock`, { ...params })
      .then((success) => {
        dispatch({ type: SUCCESS_CARD_BLOCK_UNBLOCK, payload: true });
        window.location.reload();
      })
      .catch((error) => {
        dispatch({ type: FAIL_CARD_BLOCK_UNBLOCK, payload: error });
        return error;
      });
  };

// CARD TRANSACTIONS
export const LOADING_SEARCH_CARD_LIST = 'LOADING_SEARCH_CARD_LIST';
export const RECEIVED_SEARCH_CARD_LIST = 'RECEIVED_SEARCH_CARD_LIST';
export const ERROR_SEARCH_CARD_LIST = 'ERROR_SEARCH_CARD_LIST';
export const getCardsListByPhoneNumber =
  (phoneNumber, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_SEARCH_CARD_LIST });
    dispatch({ type: APP_COUNT });

    const { tagID } = getState().tag;
    const params = {
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
      phone_number: `+${phoneNumber}`,
      page,
    };

    return issuingAPI
      .get(`tag/${tagID}/search/card/by-phone`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_SEARCH_CARD_LIST, payload: success.data });
      })
      .catch((error) => {
        dispatch({ type: ERROR_SEARCH_CARD_LIST, payload: error });
      });
  };
export const RESET_SEARCH_CARD_LIST = 'RESET_SEARCH_CARD_LIST';
export const CHANGE_SEARCH_TYPE = 'CHANGE_SEARCH_TYPE';
export const RESET_SEARCH_TYPE = 'RESET_SEARCH_TYPE';
export const CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER';
