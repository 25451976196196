import React, { useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import CloseIcon from '../../../../assets/svg/CloseIcon';

ReactModal.setAppElement('#root');

export default function PrintPreview({ modalVisibility, closeHandler }) {
  const { t } = useTranslation();
  const componentRef = useRef();
  const { tagCurrency } = useSelector((state) => state.tag);
  const { cardInformation, cardTransactionInfo } = useSelector(
    (state) => state.cardInformation,
  );

  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <ReactModal
      isOpen={modalVisibility}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col max-w-xl mx-auto bg-gray-100">
        <div className="flex justify-between p-5">
          <button
            className="focus:outline-none bg-gfCoral text-base rounded font-MulishBold hover:bg-opacity-75 px-4 py-1"
            onClick={printReceipt}
          >
            {t('Print')}
          </button>
          <button onClick={() => closeHandler(!modalVisibility)}>
            <CloseIcon />
          </button>
        </div>
        <div
          ref={componentRef}
          style={{
            maxWidth: '165px',
            margin: 'auto',
            backgroundColor: 'white',
            color: 'black',
            fontSize: '10px',
          }}
        >
          {cardInformation && (
            <div className="mb-5">
              <div
                style={{
                  textAlign: 'left',
                  fontSize: '14px',
                  paddingTop: '30px',
                  paddingBottom: '10px',
                  fontWeight: 'bolder',
                }}
              >
                {t('Transactions')}
              </div>

              <div
                style={{
                  display: 'flex',
                  paddingTop: '10px',
                  paddingBottom: '3px',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>{`${t('Token')}: `}</span>
                <span style={{ marginLeft: '4px' }}>
                  {cardInformation.token}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  paddingTop: '3px',
                  paddingBottom: '3px',
                }}
              >
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${t('Card-balance')}: `}</span>
                <div style={{ marginLeft: '4px' }}>
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={cardInformation.balance_available}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                </div>
              </div>

              <div
                style={{
                  textAlign: 'left',
                  paddingTop: '3px',
                  paddingBottom: '3px',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    marginRight: '4px',
                  }}
                >{`${t('Expiry-date')}: `}</span>
                <span style={{ display: 'inline-block' }}>
                  {cardInformation.expiration_date}
                </span>
              </div>

              <div
                style={{
                  textAlign: 'left',
                  paddingTop: '3px',
                  paddingBottom: '3px',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    marginRight: '4px',
                  }}
                >{`${t('Card-brand')}: `}</span>
                <span style={{ display: 'inline-block' }}>
                  {cardInformation.card_brand.name}
                </span>
              </div>
            </div>
          )}

          {cardTransactionInfo &&
            cardTransactionInfo.length !== 0 &&
            cardTransactionInfo.map((trx) => (
              <div
                key={trx.id}
                className="flex flex-col"
                style={{
                  textAlign: 'left',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                <span style={{ display: 'inline-block' }}>{trx.date}</span>
                <div
                  style={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    marginRight: '4px',
                  }}
                >
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={trx.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                </div>
                <span style={{ display: 'inline-block' }}>{`${
                  trx.status ? String(trx.status).toUpperCase() : ''
                }`}</span>
              </div>
            ))}
        </div>
      </div>
    </ReactModal>
  );
}
