import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import CloseIcon from '../../../assets/svg/CloseIcon';
import FailAlert from '../../Alert/FailAlert';
import SuccessAlert from '../../Alert/SuccessAlert';
import {
  generateMVCInvoice,
  RESET_MVC_LOAD,
} from '../../../actions/MVCLoadAction';

ReactModal.setAppElement('#root');

export default function GenerateMVCInvoiceRequest({
  modalVisibility,
  closeHandler,
  mvcToken,
  selectedTag,
}) {
  const { register, handleSubmit, errors, reset } = useForm();
  const dispatch = useDispatch();
  const {
    loadingGenerateMVCInvoice,
    errorGenerateMVCInvoice,
    successGenerateMVCInvoice,
  } = useSelector((state) => state.mvcLoad);
  const { t } = useTranslation();
  const { selectedLanguage } = useSelector((state) => state.language);

  const onSubmit = (data) => {
    if (selectedLanguage) {
      Object.assign(data, { lang: selectedLanguage.value });
    }
    dispatch(generateMVCInvoice(data));
  };
  return (
    <ReactModal
      isOpen={modalVisibility}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-6 border max-w-xl rounded shadow-md bg-white text-gfDarkBlue font-MulishRegular">
        <div className="modal-content py-4 text-left px-4 text-base text-gfDarkBlue">
          {/* <!--Title--> */}
          <div className="flex justify-between items-center ">
            <div className="font-semibold">{t('invoice-request')}</div>
            <button
              onClick={() => {
                dispatch({ type: RESET_MVC_LOAD });
                closeHandler(!modalVisibility);
              }}
            >
              <CloseIcon />
            </button>
          </div>
          {/* <!--Body--> */}
          {errorGenerateMVCInvoice === null &&
          successGenerateMVCInvoice === null ? (
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col my-5 text-sm  ">
                <div className="mb-1">{t('name-entity-invoice')}:</div>
                <div className="flex flex-row">
                  <input
                    name={'nameEntityInvoice'}
                    ref={register({ required: true })}
                    defaultValue={selectedTag.name}
                    placeholder={t('name-entity-invoice')}
                    className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>
                {errors.nameEntityInvoice && (
                  <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                )}
                <div className="mb-1 mt-4">{t('Street-Name')}:</div>
                <div className="flex flex-row">
                  <input
                    name={'streetName'}
                    ref={register({ required: true })}
                    defaultValue={selectedTag.address}
                    placeholder={t('Street-Name')}
                    className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>
                {errors.streetName && (
                  <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                )}
                <div className="mb-1 mt-4">{t('postal-code')}:</div>
                <div className="flex flex-row">
                  <input
                    name={'postalCode'}
                    ref={register({
                      required: true,
                      pattern: /^[A-Za-z0-9]+$/,
                    })}
                    defaultValue={selectedTag.zip}
                    placeholder={t('postal-code')}
                    className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>
                {errors.postalCode && errors.postalCode.type === 'required' && (
                  <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                )}
                {errors.postalCode && errors.postalCode.type === 'pattern' && (
                  <p className="text-xs text-gfCoral p-1">
                    {t('Please-enter-a-alphanumeric-value')}
                  </p>
                )}
                <div className="flex flex-row mt-4">
                  <div className="flex flex-col w-full mr-4">
                    <div className="mb-1">{t('City')}:</div>
                    <div className="flex flex-row">
                      <input
                        name={'city'}
                        ref={register({
                          required: true,
                          pattern: /^[a-zA-Z -]+$/,
                        })}
                        defaultValue={selectedTag.city}
                        placeholder={t('City')}
                        className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                      />
                    </div>
                    {errors.city && errors.city.type === 'required' && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Required')}
                      </p>
                    )}
                    {errors.city && errors.city.type === 'pattern' && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Please-enter-a-alphabetical-characters')}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="mb-1">{t('Country')}:</div>
                    <div className="flex flex-row">
                      <input
                        name={'country'}
                        ref={register({ required: true })}
                        defaultValue={selectedTag.country_code}
                        placeholder={t('Country')}
                        readOnly={true}
                        className="bg-gray-200 h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                      />
                    </div>
                    {errors.country && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Required')}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-1 mt-4">{t('vat-number')}:</div>
                <div className="flex flex-row">
                  <input
                    name={'vatNumber'}
                    ref={register({ required: true })}
                    placeholder={t('vat-number')}
                    className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>
                {errors.vatNumber && (
                  <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                )}
                <div className="mb-1 mt-4">{t('mvc-to-be-loaded')}:</div>
                <div className="flex flex-row">
                  <input
                    name={'mvc'}
                    ref={register({ required: true })}
                    defaultValue={mvcToken}
                    placeholder={t('mvc-to-be-loaded')}
                    autoComplete="false"
                    readOnly={true}
                    className="bg-gray-200 h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>
                {errors.mvc && (
                  <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                )}
                <div className="flex flex-row mt-4">
                  <div className="flex flex-col w-full mr-4">
                    <div className="mb-1">{t('amount-to-be-loaded')}:</div>
                    <div className="flex flex-row">
                      <input
                        name={'amount'}
                        ref={register({
                          required: true,
                          pattern: /^\d+(?:[.,]\d+)?$/,
                        })}
                        placeholder={t('amount-to-be-loaded')}
                        className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                      />
                    </div>
                    {errors.amount && errors.amount.type === 'required' && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Required')}
                      </p>
                    )}
                    {errors.amount && errors.amount.type === 'pattern' && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Please-enter-a-numeric-value')}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="mb-1">{t('Currency')}:</div>
                    <div className="flex flex-row">
                      <input
                        name={'currency'}
                        ref={register({ required: true })}
                        defaultValue={selectedTag.currency}
                        placeholder={t('Currency')}
                        autoComplete="false"
                        readOnly={true}
                        className="bg-gray-200 h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                      />
                    </div>
                    {errors.currency && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Required')}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-1 mt-4">
                  {t('email-address-invoice-sent')}:
                </div>
                <div className="flex flex-row">
                  <input
                    name={'email'}
                    type="text"
                    ref={register({
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    placeholder={t('email-address-invoice-sent')}
                    className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>
                {errors.email && errors.email.type === 'required' && (
                  <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                )}
                {errors.email && errors.email.type === 'pattern' && (
                  <p className="text-xs text-gfCoral p-1">
                    {t('Please-enter-valid-email')}
                  </p>
                )}

                <div className="mb-1 mt-4">{t('po-number-title')}:</div>
                <div className="flex flex-row">
                  <input
                    name={'poNumber'}
                    type="text"
                    ref={register({ required: false })}
                    placeholder={t('po-number-placeholder')}
                    className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>

                <div className="mb-1 mt-4">
                  {t('additional-comment-title')}:
                </div>
                <div className="flex flex-row">
                  <input
                    name={'additionalComment'}
                    type="text"
                    ref={register({ required: false })}
                    placeholder={t('additional-comment-placeholder')}
                    className="h-8 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </div>

                <div className="flex flex-row justify-end mt-4">
                  <button
                    type="submit"
                    className="border rounded  py-2 px-8 text-gfDarkBlue w-32 bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium"
                  >
                    {t('Confirm')}
                    {loadingGenerateMVCInvoice && (
                      <ButtonLoading color={'#4A5568'} />
                    )}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className="flex flex-col my-5 text-sm justify-center">
              {successGenerateMVCInvoice && (
                <SuccessAlert
                  title="Success"
                  message={`${t(successGenerateMVCInvoice.message)} ${successGenerateMVCInvoice?.payload?.email}`}
                />
              )}
              {errorGenerateMVCInvoice && (
                <FailAlert message={t(errorGenerateMVCInvoice.message)} />
              )}
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
