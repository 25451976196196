import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchShops,
  RESET_SHOPS,
  setShopStatus,
} from '../actions/Shops/ListOfShopsActions';
import { APP_COUNT } from '../actions/CommonActions';
import { wait } from '../utils';
import { issuingAPI } from '../services/Axios';
import useApiHook from './useAPI';

function useShops({ defaultParams = {} }) {
  const dispatch = useDispatch();
  const { appCount } = useSelector((state) => state.commonReducer);
  const { data, loading, fetched, error, meta } = useSelector(
    (state) => state.listOfShops.shops,
  );

  const countByStatus = useSelector((state) => state.listOfShops.countByStatus);

  const fetch = useCallback(
    (params = {}) => {
      dispatch({ type: APP_COUNT });
      dispatch(fetchShops(params));
    },
    [fetchShops, dispatch],
  );

  useEffect(() => {
    if (appCount === 0) {
      dispatch({ type: RESET_SHOPS });
      fetch(defaultParams);
    }
  }, [appCount, fetch, dispatch, defaultParams]);

  const findById = useCallback(
    (id) => data.find((item) => item.id === id),
    [data],
  );

  return {
    shops: data,
    fetch,
    isLoading: loading,
    isFetched: fetched,
    isError: error !== null,
    error,
    countByStatus,
    pagination: {
      isPaginated: meta.total > meta.per_page,
      currentPage: meta.current_page || 1,
      totalPages: meta.total / meta.per_page,
    },
    findById,
  };
}

function useExportShops() {
  const { tagCardBrands } = useSelector((state) => state.tag);

  const fetchExport = useCallback(
    async (params) =>
      await issuingAPI.get(`/card-brand/${tagCardBrands}/referenced-shops`, {
        params,
        responseType: 'blob',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      }),
    [tagCardBrands],
  );

  const [result, fetch] = useApiHook(fetchExport);

  return {
    fetch,
    isLoading: result.loading,
  };
}

function useShop() {
  const { tagCardBrands } = useSelector((state) => state.tag);
  const dispatch = useDispatch();

  const update = useCallback(
    async (id, payload) => {
      return issuingAPI.patch(
        `/card-brand/${tagCardBrands}/referenced-shops/${id}`,
        payload,
      );
    },
    [tagCardBrands],
  );

  const updateAcceptanceStatus = useCallback(
    (shop, status) => {
      return dispatch(setShopStatus(shop.id, status));
    },
    [dispatch],
  );

  return {
    update,
    updateAcceptanceStatus,
  };
}

export { useShops, useShop, useExportShops };
