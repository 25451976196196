import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_ONE_CARD,
  checkValidityOfFromToken,
  checkValidityOfToToken,
  CLEAR_CARD_ERRORS,
  FROM_TOKEN_TEXT_COLOR,
  getCardRange,
  RECEIVED_FROM_TOKEN_VALIDITY,
  RECEIVED_TO_TOKEN_VALIDITY,
  REMOVE_CARD_FROM_LIST,
  SET_AMOUNT,
  SET_CARD_AMOUNT,
  SET_FROM_TOKEN,
  SET_TO_TOKEN,
  SET_TOTAL_AMOUNT,
  TO_TOKEN_TEXT_COLOR,
  RESET_ADD_CARD_FIELDS,
} from '../../actions/LoadCardActions';
import ButtonLoading from '../../assets/svg/ButtonLoading';
import CloseIcon from '../../assets/svg/CloseIcon';
import { isNumeric } from '../../utils/Helper';
import FailAlert from '../Alert/FailAlert';
import { ENTER_KEY, GF_DARK_BLUE } from '../../utils/Constants';

export default function AddCard({ totalRows, amountsArray }) {
  const [validation, setValidation] = useState('');

  const {
    cards,
    fromTokenValidity,
    toTokenValidity,
    errorCardRange,
    loadingCardRange,
    isCardRangeDirty,
    fromToken,
    toToken,
    tokenAmount,
  } = useSelector((state) => state.loadCard);
  const { selectedTag } = useSelector((state) => state.tag);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fromTokenTextColor, toTokenTextColor } = useSelector(
    (state) => state.loadCard,
  );

  const fromTokenChangeHandler = (event) => {
    if (event.currentTarget.value !== '') {
      dispatch({ type: FROM_TOKEN_TEXT_COLOR, payload: 'text-gfDarkBlue' });
      dispatch({ type: CLEAR_CARD_ERRORS });
      dispatch({ type: SET_FROM_TOKEN, payload: event.currentTarget.value });
      if (
        isNumeric(event.currentTarget.value) &&
        event.currentTarget.value.length === 9
      ) {
        dispatch(checkValidityOfFromToken(event.currentTarget.value));
      }
    } else {
      setValidation('');
      dispatch({ type: RECEIVED_FROM_TOKEN_VALIDITY, payload: false });
      dispatch({ type: SET_FROM_TOKEN, payload: '' });
      dispatch({ type: FROM_TOKEN_TEXT_COLOR, payload: 'text-gfDarkBlue' });
    }
  };

  const toTokenChangeHandler = (event) => {
    if (event.currentTarget.value !== '') {
      dispatch({ type: TO_TOKEN_TEXT_COLOR, payload: 'text-gfDarkBlue' });
      dispatch({ type: CLEAR_CARD_ERRORS });
      dispatch({ type: SET_TO_TOKEN, payload: event.currentTarget.value });
      if (
        isNumeric(event.currentTarget.value) &&
        event.currentTarget.value.length === 9
      ) {
        dispatch(checkValidityOfToToken(event.currentTarget.value));
      }
    } else {
      dispatch({ type: RECEIVED_TO_TOKEN_VALIDITY, payload: false });
      dispatch({ type: SET_TO_TOKEN, payload: '' });
      dispatch({ type: TO_TOKEN_TEXT_COLOR, payload: 'text-gfDarkBlue' });
    }
  };

  const amountChangeHandler = (event) => {
    dispatch({ type: SET_AMOUNT, payload: event.target.value });
  };

  const checkTokenExist = (token) => {
    const { length } = cards;
    for (let i = 0; i < length; i++) {
      if (cards[i].token === token) return true;
    }
    return false;
  };

  const checkEnteredTokenAmount = (amount) => {
    return amountsArray.includes(parseInt(amount));
  };

  const addCardToListHandler = () => {
    if (tokenAmount === '') {
      setValidation(t('Amount-cannot-be-empty'));
    } else if (
      tokenAmount < selectedTag.min_load ||
      tokenAmount > selectedTag.max_load
    ) {
      setValidation(
        t(
          'Cards-are-loadable-between-Please-select-another-amount-and-try-again',
          {
            min: selectedTag.min_load,
            max: selectedTag.max_load,
            currency: selectedTag.currency,
          },
        ),
      );
    } else if (checkTokenExist(fromToken)) {
      setValidation(t('Token-already-exist'));
    } else if (totalRows && cards.length >= totalRows) {
      setValidation(t('cannot-add-more-than-cards', { totalRows }));
    } else if (
      amountsArray &&
      !checkEnteredTokenAmount(parseInt(tokenAmount))
    ) {
      setValidation(
        t('amount-should-be-one-of-these', {
          amountsArray: amountsArray.toString(),
        }),
      );
    } else {
      setValidation('');

      if (fromTokenValidity && tokenAmount !== '' && toToken === '') {
        const card = {
          token: fromToken,
          valid: fromTokenValidity,
          amount: parseFloat(tokenAmount).toFixed(2),
        };
        dispatch({ type: ADD_ONE_CARD, payload: card });
        dispatch({ type: SET_TOTAL_AMOUNT });
        dispatch({ type: RESET_ADD_CARD_FIELDS });
      }

      if (fromTokenValidity && toTokenValidity && toToken !== '') {
        dispatch({ type: SET_CARD_AMOUNT, payload: tokenAmount });
        dispatch(getCardRange(fromToken, toToken));
        dispatch({ type: RESET_ADD_CARD_FIELDS });
      }
    }
  };

  const removeTokenFromList = (event) => {
    dispatch({ type: REMOVE_CARD_FROM_LIST, payload: event.currentTarget.id });
    dispatch({ type: SET_TOTAL_AMOUNT });
  };

  const invalidChars = ['-', '+', 'e'];

  const preventNonNumeric = (event) => {
    if (event.which === 38 || event.which === 40) {
      event.preventDefault();
    }
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const keyPressHandler = (event) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      addCardToListHandler();
    }
  };

  const keyPressHandlerTOtoken = (event) => {
    if (
      event.key === ENTER_KEY &&
      fromToken !== '' &&
      fromTokenValidity &&
      toToken !== '' &&
      toTokenValidity &&
      tokenAmount !== ''
    ) {
      event.preventDefault();
      addCardToListHandler();
    }
  };

  const keyPressHandlerFROMtoken = (event) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      if (
        fromToken !== '' &&
        fromTokenValidity &&
        toToken === '' &&
        tokenAmount !== ''
      ) {
        addCardToListHandler();
      } else if (
        fromToken !== '' &&
        fromTokenValidity &&
        toToken !== '' &&
        toTokenValidity &&
        tokenAmount !== ''
      ) {
        addCardToListHandler();
      }
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <input
          id="tokenInput"
          autoFocus
          type="text"
          maxLength="9"
          className={`
                    text-center
                    border
                    focus:outline-none
                    focus:border-gfPeriwinkle
                    h-10
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 
                    ${fromTokenTextColor} `}
          value={fromToken}
          onChange={fromTokenChangeHandler}
          placeholder={t('TOKEN')}
          onKeyPress={(event) => {
            keyPressHandlerFROMtoken(event);
          }}
        />
        <input
          id="tokenToInput"
          maxLength="9"
          type="text"
          className={`
                    text-center
                    border
                    focus:outline-none
                    focus:border-gfPeriwinkle
                    h-10
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 
                    ${toTokenTextColor} `}
          value={toToken}
          onChange={toTokenChangeHandler}
          placeholder={t('Token-to')}
          onKeyPress={(event) => {
            keyPressHandlerTOtoken(event);
          }}
        />
        <input
          id="amountInput"
          type="number"
          min="0"
          max="9999"
          className="border
                    text-center
                    focus:outline-none
                    h-10
                    focus:border-gfPeriwinkle
                    text-gfDarkBlue
                    font-MulishRegular
                    text-2xl
                    border-gfPeriwinkle
                    rounded
                    placeholder-gfGrey
                    px-4 "
          placeholder={t('Amount-in-EUR', { currency: selectedTag.currency })}
          value={tokenAmount}
          onChange={amountChangeHandler}
          onKeyDown={preventNonNumeric}
          onWheel={(e) => e.target.blur()}
          onKeyPress={(event) => {
            keyPressHandler(event);
          }}
        />
        <button
          id="addCardButton"
          disabled={!fromTokenValidity && tokenAmount === ''}
          type="button"
          onClick={addCardToListHandler}
          className={`bg-gfPeriwinkle font-MulishBold ${
            !fromTokenValidity && tokenAmount === ''
              ? 'bg-opacity-50'
              : 'bg-opacity-100'
          } text-gfDarkBlue rounded py-2 px-3 flex justify-center items-center focus:outline-none`}
        >
          {loadingCardRange && <ButtonLoading color={GF_DARK_BLUE} />}
          {t('Add-a-card')}
        </button>
      </div>
      {validation !== '' && (
        <div className="text-sm text-gfCoral flex justify-items-center py-2">
          {validation}
        </div>
      )}
      {cards && cards.length !== 0 && (
        <div className="mt-4">
          <div className="flex flex-row w-full lg:w-2/4 xl:w-2/4 2xl:w-2/6 justify-between bg-gfPeriwinkle bg-opacity-75 font-MulishBlack rounded text-lg items-center md:px-4 py-1 px-1">
            <div className="text-gfDarkBlue">
              <span className="">{cards.length}</span>
              {cards.length === 1 ? ' Card' : ' Cards'}
            </div>
            <div className=" flex flex-row text-lg text-gfDarkBlue space-x-4">
              <span className="text-gfDarkBlue">Total :</span>
              <div className="flex justify-start text-gfLightBlue font-semibold">
                <CurrencyFormat
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={cards.reduce(
                    (a, card) => (a += parseFloat(card.amount)),
                    0,
                  )}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                <span className="ml-1">{`${selectedTag.currency}`}</span>
              </div>
            </div>
          </div>

          <div className="space-y-1 mt-4 ">
            {cards.map((card, index) => (
              <div
                key={index}
                className="flex flex-row w-full lg:w-2/4 xl:w-2/4 2xl:w-2/6 justify-between bg-gfPeriwinkle bg-opacity-75 rounded text-lg items-center md:px-4 py-1 px-1"
              >
                <div
                  className={`${
                    !card.valid && isCardRangeDirty
                      ? 'text-gfCoral'
                      : 'text-gfDarkBlue'
                  }`}
                >
                  {card.token}
                </div>
                {!card.valid && (
                  <div
                    className={`${
                      !card.valid && isCardRangeDirty
                        ? 'text-gfCoral'
                        : 'text-gfDarkBlue'
                    }`}
                  >
                    {t('Card-is-not-loadable')}
                  </div>
                )}
                <div className="flex flex-row">
                  <div className="flex justify-start text-gfLightBlue mx-4">
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={card.amount}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    <span className="ml-1">{`${selectedTag.currency}`}</span>
                  </div>
                  <button
                    id={card.token}
                    type="button"
                    onClick={removeTokenFromList}
                  >
                    <CloseIcon w={15} h={16} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {errorCardRange && <FailAlert message={errorCardRange.message} />}
    </div>
  );
}
