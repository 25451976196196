import React from 'react';
import { GF_CORAL } from '../../utils/Constants';

export default function DeclineIcon({ w, h }) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.125 3.66406C5.02604 1.76302 7.31771 0.8125 10 0.8125C12.6823 0.8125 14.9609 1.76302 16.8359 3.66406C18.737 5.53906 19.6875 7.81771 19.6875 10.5C19.6875 13.1823 18.737 15.474 16.8359 17.375C14.9609 19.25 12.6823 20.1875 10 20.1875C7.31771 20.1875 5.02604 19.25 3.125 17.375C1.25 15.474 0.3125 13.1823 0.3125 10.5C0.3125 7.81771 1.25 5.53906 3.125 3.66406ZM14.7656 13.0391L12.1875 10.5L14.7656 7.96094C14.974 7.72656 14.974 7.50521 14.7656 7.29688L13.2031 5.73438C12.9948 5.52604 12.7734 5.52604 12.5391 5.73438L10 8.3125L7.46094 5.73438C7.22656 5.52604 7.00521 5.52604 6.79688 5.73438L5.23438 7.29688C5.02604 7.50521 5.02604 7.72656 5.23438 7.96094L7.8125 10.5L5.23438 13.0391C5.02604 13.2734 5.02604 13.4948 5.23438 13.7031L6.79688 15.2656C7.00521 15.474 7.22656 15.474 7.46094 15.2656L10 12.6875L12.5391 15.2656C12.7734 15.474 12.9948 15.474 13.2031 15.2656L14.7656 13.7031C14.974 13.4948 14.974 13.2734 14.7656 13.0391Z"
        fill={GF_CORAL}
      />
    </svg>
  );
}
