import { issuingAPI } from '../services/Axios';
import { getNotificationList } from './NotificationActions';

export const PREFIX = '@terminals/';

// TERMINALS
export const LOADING_TERMINALS = `${PREFIX}LOADING_TERMINALS`;
export const RECEIVED_TERMINALS = `${PREFIX}RECEIVED_TERMINALS`;
export const ERROR_TERMINAL = `${PREFIX}ERROR_TERMINAL`;

export const SELECTED_TERMINAL = `${PREFIX}SELECTED_TERMINAL`;
export const TERMINAL_CHANGED = `${PREFIX}TERMINAL_CHANGED`;

export const getTerminals = () => (dispatch, getState) => {
  dispatch({ type: LOADING_TERMINALS });

  const { tagID } = getState().tag;
  const { reference_id } = getState().auth.user;

  return issuingAPI
    .get(`/tag/${tagID}/terminal`)
    .then((success) => {
      dispatch({
        type: RECEIVED_TERMINALS,
        payload: { terminals: success.data.data, reference: reference_id },
      });
      dispatch(getNotificationList());
    })
    .catch((error) => {
      dispatch({ type: ERROR_TERMINAL, payload: error });
      return error;
    });
};
