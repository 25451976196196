import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {
  POPULATED_STOCK_THREE_MONTHS_LIST,
  getThreeMonthsStockCSV,
  getExpiredCardsExcel,
  getThreeMonthsStockExcel,
} from '../../../../../actions/CardStockActions';
import MessageAlert from '../../../../Alert/MessageAlert';
import Table from '../../../../UIComponents/Table';
import ButtonLoading from '../../../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../../../utils/Constants';
import FailAlert from '../../../../Alert/FailAlert';

export default function CardsValidThreeMonths({ data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { populatedStockThree } = useSelector((state) => state.cardStock);
  const { loadingCSV, errorCSV, loadingExcel } = useSelector(
    (state) => state.commonReducer,
  );

  const handlePageClick = (event) => {
    dispatch({
      type: POPULATED_STOCK_THREE_MONTHS_LIST,
      payload: event.selected + 1,
    });
  };

  const exportCSV = () => {
    dispatch(getThreeMonthsStockCSV());
  };

  const exportExel = () => {
    dispatch(getThreeMonthsStockExcel());
  };

  return (
    <div>
      {data.count !== 0 && data.cards.length !== 0 ? (
        <div className="my-4">
          <div className="flex flex-row w-1/3 justify-between">
            <div className="flex flex-col">
              <div className="font-MulishBold text-xl">{`Cards: ${data.count}`}</div>
              <div className="font-MulishBold text-xl">{`Date: ${data.date}`}</div>
            </div>
            <div className="flex flex-row">
              <button
                disabled={loadingCSV}
                onClick={exportCSV}
                className={`px-4 py-2 mb-4 ml-4 rounded focus:outline-none ${loadingCSV ? 'bg-gfPeriwinkle' : 'bg-gfCoral'} flex flex-row justify-center items-center font-MulishBold`}
              >
                {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Export-as-a-CSV-file')}
              </button>

              <button
                disabled={loadingExcel}
                onClick={exportExel}
                className={`px-4 py-2 mb-4 ml-4 rounded focus:outline-none ${loadingExcel ? 'bg-gfPeriwinkle' : 'bg-gfCoral'} flex flex-row justify-center items-center font-MulishBold`}
              >
                {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Export-as-a-Excel-file')}
              </button>
            </div>
          </div>

          {errorCSV && (
            <div className="py-4">
              <FailAlert message={errorCSV.message} />
            </div>
          )}

          {data.cards.length !== 0 &&
            data.cards.length >
              window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
              <div className="py-1">
                <ReactPaginate
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={
                    data.cards.length /
                    window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                  }
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            )}

          <div className="w-1/3">
            <Table>
              <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-center   uppercase tracking-wider"
                  >
                    {t('Token')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-center   uppercase tracking-wider"
                  >
                    {t('Expiry-date')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
                {data.cards &&
                  populatedStockThree &&
                  populatedStockThree.map((report) => (
                    <tr key={report.CMAPI_public_token}>
                      <td className="px-4 py-4 text-sm text-center">
                        {report.CMAPI_public_token}
                      </td>
                      <td className="px-4 py-4 text-sm text-center">
                        {report.expiration_date}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <MessageAlert
          message={t('There-are-no-information-to-display-for-this-date')}
        />
      )}
    </div>
  );
}
