/* eslint-disable import/no-anonymous-default-export */
import i18n from 'i18next';
import {
  ERROR_CARD_TRANSFER_DESK_LIST,
  ERROR_CARD_TRANSFER_LIST,
  ERROR_FROM_TOKEN,
  ERROR_FROM_TOKEN_VALIDITY,
  ERROR_TO_TOKEN,
  ERROR_TO_TOKEN_VALIDITY,
  ERROR_TRANSFER_USER_LIST,
  FROM_TOKEN_TEXT_COLOR,
  LOADING_CARD_TRANSFER_DESK_LIST,
  LOADING_CARD_TRANSFER_LIST,
  LOADING_FROM_TOKEN,
  LOADING_FROM_TOKEN_VALIDITY,
  LOADING_TO_TOKEN,
  LOADING_TO_TOKEN_VALIDITY,
  LOADING_TRANSFER_USER_LIST,
  PAGINATE_TRANSFER_LIST,
  RECEIVED_CARD_TRANSFER_DESK_LIST,
  RECEIVED_CARD_TRANSFER_LIST,
  RECEIVED_FROM_TOKEN,
  RECEIVED_FROM_TOKEN_VALIDITY,
  RECEIVED_TO_TOKEN,
  RECEIVED_TO_TOKEN_VALIDITY,
  RECEIVED_TRANSFER_USER_LIST,
  RESET_TRANSFER,
  TO_TOKEN_TEXT_COLOR,
  RESET_FROM_TOKEN,
  RESET_TO_TOKEN,
  SENDING_TRANSFER_AND_TERMINATE_CARD,
  SUCCESS_TRANSFER_AND_TERMINATE_CARD,
  ERROR_TRANSFER_AND_TERMINATE_CARD,
  TRANSFER_AND_TERMINATE_OPERATION_ID,
} from '../actions/CardTransferActions';
import { randomString } from '../utils/Helper';

const initialState = {
  loadingTransferUserList: false,
  receivedTransferUserList: null,
  errorTransferUserList: null,

  loadingTransferDeskList: false,
  receivedTransferDeskList: null,
  errorTransferDeskList: null,

  loadingCardTransferList: false,
  receivedCardTransferList: null,
  errorCardTransferList: null,
  metaCardTransferList: null,
  summaryCardTransferList: null,

  loadingFromToken: false,
  receivedFromToken: null,
  errorFromToken: null,

  loadingToToken: false,
  receivedToToken: null,
  errorToToken: null,

  sendingTransferAndTerminate: false,
  successTransferAndTerminate: null,
  errorTransferAndTerminate: null,

  transferAndTerminateCardOperationID: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FROM_TOKEN:
      return {
        ...state,
        loadingFromToken: true,
        receivedFromToken: null,
        errorFromToken: null,
      };

    case RECEIVED_FROM_TOKEN:
      return {
        ...state,
        loadingFromToken: false,
        receivedFromToken: action.payload,
        errorFromToken: null,
      };

    case ERROR_FROM_TOKEN:
      return {
        ...state,
        loadingFromToken: false,
        receivedFromToken: null,
        errorFromToken: action.payload,
      };

    case RESET_FROM_TOKEN:
      return {
        ...state,
        loadingFromToken: false,
        receivedFromToken: null,
        errorFromToken: null,
      };

    case RESET_TO_TOKEN:
      return {
        ...state,
        loadingToToken: false,
        receivedToToken: null,
        errorToToken: null,
      };

    case LOADING_TO_TOKEN:
      return {
        ...state,
        loadingToToken: true,
        receivedToToken: null,
        errorToToken: null,
      };

    case RECEIVED_TO_TOKEN:
      return {
        ...state,
        loadingToToken: false,
        receivedToToken: action.payload,
        errorToToken: null,
      };

    case ERROR_TO_TOKEN:
      return {
        ...state,
        loadingToToken: false,
        receivedToToken: null,
        errorToToken: action.payload,
      };

    case LOADING_TRANSFER_USER_LIST:
      return {
        ...state,
        loadingTransferUserList: true,
        receivedTransferUserList: null,
        errorTransferUserList: null,
      };

    case RECEIVED_TRANSFER_USER_LIST:
      function compare(a, b) {
        if (a.id > b.id) return 1;
        if (b.id > a.id) return -1;

        return 0;
      }

      return {
        ...state,
        loadingTransferUserList: false,
        receivedTransferUserList: action.payload.sort(compare),
        errorTransferUserList: null,
      };

    case ERROR_TRANSFER_USER_LIST:
      return {
        ...state,
        loadingTransferUserList: false,
        receivedTransferUserList: null,
        errorTransferUserList: action.payload,
      };

    case LOADING_CARD_TRANSFER_DESK_LIST:
      return {
        ...state,
        loadingTransferDeskList: true,
        receivedTransferDeskList: null,
        errorTransferDeskList: null,
      };

    case RECEIVED_CARD_TRANSFER_DESK_LIST:
      let temp;
      temp = [{ id: -1, name: i18n.t('All') }, ...action.payload];
      return {
        ...state,
        loadingTransferDeskList: false,
        receivedTransferDeskList: temp,
        errorTransferDeskList: null,
      };

    case ERROR_CARD_TRANSFER_DESK_LIST:
      return {
        ...state,
        loadingTransferDeskList: false,
        receivedTransferDeskList: null,
        errorTransferDeskList: action.payload,
      };

    case LOADING_CARD_TRANSFER_LIST:
      return {
        ...state,
        loadingCardTransferList: true,
        receivedCardTransferList: null,
        errorCardTransferList: null,
      };

    case RECEIVED_CARD_TRANSFER_LIST:
      return {
        ...state,
        loadingCardTransferList: false,
        receivedCardTransferList: action.payload.data,
        errorCardTransferList: null,
        metaCardTransferList: action.payload.meta,
        summaryCardTransferList: action.payload.summary,
      };

    case ERROR_CARD_TRANSFER_LIST:
      return {
        ...state,
        loadingCardTransferList: false,
        receivedCardTransferList: null,
        errorCardTransferList: action.payload,
        metaCardTransferList: null,
        summaryCardTransferList: null,
      };

    case PAGINATE_TRANSFER_LIST:
      return {
        ...state,
        loadingCardTransferList: true,
      };

    case SENDING_TRANSFER_AND_TERMINATE_CARD:
      return {
        ...state,
        sendingTransferAndTerminate: true,
        successTransferAndTerminate: null,
        errorTransferAndTerminate: null,
      };

    case SUCCESS_TRANSFER_AND_TERMINATE_CARD:
      return {
        ...state,
        sendingTransferAndTerminate: false,
        successTransferAndTerminate: action.payload,
        errorTransferAndTerminate: null,
      };

    case ERROR_TRANSFER_AND_TERMINATE_CARD:
      return {
        ...state,
        sendingTransferAndTerminate: false,
        successTransferAndTerminate: null,
        errorTransferAndTerminate: action.payload,
      };

    case TRANSFER_AND_TERMINATE_OPERATION_ID:
      return {
        ...state,
        transferAndTerminateCardOperationID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
      };

    case RESET_TRANSFER:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
