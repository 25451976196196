import axios, { issuingAPI, imageAPI } from '../services/Axios';
import { getLoggedInUserTagPermissions } from './AuthActions';
import { getTerminals } from './TerminalActions';

export const PREFIX = '@tagList/';

// TAG LIST
export const LOADING_TAG_LIST = `${PREFIX}LOADING_TAG_LIST`;
export const RECEIVED_TAG_LIST = `${PREFIX}RECEIVED_TAG_LIST`;
export const ERROR_TAG_LIST = `${PREFIX}ERROR_TAG_LIST`;

export const SELECTED_TAG = `${PREFIX}SELECTED_TAG`;
export const RESET_TAG_LIST = `${PREFIX}RESET_TAG_LIST`;
export const TAG_CHANGED = `${PREFIX}TAG_CHANGED`;

export const REMOVE_HAS_CB_ALERT = `${PREFIX}REMOVE_HAS_CB_ALERT`;

export const loadTagList = (history) => (dispatch, getState) => {
  dispatch({ type: LOADING_TAG_LIST });
  dispatch({ type: RESET_UPDATING_TAG });

  const { reference_id, id } = getState().auth.user;

  return issuingAPI
    .get('/tag')
    .then((success) => {
      dispatch({
        type: RECEIVED_TAG_LIST,
        payload: { tags: success.data, reference: reference_id },
      });
      dispatch(getLoggedInUserTagPermissions(id, history));
      dispatch(getTerminals());
    })
    .catch((error) => {
      dispatch({ type: ERROR_TAG_LIST, payload: error });
      return error;
    });
};

export const UPDATING_TAG = `${PREFIX}UPDATING_TAG`;
export const SUCCESS_UPDATING_TAG = `${PREFIX}SUCCESS_UPDATING_TAG`;
export const FAIL_UPDATING_TAG = `${PREFIX}FAIL_UPDATING_TAG`;
export const RESET_UPDATING_TAG = `${PREFIX}RESET_UPDATING_TAG`;

export const updateTag = (editedTag, history) => (dispatch, getState) => {
  dispatch({ type: UPDATING_TAG });

  // https://portalapi.dev.giftify.me/tag/{tagId}

  const { tagID } = getState().tag;

  const data = {
    website: editedTag.website,
    ticket_footer: editedTag.ticket_footer,
    support_email: editedTag.support_email,
    support_phone: editedTag.support_phone,
    ticket_footer_invoice: editedTag.ticket_footer_invoice,
  };

  return issuingAPI
    .put(`/tag/${tagID}`, { ...data })
    .then((success) => {
      dispatch({ type: SUCCESS_UPDATING_TAG, payload: success.data });
      setTimeout(function () {
        dispatch(loadTagList(history));
      }, 2000);
    })
    .catch((error) => {
      dispatch({ type: FAIL_UPDATING_TAG, payload: error });
      setTimeout(function () {
        dispatch({ type: RESET_UPDATING_TAG });
      }, 2000);
      return error;
    });
};

export const UPDATED_LOGO = `${PREFIX}UPDATED_LOGO`;
export const UPDATED_LOGO_BW = `${PREFIX}UPDATED_LOGO_BW`;

export const uploadLogoFile = (imageData) => (dispatch, getState, history) => {
  // https://portalapi.dev.giftify.me/tag/{tagId}/image

  const { tagID } = getState().tag;

  const params = {
    type: 'logo',
  };

  return imageAPI
    .post(`/tag/${tagID}/image`, imageData, { params })
    .then((success) => {
      dispatch({ type: UPDATED_LOGO, payload: success.data });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: FAIL_UPDATING_TAG, payload: error });
    });
};

export const uploadLogoBWFile =
  (imageData) => (dispatch, getState, history) => {
    // https://portalapi.dev.giftify.me/tag/{tagId}/image

    const { tagID } = getState().tag;

    const params = {
      type: 'black_and_white',
    };

    return imageAPI
      .post(`/tag/${tagID}/image`, imageData, { params })
      .then((success) => {
        dispatch({ type: UPDATED_LOGO_BW, payload: success.data });
      })
      .catch((error) => {
        dispatch({ type: FAIL_UPDATING_TAG, payload: error });
      });
  };
