/* eslint-disable import/no-anonymous-default-export */
import { remove } from 'final-form-arrays';
import {
  LOADING_ADDITIONAL_PRODUCTS,
  RECEIVED_ADDITIONAL_PRODUCTS,
  INITIAL_LOAD_CARD,
  RESET_LOAD_CARD,
  LOAD_CARD_RESPONSE_ERROR,
  LOADING_PAYMENT_METHODS,
  RECEIVED_PAYMENT_METHODS,
  LOADING_CHANNELS,
  RECEIVED_CHANNELS,
  LOADING_FIELDS,
  RECEIVED_FIELDS,
  LOADING_DESKS,
  RECEIVED_DESKS,
  LOADING_FROM_TOKEN_VALIDITY,
  RECEIVED_FROM_TOKEN_VALIDITY,
  FROM_TOKEN_TEXT_COLOR,
  LOADING_TO_TOKEN_VALIDITY,
  RECEIVED_TO_TOKEN_VALIDITY,
  TO_TOKEN_TEXT_COLOR,
  LOADING_CARD_RANGE,
  RECEIVED_CARD_RANGE,
  ERROR_CARD_RANGE,
  CLEAR_CARD_ERRORS,
  ADD_ONE_CARD,
  SET_CARD_AMOUNT,
  REMOVE_CARD_FROM_LIST,
  LOADING_TICKET_NUMBER,
  RECEIVED_TICKET_NUMBER,
  LOADING_LOAD_CARDS,
  SUCCESS_LOAD_CARDS,
  ERROR_LOAD_CARDS,
  LOADING_LOAD_CARDS_PAYMENT,
  SUCCESS_LOAD_CARDS_PAYMENT,
  ERROR_LOAD_CARDS_PAYMENTS,
  SET_TOTAL_AMOUNT,
  CLEAR_CARDS,
  REMOVE_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD,
  LOADING_TICKET_INFO,
  RECEIVED_TICKET_INFO,
  ERROR_TICKET_INFO,
  GENERATE_OPERATION_ID,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_ERROR,
  SENDING_TICKET_BY_EMAIL,
  SET_FROM_TOKEN,
  SET_TO_TOKEN,
  SET_AMOUNT,
  RESET_ADD_CARD_FIELDS,
} from '../actions/LoadCardActions';
import { checkCardRangeDirty, randomString } from '../utils/Helper';

const initialState = {
  additionalProducts: null,
  loadingAdditionalProducts: false,

  paymentMethods: null,
  loadingPaymentMethods: false,
  deafultPaymentmethod: null,

  channels: null,
  loadingChannels: false,
  isChannelMandatory: false,
  deafultChannel: null,

  fields: null,
  loadingFields: false,

  desks: null,
  loadingDesk: false,
  defaultDesk: null,

  isLoading: false,
  error: null,

  fieldErrors: false,

  hasSubmit: false,

  loadingFromTokenValidity: false,
  fromTokenValidity: null,
  fromTokenTextColor: 'text-gfDarkBlue',

  loadingToTokenValidity: false,
  toTokenValidity: false,
  toTokenTextColor: 'text-gfDarkBlue',

  loadingCardRange: false,
  errorCardRange: null,
  isCardRangeDirty: false,

  cards: [],

  addCardAmount: 0,
  totalAmount: 0,

  loadingTicketNumber: false,
  ticketNumber: null,

  loadingLoadCards: false,
  loadCards: null,
  errorLoadCards: null,

  loadingLoadCardPayments: false,
  loadCardPayments: null,
  errorLoadCardsPayments: null,

  filteredPaymentMethods: null,
  selectedPaymentMethods: [],

  loadingTicketInfo: false,
  receivedTicketInfo: null,
  errorTicketInfo: null,

  sendingTicketViaEmail: false,
  emailSuccess: null,
  emailError: null,

  loadCardOperationID: '',
  loacCardPaymentOperationID: '',

  fromToken: '',
  toToken: '',
  tokenAmount: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_LOAD_CARD:
      return {
        ...state,
        cards: [],
        fromTokenValidity: false,
      };

    case INITIAL_LOAD_CARD:
      return {
        ...state,
        ...initialState,
      };

    case SET_FROM_TOKEN:
      return {
        ...state,
        fromToken: action.payload,
      };

    case SET_TO_TOKEN:
      return {
        ...state,
        toToken: action.payload,
      };

    case SET_AMOUNT:
      return {
        ...state,
        tokenAmount: action.payload,
      };

    case RESET_ADD_CARD_FIELDS:
      return {
        ...state,
        fromToken: '',
        toToken: '',
        tokenAmount: '',
      };

    case LOADING_ADDITIONAL_PRODUCTS:
      return {
        ...state,
        loadingAdditionalProducts: true,
        error: null,
      };

    case RECEIVED_ADDITIONAL_PRODUCTS:
      return {
        ...state,
        loadingAdditionalProducts: false,
        error: null,
        additionalProducts: action.payload,
      };

    case LOAD_CARD_RESPONSE_ERROR:
      return {
        ...state,
        isLoading: null,
        error: action.payload,
      };

    case LOADING_PAYMENT_METHODS:
      return {
        ...state,
        loadingPaymentMethods: true,
        error: null,
      };

    case RECEIVED_PAYMENT_METHODS:
      let payemtObj = null;
      const tempPayement = action.payload.filter(
        (payment) => payment.requires_transhandler,
      );
      if (tempPayement.length !== 0) {
        payemtObj = tempPayement[0];
      }

      return {
        ...state,
        loadingPaymentMethods: false,
        error: null,
        // paymentMethods: [...state.paymentMethods, ...action.payload],
        // filteredPaymentMethods: [...state.paymentMethods, ...action.payload],
        paymentMethods: action.payload,
        filteredPaymentMethods: action.payload,
        deafultPaymentmethod: payemtObj,
      };

    case LOADING_CHANNELS:
      return {
        ...state,
        loadingChannels: true,
        error: null,
      };

    case RECEIVED_CHANNELS:
      let channelObj = null;
      const tempChannel = action.payload.data.filter(
        (channel) => channel.is_default,
      );
      if (tempChannel.length === 1) {
        channelObj = tempChannel[0];
      }
      return {
        ...state,
        loadingChannels: false,
        error: null,
        channels: action.payload.data,
        isChannelMandatory: action.payload.is_mandatory,
        deafultChannel: channelObj,
      };

    case LOADING_FIELDS:
      return {
        ...state,
        loadingFields: true,
        error: null,
      };

    case RECEIVED_FIELDS:
      return {
        ...state,
        loadingFields: false,
        error: null,
        fields: action.payload,
      };

    case LOADING_DESKS:
      return {
        ...state,
        loadingDesks: true,
        error: null,
      };

    case RECEIVED_DESKS:
      const deskArray = action.payload;
      let tempDesk;
      const localDesk = localStorage.getItem('desk');

      if (localDesk) {
        deskArray.forEach(function (desk) {
          if (desk.id === parseInt(localDesk)) {
            tempDesk = desk;
          }
        });
      }
      return {
        ...state,
        loadingDesks: false,
        error: null,
        desks: deskArray,
        defaultDesk: tempDesk || null,
      };

    case LOADING_FROM_TOKEN_VALIDITY:
      return {
        ...state,
        loadingFromTokenValidity: true,
        fromTokenValidity: false,
      };

    case LOADING_TO_TOKEN_VALIDITY:
      return {
        ...state,
        loadingToTokenValidity: true,
        toTokenValidity: false,
      };

    case RECEIVED_FROM_TOKEN_VALIDITY:
      return {
        ...state,
        loadingFromTokenValidity: false,
        fromTokenValidity: action.payload,
      };

    case FROM_TOKEN_TEXT_COLOR:
      return {
        ...state,
        fromTokenTextColor: action.payload,
      };

    case RECEIVED_TO_TOKEN_VALIDITY:
      return {
        ...state,
        loadingToTokenValidity: false,
        toTokenValidity: action.payload,
      };

    case TO_TOKEN_TEXT_COLOR:
      return {
        ...state,
        toTokenTextColor: action.payload,
      };

    case LOADING_CARD_RANGE:
      return {
        ...state,
        loadingCardRange: true,
        errorCardRange: null,
      };

    case RECEIVED_CARD_RANGE:
      return {
        ...state,
        cards: [...state.cards, ...action.payload],
        loadingCardRange: false,
        errorCardRange: null,
        isCardRangeDirty: checkCardRangeDirty(action.payload),
      };

    case ERROR_CARD_RANGE:
      return {
        ...state,
        loadingCardRange: false,
        errorCardRange: action.payload,
      };

    case CLEAR_CARD_ERRORS:
      return {
        ...state,
        errorCardRange: null,
      };

    case ADD_ONE_CARD:
      return {
        ...state,
        cards: [...state.cards, action.payload],
        fromTokenValidity: false,
        fromTokenTextColor: 'text-gfDarkBlue',
      };

    case SET_CARD_AMOUNT:
      return {
        ...state,
        addCardAmount: action.payload,
      };

    case REMOVE_CARD_FROM_LIST:
      const filteredCards = state.cards.filter(
        (card) => card.token !== action.payload,
      );
      return {
        ...state,
        cards: filteredCards,
        isCardRangeDirty: checkCardRangeDirty(filteredCards),
      };

    case GENERATE_OPERATION_ID:
      return {
        ...state,
        loadCardOperationID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
        loacCardPaymentOperationID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
      };

    case LOADING_TICKET_NUMBER:
      return {
        ...state,
        loadingTicketNumber: true,
        error: null,
        errorLoadCards: null,
        errorLoadCardsPayments: null,
      };

    case RECEIVED_TICKET_NUMBER:
      return {
        ...state,
        ticketNumber: action.payload,
        loadingTicketNumber: false,
        error: null,
      };

    case SET_TOTAL_AMOUNT:
      const ta = state.cards.reduce(
        (a, card) => (a += parseFloat(card.amount)),
        0,
      );
      return {
        ...state,
        totalAmount: parseFloat(ta).toFixed(2),
      };

    case CLEAR_CARDS:
      const c = state.cards.map(function (item) {
        delete item.valid;
        return item;
      });
      return {
        ...state,
        cards: c,
      };

    case LOADING_LOAD_CARDS:
      return {
        ...state,
        loadingLoadCards: true,
        loadCards: null,
        errorLoadCards: null,
      };
    case SUCCESS_LOAD_CARDS:
      return {
        ...state,
        loadingLoadCards: false,
        loadCards: action.payload,
        errorLoadCards: null,
      };

    case ERROR_LOAD_CARDS:
      return {
        ...state,
        loadingLoadCards: false,
        loadCards: null,
        errorLoadCards: action.payload,
      };

    case LOADING_LOAD_CARDS_PAYMENT:
      return {
        ...state,
        loadingLoadCardPayments: true,
        loadCardPayments: null,
        errorLoadCardsPayments: null,
      };

    case SUCCESS_LOAD_CARDS_PAYMENT:
      return {
        ...state,
        loadingLoadCardPayments: false,
        loadCardPayments: action.payload,
        errorLoadCardsPayments: null,
      };

    case ERROR_LOAD_CARDS_PAYMENTS:
      return {
        ...state,
        loadingLoadCardPayments: false,
        loadCardPayments: null,
        errorLoadCardsPayments: action.payload,
      };

    case REMOVE_PAYMENT_METHOD:
      const newItem = action.payload;
      let temp = [];
      let selected = [];

      const found = state.selectedPaymentMethods.filter(
        (element) => element.index === newItem.index,
      );

      if (found.length !== 0) {
        const index = state.selectedPaymentMethods
          .map((item) => item.index)
          .indexOf(found[0].index);
        if (index > -1) {
          state.selectedPaymentMethods.splice(index, 1);
        }
        selected = [...selected, newItem];

        temp = state.filteredPaymentMethods.filter(
          (element) => element.id !== newItem.item.id,
        );
        temp = [...temp, found[0].item];
      } else {
        temp = state.filteredPaymentMethods.filter(
          (element) => element.id !== newItem.item.id,
        );
        selected = [...selected, newItem];
      }

      return {
        ...state,
        filteredPaymentMethods: temp,
        selectedPaymentMethods: [...state.selectedPaymentMethods, ...selected],
      };

    case ADD_PAYMENT_METHOD:
      const removed = state.selectedPaymentMethods.filter(
        (element) => element.index === action.payload,
      );
      const result = state.selectedPaymentMethods.filter(
        (element) => element.index !== action.payload,
      );

      return {
        ...state,
        filteredPaymentMethods:
          removed.length !== 0
            ? [...state.filteredPaymentMethods, { ...removed[0].item }]
            : [...state.filteredPaymentMethods],
        selectedPaymentMethods: result,
      };

    case LOADING_TICKET_INFO:
      return {
        ...state,
        loadingTicketInfo: true,
        receivedTicketInfo: null,
        errorTicketInfo: null,
      };

    case RECEIVED_TICKET_INFO:
      return {
        ...state,
        loadingTicketInfo: false,
        receivedTicketInfo: action.payload,
        errorTicketInfo: null,
      };

    case ERROR_TICKET_INFO:
      return {
        ...state,
        loadingTicketInfo: false,
        receivedTicketInfo: null,
        errorTicketInfo: action.payload,
      };
    case SENDING_TICKET_BY_EMAIL:
      return {
        ...state,
        sendingTicketViaEmail: true,
        emailSuccess: null,
        emailError: null,
      };
    case EMAIL_SENT_SUCCESS:
      return {
        ...state,
        sendingTicketViaEmail: false,
        emailSuccess: true,
        emailError: false,
      };
    case EMAIL_SENT_ERROR:
      return {
        ...state,
        sendingTicketViaEmail: false,
        emailSuccess: false,
        emailError: action.payload,
      };
    default:
      return state;
  }
}
