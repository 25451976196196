import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { Userpilot } from 'userpilot';
import {
  ADD_PROMO_CARD_TO_LIST,
  checkTokenRangeValidity,
  check_from_TokenValidity,
  check_to_TokenValidity,
  FROM_TOKEN_TEXT_COLOR,
  getTicketNumber,
  loadPromoCard,
  REMOVE_ITEM_FROM_LIST,
  TEXT_FROM_TOKEN,
  TEXT_TO_TOKEN,
  TO_TOKEN_TEXT_COLOR,
} from '../../../../actions/ActivatePromoCardsActions';
import { TAG_CHANGED } from '../../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import MessageAlert from '../../../Alert/MessageAlert';
import FailAlert from '../../../Alert/FailAlert';
import Container from '../../../UIComponents/Container';
import ActivateCardsInfor from './ActivateCardsInfor';

export default function ActivatePromoCards({ history }) {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const { t } = useTranslation();
  const { appCount } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    Userpilot.reload();
    if (appCount === 0) dispatch(getTicketNumber());

    window.addEventListener(TAG_CHANGED, () => {
      dispatch(getTicketNumber());
    });

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch(getTicketNumber());
    });

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const {
    loadingTicketNumber,
    receivedTicketNumber,
    errorTicketNumber,

    received_from_TokenValidity,
    fromTokenTextColor,
    textFromToken,
    error_from_TokenValidity,

    promoCards,

    itemAlreadyExist,

    received_to_TokenValidity,
    toTokenTextColor,
    textToToken,
    error_to_TokenValidity,

    loadingTokenRange,
    receivedTokenRange,
    errorTokenRange,
  } = useSelector((state) => state.activatePromoCards);
  const { tagCurrency } = useSelector((state) => state.tag);

  const fromTokenChangeHandler = (event) => {
    if (event.currentTarget.value !== '') {
      dispatch({ type: TEXT_FROM_TOKEN, payload: event.currentTarget.value });
      dispatch(check_from_TokenValidity(event.currentTarget.value));
    } else {
      dispatch({ type: TEXT_FROM_TOKEN, payload: '' });
      dispatch({ type: FROM_TOKEN_TEXT_COLOR });
    }
  };

  const toTokenChangeHandler = (event) => {
    if (event.currentTarget.value !== '') {
      dispatch({ type: TEXT_TO_TOKEN, payload: event.currentTarget.value });
      dispatch(check_to_TokenValidity(event.currentTarget.value));
    } else {
      dispatch({ type: TEXT_TO_TOKEN, payload: '' });
      dispatch({ type: TO_TOKEN_TEXT_COLOR });
    }
  };

  const addCardToListHandler = () => {
    if (
      textFromToken !== '' &&
      received_from_TokenValidity &&
      !received_to_TokenValidity &&
      toTokenTextColor !== ''
    ) {
      dispatch({ type: ADD_PROMO_CARD_TO_LIST });
    }

    if (
      textFromToken !== '' &&
      received_from_TokenValidity &&
      toTokenTextColor !== '' &&
      received_to_TokenValidity
    ) {
      dispatch(checkTokenRangeValidity(textFromToken, textToToken));
    }
  };

  const activateCardLoad = () => {
    dispatch(loadPromoCard(receivedTicketNumber, promoCards));
    setVisibility(!visibility);
  };

  const activateCardLoadCloseHandler = () => {
    setVisibility(!visibility);
  };

  const removeItemFromList = (token) => {
    dispatch({ type: REMOVE_ITEM_FROM_LIST, payload: token });
  };

  return (
    <Container
      title={t('activate-promo-cards')}
      loading={loadingTicketNumber || loadingTokenRange}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:lg:grid-cols-3 2xl:lg:grid-cols-3 gap-5">
          <input
            id="tokenFromInput"
            autoFocus
            maxLength="9"
            type="text"
            className={`${fromTokenTextColor} h-10 text-center border focus:outline-none focus:border-gfPeriwinkle text-2xl border-gfPeriwinkle rounded placeholder-gfGrey px-4`}
            placeholder={t('TOKEN')}
            value={textFromToken}
            onChange={fromTokenChangeHandler}
          />

          <input
            id="tokenToInput"
            disabled={!received_from_TokenValidity}
            maxLength="9"
            type="text"
            className={`${
              !received_from_TokenValidity ? 'bg-gray-200' : 'bg-white'
            } ${toTokenTextColor} h-10 border text-center focus:outline-none focus:border-gfPeriwinkle  text-2xl border-gfPeriwinkle rounded placeholder-gfGrey px-4 `}
            placeholder={t('Token-to')}
            value={textToToken}
            onChange={toTokenChangeHandler}
          />

          <button
            id="addCardButton"
            type="button"
            onClick={addCardToListHandler}
            disabled={!received_from_TokenValidity || itemAlreadyExist}
            className={`bg-gfPeriwinkle ${
              !received_from_TokenValidity || itemAlreadyExist
                ? 'bg-opacity-50'
                : 'bg-opacity-100'
            } rounded text-gfDarkBlue font-MulishBold py-2 px-4 focus:outline-none`}
          >
            {t('Add-a-card')}
          </button>
        </div>

        <div className="py-5">
          {itemAlreadyExist && (
            <div className="py-2 text-sm text-red-600">
              Token already exist in the list
            </div>
          )}
          {errorTicketNumber && (
            <FailAlert message={errorTicketNumber.message} />
          )}
          {error_from_TokenValidity && (
            <FailAlert message={error_from_TokenValidity.message} />
          )}
          {error_to_TokenValidity && (
            <FailAlert message={error_to_TokenValidity.message} />
          )}
          {errorTokenRange && <FailAlert message={errorTokenRange.message} />}

          {receivedTokenRange && receivedTokenRange.length === 0 && (
            <MessageAlert message="Token range is empty" />
          )}

          <div className="flex flex-col my-5 space-y-1">
            {promoCards.length !== 0 &&
              promoCards.map((card) => (
                <div key={card.token} className="flex flex-row ">
                  <div className="pl-2 rounded-tl rounded-bl w-64 text-xl flex justify-start items-center bg-kadozNormalGray text-gfDarkBlue ">
                    {card.token}
                  </div>
                  <div className=" rounded-tr rounded-br w-64 text-xl flex justify-end items-center pr-5 bg-kadozNormalGray text-kadozSemiDarkGreen">
                    <div className="flex justify-end text-gfLightBlue">
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={card.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <span className="ml-2 text-sm font-semibold">{` ${tagCurrency}`}</span>
                    </div>
                  </div>
                  <button
                    className="pr-3 w-5 h-10 "
                    type="button"
                    onClick={() => removeItemFromList(card.token)}
                  >
                    <CloseIcon />
                  </button>
                </div>
              ))}
          </div>

          {promoCards.length !== 0 && (
            <div className="flex flex-row mt-5 ">
              <div className="pl-2 rounded-tl rounded-bl w-64 text-xl flex justify-start items-center bg-kadozNormalGray text-gfDarkBlue font-semibold">
                {promoCards.length === 1 && promoCards.length !== 0 ? (
                  <span>1 Card</span>
                ) : (
                  <span>{`${promoCards.length}`} Cards</span>
                )}
              </div>
              <div className=" rounded-tr rounded-br w-64 text-2xl flex justify-end items-center pr-5 bg-kadozNormalGray">
                <div className="flex justify-end text-gfLightBlue font-semibold">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={promoCards.reduce(
                      (a, card) => (a += parseFloat(card.amount)),
                      0,
                    )}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span className="ml-2 text-sm">{` ${tagCurrency}`}</span>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-end items-center flex-row mt-10 pr-5">
            <button
              onClick={activateCardLoad}
              disabled={promoCards.length === 0}
              type="button"
              className={`bg-gfCoral ${
                promoCards.length !== 0 ? '' : 'hidden'
              } rounded text-gfDarkBlue font-MulishBold py-2 px-8 focus:outline-none`}
            >
              {t('Activate-cards')}
            </button>
          </div>
          <ActivateCardsInfor
            modalVisibility={visibility}
            closeHandler={activateCardLoadCloseHandler}
          />
        </div>
      </div>
    </Container>
  );
}
