import React from 'react';
import DefaultModal from './DefaultModal';
import { useTranslation } from 'react-i18next';
import Table from '../Table';

function LoadRow({ reconData }) {
  const { t } = useTranslation();

  return (
    <>
      <Table.Row
        data-testid={reconData.ticket_number}
        className="font-MulishRegular"
      >
        <Table.Cell data-testid="schema">
          {t(`reconciliation.${reconData.name}`)}
        </Table.Cell>
        <Table.Cell data-testid="authenticationCode">
          {reconData.value}
        </Table.Cell>
      </Table.Row>
    </>
  );
}

export default function ReconciliationModal({ isOpen, onClose, data }) {
  const { t } = useTranslation();

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('reconciliation.title')}
    >
      <div className="mt-5">
        {data !== null && data?.length !== 0 && (
          <div className=" overflow-x-auto sm:overflow-x-auto md:overflow-x-auto rounded border ">
            <div className="align-middle inline-block min-w-full  ">
              <div className="shadow overflow-hidden  ">
                <Table>
                  <Table.Body>
                    {data?.map((reconData) => (
                      <LoadRow reconData={reconData} />
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        )}
      </div>
    </DefaultModal>
  );
}
