import { Userpilot } from 'userpilot';
import { loadTagList } from './TagActions';
import { authServerAPI } from '../services/Axios';
import { SELECTED_LANGUAGE } from './LanguageAction';
import { checkLoggedInUserPermissions } from '../utils/Helper';

export const PREFIX = '@auth/';

export const LOADING_ACCESS_TOKEN = `${PREFIX}LOADING_ACCESS_TOKEN`;
export const RECEIVED_ACCESS_TOKEN = `${PREFIX}RECEIVED_ACCESS_TOKEN`;
export const ERROR_ACCESS_TOKEN = `${PREFIX}ERROR_ACCESS_TOKEN`;

export const AUTHENTICATION_ERROR = `${PREFIX}AUTHENTICATION_ERROR`;
export const ACQUIRING_AUTHENTICATION = `${PREFIX}ACQUIRING_AUTHENTICATION`;

export const LOADING_USER = `${PREFIX}LOADING_USER`;
export const RECEIVED_USER = `${PREFIX}RECEIVED_USER`;
export const ERROR_USER = `${PREFIX}ERROR_USER`;

export const USER_LOGOUT = `${PREFIX}USER_LOGOUT`;
export const TIMEOUT_LOGOUT = `${PREFIX}TIMEOUT_LOGOUT`;

export const REFRESH_TOKEN_RECEIVED = `${PREFIX}REFRESH_TOKEN_RECEIVED`;

export const CLEAR_LOCAL_STORAGE = `${PREFIX}CLEAR_LOCAL_STORAGE`;

export const getAccessToken = (authToken, history) => (dispatch) => {
  dispatch({ type: LOADING_ACCESS_TOKEN, payload: authToken });

  const data = {
    grant_type: 'exchange_token',
    client_id: window?.appConfig.REACT_APP_ISSUING_API_CLIENT_ID,
    exchangeable_token: `${authToken}`,
  };

  return authServerAPI
    .post('/oauth/token', { ...data })
    .then((response) => {
      dispatch({ type: RECEIVED_ACCESS_TOKEN, payload: response.data });
      dispatch(getUser(history));
    })
    .catch((error) => {
      dispatch({ type: CLEAR_LOCAL_STORAGE });
      dispatch({ type: ERROR_ACCESS_TOKEN, payload: error });
      return error;
    });
};

export const getUser = (history) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  const lan = localStorage.getItem('i18nextLng');
  dispatch({ type: SELECTED_LANGUAGE, payload: lan });
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
    },
  };

  return authServerAPI
    .get('/api/user/me', config)
    .then((response) => {
      dispatch({ type: RECEIVED_USER, payload: response.data });
      Userpilot.identify(response.data.id, {
        name: response.data.name,
        email: response.data.email,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
      });
      dispatch(loadTagList(history));
    })
    .catch((error) => {
      dispatch({ type: ERROR_USER, payload: error });
      history.push('/');
    });
};

export const LOADING_LOGIN_USER_TAG_PERMISSION = `${PREFIX}LOADING_LOGIN_USER_TAG_PERMISSION`;
export const RECEIVED_LOGIN_USER_TAG_PERMISSION = `${PREFIX}RECEIVED_LOGIN_USER_TAG_PERMISSION`;
export const ERROR_LOGIN_USER_TAG_PERMISSION = `${PREFIX}ERROR_LOGIN_USER_TAG_PERMISSION`;

export const getLoggedInUserTagPermissions =
  (userID, history) => (dispatch, getState) => {
    dispatch({ type: LOADING_LOGIN_USER_TAG_PERMISSION });

    const { tagID } = getState().tag;

    const params = {
      grouped: 1,
    };

    return authServerAPI
      .get(
        `/api/user/${userID}/entity/${tagID}/type/tag/client/${window?.appConfig.REACT_APP_AUTH_CLIENT_ID}/permission`,
        {
          params,
        },
      )
      .then((response) => {
        dispatch({
          type: RECEIVED_LOGIN_USER_TAG_PERMISSION,
          payload: response.data,
        });

        dispatch(getUserSettings(userID));

        const path = localStorage.getItem('path');
        const view = localStorage.getItem('view');

        if (path) {
          const params = new URLSearchParams(history.location.search);
          const queryLan = params.get('lan');

          if (view && view === 'admin') {
            if (queryLan) {
              dispatch({ type: SELECTED_LANGUAGE, payload: queryLan });
              history.push({
                pathname: path,
                search: `?lan=${queryLan}`,
              });
            }
          } else if (queryLan) {
            dispatch({ type: SELECTED_LANGUAGE, payload: queryLan });
            if (
              checkLoggedInUserPermissions('load-card') &&
              path.includes('administration')
            ) {
              history.push({
                pathname: '/card/load-card',
                search: `?lan=${queryLan}`,
              });
            } else {
              history.push({
                pathname: '/',
                search: `?lan=${queryLan}`,
              });
            }
          }
        } else {
          const i18lan = localStorage.getItem('i18nextLng');
          dispatch({ type: SELECTED_LANGUAGE, payload: i18lan });

          if (checkLoggedInUserPermissions('load-card')) {
            history.push({
              pathname: '/card/load-card',
              search: `?lan=${i18lan}`,
            });
          } else {
            history.push({
              pathname: '/',
              search: `?lan=${i18lan}`,
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: ERROR_LOGIN_USER_TAG_PERMISSION, payload: error });
      });
  };

export const LOADING_LOGIN_USER_SETTINGS = `${PREFIX}LOADING_LOGIN_SETTINGS`;
export const RECEIVED_LOGIN_USER_SETTINGS = `${PREFIX}RECEIVED_LOGIN_USER_SETTINGS`;
export const ERROR_LOGIN_USER_SETTINGS = `${PREFIX}ERROR_LOGIN_USER_SETTINGS`;

export const getUserSettings = () => (dispatch, getState) => {
  dispatch({ type: LOADING_LOGIN_USER_SETTINGS });

  const { tagID } = getState().tag;
  const { id } = getState().auth.user;

  return authServerAPI
    .get(
      `/api/user/${id}/entity/${tagID}/type/tag/client/${window?.appConfig.REACT_APP_AUTH_CLIENT_ID}/settings`,
    )
    .then((response) => {
      dispatch({ type: RECEIVED_LOGIN_USER_SETTINGS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: ERROR_LOGIN_USER_SETTINGS, payload: error });
    });
};

export const CREATING_LOGIN_USER_SETTINGS = `${PREFIX}CREATING_LOGIN_USER_SETTINGS`;
export const CREATED_LOGIN_USER_SETTINGS = `${PREFIX}CREATED_LOGIN_USER_SETTINGS`;
export const FAIL_CREATING_LOGIN_USER_SETTINGS = `${PREFIX}FAIL_CREATING_LOGIN_USER_SETTINGS`;
export const RESET_CREATING_LOGIN_USER_SETTINGS = `${PREFIX}RESET_CREATING_LOGIN_USER_SETTINGS`;

export const createUserSettings = (data) => (dispatch, getState) => {
  dispatch({ type: CREATING_LOGIN_USER_SETTINGS });

  const { tagID } = getState().tag;
  const { id } = getState().auth.user;

  const params = {
    notifications: data.notifications,
    start_page: data.start_page,
    default_language: data.default_language,
  };

  return authServerAPI
    .post(
      `/api/user/${id}/entity/${tagID}/type/tag/client/${window?.appConfig.REACT_APP_AUTH_CLIENT_ID}/settings`,
      { ...params },
    )
    .then((response) => {
      dispatch({ type: CREATED_LOGIN_USER_SETTINGS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: FAIL_CREATING_LOGIN_USER_SETTINGS, payload: error });
    });
};

export const logout = (history) => (dispatch) => {
  dispatch({ type: USER_LOGOUT, payload: history.location.pathname });
};
