import {
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS,
  RESET_SHOPS,
  FETCH_SHOPS_ERROR,
  UPDATE_SHOP_ACCEPTANCE_REQUEST,
  UPDATE_SHOP_ACCEPTANCE_SUCCESS,
  UPDATE_SHOP_ACCEPTANCE_ERROR,
} from '../../actions/Shops/ListOfShopsActions';

const initialState = {
  shops: {
    data: [],
    meta: {},
    loading: false,
    fetched: false,
    error: null,
  },
  countByStatus: {
    accepted: 0,
    unconfirmed: 0,
    rejected: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SHOPS:
      return {
        ...state,
        shops: {
          ...state.shops,
          loading: true,
        },
      };
    case FETCH_SHOPS_SUCCESS:
      const countByStatus = (action.payload.meta.summary || []).reduce(
        (summary, item) => ({
          ...summary,
          [item.status]: item.total,
        }),
        {},
      );

      return {
        ...state,
        shops: {
          data: action.payload.data.map((shop) => ({
            ...shop,
            serialNumbers: shop.terminals.flatMap((terminal) =>
              terminal.devices.flatMap((device) => device.serial_number),
            ),
          })),
          meta: action.payload.meta,
          loading: false,
          fetched: true,
          error: null,
        },
        countByStatus,
      };
    case FETCH_SHOPS_ERROR:
      return {
        ...state,
        shops: {
          ...state.shops,
          meta: {},
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_SHOP_ACCEPTANCE_REQUEST:
      return {
        ...state,
        shops: {
          ...state.shops,
          loading: true,
        },
      };
    case UPDATE_SHOP_ACCEPTANCE_SUCCESS:
      return {
        ...state,
        shops: {
          ...state.shops,
          loading: false,
        },
      };
    case UPDATE_SHOP_ACCEPTANCE_ERROR:
      return {
        ...state,
        shops: {
          ...state.shops,
          loading: false,
          error: action.payload,
        },
      };
    case RESET_SHOPS:
      return {
        ...state,
        shops: initialState.shops,
        countByStatus: initialState.countByStatus,
      };
    default:
      return state;
  }
}
