import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import DefaultModal from '../../../Modal/DefaultModal';
import { Button } from '../../../Button';
import { DataList, DataListItem } from '../../../DataList';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../Form';
import SelectField from '../../../Input/SelectField';
import { useShoppingCentres } from '../../../../hooks/useShoppingCentres';
import { useShop } from '../../../../hooks/useShop';
import SuccessAlert from '../../../Alert/SuccessAlert';
import { acceptanceStatus, editMode } from '../../../../enums/shop';
import { MerchantCodeDescription } from '../../../Merchant';
import {
  useCreateMerchant,
  useMerchants,
} from '../../../../hooks/useMerchants';
import FailAlert from '../../../Alert/FailAlert';

function LocationField({ locations }) {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      locations.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [locations],
  );

  return (
    <FormField
      name="tag_id"
      render={(field) => {
        if (options.length === 1) {
          return null;
        }

        const selected = options.find((option) => option.value === field.value);

        return (
          <FormItem horizontal>
            <FormLabel className="font-MulishBold w-48">
              {t('shops.manage.results.location')}:
            </FormLabel>
            <FormControl>
              <SelectField
                options={options}
                inputRef={field.ref}
                className="w-60"
                value={selected}
                onChange={(option) => {
                  field.onChange(option.value);
                }}
              />
            </FormControl>
          </FormItem>
        );
      }}
    ></FormField>
  );
}

function MerchantField() {
  const { setError } = useFormContext();
  const { t } = useTranslation();
  const { merchants, isLoading, fetch } = useMerchants();
  const { createMerchant } = useCreateMerchant();

  const options = useMemo(
    () =>
      merchants.map((merchant) => ({
        value: merchant.id,
        label: merchant.name,
      })),
    [merchants],
  );

  return (
    <FormField
      name="merchant_id"
      rules={{
        required: t('validation.required', {
          attribute: t('shops.manage.results.merchant'),
        }),
      }}
      render={(field) => {
        const selected = options.find((option) => option.value === field.value);

        async function onCreateOption(inputValue) {
          try {
            const response = await createMerchant({ name: inputValue });
            await fetch();
            field.onChange(response.id);
          } catch (error) {
            const message = error.validationErrors?.name[0] || error.message;

            setError('merchant_id', {
              type: 'api',
              message,
            });
          }
        }

        return (
          <FormItem horizontal>
            <FormLabel className="font-MulishBold w-48">
              {t('shops.manage.results.merchant')}:
            </FormLabel>
            <div className="flex flex-col space-y-2">
              <FormControl>
                <SelectField
                  options={options}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  inputRef={field.ref}
                  className="w-60"
                  value={selected}
                  isLoading={isLoading}
                  onCreateOption={onCreateOption}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(option) => {
                    field.onChange(option.value);
                  }}
                />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        );
      }}
    ></FormField>
  );
}

function EditShopModal({ mode, shop, onClose, onSuccess = () => {} }) {
  const { t } = useTranslation();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const { update, updateAcceptanceStatus } = useShop();
  const { shoppingCentresForBrand, currentShoppingCentreId } =
    useShoppingCentres();

  const { status } = shop;

  const formMethods = useForm({
    defaultValues: {
      tag_id: shop.location?.id || currentShoppingCentreId,
      merchant_id: shop.merchant?.id || null,
    },
  });

  const {
    formState: { isSubmitting, errors },
  } = formMethods;

  const requiresAuthorization = useMemo(
    () => mode === editMode.AUTHORIZE,
    [status],
  );

  async function onSubmit(data) {
    try {
      await update(shop.id, {
        tag_id: data.tag_id,
        merchant_id: data.merchant_id,
      });

      if (requiresAuthorization) {
        await updateAcceptanceStatus(shop, acceptanceStatus.ACCEPTED);
      }

      setIsSuccessful(true);
    } catch (error) {
      formMethods.setError('root.serverError', {
        type: 'api',
        message: error.message,
      });

      return;
    }

    setTimeout(() => {
      setIsSuccessful(false);
    }, 5000);

    if (requiresAuthorization) {
      onClose();
    }

    onSuccess();
  }

  function onSave(event) {
    event.preventDefault();
    formMethods.clearErrors('root');
    formMethods.handleSubmit(onSubmit)();
  }

  return (
    <DefaultModal
      isOpen={true}
      onClose={onClose}
      size="lg"
      title={t('shops.manage.edit.title')}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={onSave} className="space-y-4 mt-4">
          <DataList>
            <DataListItem horizontal label={t('shops.manage.results.name')}>
              {shop.acquirer?.name || shop.name || '-'}
            </DataListItem>
            <DataListItem horizontal label={t('shops.manage.results.mcc')}>
              {shop.acquirer?.code || '-'}
            </DataListItem>
            <DataListItem
              horizontal
              label={t('shops.manage.results.mcc_description')}
            >
              <MerchantCodeDescription code={shop.acquirer?.code} />
            </DataListItem>
            <DataListItem horizontal label={t('shops.manage.results.city')}>
              {shop.acquirer?.city || '-'}
            </DataListItem>
            <DataListItem horizontal label={t('shops.manage.results.street')}>
              {shop.acquirer?.street || '-'}
            </DataListItem>
            <DataListItem
              horizontal
              label={t('shops.manage.results.postal_code')}
            >
              {shop.acquirer?.postcode || '-'}
            </DataListItem>
          </DataList>
          <hr />
          <div className="space-y-2">
            <LocationField locations={shoppingCentresForBrand} />
            <MerchantField />
          </div>
          <hr />
          {isSuccessful && (
            <SuccessAlert
              message={t('shops.manage.edit.success.description')}
            />
          )}
          {errors?.root?.serverError && (
            <FailAlert message={errors.root.serverError.message} />
          )}
          <div className="grid grid-cols-2 gap-x-4">
            <Button
              type="submit"
              variant="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {requiresAuthorization
                ? t('shops.manage.actions.authorize')
                : t('shops.manage.edit.actions.save')}
            </Button>
            <Button type="button" onClick={onClose} variant="secondary">
              {t('shops.manage.edit.actions.cancel')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </DefaultModal>
  );
}

export default EditShopModal;
