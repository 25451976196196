import React, { useState, useEffect } from 'react';
import ClearIcon from '../../assets/svg/ClearIcon';
import EditIcon from '../../assets/svg/EditIcon';

export default function Editable({
  text,
  type,
  placeholder,
  children,
  childRef,
  dataTestId,
  ...props
}) {
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);

  const handleKeyDown = (event, type) => {
    const { key } = event;
    const keys = ['Escape', 'Tab'];
    const enterKey = 'Enter';
    const allKeys = [...keys, enterKey];
    if (
      (type === 'textarea' && keys.indexOf(key) > -1) ||
      (type !== 'textarea' && allKeys.indexOf(key) > -1)
    ) {
      setEditing(false);
    }
  };

  return (
    <section {...props}>
      {isEditing ? (
        <div
          onBlur={() => setEditing(false)}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <div
          className={`rounded py-2 leading-tight whitespace-pre-wrap hover:shadow-outline editable-${type}`}
          onClick={() => setEditing(true)}
        >
          <div>
            <div className="relative" data-testid={dataTestId}>
              <span className="absolute inset-y-0 -right-10 flex items-center pl-2">
                <button
                  id="editButton"
                  className="focus:outline-none focus:shadow-outline"
                >
                  <EditIcon />
                </button>
              </span>
              {text || placeholder || 'Set alias'}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
