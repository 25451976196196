import React from 'react';
import { GF_LIGHT_BLUE } from '../../utils/Constants';

export default function ActiveIcon({ w, h }) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8359 3.66406C18.737 5.53906 19.6875 7.81771 19.6875 10.5C19.6875 13.1823 18.737 15.474 16.8359 17.375C14.9609 19.25 12.6823 20.1875 10 20.1875C7.31771 20.1875 5.02604 19.25 3.125 17.375C1.25 15.474 0.3125 13.1823 0.3125 10.5C0.3125 7.81771 1.25 5.53906 3.125 3.66406C5.02604 1.76302 7.31771 0.8125 10 0.8125C12.6823 0.8125 14.9609 1.76302 16.8359 3.66406ZM8.86719 15.6172L16.0547 8.42969C16.3672 8.14323 16.3672 7.85677 16.0547 7.57031L15.1953 6.67188C14.8828 6.38542 14.5833 6.38542 14.2969 6.67188L8.4375 12.5312L5.70312 9.79688C5.41667 9.51042 5.11719 9.51042 4.80469 9.79688L3.94531 10.6953C3.63281 10.9818 3.63281 11.2682 3.94531 11.5547L8.00781 15.6172C8.29427 15.9297 8.58073 15.9297 8.86719 15.6172Z"
        fill={GF_LIGHT_BLUE}
      />
    </svg>
  );
}
