import { issuingAPI } from '../services/Axios';
import { exportCSVFile, exportExcelFile } from '../utils/Helper';
import {
  APP_COUNT,
  ERROR_EXCEL_REPORT,
  ERROR_CSV_REPORT,
  GET_SHOP_LIST_API_COUNT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
} from './CommonActions';

export const PREFIX = '@shopList/';

export const LOADING_SHOP_LIST = `${PREFIX}LOADING_SHOP_LIST`;
export const RECEIVED_SHOP_LIST = `${PREFIX}RECEIVED_SHOP_LIST`;
export const ERROR_SHOP_LIST = `${PREFIX}ERROR_SHOP_LIST`;

export const LOADING_SHOP_LIST_COUNT = `${PREFIX}LOADING_SHOP_LIST_COUNT`;
export const RECEIVED_SHOP_LIST_COUNT = `${PREFIX}RECEIVED_SHOP_LIST_COUNT`;
export const ERROR_SHOP_LIST_COUNT = `${PREFIX}ERROR_SHOP_LIST_COUNT`;

export const CHANGING_SHOP_STATUS = `${PREFIX}CHANGING_SHOP_STATUS`;
export const SUCCESS_SHOP_STATUS_CHANGE = `${PREFIX}SUCCESS_SHOP_STATUS_CHANGE`;
export const FAIL_SHOP_STATUS_CHANGE = `${PREFIX}FAIL_SHOP_STATUS_CHANGE`;

export const RESET_SHOP_LIST = `${PREFIX}RESET_SHOP_LIST`;

export const LIST_OF_SHOP_OPERATION_ID = `${PREFIX}LIST_OF_SHOP_OPERATION_ID`;

export const getShopList = (keyword, status, page) => (dispatch, getState) => {
  dispatch({ type: LOADING_SHOP_LIST });

  dispatch({ type: APP_COUNT });

  const { tagCardBrands, tagID } = getState().tag;

  const params = {
    per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    card_brand_ids: tagCardBrands,
  };

  if (keyword !== '') {
    Object.assign(params, { query: keyword });
  }

  if (status !== -1) {
    Object.assign(params, { shop_status: status });
  }

  if (page) {
    Object.assign(params, { page });
  }

  return issuingAPI
    .get(`/tag/${tagID}/shop`, { params })
    .then((success) => {
      dispatch({ type: RECEIVED_SHOP_LIST, payload: success.data });
      dispatch({ type: LIST_OF_SHOP_OPERATION_ID });
    })
    .catch((error) => {
      dispatch({ type: ERROR_SHOP_LIST, payload: error });
      return error;
    });
};

export const getShopListCount = () => (dispatch, getState) => {
  dispatch({ type: LOADING_SHOP_LIST_COUNT });

  dispatch({ type: GET_SHOP_LIST_API_COUNT });

  const { tagCardBrands, tagID } = getState().tag;

  const params = {
    card_brand_ids: tagCardBrands,
  };

  return issuingAPI
    .get(`/tag/${tagID}/shop/get-count`, { params })
    .then((success) => {
      dispatch({ type: RECEIVED_SHOP_LIST_COUNT, payload: success.data });
    })
    .catch((error) => {
      dispatch({ type: ERROR_SHOP_LIST_COUNT, payload: error });
      return error;
    });
};

export const changeShopStatus =
  (shopID, currentStatus, newStatus) => (dispatch, getState) => {
    dispatch({ type: CHANGING_SHOP_STATUS });

    const { tagCardBrands, tagID } = getState().tag;

    const { changeShopStatusOpID } = getState().shopList;
    const headers = {
      'operation-id': changeShopStatusOpID,
    };

    const data = {
      status: newStatus,
    };

    return issuingAPI
      .put(
        `/tag/${tagID}/shop/${shopID}/card-brand/${tagCardBrands}/status/${currentStatus}`,
        { ...data },
        { headers },
      )
      .then((success) => {
        dispatch({ type: SUCCESS_SHOP_STATUS_CHANGE, payload: success.data });
      })
      .catch((error) => {
        dispatch({ type: FAIL_SHOP_STATUS_CHANGE, payload: error });
        return error;
      });
  };

export const getListofShopsCSV = (status) => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT });

  const { tagID } = getState().tag;
  const params = {};

  if (status !== -1) {
    Object.assign(params, { shop_status: status });
  }

  const headers = {
    Accept: 'text/csv',
  };

  const fileName = `List_of_Shops`;

  return issuingAPI
    .get(`/tag/${tagID}/shop/stream`, { params, headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT });
      exportCSVFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error });
      return error;
    });
};

export const getListofShopsExcel = (status) => (dispatch, getState) => {
  dispatch({ type: LOADING_EXCEL_REPORT });

  const { tagID } = getState().tag;
  const params = {};

  if (status !== -1) {
    Object.assign(params, { shop_status: status });
  }

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const fileName = `List_of_Shops`;

  return issuingAPI
    .get(`/tag/${tagID}/shop/stream`, { responseType: 'blob', params, headers })
    .then((success) => {
      dispatch({ type: SUCCESS_EXCEL_REPORT });
      exportExcelFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
      return error;
    });
};
