import { issuingAPI } from '../services/Axios';
import { useSelector } from 'react-redux';

export function useTicketNumber() {
  const { selectedTag } = useSelector((state) => state.tag);
  async function generate() {
    const response = await issuingAPI.get(`/ticket`);

    return response.data.ticket;
  }

  async function fetch(ticketNumber) {
    const response = await issuingAPI.get(
      `/tag/${selectedTag.id}/ticket/${ticketNumber}`,
    );

    return response.data;
  }

  return {
    generate,
    fetch,
  };
}
