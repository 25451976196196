import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  addCompleteUser,
  getUserListAfterChanges,
  RESET_ADDING_USER,
} from '../../../actions/Admin/UserManagement/UserManagementActions';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import CloseIcon from '../../../assets/svg/CloseIcon';
import FailAlert from '../../Alert/FailAlert';
import CheckBox from './CheckBox';
import ValidationErrors from '../../Alert/ValidationErrors';

ReactModal.setAppElement('#root');

export default function AddExistingUser({ isOpen, closeHandler, userData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset, setValue } = useForm();
  const { user, addUserPermissions } = useSelector((state) => state.auth);
  const { addingUser, successAddingUser, failAddingUser } = useSelector(
    (state) => state.userManagement,
  );
  const { validationErrors } = useSelector((state) => state.commonReducer);

  addUserPermissions.forEach((item) => {
    item.list.forEach((li) => {
      user.permissions.forEach((user) => {
        if (user === li.guard_name) {
          li.disabled = false;
        }
      });
    });
  });

  const [selectedPermission, updateSelected] = useState(addUserPermissions);

  const onSubmit = (data) => {
    console.log(data);
    const ids = [];

    selectedPermission.forEach((menu) => {
      menu.list.forEach((item) => {
        if (item.isChecked) {
          ids.push(item.permission_id);
        }
      });
    });

    dispatch(addCompleteUser(data, ids));
  };

  const handleCheckChieldElement = (event, item) => {
    const fruites = selectedPermission.slice();
    fruites.forEach((fruite) => {
      fruite.list.forEach((per) => {
        if (per.guard_name === item.guard_name) {
          // console.log(item);
          per.isChecked = !per.isChecked;
        }
      });
    });

    const newUsers = fruites.slice();
    updateSelected(newUsers);
  };

  const handleAllChecked = (event) => {
    const fruites = selectedPermission.slice();
    fruites.forEach((fruite) => {
      fruite.list.forEach((per) => {
        per.isChecked = event.target.checked;
      });
    });

    const newUsers = fruites.slice();
    updateSelected(newUsers);
  };

  const checkHiddenPermissions = (permission) => {
    const permissions =
      window?.appConfig.REACT_APP_HIDDEN_PERMISSIONS.split(',');

    return !permissions.includes(permission.guard_name);
  };

  const okHandler = () => {
    dispatch(getUserListAfterChanges());
    closeHandler(!isOpen);
    dispatch({ type: RESET_ADDING_USER });
  };

  useEffect(() => {
    console.log(userData);
    const fields = ['name', 'email', 'first_name', 'last_name'];

    if (isOpen && userData) {
      setTimeout(
        () => fields.forEach((field) => setValue(field, userData[field])),
        600,
      );
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-10 border max-w-xl rounded shadow-md bg-white text-gfDarkBlue font-MulishRegular">
        <div>
          <div className="flex justify-between items-center mb-5">
            <div className="font-MulishBold">{t('Create-New-User')}</div>
            <button
              onClick={() => {
                closeHandler(!isOpen);
                dispatch({ type: RESET_ADDING_USER });
              }}
            >
              <CloseIcon />
            </button>
          </div>

          {successAddingUser === null ? (
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-5">
                <div className="flex flex-col">
                  <div className="flex justify-start font-MulishBold">
                    {t('First-name')}
                  </div>
                  <input
                    name="first_name"
                    ref={register({ required: true })}
                    type="text"
                    autoComplete="off"
                    className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                  />
                  {errors.first_name && (
                    <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="flex justify-start font-MulishBold">
                    {t('Last-name')}
                  </div>
                  <input
                    name="last_name"
                    ref={register({ required: true })}
                    type="text"
                    autoComplete="off"
                    className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                  />
                  {errors.last_name && (
                    <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="flex justify-start font-MulishBold">
                    {t('Email-address')}
                  </div>
                  <input
                    name="email"
                    ref={register({
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    type="text"
                    autoComplete="off"
                    className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                  )}
                  {errors.email && errors.email.type === 'pattern' && (
                    <p className="text-xs text-gfCoral p-1">
                      {t('Please-enter-valid-email')}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-start items-center py-6 font-MulishBold">
                <input
                  className="mr-2"
                  type="checkbox"
                  onClick={(event) => handleAllChecked(event)}
                  value="checkedall"
                />
                {t('Select-All-Permissions')}
              </div>

              <div className="py-5">
                {selectedPermission.map((per) => (
                  <div
                    key={per.name}
                    className="flex flex-col justify-start items-start"
                  >
                    <div className="px-4 font-MulishBold">
                      {t(String(per.name).replace(/\s+/g, '-'))}
                    </div>
                    <ul className="mb-5">
                      {per.list.map(
                        (item) =>
                          checkHiddenPermissions(item) && (
                            <div key={item.permission_id}>
                              <CheckBox
                                handleCheckChieldElement={(event) =>
                                  handleCheckChieldElement(event, item)
                                }
                                {...item}
                              />
                            </div>
                          ),
                      )}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="flex flex-row justify-between space-x-10">
                <button
                  type="submit"
                  className="w-full flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                >
                  {addingUser && <ButtonLoading color={'#4A5568'} />}
                  {!addingUser && <span>{t('Create')}</span>}
                </button>

                <input
                  disabled={addingUser}
                  onClick={() => reset()}
                  type="button"
                  value={t('Reset')}
                  className="w-full border rounded px-4 py-2  bg-gfPeriwinkle font-MulishBold focus:outline-none  hover:bg-opacity-75  cursor-pointer"
                />
              </div>

              {failAddingUser && (
                <div className="py-2">
                  <FailAlert message={failAddingUser} />
                </div>
              )}

              {validationErrors && (
                <div className="py-2">
                  <ValidationErrors {...validationErrors} />
                </div>
              )}
            </form>
          ) : (
            <div className="flex flex-col justify-center items-center p-5">
              <p className="flex justify-start p-2">
                {t('User-has-been-successfully-created-A-confirmation-email')}
              </p>
              <button
                className="w-full flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                onClick={okHandler}
              >
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
