/* eslint-disable import/no-anonymous-default-export */
import {
  LOADING_CARD_BLOCK_UNBLOCK,
  SUCCESS_CARD_BLOCK_UNBLOCK,
  FAIL_CARD_BLOCK_UNBLOCK,
  ERROR_CARD_INFORMATION,
  ERROR_CARD_TRANSACTION_INFO,
  LOADING_CARD_INFORMATION,
  LOADING_CARD_TRANSACTIONS,
  RECEIVED_CARD_INFORMATION,
  RECEIVED_CARD_TRANSACTION_INFO,
  RESET_CARD_INFO,
  LOADING_SEARCH_CARD_LIST,
  RECEIVED_SEARCH_CARD_LIST,
  ERROR_SEARCH_CARD_LIST,
  CHANGE_COUNTRY_PHONE_NUMBER,
  CHANGE_SEARCH_TYPE,
  RESET_SEARCH_TYPE,
  CHANGE_PHONE_NUMBER,
  SWITCH_TO_LIST_VIEW,
  RESET_SEARCH_CARD_LIST,
} from '../actions/CardInformationActions';

const initialState = {
  loadingCardInformation: false,
  cardInformation: null,
  errorCardInformation: null,

  loadingCardTransactions: false,
  cardTransactionInfo: null,
  errorTransactionInfo: null,
  cardTransactionMeta: null,

  loadingBlockUnblock: false,
  successBlockUnblock: null,
  failBlockUnblock: null,

  cardPhoneNumber: '',
  loadListSearchCardByPhone: false,
  listCardSearchByPhone: null,
  listCardSearchByPhoneMeta: null,
  errorListCardSearchByPhone: null,

  selectedPhoneNumberCountry: 'ae',
  selectedSearchType: {
    id: 0,
    name: 'Token',
    value: 'token',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_CARD_INFORMATION:
      return {
        ...state,
        loadingCardInformation: true,
        errorCardInformation: null,
        cardInformation: null,
      };

    case RECEIVED_CARD_INFORMATION:
      return {
        ...state,
        cardInformation: action.payload,
        loadingCardInformation: false,
        errorCardInformation: null,
      };

    case ERROR_CARD_INFORMATION:
      return {
        ...state,
        cardInformation: null,
        loadingCardInformation: false,
        errorCardInformation: action.payload,
      };

    case LOADING_CARD_TRANSACTIONS:
      return {
        ...state,
        loadingCardTransactions: true,
      };

    case RECEIVED_CARD_TRANSACTION_INFO:
      return {
        ...state,
        loadingCardTransactions: false,
        cardTransactionInfo: action.payload.data,
        errorTransactionInfo: null,
        cardTransactionMeta: action.payload.meta,
      };

    case ERROR_CARD_TRANSACTION_INFO:
      return {
        ...state,
        loadingCardTransactions: false,
        cardTransactionInfo: null,
        errorTransactionInfo: action.payload,
      };

    case RESET_CARD_INFO:
      return {
        ...initialState,
        ...state,
      };

    case LOADING_CARD_BLOCK_UNBLOCK:
      return {
        ...state,
        loadingBlockUnblock: true,
        successBlockUnblock: null,
        failBlockUnblock: null,
      };

    case SUCCESS_CARD_BLOCK_UNBLOCK:
      return {
        ...state,
        loadingBlockUnblock: false,
        successBlockUnblock: action.payload,
        failBlockUnblock: null,
      };

    case FAIL_CARD_BLOCK_UNBLOCK:
      return {
        ...state,
        loadingBlockUnblock: false,
        successBlockUnblock: null,
        failBlockUnblock: action.payload,
      };

    case LOADING_SEARCH_CARD_LIST:
      return {
        ...state,
        loadListSearchCardByPhone: true,
        listCardSearchByPhone: null,
        errorListCardSearchByPhone: null,
      };

    case RECEIVED_SEARCH_CARD_LIST:
      return {
        ...state,
        loadListSearchCardByPhone: false,
        listCardSearchByPhone: action.payload.data,
        listCardSearchByPhoneMeta: action.payload.meta,
        errorListCardSearchByPhone: null,
      };

    case ERROR_SEARCH_CARD_LIST:
      return {
        ...state,
        loadListSearchCardByPhone: false,
        listCardSearchByPhone: null,
        errorListCardSearchByPhone: action.payload,
      };

    case RESET_SEARCH_CARD_LIST:
      return {
        ...state,
        listCardSearchByPhone: null,
        listCardSearchByPhoneMeta: null,
      };

    case CHANGE_SEARCH_TYPE:
      return {
        ...state,
        selectedSearchType: action.payload,
      };

    case RESET_SEARCH_TYPE:
      return {
        ...state,
        selectedSearchType: initialState.selectedSearchType,
        listCardSearchByPhoneMeta: null,
      };

    case CHANGE_PHONE_NUMBER:
      return {
        ...state,
        cardPhoneNumber: action.payload.phone_number,
        selectedPhoneNumberCountry: action.payload.country_code,
      };

    default:
      return state;
  }
}
