import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import DropdownIndicator from './Components/DropdownIndicator';

export default function OnlineSaleStatusDropdown({
  options,
  modalData,
  onChangeHandler,
}) {
  const { t } = useTranslation();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: '#D8D8D9',
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: '#4A5568',
      cursor: 'pointer',

      '&:hover': {
        background: '#F7F7F8',
        color: '#4A5568',
      },

      '&:hover div': {
        color: '#4A5568',
      },

      '&:focus': {
        borderColor: '#E2007A',
        background: '#F7F7F8',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FFFFFF' : '#FFFFFF',
      color: '#4A5568',
      textAlign: 'left',
      '&:hover': {
        background: '#FF666E',
        color: '#FFF',
      },
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: '100%',
      color: '#4A5568',
      textAlign: 'left',
      zIndex: '9999',
    }),

    singleValue: (provided) => ({
      ...provided,
      color: '#4A5568',
    }),

    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  };
  const handleChanged = (event) => {
    onChangeHandler(modalData, event);
  };

  const defaultValue = {
    id: 'awaiting-payment',
    name: `${t('awaiting-payment').toUpperCase()}`,
  };

  return (
    <div className="w-48">
      <Select
        menuPortalTarget={document.body}
        menuPlacement="auto"
        minMenuHeight={300}
        defaultValue={defaultValue}
        onChange={handleChanged}
        placeholder={false}
        styles={customStyles}
        options={options && options.constructor === Array ? options : []}
        getOptionLabel={(option) => `${option.name}`}
        isSearchable={false}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
      />
    </div>
  );
}
