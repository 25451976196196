import React, { useEffect, useState, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Userpilot } from 'userpilot';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import ReactPaginate from 'react-paginate';
import CurrencyFormat from 'react-currency-format';
import { Link, useHistory } from 'react-router-dom';
import Container from '../../UIComponents/Container';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../utils/Constants';
import {
  RESET_TRANSACTION_OVERVIEW,
  getTransactionOverview,
  getMVCOverviewExcelExport,
} from '../../../actions/TransactionOverviewActions';
import PaymentDirectionDropdown from '../../Dropdown/PaymentDirectionDropdown';
import Table from '../../UIComponents/Table';
import MessageAlert from '../../Alert/MessageAlert';
import FailAlert from '../../Alert/FailAlert';
import {
  checkLoggedInUserPermissions,
  randomString,
} from '../../../utils/Helper';
import { RESET_APP_COUNT } from '../../../actions/CommonActions';
import CardInfoWidget from '../../CardInfoWidget';

const DPCustomInput = forwardRef(({ value, onClick, id }, ref) => (
  <input
    data-testid={id}
    ref={ref}
    value={value}
    onClick={onClick}
    readOnly={true}
    className="border cursor-pointer text-center w-full rounded hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 h-10 focus:outline-none"
  />
));

const AmountColumn = ({ amount, currency, className }) => (
  <div
    className={`flex justify-center items-center ${className}  ${amount === 0 ? 'text-gfLightBlue' : amount > 0 ? 'text-gfPositiveAmount' : 'text-gfNegativeAmount'}`}
  >
    <CurrencyFormat
      decimalScale={2}
      fixedDecimalScale={true}
      value={amount}
      displayType={'text'}
      thousandSeparator={true}
    />
    <span className="ml-1">{`${String(currency).toUpperCase()}`}</span>
  </div>
);

const TransactionDetailAccount = ({ accounts, linkClick }) => {
  const { t } = useTranslation();

  const canAccessCardInformation = useMemo(
    () => checkLoggedInUserPermissions('card-information'),
    [checkLoggedInUserPermissions],
  );

  return (
    <div className="flex flex-col justify-center items-center">
      {accounts.map((item, index) =>
        item.type === 'card' && canAccessCardInformation ? (
          <Link
            key={index}
            className="underline"
            onClick={linkClick}
            to={`/gift-cards/card-information?token=${item.name}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${item.name}${index + 1 === accounts.length ? '' : ','}`}
          </Link>
        ) : (
          <div key={randomString(5)}>{t(item.name)}</div>
        ),
      )}
    </div>
  );
};

const TransactionDetailRow = ({ transaction, currency, linkClick }) => {
  const { t } = useTranslation();
  return (
    <tr key={transaction.transaction_id}>
      <td data-testid="dateID" className="px-4 py-4">
        <div className="flex justify-center items-center">
          {transaction.date}
        </div>
      </td>
      <td data-testid="transactionID" className="px-4 py-4">
        <div className="flex justify-center items-center">
          {transaction.transaction_id}
        </div>
      </td>
      <td data-testid="amountID" className="px-4 py-4">
        <AmountColumn
          amount={transaction.amount}
          currency={currency}
          className={'font-MulishBold'}
        />
      </td>
      <td data-testid="transactionTypeID" className="px-4 py-4">
        <div className="flex justify-center items-center">
          {t(`transaction-overview.type.${transaction.type}`)}
        </div>
      </td>
      <td data-testid="transactionSourceID" className="px-4 py-4">
        <TransactionDetailAccount
          accounts={transaction.source}
          linkClick={linkClick}
        />
      </td>
      <td data-testid="transactionDestinationID" className="px-4 py-4">
        <TransactionDetailAccount
          accounts={transaction.destination}
          linkClick={linkClick}
        />
      </td>
      <td data-testid="transactionCurrencyID" className="px-4 py-4">
        <AmountColumn
          amount={transaction.balance}
          currency={currency}
          className={'font-MulishRegular'}
        />
      </td>
    </tr>
  );
};

export default function TransactionOverview(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadingTransactionOverview,
    receivedTransactionOverview,
    errorTransactionOverView,
    metaTransactionOverview,
    summaryOverview,
  } = useSelector((state) => state.transactionOverview);
  const { tagCurrency } = useSelector((state) => state.tag);
  const { selectedLanguage } = useSelector((state) => state.language);
  const { appCount, loadingExcel, errorExcel } = useSelector(
    (state) => state.commonReducer,
  );

  const [mvcToken, setMVCToken] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [paymentDirection, setPaymentDirection] = useState({
    id: 'all',
    name: t('All'),
  });

  useEffect(() => {
    Userpilot.reload();
    if (appCount === 0) dispatch({ type: RESET_TRANSACTION_OVERVIEW });

    const { token } = props.match.params;
    if (token) {
      setMVCToken(token);
    } else {
      history.push('/mvc-management/mvc-list');
    }
    dispatch(
      getTransactionOverview(
        null,
        null,
        paymentDirection.id,
        selectedPage,
        token,
      ),
    );
  }, []);

  const paymentDirectionChangeHandler = (event) => {
    setPaymentDirection(event);
    dispatch({ type: RESET_TRANSACTION_OVERVIEW });
  };

  const getMVCTransactionOverview = () => {
    const from = fromDate ? format(fromDate, 'yyyy-MM-dd') : null;
    const to = toDate !== null ? format(toDate, 'yyyy-MM-dd') : from;

    dispatch(
      getTransactionOverview(
        from,
        to,
        paymentDirection.id,
        selectedPage,
        mvcToken,
      ),
    );
  };

  const handlePageClick = (event) => {
    setSelectedPage(event.selected + 1);

    const from = fromDate ? format(fromDate, 'yyyy-MM-dd') : null;
    const to = toDate !== null ? format(toDate, 'yyyy-MM-dd') : from;

    dispatch(
      getTransactionOverview(
        from,
        to,
        paymentDirection.id,
        event.selected + 1,
        mvcToken,
      ),
    );
  };

  const getMVCTransactionOverviewCSV = () => {
    const from = fromDate ? format(fromDate, 'yyyy-MM-dd') : null;
    const to = toDate !== null ? format(toDate, 'yyyy-MM-dd') : from;

    dispatch(
      getMVCOverviewExcelExport(
        from,
        to,
        paymentDirection.id,
        selectedPage,
        mvcToken,
      ),
    );
  };

  const linkClickHandler = () => {
    dispatch({ type: RESET_APP_COUNT });
  };

  return (
    <Container
      title={t('transaction-overview.transaction-overview-for', {
        mvcToken,
      })}
    >
      <div className="grid grid-cols-1 md:grid-cols-4 items-end gap-5 mb-10">
        <div className="flex flex-col">
          <div className="flex items-center  py-1">{t('Choose-the-date')}:</div>{' '}
          <ReactDatePicker
            id="chooseDateID"
            showMonthDropdown
            showYearDropdown
            disabled={loadingTransactionOverview}
            dateFormat="dd/MM/yyyy"
            customInput={<DPCustomInput />}
            selected={fromDate}
            onChange={(date) => {
              setFromDate(date);
              dispatch({ type: RESET_TRANSACTION_OVERVIEW });
            }}
            locale={selectedLanguage.value}
            maxDate={new Date()}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center  py-1">
            {`${t('End-date')}(${t('Optional')})`}:
          </div>{' '}
          <ReactDatePicker
            id="endDateID"
            showMonthDropdown
            showYearDropdown
            disabled={loadingTransactionOverview}
            dateFormat="dd/MM/yyyy"
            selected={toDate}
            onChange={(date) => {
              setToDate(date);
              dispatch({ type: RESET_TRANSACTION_OVERVIEW });
            }}
            customInput={<DPCustomInput />}
            locale={selectedLanguage.value}
            maxDate={new Date()}
          />
        </div>

        <div className="flex flex-col w-full">
          <div className="flex items-center  font-medium py-1">
            {t('transaction-overview.payment-direction')}:{' '}
          </div>{' '}
          <PaymentDirectionDropdown
            id={'paymentDirectionDropdownID'}
            value={paymentDirection}
            onChangeHandler={paymentDirectionChangeHandler}
          />
        </div>

        <button
          disabled={loadingTransactionOverview}
          onClick={getMVCTransactionOverview}
          className="h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none"
        >
          {loadingTransactionOverview && <ButtonLoading color={GF_DARK_BLUE} />}
          {t('Go')}!
        </button>
      </div>

      {receivedTransactionOverview &&
        receivedTransactionOverview.length === 0 && (
          <div className="my-10">
            <MessageAlert
              message={t('There-are-no-information-to-display-for-this-date')}
            />
          </div>
        )}
      {errorTransactionOverView && (
        <div className="my-10">
          <FailAlert message={errorTransactionOverView.message} />
        </div>
      )}
      {errorExcel && (
        <div className="my-10">
          <FailAlert message={errorExcel.message} />
        </div>
      )}

      {receivedTransactionOverview &&
        receivedTransactionOverview.length !== 0 && (
          <div className="flex flex-col space-y-5">
            {summaryOverview?.is_settlement_mvc === true && (
              <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
                <CardInfoWidget
                  bottomLabel={t('To-Pay')}
                  isCurrency
                  value={summaryOverview?.to_pay}
                  labelColor={'text-gfDarkBlue'}
                  valueColor={'text-gfDarkBlue'}
                  backgroundColor={'bg-gfCoral'}
                />
                <CardInfoWidget
                  bottomLabel={t('Minimum-Balance-Required')}
                  isCurrency
                  value={summaryOverview?.min_balance_required}
                  labelColor={'text-gfDarkBlue'}
                  valueColor={'text-gfDarkBlue'}
                />
                <CardInfoWidget
                  bottomLabel={t('Current-Balance')}
                  isCurrency
                  value={summaryOverview?.current_balance}
                  labelColor={'text-gfLightBlue'}
                  valueColor={'text-gfLightBlue'}
                />
              </div>
            )}
            <div className="flex flex-col items-end my-4">
              <div className="flex flex-row mb-5">
                <button
                  id="exportCsv"
                  onClick={getMVCTransactionOverviewCSV}
                  className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                >
                  {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
                  {t('export-excel')}
                </button>
              </div>

              {receivedTransactionOverview &&
                metaTransactionOverview &&
                metaTransactionOverview.total >
                  process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                  <div>
                    <ReactPaginate
                      previousLabel={'prev'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={
                        metaTransactionOverview.total /
                        process.env.REACT_APP_PAGINATION_PER_PAGE_ROWS
                      }
                      marginPagesDisplayed={4}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  </div>
                )}
            </div>

            <Table>
              <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('Date')}
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('transaction-overview.transaction-id')}
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('transaction-overview.transaction-amount')}
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3  text-center  uppercase tracking-wider"
                  >
                    {t('transaction-overview.transaction-type')}
                  </th>
                  <th
                    scope="col"
                    className="py-3  text-center  uppercase tracking-wider"
                  >
                    {t('transaction-overview.from')}
                  </th>
                  <th
                    scope="col"
                    className="py-3  text-center  uppercase tracking-wider"
                  >
                    {t('transaction-overview.to')}
                  </th>
                  <th
                    scope="col"
                    className="py-3  text-center  uppercase tracking-wider"
                  >
                    {t('Balance')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gfPeriwinkle">
                {receivedTransactionOverview &&
                  receivedTransactionOverview.map((tr) => (
                    <TransactionDetailRow
                      key={tr.transaction_id}
                      linkClick={linkClickHandler}
                      transaction={tr}
                      currency={tagCurrency}
                    />
                  ))}
              </tbody>
            </Table>
          </div>
        )}
    </Container>
  );
}
