import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactSelect } from 'react-select';
import i18n from 'i18next';

const MySelect = (props) => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].id === props.allOption.id) {
              return props.onChange([...props.options]);
            }
            let result = [];
            if (selected.length === props.options.length - 1) {
              const checkUsername = (obj) => obj.id === -1;
              if (selected.some(checkUsername)) {
                result = selected.filter(
                  (option) => option.id !== props.allOption.id,
                );
              } else if (event.action === 'select-option') {
                result = [...props.options];
              }
              return props.onChange(result);
            }
          }
          return props.onChange(selected);
        }}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        defaultValue={props.defaultValue}
      />
    );
  }

  return <ReactSelect {...props} />;
};

MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.any,
  }),
};

MySelect.defaultProps = {
  allOption: {
    id: -1,
    name: i18n.t('All'),
  },
};

export default MySelect;
