import {
  FETCH_CARRIERS,
  FETCH_CARRIERS_ERROR,
  FETCH_CARRIERS_SUCCESS,
} from '../actions/CarriersActions';

const initialState = {
  loadingCarriers: false,
  carriers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CARRIERS:
      return { ...state, loadingCarriers: true };

    case FETCH_CARRIERS_SUCCESS:
      return { ...state, carriers: action.payload, loadingCarriers: false };

    case FETCH_CARRIERS_ERROR:
      return { ...state, loadingCarriers: false };

    default:
      return state;
  }
}
