import React from 'react';
import ButtonLoading from '../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../utils/Constants';
import PageTitle from '../PageTitle';

/**
 *
 * @param children
 * @param loading
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
export default function TabContainer({ children, loading, title }) {
  return (
    <div
      className={
        'shadow-md text-gfDarkBlue font-MulishRegular rounded mx-1 sm:mx-1 md:mx-1 lg:mx-3 xl:mx-5 2xl:mx-20 bg-white'
      }
    >
      <div className="flex flex-row items-center justify-start">
        {title && <PageTitle title={title} />}
        {loading && <ButtonLoading color={GF_DARK_BLUE}></ButtonLoading>}
      </div>
      <div className="border-t border-gfPeriwinkle pt-5 bg-gray-200">
        {children}
      </div>
    </div>
  );
}
