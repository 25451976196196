/* eslint-disable import/no-anonymous-default-export */

import {
  ERROR_SHOPPING_CENTER_BALANCE,
  LOADING_SHOPPING_CENTER_BALANCE,
  RECEIVED_SHOPPING_CENTER_BALANCE,
} from '../actions/ShoppingCenterBalanceActions';

const initialState = {
  loadingShoppingCenterBalance: false,
  shoppingCenterBalance: null,
  errorShoppingCenterBalance: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVED_SHOPPING_CENTER_BALANCE:
      return {
        ...state,
        loadingShoppingCenterBalance: false,
        errorShoppingCenterBalance: null,
        shoppingCenterBalance: action.payload,
      };

    case LOADING_SHOPPING_CENTER_BALANCE:
      return {
        ...state,
        loadingShoppingCenterBalance: true,
        errorShoppingCenterBalance: null,
        shoppingCenterBalance: null,
      };

    case ERROR_SHOPPING_CENTER_BALANCE:
      return {
        ...state,
        loadingShoppingCenterBalance: false,
        errorShoppingCenterBalance: action.payload,
        shoppingCenterBalance: null,
      };

    default:
      return state;
  }
}
