import React, { forwardRef } from 'react';
import { cn } from '../../utils';

const Input = forwardRef(function ({ className, type, ...props }, ref) {
  return (
    <input
      onWheel={(e) => e.target.blur()}
      type={type}
      data-testid={ref.current.name}
      {...props}
      ref={ref}
      className={cn(
        'border border-gfPeriwinkle rounded-md p-2 h-10 focus:outline-none focus:border-gfPeriwinkle w-full',
        className,
      )}
    />
  );
});

export default Input;
