/* eslint-disable no-loop-func */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimeAgo from 'timeago-react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fromUnixTime } from 'date-fns';
import {
  deleteNotification,
  setNotificationSeen,
} from '../../actions/NotificationActions';
import Container from '../UIComponents/Container';
import MessageAlert from '../Alert/MessageAlert';
import TrashIcon from '../../assets/svg/TrashIcon';

export default function NotificationList() {
  const notificationRoutes = [
    {
      type: 'new_pending_shop',
      route: '/shops/shop-list',
      name: 'List of Shops',
    },
    { type: 'new_merchant', route: '/shops/shop-list', name: 'List of Shops' },
    {
      type: 'new_promo_card_order',
      route: '/promo-cards/order-history',
      name: 'Order History',
    },
    {
      type: 'online_order',
      route: '/online-sales/manage-sales',
      name: 'Manage Sales',
    },
    {
      type: 'mvc_loaded',
      route: '/mvc-management/mvc-transfer-list',
      name: 'MVC Transfer List',
    },
  ];
  const dispatch = useDispatch();
  const { receivedNotifications } = useSelector((state) => state.notification);
  const { t } = useTranslation();

  const onClickHandler = (id, seen) => {
    if (seen === 'unseen') {
      dispatch(setNotificationSeen(id));
    }
  };

  const getRoute = (type) => {
    const r = notificationRoutes.find((element) => element.type === type);
    return r;
  };

  const deleteThisNotification = (id) => {
    dispatch(deleteNotification(id));
  };

  return (
    <Container title={t('Notifications')}>
      <div className="mx-auto">
        {receivedNotifications && receivedNotifications.length !== 0 && (
          <Accordion
            allowZeroExpanded
            className="text-gfDarkBlue shadow-md w-600 border rounded"
          >
            {receivedNotifications.map((notification) => (
              <AccordionItem key={notification.id}>
                <AccordionItemHeading
                  onClick={() =>
                    onClickHandler(notification.id, notification.seen)
                  }
                >
                  <AccordionItemButton>
                    <div className="flex flex-col pl-8 w-full">
                      <div className="flex flex-row justify-items-start items-center space-x-8 mb-2">
                        {notification.seen === 'unseen' && (
                          <div>
                            <div className="rounded-full w-2 h-2 bg-gfCoral"></div>
                          </div>
                        )}
                        <span
                          className={`${notification.seen === 'unseen' ? 'font-bold' : 'font-normal'}`}
                        >
                          {notification.subject !== null
                            ? notification.subject
                            : notification.message}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <TimeAgo
                          datetime={fromUnixTime(notification.timestamp)}
                          className="text-sm text-kadozGray"
                        />
                        <div className="rounded  text-xs px-4 bg-gfSand">
                          {notification.type}
                        </div>
                        <button
                          onClick={() =>
                            deleteThisNotification(notification.id)
                          }
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="py-5 pl-24 pr-4 bg-gray-100">
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row">
                      <span className="font-semibold text-xs mx-2">
                        Message:
                      </span>
                      <span>{notification.message}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="font-semibold  text-xs mx-2">Link:</span>
                      <span className="underline font-medium text-pink-900">
                        <a
                          href={
                            notification.link !== '' ? notification.link : ''
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {notification.type}
                        </a>
                      </span>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </div>

      {receivedNotifications && receivedNotifications.length === 0 && (
        <div>
          <MessageAlert
            message={t('There-are-no-active-notifications')}
          ></MessageAlert>
        </div>
      )}
    </Container>
  );
}
