import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { subMonths, addWeeks } from 'date-fns';
import uniq from 'lodash/uniq';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel } from '../../../Form';
import DatePickerField from '../../../Input/DatePickerField';
import { RadioGroup, RadioGroupButton } from '../../../RadioGroup';
import SelectField from '../../../Input/SelectField';
import MessageAlert from '../../../Alert/MessageAlert';
import { useCardUsageSummary } from '../../../../hooks/useCardUsage';
import { Button } from '../../../Button';
import format from 'date-fns/format';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import FailAlert from '../../../Alert/FailAlert';

function Filters({ onSubmit, onChangeGroupBy }) {
  const { t } = useTranslation();
  const form = useRef(null);
  const { handleSubmit, setValue } = useFormContext();
  const [range, setDateRange] = useState(3);

  const dateRanges = useMemo(() => {
    return [
      { label: `1 ${t('Month')}`, value: 1 },
      { label: `3 ${t('Months')}`, value: 3 },
      { label: `6 ${t('Months')}`, value: 6 },
      { label: `1 ${t('Year')}`, value: 12 },
    ];
  }, [t]);

  const groupBy = useMemo(
    () => [
      { label: t('Day'), value: 'day' },
      { label: t('Week'), value: 'week' },
      { label: t('Month'), value: 'month' },
      { label: t('Year'), value: 'year' },
    ],
    [t],
  );

  function onChangeDateRange(value) {
    const selected = parseInt(value, 10);
    setDateRange(selected);
    setValue('fromDate', subMonths(new Date(), selected));
    setValue('toDate', new Date());

    form.current.requestSubmit();
  }

  function onChangeDate(field, value) {
    field.onChange(value);
    setDateRange(null);

    form.current.requestSubmit();
  }

  return (
    <form
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-flow-col gap-5 items-end"
      ref={form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormField
        name="fromDate"
        render={(field) => {
          return (
            <FormItem>
              <FormControl>
                <DatePickerField
                  label={t('Choose-the-date')}
                  dateFormat="dd/MM/yyyy"
                  selected={field.value}
                  onChange={(date) => {
                    onChangeDate(field, date);
                  }}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <FormField
        name="toDate"
        render={(field) => {
          return (
            <FormItem>
              <FormControl>
                <DatePickerField
                  label={`${t('End-date')} (${t('Optional')})`}
                  dateFormat="dd/MM/yyyy"
                  selected={field.value}
                  onChange={(date) => onChangeDate(field, date)}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <RadioGroup className="w-full">
        {dateRanges.map((option) => (
          <RadioGroupButton
            name="dateRange"
            key={option.value}
            value={option.value}
            selected={option.value === range}
            onChange={onChangeDateRange}
          >
            {option.label}
          </RadioGroupButton>
        ))}
      </RadioGroup>
      <FormField
        name="groupBy"
        render={(field) => {
          const selectedOption = groupBy.find(
            (option) => field.value === option.value,
          );

          return (
            <FormItem className="w-60">
              <FormLabel>{t('Group-By')}:</FormLabel>
              <FormControl>
                <SelectField
                  options={groupBy}
                  inputRef={field.ref}
                  value={selectedOption}
                  onChange={(option) => {
                    field.onChange(option.value);
                    onChangeGroupBy();
                  }}
                  className="w-full"
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
    </form>
  );
}

function filterByName(merchants, query) {
  const regex = new RegExp(query, 'i');

  return merchants.filter((merchant) => regex.test(merchant.name));
}

function MerchantListItem({ onChange, checked, merchant, backgroundColor }) {
  return (
    <label
      className="flex flex-row items-center rounded bg-gfGrey p-2 space-x-4"
      style={{ backgroundColor }}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={() => onChange(merchant)}
      />
      <span className="font-medium text-sm text-white">{merchant.name}</span>
    </label>
  );
}

function MerchantList({ value, onChange, onSubmit, merchants }) {
  const { t } = useTranslation();
  const { handleSubmit, formState } = useFormContext();
  const { isSubmitting } = formState;
  const [search, setSearch] = useState('');

  function onToggle(merchant) {
    if (value.includes(merchant.id)) {
      onChange(value.filter((id) => id !== merchant.id));

      return;
    }

    onChange([...value, merchant.id]);
  }

  function onSearch(event) {
    setSearch(event.target.value);
  }

  const selectedMerchants = useMemo(
    () => merchants.filter((merchant) => value.includes(merchant.id)),
    [merchants, value],
  );
  const notSelectedMerchants = useMemo(
    () =>
      filterByName(
        merchants.filter((merchant) => !value.includes(merchant.id)),
        search,
      ),
    [merchants, value, search],
  );

  return (
    <div className="flex flex-col space-y-5">
      <input
        onChange={onSearch}
        className="w-full py-2 px-2 border-gfPeriwinkle border focus:outline-none rounded"
        placeholder={t('Look-for-a-merchant')}
        value={search}
      />
      <div className="flex flex-col space-y-5 overflow-y-auto h-600">
        {selectedMerchants.length > 0 && (
          <div className="flex flex-col space-y-1">
            {selectedMerchants.map((merchant) => (
              <MerchantListItem
                key={merchant.id}
                merchant={merchant}
                checked
                onChange={onToggle}
                backgroundColor={merchant.color}
              />
            ))}
          </div>
        )}
        <Button
          variant="primary"
          type="button"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting || selectedMerchants.length === 0}
          loading={isSubmitting}
        >
          {t('Go')}
        </Button>
        {notSelectedMerchants.length > 0 && (
          <div className="flex flex-col space-y-1">
            {notSelectedMerchants.map((merchant) => (
              <MerchantListItem
                key={merchant.id}
                merchant={merchant}
                checked={false}
                onChange={onToggle}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function weekToDate(year, week) {
  return addWeeks(new Date(year, 0, 1), week - 1);
}

function FormatLegend(value, merchant) {
  if (!value) {
    return null;
  }

  const { color } = merchant;

  return (
    <span className="font-MulishBold py-4 px-4" style={{ color }}>
      {value}
    </span>
  );
}

function Summary({ summary }) {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const { fromDate, toDate, groupBy } = watch([
    'fromDate',
    'toDate',
    'groupBy',
  ]);

  const isGroupedByWeek = useMemo(() => groupBy === 'week', [groupBy]);
  const title = useMemo(() => {
    return `${t('sales-by-merchant')} -> ${format(fromDate, 'yyyy-MM-dd')} - ${format(toDate, 'yyyy-MM-dd')}`;
  }, [t, fromDate, toDate]);

  const series = useMemo(
    () =>
      summary.map((merchant) => ({
        ...merchant,
        sales: merchant.sales
          .map((sale) => {
            let date;
            if (sale.week) {
              const [year, week] = sale.week.match(/\d+/g).map(Number);
              date = weekToDate(year, week);
            } else {
              date = new Date(sale.date);
            }

            return {
              amount: sale.amount,
              date: date.getTime(),
            };
          })
          .sort((a, b) => a.date - b.date),
      })),
    [summary, isGroupedByWeek],
  );

  const dates = useMemo(() => {
    return uniq(series.flatMap((serie) => serie.sales.map((sale) => sale.date)))
      .sort((a, b) => a - b)
      .map((date) => ({
        date,
      }));
  }, [series]);

  const formatTick = useCallback(
    (tick) => {
      if (tick === '') {
        return tick;
      }

      if (isGroupedByWeek) {
        return format(new Date(tick), "yyyy 'Week' I", {
          useAdditionalWeekYearTokens: true,
        });
      }

      return format(new Date(tick), 'dd-MM-yyyy');
    },
    [isGroupedByWeek],
  );

  const SaleTooltip = useCallback(
    ({ active, payload, label }) => {
      if (!active || !label) {
        return null;
      }

      return (
        <div className="flex flex-col p-4 border bg-white border-gfDarkSand font-MulishBold text-sm space-y-2 shadow-md">
          <div>{formatTick(label)}</div>
          {payload.map((item, index) => (
            <div key={index} className="flex flex-row space-x-1">
              <span>{item.name}</span>
              <div className="flex justify-start text-gfLightBlue">
                <ShoppingCenterMoney value={item.payload.amount} />
              </div>
            </div>
          ))}
        </div>
      );
    },
    [formatTick],
  );

  return (
    <div className="space-y-5">
      <h3 className="font-MulishBold text-center">{title}</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 items-end">
        {summary.map((merchant) => {
          return (
            <div
              key={merchant.merchant_id}
              className="border border-b-8 rounded w-full p-2 flex flex-col justify-center items-center"
            >
              <span className="font-MulishBold text-gfLightBlue text-sm">
                {merchant.merchant_name}
              </span>
              <ShoppingCenterMoney
                value={merchant.total_amount}
                className="font-MulishBold"
                suffix={''}
                renderText={(formattedValue, currency) => {
                  return (
                    <div className="flex justify-start text-gfLightBlue font-MulishBlack space-x-2">
                      <span>{formattedValue}</span>
                      <span className="text-xs">{currency}</span>
                    </div>
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="flex flex-col w-full h-600">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            margin={{ top: 20, right: 40, left: 40, bottom: 60 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={'date'}
              allowDuplicatedCategory={false}
              tickFormatter={formatTick}
              angle={90}
              minTickGap={-200}
              padding={{ left: 20, right: 20, top: 20, bottom: 20 }}
              tick={{ fontSize: 12 }}
              dy={40}
              stroke={GF_DARK_BLUE}
              allowDataOverflow
            />
            <YAxis dataKey="amount" stroke={GF_DARK_BLUE}>
              <Label
                className="font-MulishBold"
                angle={-90}
                position="insideLeft"
              >
                {t('Amount')}
              </Label>
            </YAxis>
            <Tooltip content={SaleTooltip} />
            <Legend
              verticalAlign="top"
              formatter={FormatLegend}
              margin={{
                top: 50,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            />
            <Line data={dates} />
            {series.map((merchant) => (
              <Line
                dataKey="amount"
                data={merchant.sales}
                name={merchant.merchant_name}
                key={merchant.merchant_id}
                // stroke={}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

const defaultValues = {
  fromDate: subMonths(new Date(), 3),
  toDate: new Date(),
  groupBy: 'week',
  merchantIds: [],
};

export default function Page() {
  const { t } = useTranslation();
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const {
    isLoading,
    error,
    hasMerchants,
    hasFetchedMerchants,
    fetchMerchants,
    merchants,
    fetchSummary,
    summary,
    clearSummary,
    hasFetchedSummary,
  } = useCardUsageSummary(defaultValues);

  const hasSummary = useMemo(() => summary.length > 0, [summary]);

  async function onChangeFilters(data) {
    clearSummary();
    // @TODO: handle filtering merchant list by date (based on sales that happened in that date range).
    await fetchMerchants();
  }

  async function onChangeGroupBy() {
    const values = formMethods.getValues();

    if (values.merchantIds.length === 0) {
      return;
    }

    await onSubmitMerchants(values);
  }

  async function onSubmitMerchants(data) {
    fetchSummary({
      from_date: format(data.fromDate, 'yyyy-MM-dd'),
      to_date: format(data.toDate, 'yyyy-MM-dd'),
      group_by: data.groupBy,
      merchant_ids: data.merchantIds,
    });
  }

  return (
    <TabItemContainer
      title={t('reports.usage.summary.title')}
      loading={isLoading}
    >
      <FormProvider {...formMethods}>
        <div className="space-y-4">
          <Filters
            onSubmit={onChangeFilters}
            onChangeGroupBy={onChangeGroupBy}
          />
          <div>
            {hasFetchedMerchants && !hasMerchants && (
              <MessageAlert message={t('Merchant-list-is-empty')} />
            )}
            {error && (
              <FailAlert message={t('reports.usage.summary.errors.general')} />
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-5 h-800">
            {hasFetchedMerchants && hasMerchants && (
              <div className="flex flex-col col-span-2 space-y-5">
                <h3 className="font-MulishBold">{t('Filters')}</h3>
                <FormField
                  name={'merchantIds'}
                  render={(field) => (
                    <MerchantList
                      merchants={merchants}
                      value={field.value}
                      onChange={field.onChange}
                      onSubmit={onSubmitMerchants}
                    />
                  )}
                />
              </div>
            )}
            {hasFetchedSummary && (
              <div className="flex flex-col col-span-10">
                {hasSummary ? (
                  <Summary summary={summary} />
                ) : (
                  <MessageAlert
                    message={t(
                      'There-are-no-information-to-display-for-this-date',
                    )}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </TabItemContainer>
  );
}
