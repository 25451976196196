import {
  enGB,
  de,
  es,
  fr,
  hu,
  pt,
  ro,
  nl,
  sk,
  it,
  fi,
  pl,
  cs,
  lt,
  hr,
} from 'date-fns/locale';

export function getDateLocale(lan) {
  switch (lan.value) {
    case 'en':
      return enGB;
    case 'de':
      return de;
    case 'fr':
      return fr;
    case 'es':
      return es;
    case 'hu':
      return hu;
    case 'pt':
      return pt;
    case 'ro':
      return ro;
    case 'nl':
      return nl;
    case 'sk':
      return sk;
    case 'it':
      return it;
    case 'fi':
      return fi;
    case 'pl':
      return pl;
    case 'cs':
      return cs;
    case 'lt':
      return lt;
    case 'hr':
      return hr;
    default:
      break;
  }
}
