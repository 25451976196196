import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import ValidationErrors from '../../Alert/ValidationErrors';
import SuccessAlert from '../../Alert/SuccessAlert';
import FailAlert from '../../Alert/FailAlert';
import {
  RESET_UPDATING_SSO_CONFIGURATION,
  updateSsoClient,
  getSsoCallbackUrl,
  deleteSsoSetting,
  RESET_CLEARING_SSO_CONFIGURATION,
} from '../../../actions/SsoActions';
import SsoConfigClearConfirmation from './SsoConfigClearConfirmation';
import CopyButton from '../../Button/CopyButton';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel,
} from '../../Form';
import { useForm } from 'react-hook-form';
import TextField from '../../Input/TextField';
import { GF_DARK_BLUE } from '../../../utils/Constants';

export default function SsoProviderForm(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { selectedTag } = useSelector((state) => state.tag);
  const { validationErrors } = useSelector((state) => state.commonReducer);
  const {
    updatingSsoConfig,
    successUpdatingSsoConfig,
    failUpdatingSsoConfig,
    updatingSsoProvider,
  } = useSelector((state) => state.sso);
  const { ssoSetting, ssoProvider } = props;
  const formFields = ssoProvider.formFields;
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [deleteSsoSettingID, setDeleteSsoSettingID] = useState('');

  const defaultValues = formFields.reduce((item, field) => {
    item[field.name] = ssoSetting?.providerMetadata?.[field.name] || '';
    return item;
  }, {});

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { reset } = methods;

  useEffect(() => {
    if (ssoSetting) {
      const defaultValues = formFields.reduce((item, field) => {
        item[field.name] = ssoSetting?.providerMetadata?.[field.name] || '';
        return item;
      }, {});

      reset(defaultValues);
    }
  }, [ssoSetting, reset, formFields]);

  const onSubmit = (data) => {
    dispatch(updateSsoClient(data, history, ssoProvider, ssoSetting));
  };

  const resetUpdating = () => {
    setTimeout(function () {
      dispatch({ type: RESET_UPDATING_SSO_CONFIGURATION });
    }, 3000);
  };

  const openDeleteView = () => {
    setDeleteSsoSettingID(ssoSetting.id);
    setDeleteIsOpen(!deleteIsOpen);
  };

  const clearSsoSetting = () => {
    dispatch(deleteSsoSetting(deleteSsoSettingID)).then(
      (success) => {
        closeDeleteSsoSetting();
      },
      (error) => {
        console.log('error');
      },
    );
  };

  const closeDeleteSsoSetting = () => {
    setDeleteSsoSettingID('');
    setDeleteIsOpen(!deleteIsOpen);
    dispatch({ type: RESET_CLEARING_SSO_CONFIGURATION });
  };

  return (
    <Form {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-10">
          <div className="font-MulishBold text-red-500">
            {t('sso.callback-url')}
          </div>
          <div className="flex items-center">
            <input
              type="text"
              disabled="disabled"
              value={getSsoCallbackUrl(ssoProvider.name, selectedTag.name)}
              className="w-full px-4 py-2 disabled text-blue-gray-700 disabled:bg-blue-gray-50"
            />

            <CopyButton
              value={getSsoCallbackUrl(ssoProvider.name, selectedTag.name)}
              label={t('sso.copy')}
              copiedLabel={t('sso.copied')}
            />
          </div>

          {formFields &&
            formFields.map((item, index) => (
              <div className="flex flex-col" key={index}>
                <FormField
                  name={item.name}
                  rules={{ required: t('place-b2b-order.required') }}
                  defaultValue={''}
                  render={(field) => (
                    <FormItem className="mb-3">
                      <FormLabel className="font-MulishBold text-gfDarkBlue">
                        {t('sso.' + item.label)}
                      </FormLabel>
                      <FormControl>
                        <TextField
                          type={
                            item.name.includes('Secret') ? 'password' : 'text'
                          }
                          {...field}
                          placeholder={item.label}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}
        </div>
        <div className="flex flex-row justify-between space-x-5">
          <button
            type="submit"
            className="w-full flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfDarkBlue text-gfDarkSand focus:outline-none hover:bg-opacity-75  cursor-pointer"
          >
            {updatingSsoConfig && <ButtonLoading color={GF_DARK_BLUE} />}
            {t('Update')}
          </button>
          {ssoSetting && (
            <button
              type="button"
              onClick={openDeleteView}
              className="w-auto px-4 py-2 bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer text-white rounded"
            >
              {t('Delete')}
            </button>
          )}
        </div>
        {updatingSsoProvider === ssoProvider.name &&
          validationErrors === null &&
          successUpdatingSsoConfig !== null &&
          successUpdatingSsoConfig.length === 0 && (
            <div className="my-4">
              <SuccessAlert title={'Success'} message={'Updated !'} />
              {resetUpdating()}
            </div>
          )}

        {updatingSsoProvider === ssoProvider.name && failUpdatingSsoConfig && (
          <div className="my-4">
            <FailAlert message={failUpdatingSsoConfig.message} />
            {resetUpdating()}
          </div>
        )}
        {updatingSsoProvider === ssoProvider.name && validationErrors && (
          <div className="py-2">
            <ValidationErrors {...validationErrors} />
          </div>
        )}
        <SsoConfigClearConfirmation
          isOpen={deleteIsOpen}
          yesOption={clearSsoSetting}
          noOption={closeDeleteSsoSetting}
        />
      </form>
    </Form>
  );
}
