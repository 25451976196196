import { twMerge } from 'tailwind-merge';
import cnBase from 'classnames';

export function cn(...inputs) {
  return twMerge(cnBase(inputs));
}

export async function wait(timeout = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}
