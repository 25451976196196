import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getAccessToken,
  AUTHENTICATION_ERROR,
  CLEAR_LOCAL_STORAGE,
} from '../actions/AuthActions';
import RippleLoading from '../assets/svg/RippleLoading';

export default function Callback(props) {
  const dispatch = useDispatch();

  localStorage.removeItem('access_token');
  localStorage.removeItem('auth_token');

  const params = new URLSearchParams(props.location.search);
  const authToken = params.get('access_token');

  if (authToken !== null) {
    dispatch(getAccessToken(authToken, props.history));
  } else {
    dispatch({
      type: AUTHENTICATION_ERROR,
      payload: 'callback authentication error !!',
    });
  }

  return (
    <div>
      <RippleLoading />
    </div>
  );
}
