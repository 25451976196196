/* eslint-disable import/no-anonymous-default-export */
import {
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_ERROR,
  FETCH_COUNTRIES_SUCCESS,
} from '../actions/CountriesActions';

const initialState = {
  loadingCountries: false,
  countries: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return { ...state, loadingCountries: true };

    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload, loadingCountries: false };

    case FETCH_COUNTRIES_ERROR:
      return { ...state, loadingCountries: false };

    default:
      return state;
  }
}
