import React, { useMemo } from 'react';
import { useMerchantCodes } from '../../hooks/useMerchantCodes';

const MerchantCodeDescription = ({ code }) => {
  const { getDescriptionForCode } = useMerchantCodes();

  const description = useMemo(
    () => (code ? getDescriptionForCode(code) : '-'),
    [code, getDescriptionForCode],
  );

  return <span>{description}</span>;
};

export { MerchantCodeDescription };
