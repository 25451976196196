import React, { forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { DefaultReceipt, ReceiptField, ReceiptTitle } from '../../../Receipt';
import Money from '../../../Money';

export default forwardRef(function Receipt({ ticket }, ref) {
  const { selectedTag, tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  const componentRef = useRef();
  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const parsedName = useMemo(() => {
    return `${ticket.user.first_name} ${ticket.user.last_name}`;
  }, [ticket.user.first_name, ticket.user.last_name]);

  return (
    <div className="flex flex-col max-w-xl mx-auto bg-gray-100">
      <DefaultReceipt ref={ref}>
        <>
          {ticket.user !== null && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <ReceiptField name={t('User')} value={parsedName} />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '3px',
              paddingBottom: '3px',
            }}
          >
            <ReceiptField
              name={t('Transaction')}
              value={ticket.ticket_number}
            />
          </div>

          <div
            style={{
              textAlign: 'left',
              paddingTop: '3px',
              paddingBottom: '3px',
            }}
          >
            <ReceiptTitle
              title={t('card-replacements.receipt.date-of-transfer')}
            />
            <span style={{ display: 'inline-block' }}>
              {ticket.transaction_date_time}
            </span>
          </div>

          {ticket.entries && ticket.entries.length !== 0 && (
            <div>
              <div
                style={{
                  textAlign: 'left',
                  paddingTop: '20px',
                  paddingBottom: '10px',
                  fontWeight: 'bolder',
                  fontSize: '13px',
                }}
              >
                {`${t('Gift-Cards')}`}
              </div>

              {ticket.entries.map((entry) => (
                <div
                  style={{ paddingTop: '3px', paddingBottom: '3px' }}
                  key={entry.token}
                >
                  <ReceiptField name={t('Token')} value={entry.token} />
                  <ReceiptField
                    name={t('Amount')}
                    value={
                      <Money value={entry.amount} currency={tagCurrency} />
                    }
                  />
                  <ReceiptField
                    name={t('Expiry-date')}
                    value={entry.expiry_date}
                  />
                </div>
              ))}
              <div style={{ paddingLeft: '12px', marginTop: '10px' }}>
                <ReceiptField name={t('Cards')} value={ticket.no_of_entries} />
                <ReceiptField
                  name={t('Amount')}
                  value={
                    <Money
                      value={ticket.entries.reduce((sum, item) => {
                        sum += item.amount;
                        return sum;
                      }, 0)}
                      currency={tagCurrency}
                    />
                  }
                />
              </div>
            </div>
          )}

          <ReceiptField
            name={t('card-replacements.receipt.transfer-fee')}
            value={
              <Money
                value={ticket.transfer_fee.exclusive}
                currency={tagCurrency}
              />
            }
          />
          <ReceiptField
            name={t('vat')}
            value={
              <Money
                value={ticket.transfer_fee.tax.amount}
                currency={tagCurrency}
              />
            }
          />

          <div
            style={{
              textAlign: 'left',
              paddingTop: '20px',
              paddingBottom: '10px',
              fontWeight: 'bolder',
              fontSize: '13px',
            }}
          >{`${t('Order-Recap')}`}</div>
          <ReceiptField name={t('Cards')} value={ticket.no_of_entries} />
          <ReceiptField
            name={t('Total-amount')}
            value={<Money value={ticket.total_amount} currency={tagCurrency} />}
          />

          <ReceiptField
            name={t('Included-Tax')}
            value={
              <Money value={ticket.total_product_tax} currency={tagCurrency} />
            }
          />
          <ReceiptField
            name={t('card-replacements.receipt.transfer-fee')}
            value={
              <Money
                value={ticket.transfer_fee.exclusive}
                currency={tagCurrency}
              />
            }
          />
          <ReceiptField
            name={t('vat')}
            value={
              <Money
                value={ticket.transfer_fee.tax.amount}
                currency={tagCurrency}
              />
            }
          />
        </>
      </DefaultReceipt>
    </div>
  );
});
