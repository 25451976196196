import React, { forwardRef } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DropDownStyle from '../Dropdown/Components/DropDownStyle';

const SelectField = forwardRef(function ({ styles = {}, ...props }, ref) {
  const Component = props.onCreateOption ? CreatableSelect : Select;

  const composedStyles = {
    ...DropDownStyle((option) => option),
    ...styles,
  };

  return (
    <Component
      inputRef={ref}
      styles={composedStyles}
      components={{
        IndicatorSeparator: null,
      }}
      {...props}
    />
  );
});

export default SelectField;
