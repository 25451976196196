import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultModal from '../../../Modal/DefaultModal';

function ViewTerminalsModal({ shop, onClose }) {
  const { t } = useTranslation();

  return (
    <DefaultModal
      isOpen={true}
      onClose={onClose}
      title={t('shops.manage.results.terminals')}
    >
      {shop.serialNumbers.join(', ')}
    </DefaultModal>
  );
}

export default ViewTerminalsModal;
