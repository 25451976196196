import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from './TextField';
import { cn } from '../../utils';
import { InputGroup, InputGroupText } from '../InputGroup';
import SearchIcon from '../../assets/svg/SearchIcon';
import ClearIcon from '../../assets/svg/ClearIcon';

const SearchField = forwardRef(function (
  { className, onChange, onClear, value, ...props },
  ref,
) {
  const { t } = useTranslation();

  return (
    <InputGroup className="w-full">
      <InputGroupText position="left">
        <SearchIcon />
      </InputGroupText>
      <TextField
        ref={ref}
        placeholder={`${t('Search')}...`}
        className={cn('pl-10 pr-10', className)}
        value={value}
        {...props}
        type="text"
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
      {value.length > 0 && (
        <InputGroupText position="right">
          <button type="button" onClick={onClear}>
            <ClearIcon />
          </button>
        </InputGroupText>
      )}
    </InputGroup>
  );
});

export default SearchField;
