import { issuingAPI } from '../services/Axios';
import store from '../store/store';

const getMerchants = async () => {
  const { selectedTag, tagCardBrands } = store.getState().tag;

  return issuingAPI.get(
    `card-brand/${tagCardBrands[0]}/merchants?tag_id=${selectedTag.id}`,
  );
};

export default {
  getMerchants,
};
