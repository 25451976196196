import React, { forwardRef } from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const Pagination = forwardRef(({ ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <div>
      <ReactPaginate
        ref={ref}
        breakLabel={'...'}
        previousLabel={t('pagination.previous')}
        nextLabel={t('pagination.next')}
        breakClassName={'break-me'}
        marginPagesDisplayed={4}
        pageRangeDisplayed={5}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        {...props}
      />
    </div>
  );
});

export { Pagination };
