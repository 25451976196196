export const acceptanceStatus = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  UNCONFIRMED: 'unconfirmed',
};

export const editMode = {
  EDIT: 'edit',
  AUTHORIZE: 'authorize',
};
