import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSubTitle } from '../../components/Modal';

export default function OrderPurchaserDetails({ purchaser }) {
  const { t } = useTranslation();
  if (!purchaser) {
    return <></>;
  }

  return (
    <div>
      <ModalSubTitle>{t('orders.purchaser.title')}</ModalSubTitle>
      <div className={'flex mt-4'} data-testid={'purchaserInformation'}>
        <div className={'font-semibold truncate w-48 flex flex-col'}>
          <span>{t('purchaser.full_name')}:</span>
          <span>{t('purchaser.company_name')}:</span>
          {purchaser.vat && <span>{t('purchaser.company_vat_number')}:</span>}
          <span>{t('purchaser.customer_id')}:</span>
          <span>{t('purchaser.phone_number')}:</span>
          <span>{t('purchaser.email')}:</span>
        </div>
        <div className={'flex flex-col'}>
          <p
            data-testid={'fullName'}
          >{`${purchaser.first_name} ${purchaser.last_name}`}</p>
          <p
            data-testid={'companyName'}
          >{`${purchaser.company_name || '-'}`}</p>
          {purchaser.vat && <p>{purchaser.vat}</p>}
          <p data-testid={'customerId'}>{`${purchaser.customer_id || '-'}`}</p>
          <p
            data-testid={'phoneNumber'}
          >{`${purchaser.phone_number || '-'}`}</p>
          <p
            data-testid={'emailAddress'}
          >{`${purchaser.email_address || '-'}`}</p>
        </div>
      </div>
    </div>
  );
}
