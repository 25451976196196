import {
  ADDING_B2B_ORDER,
  SUCCESS_B2B_ORDER,
  ERROR_B2B_ORDER,
  RESET_B2B_ORDER,
} from '../actions/PlaceB2BOrderActions';
import { randomString } from '../utils/Helper';

const initialState = {
  addingB2BOrder: false,
  successB2BOrder: null,
  errorB2BOrder: null,
};

function generateOperationId() {
  return `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADDING_B2B_ORDER:
      return {
        ...state,
        addingB2BOrder: true,
        successB2BOrder: null,
        errorB2BOrder: null,
      };

    case SUCCESS_B2B_ORDER:
      return {
        ...state,
        addingB2BOrder: false,
        successB2BOrder: action.payload,
        errorB2BOrder: null,
      };

    case ERROR_B2B_ORDER:
      return {
        ...state,
        addingB2BOrder: false,
        successB2BOrder: null,
        errorB2BOrder: action.payload,
        addB2BOrderOperationID: generateOperationId(),
      };

    case RESET_B2B_ORDER:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
