import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TAG_CHANGED } from '../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../actions/TerminalActions';
import Container from '../../UIComponents/Container';
import {
  getSsoConnectionUrl,
  getSlugTag,
  copyCallbackUrl,
  SELECTED_SSO_PROVIDER,
} from '../../../actions/SsoActions';
import SsoComponentContainer from './SsoComponentContainer';
import DropdownIndicator from '../../Dropdown/Components/DropdownIndicator';
import LoadingIndicator from '../../Dropdown/Components/LoadingIndicator';
import Select from 'react-select';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_PERIWINKLE,
} from '../../../utils/Constants';
import { capitalize } from 'lodash';
import SelectField from '../../Input/SelectField';
import CopyButton from '../../Button/CopyButton';
import { getSsoProviders } from '../../../actions//SsoActions';

export default function EditSsoConfiguration() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedTag } = useSelector((state) => state.tag);
  const {
    ssoProviders,
    ssoSettings,
    selectedSsoProvider,
    loadingSsoProviders,
  } = useSelector((state) => state.sso);

  const [isConnectionUrlCopied, setIsConnectionUrlCopied] = useState(false);

  useEffect(() => {
    window.addEventListener(TAG_CHANGED, () => {});
    window.addEventListener(TERMINAL_CHANGED, () => {});

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const connectionUrl = useMemo(() => {
    if (ssoSettings && ssoSettings.length > 0) {
      return getSsoConnectionUrl(ssoSettings[0].tagName);
    } else {
      return getSsoConnectionUrl(getSlugTag(selectedTag.name));
    }
  }, [ssoSettings, selectedTag]);

  useEffect(() => {
    dispatch(getSsoProviders());
  }, [dispatch, selectedTag]);

  const onOptionSelect = (event) => {
    dispatch({ type: SELECTED_SSO_PROVIDER, payload: event });
  };

  return (
    <Container
      title={t('sso.edit-sso-configuration', { name: selectedTag.name })}
    >
      <div className="px-4 sm:px-6 lg:px-8 sm:m-2 m-2 md:my-10 lg:my-5 md:mx-10 lg:mx-10 2xl:mx-20 bg-white font-MulishRegular rounded">
        <div className="border rounded p-2 md:p-5">
          <div className="px-5 pb-5">
            <div className="font-MulishBold text-red-500">
              {t('sso.connection-url')}
            </div>
            <div className="flex items-center">
              <input
                type="text"
                disabled="disabled"
                value={connectionUrl}
                className="w-full px-4 py-2 disabled text-blue-gray-700 disabled:bg-blue-gray-50"
              />
              <CopyButton
                value={connectionUrl}
                label={t('sso.copy')}
                copiedLabel={t('sso.copied')}
              />
            </div>
          </div>
          <div className="px-5 pb-5">
            {ssoProviders?.length === 0 && (
              <div>{t('sso.no-sso-provider')}</div>
            )}
            {ssoProviders && ssoProviders.length > 1 && (
              <SelectField
                className="w-64 h-10"
                value={selectedSsoProvider}
                isDisabled={loadingSsoProviders || ssoProviders === null}
                placeholder={
                  loadingSsoProviders
                    ? t('sso.loading-provider')
                    : t('sso.select-provider')
                }
                onChange={onOptionSelect}
                options={ssoProviders}
                isSearchable
                getOptionLabel={(option) => `${capitalize(option.name)}`}
                isLoading={loadingSsoProviders}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropdownIndicator,
                  LoadingIndicator: LoadingIndicator,
                }}
              />
            )}
            {selectedSsoProvider && <SsoComponentContainer />}
          </div>
        </div>
      </div>
    </Container>
  );
}
