import React from 'react';
import { DefaultReceipt, ReceiptField } from '../../../Receipt';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Money from '../../../Money';

export default function OneReceipt({ componentRef, loads }) {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100" ref={componentRef}>
      <DefaultReceipt>
        <div>
          {loads?.user && loads?.user !== '' && (
            <ReceiptField name={t('User')} value={`${loads?.user ?? '-'}`} />
          )}

          <ReceiptField name={t('Transaction')} value={loads.ticket_number} />

          {loads.desk && (
            <ReceiptField name={t('Desk')} value={loads.desk.name} />
          )}

          <ReceiptField name={t('Date-of-Purchase')} value={loads.date} />

          {loads?.token && (
            <div className="mt-2">
              <div
                style={{ paddingTop: '3px', paddingBottom: '3px' }}
                key={loads?.token.tokenNumber}
              >
                <ReceiptField
                  name={t('Token')}
                  value={loads?.token.tokenNumber}
                />
                <ReceiptField
                  name={t('Amount')}
                  value={
                    <Money value={loads?.token.amount} currency={tagCurrency} />
                  }
                />
                <ReceiptField
                  name={t('Fee')}
                  value={
                    <Money value={loads?.token.fee} currency={tagCurrency} />
                  }
                />
              </div>
            </div>
          )}
        </div>
      </DefaultReceipt>
    </div>
  );
}
