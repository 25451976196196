import { invoiceService } from '../services/Axios';

export const GENERATE_MVC_INVOICE = 'GENERATE_MVC_INVOICE';
export const SUCCESS_GENERATE_MVC_INVOICE = 'SUCCESS_GENERATE_MVC_INVOICE';
export const ERROR_GENERATE_MVC_INVOICE = 'ERROR_GENERATE_MVC_INVOICE';
export const RESET_MVC_LOAD = 'RESET_MVC_LOAD';

export const generateMVCInvoice = (data) => (dispatch, getState) => {
  dispatch({ type: GENERATE_MVC_INVOICE });
  const { tagID } = getState().tag;
  const payload = {
    company_name: data.nameEntityInvoice,
    street: data.streetName,
    zip_code: data.postalCode,
    city: data.city,
    country: data.country,
    country_code: data.country,
    vat_number: data.vatNumber,
    mvc_id: data.mvc,
    amount: data.amount.replace(/,/g, '.'),
    currency: data.currency,
    email: data.email,
    tag_id: tagID,
    used_language: data.lang,
    // the 'extra_' or 'custom_' prefix is used to flag optional fields
    extra_po_number: data.poNumber,
    extra_additional_comment: data.additionalComment,
  };

  return invoiceService
    .post(`/api/mvc-request`, payload)
    .then((success) => {
      if (success.data.status === 201) {
        dispatch({ type: SUCCESS_GENERATE_MVC_INVOICE, payload: success.data });
      } else {
        dispatch({ type: ERROR_GENERATE_MVC_INVOICE, payload: success.data });
      }
    })
    .catch((error) => {
      dispatch({ type: ERROR_GENERATE_MVC_INVOICE, payload: error });
      return error;
    });
};
