import { issuingAPI } from '../services/Axios';
import { randomString } from '../utils/Helper';
import { APP_COUNT } from './CommonActions';

export const PREFIX = '@activatePromoCard/';

export const LOADING_TICKET_NUMBER = `${PREFIX}LOADING_TICKET_NUMBER`;
export const RECEIVED_TICKET_NUMBER = `${PREFIX}RECEIVED_TICKET_NUMBER`;
export const ERROR_TICKET_NUMBER = `${PREFIX}ERROR_TICKET_NUMBER`;

export const LOADING_TO_TOKEN_VALIDITY = `${PREFIX}LOADING_TO_TOKEN_VALIDITY`;
export const RECEIVED_TO_TOKEN_VALIDITY = `${PREFIX}RECEIVED_TO_TOKEN_VALIDITY`;
export const ERROR_TO_TOKEN_VALIDITY = `${PREFIX}ERROR_TO_TOKEN_VALIDITY`;

export const LOADING_FROM_TOKEN_VALIDITY = `${PREFIX}LOADING_FROM_TOKEN_VALIDITY`;
export const RECEIVED_FROM_TOKEN_VALIDITY = `${PREFIX}RECEIVED_FROM_TOKEN_VALIDITY`;
export const ERROR_FROM_TOKEN_VALIDITY = `${PREFIX}ERROR_FROM_TOKEN_VALIDITY`;

export const LOADING_TOKEN_RANGE_VALIDITY = `${PREFIX}LOADING_TOKEN_RANGE_VALIDITY`;
export const RECEIVED_TOKEN_RANGE_VALIDITY = `${PREFIX}RECEIVED_TOKEN_RANGE_VALIDITY`;
export const ERROR_TOKEN_RANGE_VALIDITY = `${PREFIX}ERROR_TOKEN_RANGE_VALIDITY`;

export const LOADING_PROMO_CARD_LOAD = `${PREFIX}LOADING_PROMO_CARD_LOAD`;
export const SUCCESS_PROMO_CARD_LOAD = `${PREFIX}SUCCESS_PROMO_CARD_LOAD`;
export const FAIL_PROMO_CARD_LOAD = `${PREFIX}FAIL_PROMO_CARD_LOAD`;

export const LOADING_PROMO_CARD_PAYMENT = `${PREFIX}LOADING_PROMO_CARD_PAYMENT`;
export const SUCCESS_PROMO_CARD_PAYMENT = `${PREFIX}SUCCESS_PROMO_CARD_PAYMENT`;
export const FAIL_PROMO_CARD_PAYMENT = `${PREFIX}FAIL_PROMO_CARD_PAYMENT`;

export const FROM_TOKEN_TEXT_COLOR = `${PREFIX}FROM_TOKEN_TEXT_COLOR`;
export const TO_TOKEN_TEXT_COLOR = `${PREFIX}TO_TOKEN_TEXT_COLOR`;

export const ADD_PROMO_CARD_TO_LIST = `${PREFIX}ADD_PROMO_CARD_TO_LIST`;
export const REMOVE_ITEM_FROM_LIST = `${PREFIX}REMOVE_ITEM_FROM_LIST`;

export const TEXT_FROM_TOKEN = `${PREFIX}TEXT_FROM_TOKEN`;
export const TEXT_TO_TOKEN = `${PREFIX}TEXT_TO_TOKEN`;

export const ACTIVATE_PRO_CARD_OPERATION_ID = `${PREFIX}ACTIVATE_PRO_CARD_LOAD_CARD`;
export const RESET_ERRORS_ACTIVATE_PROMMO_CARDS = `${PREFIX}RESET_ERRORS_ACTIVATE_PROMMO_CARDS`;
export const getTicketNumber = () => (dispatch) => {
  dispatch({ type: LOADING_TICKET_NUMBER });

  dispatch({ type: APP_COUNT });

  return issuingAPI
    .get('/ticket')
    .then((success) => {
      dispatch({ type: RECEIVED_TICKET_NUMBER, payload: success.data });
      dispatch({ type: ACTIVATE_PRO_CARD_OPERATION_ID });
    })
    .catch((error) => {
      dispatch({ type: ERROR_TICKET_NUMBER, payload: error });
      return error;
    });
};

export const check_to_TokenValidity = (token) => (dispatch, getState) => {
  dispatch({ type: LOADING_TO_TOKEN_VALIDITY });

  const { tagID } = getState().tag;

  const params = {
    token,
  };

  return issuingAPI
    .get(`/tag/${tagID}/promo-card/validate-token`, { params })
    .then((success) => {
      dispatch({ type: RECEIVED_TO_TOKEN_VALIDITY, payload: success.data });
    })
    .catch((error) => {
      dispatch({ type: ERROR_TO_TOKEN_VALIDITY, payload: error });
      return error;
    });
};

export const check_from_TokenValidity = (token) => (dispatch, getState) => {
  dispatch({ type: LOADING_FROM_TOKEN_VALIDITY });

  const { tagID } = getState().tag;

  const params = {
    token,
  };

  return issuingAPI
    .get(`/tag/${tagID}/promo-card/validate-token`, { params })
    .then((success) => {
      dispatch({
        type: RECEIVED_FROM_TOKEN_VALIDITY,
        payload: { result: { ...success.data, token } },
      });
    })
    .catch((error) => {
      dispatch({ type: ERROR_FROM_TOKEN_VALIDITY, payload: error });
      return error;
    });
};

export const checkTokenRangeValidity =
  (fromToken, toToken) => (dispatch, getState) => {
    dispatch({ type: LOADING_TOKEN_RANGE_VALIDITY });

    const { tagID } = getState().tag;

    const params = {
      from: fromToken,
      to: toToken,
    };

    return issuingAPI
      .get(`/tag/${tagID}/promo-card/validate-token-range`, { params })
      .then((success) => {
        dispatch({
          type: RECEIVED_TOKEN_RANGE_VALIDITY,
          payload: success.data,
        });
      })
      .catch((error) => {
        dispatch({ type: ERROR_TOKEN_RANGE_VALIDITY, payload: error });
        return error;
      });
  };

export const loadPromoCard = (ticket, tokens) => (dispatch, getState) => {
  dispatch({ type: LOADING_PROMO_CARD_LOAD });

  const { tagID } = getState().tag;
  const { activateProCardLoadCardOpID } = getState().activatePromoCards;

  const data = {
    tokens,
  };

  const headers = {
    'operation-id': activateProCardLoadCardOpID,
  };

  return issuingAPI
    .post(
      `tag/${tagID}/ticket/${ticket}/promo-card-load`,
      { ...data },
      { headers },
    )
    .then((success) => {
      dispatch({ type: SUCCESS_PROMO_CARD_LOAD, payload: success.data });
      dispatch(loadPromoCardPayment(ticket));
    })
    .catch((error) => {
      dispatch({ type: FAIL_PROMO_CARD_LOAD, payload: error });
      return error;
    });
};

export const loadPromoCardPayment = (ticket) => (dispatch, getState) => {
  dispatch({ type: LOADING_PROMO_CARD_PAYMENT });

  const { tagID } = getState().tag;
  const { selectedTerminal } = getState().terminal;
  const { activateProCardLoadPaymentOpID } = getState().activatePromoCards;

  const headers = {
    'operation-id': activateProCardLoadPaymentOpID,
  };

  if (selectedTerminal !== null) {
    const params = { terminal_id: selectedTerminal.id };

    return issuingAPI
      .get(`tag/${tagID}/ticket/${ticket}/load-card-payment`, {
        params,
        headers,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_PROMO_CARD_PAYMENT, payload: success.data });
      })
      .catch((error) => {
        dispatch({ type: FAIL_PROMO_CARD_PAYMENT, payload: error });
        return error;
      });
  }
  return issuingAPI
    .get(`tag/${tagID}/ticket/${ticket}/load-card-payment`, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_PROMO_CARD_PAYMENT, payload: success.data });
    })
    .catch((error) => {
      dispatch({ type: FAIL_PROMO_CARD_PAYMENT, payload: error });
      return error;
    });
};
