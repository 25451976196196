import React, { forwardRef } from 'react';
import ChevronUp from '../../assets/svg/ChevronUp';
import ChevronDown from '../../assets/svg/ChevronDown';
import Input from './index';

const QuantityField = forwardRef(function (
  { max = undefined, min = 0, value, onBlur, onChange, dataTestId },
  ref,
) {
  function setValue(value) {
    if (value <= min) {
      onChange(min);
      return;
    }

    if (max && value >= max) {
      onChange(max);
      return;
    }

    onChange(value);
  }

  const onClickDecrease = () => {
    setValue(value - 1);
  };

  const onClickIncrease = () => {
    setValue(value + 1);
  };

  const onInput = (e) => {
    const value = parseInt(e.target.value);

    if (isNaN(value)) {
      onChange('');
      return;
    }

    setValue(value);
  };

  return (
    <div className="flex flex-row">
      <button
        data-testid="decreaseButton"
        type="button"
        onClick={onClickDecrease}
        className="flex flex-row justify-center items-center p-2 px-3 font-MulishBlack rounded-l-md bg-gfPeriwinkle"
      >
        -
      </button>
      <Input
        type="number"
        ref={ref}
        value={value}
        onChange={onInput}
        onBlur={onBlur}
        className="text-center rounded-none w-20"
        min={min}
        max={max}
        data-testid={dataTestId}
      />
      <button
        data-testid="increaseButton"
        type="button"
        onClick={onClickIncrease}
        className="flex flex-row justify-center items-center p-2 px-3 font-MulishBlack rounded-r-md bg-gfPeriwinkle"
      >
        +
      </button>
    </div>
  );
});

export default QuantityField;
