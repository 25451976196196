import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sumBy } from 'lodash';

export default function useOrder({
  cards,
  purchaseFee,
  paymentMethods,
  onRemoveCard,
}) {
  const { t } = useTranslation();
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);

  const hasPurchaseFee = useMemo(
    () => selectedTag.purchase_fee_enabled,
    [selectedTag],
  );

  const totalCardsQuantity = useMemo(
    () => cards.reduce((accumulator, card) => accumulator + card.quantity, 0),
    [cards],
  );

  const totalPurchaseFeeAmount = useMemo(
    () => parseFloat(totalCardsQuantity * purchaseFee).toFixed(2),
    [purchaseFee, totalCardsQuantity],
  );

  const paymentTotalAmount = useMemo(
    () => getPaymentMethodsTotalAmount(paymentMethods),
    [paymentMethods],
  );

  const totalAmount = useMemo(
    () => getCardsTotalAmount(cards, purchaseFee),
    [cards, purchaseFee],
  );

  const lineItems = useMemo(() => {
    let items = [];

    items = cards.map((card, index) => ({
      name: `${card.amount} ${t('Card')} (x${card.quantity})`,
      total: parseFloat(card.amount * card.quantity).toFixed(2),
      currency: tagCurrency,
      onRemove: () => onRemoveCard(index),
    }));

    return items;
  }, [cards, t, onRemoveCard]);

  function getCardsTotalAmount(cards) {
    const sumCards = parseFloat(
      sumBy(cards, (card) => card.amount * card.quantity),
    );

    const fees = parseFloat(totalPurchaseFeeAmount);

    return parseFloat(sumCards + fees).toFixed(2);
  }

  function getPaymentMethodsTotalAmount(paymentMethods) {
    return sumBy(paymentMethods, (payment) =>
      parseFloat(payment.amount),
    ).toFixed(2);
  }

  return {
    totalAmount,
    paymentTotalAmount,
    hasPurchaseFee,
    purchaseFee,
    lineItems,
    totalPurchaseFeeAmount,
    totalCardsQuantity,
  };
}
