import {
  LOADING_DESKS_LIST,
  RECEIVED_DESKS_LIST,
  ERROR_DESKS_LIST,
  RESET_DESKS_LIST,
} from '../actions/DesksListAction';
import i18n from 'i18next';

const initialState = {
  loadingDesksList: false,
  receivedDesksList: null,
  errorDesksList: null,
  defaultDesk: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DESKS_LIST:
      return {
        ...state,
        loadingDesksList: true,
        receivedDesksList: null,
        errorDesksList: null,
      };

    case RECEIVED_DESKS_LIST:
      let tempPayload = action.payload;

      let temp = [
        { id: null, name: i18n.t('All'), label: i18n.t('All') },
        ...tempPayload,
      ];
      tempPayload.forEach((item) => {
        item.label = `${item.name}`;
      });
      return {
        ...state,
        loadingDesksList: null,
        receivedDesksList: temp,
        errorDesksList: null,
      };

    case ERROR_DESKS_LIST:
      return {
        ...state,
        loadingDesksList: false,
        receivedDesksList: null,
        errorDesksList: action.payload,
      };

    case RECEIVED_DESKS_LIST:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
