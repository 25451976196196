import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import ListCardTransactions from './ListCardTransactions';
import { getCardTransactionInformation } from '../../../../../actions/CardInformationActions';
import MessageAlert from '../../../../Alert/MessageAlert';
import FailAlert from '../../../../Alert/FailAlert';

export default function CardTransactions({
  selectedCard,
  printPreview,
  cardNumber,
  cardInformation,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { cardTransactionInfo, cardTransactionMeta, errorTransactionInfo } =
    useSelector((state) => state.cardInformation);
  const [selectedPage, setSelectedPage] = useState(1);

  const paginationHandler = (event) => {
    setSelectedPage(event.selected + 1);
    dispatch(
      getCardTransactionInformation(
        cardNumber,
        cardInformation.is_mvc,
        event.selected + 1,
      ),
    );
  };
  const isCardInformation =
    cardTransactionInfo && cardTransactionInfo.length !== 0;

  return (
    <>
      <div className="w-full flex flex-row justify-end mt-8">
        {selectedCard &&
          cardTransactionMeta &&
          cardTransactionMeta.total >
            window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
            <div>
              <ReactPaginate
                previousLabel={'prev'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  cardTransactionMeta.total /
                  window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                }
                marginPagesDisplayed={4}
                pageRangeDisplayed={5}
                onPageChange={paginationHandler}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          )}
        {selectedCard && isCardInformation && (
          <button
            id="printButton"
            onClick={() => {
              printPreview();
            }}
            className="py-2 px-6 my-2 ml-8 bg-gfCoral focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
          >
            {t('Print')}
          </button>
        )}
      </div>
      {selectedCard && isCardInformation && <ListCardTransactions />}
      {selectedCard && !isCardInformation && (
        <MessageAlert
          message={t('There-are-no-transactions-to-be-displayed')}
        />
      )}
      {selectedCard && errorTransactionInfo && (
        <FailAlert message={errorTransactionInfo.message} />
      )}
    </>
  );
}
