import React, { forwardRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { format, addYears, differenceInYears } from 'date-fns';
import ReactPaginate from 'react-paginate';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import Table from '../../../UIComponents/Table';
import {
  RESET_CARD_USAGE_MERCHANT,
  getCardUsageMerchant,
  getCardUsageMerchantsExcelExport,
  getCardUsageMerchantSummary,
} from '../../../../actions/CardUsageAtMerchantReportActions';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import MessageAlert from '../../../Alert/MessageAlert';
import FailAlert from '../../../Alert/FailAlert';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import {
  RESET_APP_COUNT,
  RESET_EXCEL_REPORT,
} from '../../../../actions/CommonActions';
import CardInfoWidget from '../../../CardInfoWidget';
import MerchantListDropdown from '../../../Dropdown/Components/MerchantListDropdown';
import { getMerchantList } from '../../../../actions/SalesByMerchantActions';
import useApiHook from '../../../../hooks/useAPI';
import { filterAPI } from '../../../../api';
import ChannelListDropdown from '../../../Dropdown/ChannelListDropdown';

const DPCustomInput = forwardRef(({ value, onClick, disabled, id }, ref) => (
  <input
    data-testid={id}
    id={id}
    ref={ref}
    value={value}
    onClick={onClick}
    readOnly={true}
    className={`${disabled ? 'bg-gfPeriwinkle' : 'bg-white'} border cursor-pointer text-center w-full rounded hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 py-2 h-10 focus:outline-none`}
  />
));

const AmountColumn = ({ amount, currency, className }) => (
  <div
    className={`flex justify-center font-MulishBold items-center ${className}  ${amount === 0 ? 'text-gfLightBlue' : amount > 0 ? 'text-gfPositiveAmount' : 'text-gfNegativeAmount'}`}
  >
    <CurrencyFormat
      decimalScale={2}
      fixedDecimalScale={true}
      value={amount}
      displayType={'text'}
      thousandSeparator={true}
    />
    <span className="ml-1">{`${String(currency).toUpperCase()}`}</span>
  </div>
);

const CardTokenDetails = ({ token, linkClick }) => {
  const { t } = useTranslation();

  const canAccessCardInformation = useMemo(
    () => checkLoggedInUserPermissions('card-information'),
    [checkLoggedInUserPermissions],
  );

  return (
    <div className="flex flex-col justify-center items-center">
      {canAccessCardInformation ? (
        <Link
          key={token}
          className="underline"
          onClick={linkClick}
          to={`/gift-cards/card-information?token=${token}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {token}
        </Link>
      ) : (
        <div key={token}>{t(token)}</div>
      )}
    </div>
  );
};

const TransactionDetailRow = ({ transaction, currency, linkClick }) => {
  const { t } = useTranslation();
  return (
    <tr id={`${transaction.transaction_id}ID`} key={transaction.transaction_id}>
      <td id={'transactionDateID'} className="px-4 py-4">
        <div className="flex justify-center items-center">
          {transaction.date}
        </div>
      </td>
      <td id={'transactionShopNameID'} className="px-4 py-4">
        <div className="flex justify-center items-center">
          {transaction.shop_name}
        </div>
      </td>
      <td id={'transactionID'} className="px-4 py-4">
        <div className="flex justify-center items-center">
          {transaction.transaction_id}
        </div>
      </td>
      <td id={'transactionTokenID'} className="px-4 py-4">
        <CardTokenDetails token={transaction.token} linkClick={linkClick} />
      </td>
      <td id={'transactionAmountID'} className="px-4 py-4">
        <AmountColumn
          amount={transaction.amount}
          currency={currency}
          className={'font-MulishRegular'}
        />
      </td>
      <td id={'transactionTypeID'} className="px-4 py-4">
        <div className="flex justify-center items-center">
          {t(`card-usage-at-merchants.types.${transaction.type}`)}
        </div>
      </td>
    </tr>
  );
};

export default function CardUsageAtMerchantReport() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    loadingCardUsageMerchant,
    receivedCardUsageMerchant,
    errorCardUsageMerchant,
    metaCardUsageMerchant,
    loadingCardUsageMerchantSummary,
    receivedCardUsageMerchantSummary,
    errorCardUsageMerchantSummary,
  } = useSelector((state) => state.cardUsageAtMerchant);

  const { tagCurrency, tagCardBrands } = useSelector((state) => state.tag);
  const { selectedLanguage } = useSelector((state) => state.language);
  const { appCount, loadingExcel, errorExcel } = useSelector(
    (state) => state.commonReducer,
  );

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [getMerchantsHandler, getMerchants] = useApiHook(
    filterAPI.getMerchants,
  );
  const [selectedMerchant, setSelectedMerchant] = useState({
    id: null,
    name: t('All'),
  });

  useEffect(() => {
    Userpilot.reload();
    if (appCount === 0) {
      dispatch({ type: RESET_CARD_USAGE_MERCHANT });
      dispatch(getMerchantList());
    }
  }, [appCount, dispatch]);

  useEffect(() => {
    if (tagCardBrands.length && tagCardBrands[0]) {
      getMerchants();
    }
  }, [tagCardBrands]);

  const formatDate = (date) => (date ? format(date, 'yyyy-MM-dd') : null);

  const getCardUsageAtMerchantHandler = () => {
    dispatch({ type: RESET_EXCEL_REPORT });
    const from = formatDate(fromDate);
    const to = formatDate(toDate) || from;

    dispatch(getCardUsageMerchant(from, to, selectedMerchant.value, 1));
    dispatch(getCardUsageMerchantSummary(from, to, selectedMerchant.value));
  };

  const getCardUsageAtMerchantCSVHandler = () => {
    const from = formatDate(fromDate);
    const to = formatDate(toDate) || from;
    dispatch(
      getCardUsageMerchantsExcelExport(from, to, selectedMerchant.value),
    );
  };

  const handlePageClick = (event) => {
    const from = formatDate(fromDate);
    const to = formatDate(toDate) || from;
    dispatch(
      getCardUsageMerchant(
        from,
        to,
        selectedMerchant.value,
        event.selected + 1,
      ),
    );
  };

  const linkClickHandler = () => {
    dispatch({ type: RESET_APP_COUNT });
  };

  const getYearCount = (date) => differenceInYears(new Date(), date);

  return (
    <>
      <div className="border-t border-gfPeriwinkle px-4 py-5 bg-white">
        <div className="flex flex-col mx-auto sm:mx-auto md:mx-auto lg:mx-10 xl:mx-10 2xl:mx-10 ">
          <div className="grid grid-cols-1 md:grid-cols-5 items-end gap-5 mb-10">
            <div className="flex flex-col">
              <div className="flex items-center  py-1">
                {t('Choose-the-date')}:
              </div>{' '}
              <ReactDatePicker
                id="chooseDateID"
                showMonthDropdown
                showYearDropdown
                disabled={loadingCardUsageMerchant}
                dateFormat="dd/MM/yyyy"
                customInput={<DPCustomInput />}
                selected={fromDate}
                onChange={(date) => {
                  setFromDate(date);
                  dispatch({ type: RESET_CARD_USAGE_MERCHANT });
                  dispatch({ type: RESET_EXCEL_REPORT });
                  setToDate(null);
                }}
                locale={selectedLanguage.value}
                maxDate={new Date()}
              />
            </div>
            <div className="flex flex-col">
              <div className="flex items-center  py-1">
                {`${t('End-date')}(${t('Optional')})`}:
              </div>{' '}
              <ReactDatePicker
                id="endDateID"
                showMonthDropdown
                showYearDropdown
                disabled={loadingCardUsageMerchant || !fromDate}
                dateFormat="dd/MM/yyyy"
                selected={toDate}
                onChange={(date) => {
                  setToDate(date);
                  dispatch({ type: RESET_CARD_USAGE_MERCHANT });
                  dispatch({ type: RESET_EXCEL_REPORT });
                }}
                customInput={<DPCustomInput />}
                locale={selectedLanguage.value}
                maxDate={
                  getYearCount(fromDate) !== 0
                    ? addYears(fromDate, 1)
                    : new Date()
                }
                minDate={fromDate}
              />
            </div>

            <div className="flex flex-col" data-testid="merchantListDropdownID">
              <div className="flex items-center  py-1">
                {t('card-usage-at-merchants.select-merchant')}:
              </div>{' '}
              <ChannelListDropdown
                options={getMerchantsHandler?.data?.data?.map((merchant) => ({
                  name: merchant.name,
                  value: merchant.id,
                }))}
                onChangeHandler={(event) => {
                  setSelectedMerchant(event);
                  dispatch({ type: RESET_CARD_USAGE_MERCHANT });
                  dispatch({ type: RESET_EXCEL_REPORT });
                }}
                value={selectedMerchant}
              />
            </div>

            <button
              disabled={loadingCardUsageMerchant || !fromDate}
              onClick={getCardUsageAtMerchantHandler}
              className={`${fromDate !== null ? 'bg-gfCoral' : 'bg-gfPeriwinkle'} h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none`}
            >
              {loadingCardUsageMerchant && (
                <ButtonLoading color={GF_DARK_BLUE} />
              )}
              {t('Go')}!
            </button>
          </div>

          {receivedCardUsageMerchant &&
            receivedCardUsageMerchant.length === 0 && (
              <div className="my-10">
                <MessageAlert
                  message={t(
                    'There-are-no-information-to-display-for-this-date',
                  )}
                />
              </div>
            )}

          {errorCardUsageMerchant && (
            <div className="my-10">
              <FailAlert message={errorCardUsageMerchant.message} />
            </div>
          )}

          {errorCardUsageMerchantSummary && (
            <div className="my-10">
              <FailAlert message={errorCardUsageMerchantSummary.message} />
            </div>
          )}

          {errorExcel && (
            <div className="my-10">
              <FailAlert message={errorExcel.message} />
            </div>
          )}

          {receivedCardUsageMerchant &&
            receivedCardUsageMerchant.length !== 0 &&
            receivedCardUsageMerchantSummary && (
              <div className="flex flex-col space-y-5">
                <div className="grid grid-cols-1  md:grid-cols-5 gap-5 md:gap-5">
                  <CardInfoWidget
                    id={'netTotalAmountOwnedToMerchantID'}
                    backgroundColor="bg-gfCoral"
                    bottomLabel={t(
                      'card-usage-at-merchants.net-total-amount-owned-to-merchants',
                    )}
                    isCurrency
                    value={receivedCardUsageMerchantSummary.total_amount}
                    labelColor={'text-gfDarkBlue'}
                    valueColor={'text-gfDarkBlue'}
                  />
                  <CardInfoWidget
                    id={'totalAmountOfSpendsID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.total-amount-of-spends',
                    )}
                    isCurrency
                    value={receivedCardUsageMerchantSummary.total_spends}
                    labelColor={'text-gfDarkBlue'}
                    valueColor={'text-gfDarkBlue'}
                  />
                  <CardInfoWidget
                    id={'totalAmountOfRefundCancellationsID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.total-amount-of-refund-cancellations',
                    )}
                    isCurrency
                    value={receivedCardUsageMerchantSummary.total_refunds}
                    labelColor={'text-gfLightBlue'}
                    valueColor={'text-gfLightBlue'}
                  />
                  <CardInfoWidget
                    id={'numberOfSingleTransactionsID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.number-of-single-transactions',
                    )}
                    isCurrency={false}
                    value={receivedCardUsageMerchantSummary.transaction_count}
                    labelColor={'text-gfDarkBlue'}
                    valueColor={'text-gfDarkBlue'}
                  />
                  <CardInfoWidget
                    id={'numberOfGiftCardsUsedID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.number-of-gift-cards-used',
                    )}
                    isCurrency={false}
                    value={receivedCardUsageMerchantSummary.gift_cards_count}
                    labelColor={'text-gfLightBlue'}
                    valueColor={'text-gfLightBlue'}
                  />
                </div>
                <div className="flex flex-col items-end my-4">
                  <div className="flex flex-row mb-5">
                    <button
                      id="exportCsv"
                      onClick={getCardUsageAtMerchantCSVHandler}
                      className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                    >
                      {loadingExcel && <ButtonLoading color={GF_DARK_BLUE} />}
                      {t('export-excel')}
                    </button>
                  </div>

                  {receivedCardUsageMerchant &&
                    metaCardUsageMerchant &&
                    metaCardUsageMerchant.total >
                      metaCardUsageMerchant.per_page && (
                      <div>
                        <ReactPaginate
                          previousLabel={'prev'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={
                            metaCardUsageMerchant.total /
                            metaCardUsageMerchant.per_page
                          }
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                        />
                      </div>
                    )}
                </div>

                <Table>
                  <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-sm">
                    <tr>
                      <th
                        id="dateID"
                        scope="col"
                        className="px-4 py-3  text-center  uppercase tracking-wider"
                      >
                        {t('card-usage-at-merchants.date')}
                      </th>
                      <th
                        id="shopNameID"
                        scope="col"
                        className="px-2 py-3  text-center  uppercase tracking-wider"
                      >
                        {t('card-usage-at-merchants.shop-name')}
                      </th>
                      <th
                        id="transactionID"
                        scope="col"
                        className="px-2 py-3  text-center  uppercase tracking-wider"
                      >
                        {t('card-usage-at-merchants.transaction-id')}
                      </th>
                      <th
                        id="cardTokenID"
                        scope="col"
                        className="px-2 py-3  text-center  uppercase tracking-wider"
                      >
                        {t('card-usage-at-merchants.card-token')}
                      </th>
                      <th
                        id="transactionAmountID"
                        scope="col"
                        className="py-3  text-center  uppercase tracking-wider"
                      >
                        {t('card-usage-at-merchants.transaction-amount')}
                      </th>
                      <th
                        id="transactionTypeID"
                        scope="col"
                        className="py-3  text-center  uppercase tracking-wider"
                      >
                        {t('card-usage-at-merchants.transaction-type')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gfPeriwinkle">
                    {receivedCardUsageMerchant &&
                      receivedCardUsageMerchant.map((tr) => (
                        <TransactionDetailRow
                          key={tr.transaction_id}
                          linkClick={linkClickHandler}
                          transaction={tr}
                          currency={tagCurrency}
                        />
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
