import {
  CLEARED_SSO_CONFIGURATION,
  CLEARING_SSO_CONFIGURATION,
  ERROR_CLEARED_SSO_CONFIGURATION,
  ERROR_SSO_PROVIDER,
  LOADING_SSO_PROVIDER,
  RECEIVED_SSO_PROVIDER,
  RESET_CLEARING_SSO_CONFIGURATION,
  SELECTED_SSO_PROVIDER,
  LOADING_SSO_CONFIGURATION,
  RECEIVED_SSO_CONFIGURATION,
  ERROR_SSO_CONFIGURATION,
  UPDATING_SSO_CONFIGURATION,
  UPDATED_SSO_CONFIGURATION,
  ERROR_UPDATED_SSO_CONFIGURATION,
  RESET_UPDATING_SSO_CONFIGURATION,
} from '../actions/SsoActions';

const initialState = {
  ssoProviders: null,
  loadingSsoProviders: false,

  selectedSsoProvider: null,

  ssoSettings: null,

  updatingSsoConfig: null,
  successUpdatingSsoConfig: null,
  failUpdatingSsoConfig: null,
  updatingSsoProvider: false,

  clearingSsoConfig: null,
  successClearingSsoConfig: null,
  failClearingSsoConfig: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_SSO_PROVIDER:
      return {
        ...state,
        loadingSsoProviders: true,
        ssoProviders: null,
      };

    case RECEIVED_SSO_PROVIDER:
      const ssoProviders = action.payload;
      let selectedSsoProvider = null;
      if (ssoProviders.length === 1) {
        selectedSsoProvider = ssoProviders[0];
      }
      return {
        ...state,
        loadingSsoProviders: false,
        ssoProviders: ssoProviders ?? null,
        selectedSsoProvider: selectedSsoProvider,
      };

    case ERROR_SSO_PROVIDER:
      return {
        ...state,
        loadingSsoProviders: false,
        ssoProviders: null,
      };
    case SELECTED_SSO_PROVIDER:
      return {
        ...state,
        selectedSsoProvider: action.payload,
      };
    case LOADING_SSO_CONFIGURATION:
      return {
        ...state,
        ssoSettings: null,
      };

    case RECEIVED_SSO_CONFIGURATION:
      const ssoSettings = action.payload;

      return {
        ...state,
        ssoSettings: ssoSettings ?? null,
      };

    case ERROR_SSO_CONFIGURATION:
      return {
        ...state,
        ssoSettings: null,
      };

    case UPDATING_SSO_CONFIGURATION:
      return {
        ...state,
        updatingSsoConfig: true,
        successUpdatingSsoConfig: null,
        failUpdatingSsoConfig: null,
        updatingSsoProvider: action.sso_provider_name,
      };

    case UPDATED_SSO_CONFIGURATION:
      return {
        ...state,
        updatingSsoConfig: false,
        successUpdatingSsoConfig: action.payload ?? null,
        failUpdatingSsoConfig: null,
        updatingSsoProvider: action.sso_provider_name,
      };

    case ERROR_UPDATED_SSO_CONFIGURATION:
      return {
        ...state,
        updatingSsoConfig: false,
        successUpdatingSsoConfig: null,
        failUpdatingSsoConfig: action.payload,
        updatingSsoProvider: action.sso_provider_name,
      };

    case RESET_UPDATING_SSO_CONFIGURATION:
      return {
        ...state,
        updatingSsoConfig: false,
        successUpdatingSsoConfig: null,
        failUpdatingSsoConfig: null,
        updatingSsoProvider: false,
      };

    case CLEARING_SSO_CONFIGURATION:
      return {
        ...state,
        clearingSsoConfig: true,
        successClearingSsoConfig: null,
        failClearingSsoConfig: null,
      };

    case CLEARED_SSO_CONFIGURATION:
      return {
        ...state,
        clearingSsoConfig: false,
        successClearingSsoConfig: action.payload ?? null,
        failClearingSsoConfig: null,
      };

    case ERROR_CLEARED_SSO_CONFIGURATION:
      return {
        ...state,
        clearingSsoConfig: false,
        successClearingSsoConfig: null,
        failClearingSsoConfig: action.payload,
      };

    case RESET_CLEARING_SSO_CONFIGURATION:
      return {
        ...state,
        clearingSsoConfig: false,
        successClearingSsoConfig: null,
        failClearingSsoConfig: null,
      };

    default:
      return state;
  }
}
