import {
  ERROR_MYPORTAL_CARDS_LOAD,
  ERROR_MYPORTAL_CARDS_LOAD_SUMMARY,
  LOADING_MYPORTAL_CARDS_LOAD,
  LOADING_MYPORTAL_CARDS_LOAD_SUMMARY,
  RECEIVED_MYPORTAL_CARDS_LOAD,
  RECEIVED_MYPORTAL_CARDS_LOAD_SUMMARY,
  RESET_MYPORTAL_CARDS_LOAD,
} from '../../actions/MyPortalOperations/MyPortalCardsLoadActions';

const initialState = {
  loadingMyPortalCardsLoad: false,
  receivedMyPortalCardsLoad: null,
  errorMyPortalCardsLoad: null,
  meta: null,

  loadingMyPortalCardsLoadSummary: false,
  receivedMyPortalCardsLoadSummary: null,
  errorMyPortalCardsLoadSummary: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_MYPORTAL_CARDS_LOAD:
      return {
        ...state,
        loadingMyPortalCardsLoad: true,
        receivedMyPortalCardsLoad: null,
        errorMyPortalCardsLoad: null,
      };

    case RECEIVED_MYPORTAL_CARDS_LOAD:
      return {
        ...state,
        loadingMyPortalCardsLoad: false,
        receivedMyPortalCardsLoad: action.payload.data,
        meta: action.payload.meta,
        errorMyPortalCardsLoad: null,
      };

    case ERROR_MYPORTAL_CARDS_LOAD:
      return {
        ...state,
        loadingMyPortalCardsLoad: false,
        receivedMyPortalCardsLoad: null,
        errorMyPortalCardsLoad: action.payload,
      };

    case LOADING_MYPORTAL_CARDS_LOAD_SUMMARY:
      return {
        ...state,
        loadingMyPortalCardsLoadSummary: true,
        receivedMyPortalCardsLoadSummary: null,
        errorMyPortalCardsLoadSummary: null,
      };

    case RECEIVED_MYPORTAL_CARDS_LOAD_SUMMARY:
      return {
        ...state,
        loadingMyPortalCardsLoadSummary: false,
        receivedMyPortalCardsLoadSummary: action.payload,
        errorMyPortalCardsLoadSummary: null,
      };

    case ERROR_MYPORTAL_CARDS_LOAD_SUMMARY:
      return {
        ...state,
        loadingMyPortalCardsLoadSummary: false,
        receivedMyPortalCardsLoadSummary: null,
        errorMyPortalCardsLoadSummary: action.payload,
      };

    case RESET_MYPORTAL_CARDS_LOAD:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
