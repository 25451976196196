import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  SAVE_SHOP_OPERATION_ID,
  saveShop,
} from '../../../../actions/ManageShopActions';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_PERIWINKLE,
} from '../../../../utils/Constants';
import FailAlert from '../../../Alert/FailAlert';
import SuccessAlert from '../../../Alert/SuccessAlert';
import Editable from '../../../UIComponents/Editable';
import UnsavedConfirmation from './UnsavedConfirmation';

ReactModal.setAppElement('#root');

export default function EditShop({
  isOpen,
  data,
  closeHandler,
  successHandler,
}) {
  useEffect(() => {
    dispatch({ type: SAVE_SHOP_OPERATION_ID });
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedBglobalBrand, setSelectedBglobalBrand] = useState(null);
  const [selectedMerchantCategory, setSelectedMerchantCategory] =
    useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const inputRef = useRef();
  const [task, setTask] = useState('');
  const [isChanges, setIsChanges] = useState(false);

  const { updatingShop, successUpdate, failUpdate } = useSelector(
    (state) => state.manageShops,
  );

  const customStyles = (value) => ({
    placeholder: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      minWidth: '230px',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
        borderColor: GF_PERIWINKLE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: '#F7F7F8',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.id === value.id
          ? GF_DARK_BLUE
          : state.isFocused
            ? GF_CORAL
            : 'white',
      padding: 8,
      fontSize: '16px',
      color:
        state.isSelected && state.data.id === value.id ? '#FFF' : GF_DARK_BLUE,
      textAlign: 'left',
      '&:hover': {
        background: '#FF666E',
        color: '#FFF',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      fontSize: '18px',
    }),
  });

  const globalBrandChange = (event) => {
    setSelectedBglobalBrand(event);
    if (data.shop_sign) {
      if (event.id !== data.shop_sign.id) {
        setIsChanges(true);
      } else {
        setIsChanges(false);
      }
    } else {
      setIsChanges(true);
    }
  };

  const merchantCategoryChange = (event) => {
    setSelectedMerchantCategory(event);
    if (data.shop_category) {
      if (event.id !== data.shop_category.id) {
        setIsChanges(true);
      } else {
        setIsChanges(false);
      }
    } else {
      setIsChanges(true);
    }
  };

  const aliasChangeHandler = (event) => {
    setTask(event.target.value);
  };

  const onBlurHandler = (e) => {
    if (e.target.value !== data.alias) {
      setIsChanges(true);
    } else {
      setIsChanges(false);
    }
  };

  const yesHandler = (event) => {
    // saveMangeShop();
    // setOpenConfirmation(false);

    setOpenConfirmation(false);
    closeHandler(!isOpen);
    setIsChanges(false);
  };

  const noHandler = (event) => {
    setOpenConfirmation(false);
    if (!isChanges) {
      closeHandler(!isOpen);
    }
    // setIsChanges(true);
  };

  const savedExit = () => {
    setOpenConfirmation(false);
    closeHandler(!isOpen);
    setIsChanges(false);
  };

  const updateContent = () => {
    setTask(data.alias);
    setSelectedMerchantCategory(data.shop_category);
    setSelectedBglobalBrand(data.shop_sign);
  };

  const cancelHandler = () => {
    if (checkDirtyValues()) {
      setOpenConfirmation(checkDirtyValues());
    } else {
      noHandler();
    }
  };

  const checkDirtyValues = () => {
    if (task !== data.alias) {
      return true;
    }

    if (selectedBglobalBrand !== null) {
      if (selectedBglobalBrand.id !== (data.shop_sign && data.shop_sign.id)) {
        return true;
      }
    }

    if (selectedMerchantCategory !== null) {
      if (
        selectedMerchantCategory.id !==
        (data.shop_category && data.shop_category.id)
      ) {
        return true;
      }
    }

    return false;
  };

  const isEmpty = useMemo(() => task === '', [task]);

  const saveMangeShop = () => {
    if (!isEmpty) {
      const update = {
        shop_id: data.id,
      };

      if (task !== data.alias) {
        Object.assign(update, { alias: task });
      }

      if (selectedBglobalBrand !== null) {
        Object.assign(update, { shop_sign_id: selectedBglobalBrand.id });
      }

      if (selectedMerchantCategory !== null) {
        Object.assign(update, {
          shop_category_id: selectedMerchantCategory.id,
        });
      }

      dispatch(saveShop(update)).then((success) => {
        if (success !== undefined) {
          setOpenConfirmation(false);
          setIsChanges(false);
        } else {
          setTimeout(function () {
            savedExit();
            successHandler();
            setIsChanges(false);
          }, 1000);
        }
      });
    }
  };

  const resetGlobalBrand = () => {
    setSelectedBglobalBrand(null);
    setIsChanges(true);
  };

  const resetMerchantCategory = () => {
    setSelectedMerchantCategory(null);
    setIsChanges(true);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="PrepareOrder"
      className="bg-gray-700 z-50"
      onAfterOpen={updateContent}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-5 border rounded max-w-3xl shadow-lg bg-white text-gfDarkBlue font-MulishRegular text-sm">
        <div className="flex justify-between items-center ">
          <div className="font-MulishBold">{t('Edit-Shop')}</div>
        </div>

        <div className="flex flex-col mx-20 justify-center my-3">
          <div className="py-2 border-b ">
            <div className="flex flex-row py-3" data-testid="shopInfo">
              <span className="w-40 text-left font-MulishBold">{`${t('Shop-name')}: `}</span>
              <span className="text-left" data-testid="shopName">
                {data.name}
              </span>
            </div>

            <div className="flex flex-row py-3" data-testid="aliasInfo">
              <span className="w-40 text-left font-MulishBold">{`${t('Alias')}: `}</span>
              <Editable
                text={task}
                childRef={inputRef}
                type="input"
                dataTestId="alias"
              >
                <input
                  ref={inputRef}
                  type="text"
                  name="task"
                  className="shadow appearance-none border rounded w-64 p-2 focus:outline-none  border-gfPeriwinkle"
                  value={task || ''}
                  onChange={(e) => aliasChangeHandler(e)}
                  onKeyUp={(e) => onBlurHandler(e)}
                />
              </Editable>
            </div>

            {/* <div className="flex flex-row py-3">
                            <span className="w-40 text-left font-MulishBold">{`${t("Global-Brand")}: `}</span>
                            <Select
                                value={selectedBglobalBrand}
                                className="w-1/2"
                                styles={customStyles(selectedBglobalBrand)}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={globalBrandChange}
                                placeholder={"Select global brand"}
                                options={receivedShopSign}
                                isSearchable={true}
                                components={{
                                    IndicatorSeparator: null,
                                    DropdownIndicator: DropdownIndicator,
                                }}
                            />
                            {selectedBglobalBrand !== null && (
                                <button className="ml-1" onClick={resetGlobalBrand}>
                                    <RemoveItem color={GF_CORAL} />
                                </button>
                            )}
                        </div> */}

            {/* <div className="flex flex-row py-3">
                            <span className="w-40 text-left font-MulishBold">{`${t("Merchant-Category")}: `}</span>
                            <Select
                                value={selectedMerchantCategory}
                                className="w-1/2"
                                styles={customStyles(selectedMerchantCategory)}
                                getOptionLabel={(option) => `${option.name}`}
                                onChange={merchantCategoryChange}
                                placeholder={"Select merchant category"}
                                options={receivedShopCategory}
                                isSearchable={true}
                                components={{
                                    IndicatorSeparator: null,
                                    DropdownIndicator: DropdownIndicator,
                                }}
                            />
                            {selectedMerchantCategory !== null && (
                                <button className="ml-1" onClick={resetMerchantCategory}>
                                    <RemoveItem color={GF_CORAL} />
                                </button>
                            )}
                        </div> */}
          </div>

          {isEmpty && (
            <div className="p-2 border border-gfCoral rounded text-gfCoral mt-3 font-MulishBold bg-gfCoral bg-opacity-25">
              {t('alias-cannot-be-empty')}
            </div>
          )}

          <div className="mt-10 space-y-5">
            <div className="flex flex-row justify-between space-x-5">
              <button
                id="saveButton"
                disabled={!isChanges}
                onClick={saveMangeShop}
                className={`${
                  !isChanges || isEmpty ? 'bg-opacity-50' : 'bg-opacity-100'
                } bg-gfCoral w-full focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold`}
              >
                {updatingShop && <ButtonLoading color={GF_DARK_BLUE} />}
                {t('Save')}
              </button>
              <button
                id="closeButton"
                onClick={cancelHandler}
                className="bg-gfPeriwinkle w-full focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
              >
                {t('Close')}
              </button>
            </div>
          </div>

          <div className="py-2">
            {successUpdate === '' && (
              <SuccessAlert title={'Success'} message="Updated !" />
            )}
            {failUpdate && <FailAlert message={failUpdate.message} />}
          </div>
        </div>
      </div>
      <UnsavedConfirmation
        isOpen={openConfirmation}
        yesOption={yesHandler}
        noOption={noHandler}
      />
    </ReactModal>
  );
}
