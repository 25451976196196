import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useCustomerFields() {
  const { fields } = useSelector((state) => state.loadCard);
  const realFields = useMemo(() => fields?.fields || [], [fields]);

  return {
    fields: realFields,
    hasFields: useMemo(() => realFields.length > 0, [realFields]),
  };
}

export function useReconciliationFields() {
  const { fields } = useSelector((state) => state.loadCard);
  const realFields = useMemo(
    () => fields?.reconciliationFields || [],
    [fields],
  );

  return {
    fields: realFields,
    hasFields: useMemo(() => realFields.length > 0, [realFields]),
  };
}
