import React, { useEffect } from 'react';
import ButtonLoading from '../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../utils/Constants';

export default function TabItemContainer({
  children,
  loading,
  title,
  description,
}) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} - myPortal`;

    return () => {
      document.title = prevTitle;
    };
  }, []);

  return (
    <div className="text-gfDarkBlue font-MulishRegular bg-white py-4">
      <div className="flex flex-row items-center justify-start px-10 pt-0 pb-4">
        {description && <p>{description}</p>}
        {loading && <ButtonLoading color={GF_DARK_BLUE}></ButtonLoading>}
      </div>
      <div className="border-t border-gfPeriwinkle px-10 py-5">{children}</div>
    </div>
  );
}
