// SsoComponentContainer.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import SsoProviderForm from './SsoProviderForm';
import { SELECTED_SSO_PROVIDER } from '../../../actions/SsoActions';

export default function SsoComponentContainer() {
  const { t } = useTranslation();
  const { ssoSettings, selectedSsoProvider } = useSelector(
    (state) => state.sso,
  );

  const onOptionSelect = (event) => {
    dispatch({ type: SELECTED_SSO_PROVIDER, payload: event });
  };
  const ssoSetting = ssoSettings?.find(
    (ssoSetting) =>
      ssoSetting?.ssoServiceProvider?.id === selectedSsoProvider.id,
  );
  return (
    selectedSsoProvider.form_fields !== 'null' && (
      <div className="flex-grow">
        <div className="flex flex-col">
          <div className="flex justify-start items-center py-6 font-MulishBold capitalize">
            {t(selectedSsoProvider.name)}
          </div>
          <SsoProviderForm
            ssoSetting={ssoSetting}
            ssoProvider={selectedSsoProvider}
          />
        </div>
      </div>
    )
  );
}
