/* eslint-disable import/no-anonymous-default-export */
import i18n from 'i18next';
import {
  ERROR_DAILY_REPORT,
  ERROR_DESK_LIST,
  ERROR_USER_LIST,
  LOADING_DAILY_REPORT,
  LOADING_DESK_LIST,
  LOADING_USER_LIST,
  POPULATED_LIST,
  RECEIVED_DAILY_REPORT,
  RECEIVED_DESK_LIST,
  RECEIVED_USER_LIST,
  RESET_DAILY_REPORT,
  RESET_DAILY_REPORT_SEND_BY_EMAIL,
  PAN_CONFIRMED,
  PAN_CONFIRM_ERROR,
  CONFIRMING_PAN,
  TICKET_EMAIL_HISTORY_RECEIVED,
  TICKET_EMAIL_HISTORY_RECEIVE_ERROR,
  GET_TICKET_EMAIL_HISTORY_LOADING,
  EMAIL_SENT_SUCCESS,
  EMAIL_SENT_ERROR,
  SENDING_TICKET_BY_EMAIL,
  RESET_PAN_VERIFICATION_ERRORS,
} from '../../actions/DailyReportActions';
import { paginate } from '../../utils/Helper';

const PAGINATE_FIRST_PAGE = 1;

const initialState = {
  loadingUserList: false,
  receivedUserList: null,
  errorUserList: null,
  userArray: null,

  loadingDeskList: false,
  receivingDeskList: null,
  errorDeskList: null,
  deskArray: null,

  loadingDailyReport: false,
  receivingDailyReport: null,
  errorDailyReport: null,
  dailyReportSummary: null,
  populatedList: null,

  sendingTicketViaEmail: false,
  emailSuccess: null,
  emailError: null,

  panVerified: false,
  panVerifyError: null,
  panVerificationFailed: null,
  loadingPanVerification: false,
  ticketEmailHistory: null,
  ticketEmailHistoryError: null,
  ticketEmailHistoryLoading: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CONFIRMING_PAN:
      return {
        ...state,
        panVerified: false,
        panVerifyError: null,
        panVerificationFailed: null,
        loadingPanVerification: true,
      };
    case LOADING_USER_LIST:
      return {
        ...state,
        loadingUserList: true,
        receivedUserList: null,
        errorUserList: null,
      };

    case RECEIVED_USER_LIST:
      let tempUserArray;
      let temp;
      if (action.payload && action.payload.length !== 0) {
        tempUserArray = action.payload.map((item) => item.id);
        temp = [
          { id: -1, firstName: i18n.t('All'), lastName: '' },
          ...action.payload,
        ];
      } else {
        temp = action.payload;
      }

      return {
        ...state,
        loadingUserList: false,
        receivedUserList: temp,
        errorUserList: null,
        userArray: tempUserArray,
      };

    case ERROR_USER_LIST:
      return {
        ...state,
        loadingUserList: false,
        receivedUserList: null,
        errorUserList: action.payload,
      };

    case LOADING_DESK_LIST:
      return {
        ...state,
        loadingDeskList: true,
        receivingDeskList: null,
        errorDeskList: null,
      };

    case RECEIVED_DESK_LIST:
      const tempDeskArray = action.payload.map((item) => item.id);
      const tempDesk =
        action.payload.length === 1
          ? action.payload
          : [{ id: -1, name: i18n.t('All') }, ...action.payload];
      return {
        ...state,
        loadingDeskList: false,
        receivingDeskList: tempDesk,
        errorDeskList: null,
        deskArray: tempDeskArray,
      };

    case ERROR_DESK_LIST:
      return {
        ...state,
        loadingDeskList: false,
        receivingDeskList: action.payload,
        errorDeskList: null,
      };

    case LOADING_DAILY_REPORT:
      return {
        ...state,
        loadingDailyReport: true,
        receivingDailyReport: null,
        errorDailyReport: null,
      };

    case RECEIVED_DAILY_REPORT:
      return {
        ...state,
        loadingDailyReport: false,
        receivingDailyReport: action.payload.transactions,
        errorDailyReport: null,
        dailyReportSummary: action.payload.summary,
        populatedList: paginate(
          action.payload.transactions,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      };

    case ERROR_DAILY_REPORT:
      return {
        ...state,
        loadingDailyReport: false,
        receivingDailyReport: null,
        errorDailyReport: action.payload,
      };

    case POPULATED_LIST:
      return {
        ...state,
        populatedList: paginate(
          state.receivingDailyReport,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      };

    case RESET_DAILY_REPORT:
      return {
        ...state,
        ...initialState,
      };
    case RESET_DAILY_REPORT_SEND_BY_EMAIL:
      return {
        ...state,
        sendingTicketViaEmail: false,
        emailSuccess: null,
        emailError: null,
        panVerified: false,
        panVerifyError: null,
        panVerificationFailed: null,
      };
    case PAN_CONFIRMED:
      return {
        ...state,
        panVerified: action.payload.data.status === 'valid',
        panVerificationFailed: action.payload.data.status === 'invalid',
        panVerifyError: false,
        loadingPanVerification: false,
      };

    case PAN_CONFIRM_ERROR:
      return {
        ...state,
        panVerified: false,
        loadingPanVerification: false,
        panVerifyError: action.payload,
      };

    case RESET_PAN_VERIFICATION_ERRORS:
      return {
        ...state,
        panVerifyError: false,
        panVerificationFailed: false,
      };

    case TICKET_EMAIL_HISTORY_RECEIVED:
      return {
        ...state,
        ticketEmailHistory: action.payload.data,
        ticketEmailHistoryLoader: false,
        emailSuccess: false,
        emailError: false,
      };
    case TICKET_EMAIL_HISTORY_RECEIVE_ERROR:
      return {
        ...state,
        ticketEmailHistory: null,
        ticketEmailHistoryError: action.payload,
        ticketEmailHistoryLoader: false,
      };
    case GET_TICKET_EMAIL_HISTORY_LOADING:
      return {
        ...state,
        ticketEmailHistoryLoader: true,
        ticketEmailHistory: null,
        ticketEmailHistoryError: null,
      };
    case SENDING_TICKET_BY_EMAIL:
      return {
        ...state,
        sendingTicketViaEmail: true,
        emailSuccess: null,
        emailError: null,
      };
    case EMAIL_SENT_SUCCESS:
      return {
        ...state,
        sendingTicketViaEmail: false,
        emailSuccess: true,
        emailError: false,
      };
    case EMAIL_SENT_ERROR:
      return {
        ...state,
        sendingTicketViaEmail: false,
        emailSuccess: false,
        emailError: action.payload,
      };

    default:
      return state;
  }
}

// const paginate = function (array, index, size) {
//     index = Math.abs(parseInt(index));
//     index = index > 0 ? index - 1 : index;
//     size = parseInt(size);
//     size = size < 1 ? 1 : size;

//     return [
//         ...array.filter((value, n) => {
//             return n >= index * size && n < (index + 1) * size;
//         }),
//     ];
// };
