import React from 'react';
import { useTranslation } from 'react-i18next';
import FailAlert from './FailAlert';
import ButtonLoading from '../../assets/svg/ButtonLoading';
import MessageAlert from './MessageAlert';

export default function AlertHandler({ handler, messages = {} }) {
  const { t } = useTranslation();
  return (
    <>
      {handler.error && <FailAlert message={messages.error ?? handler.error} />}
      {handler.loading && (
        <div className={'flex justify-center mt-6'}>
          <ButtonLoading />
          <p>{t(messages.loader ?? 'Loading-data')}...</p>
        </div>
      )}
      {handler.success && !handler?.data?.data?.length && (
        <MessageAlert
          className={'mt-6'}
          message={t(
            messages.empty ??
              'There-are-no-information-to-display-for-this-date',
          )}
        />
      )}
    </>
  );
}
