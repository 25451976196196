import { issuingAPI } from '../services/Axios';
import { generateOperationID } from '../utils/Helper';

const PREFIX = '@place-b2b-order/';

export const ADDING_B2B_ORDER = `${PREFIX}ADDING_B2B_ORDER`;
export const SUCCESS_B2B_ORDER = `${PREFIX}SUCCESS_B2B_ORDER`;
export const ERROR_B2B_ORDER = `${PREFIX}ERROR_B2B_ORDER`;
export const RESET_B2B_ORDER = `${PREFIX}RESET_B2B_ORDER`;

export const addB2BOrder = (order) => (dispatch, getState) => {
  dispatch({ type: ADDING_B2B_ORDER });
  const { tagID } = getState().tag;

  const headers = { 'operation-id': generateOperationID() };
  const params = {
    order_lines: order.cards,
    payments: order.paymentMethod,
    purchaser: order.purchaser,
    comment: order.additional_comment,
    purchase_fee: order.purchase_fee || 0,
  };

  // {{host}}/tag/{{tag_id}}/order/b2b
  return issuingAPI
    .post(`/tag/${tagID}/order/b2b`, { ...params }, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_B2B_ORDER, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_B2B_ORDER, payload: error });
      return Promise.reject(error);
    });
};
