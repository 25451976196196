import { issuingAPI } from '../services/Axios';
import { exportCSVFile, exportExcelFile } from '../utils/Helper';
import {
  APP_COUNT,
  ERROR_CSV_REPORT,
  GET_DESK_API_COUNT,
  GET_USE_API_COUNT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
  SUCCESS_EXCEL_REPORT,
  ERROR_EXCEL_REPORT,
  LOADING_EXCEL_REPORT,
} from './CommonActions';
import { OPEN_TICKET_PRINT } from './LoadCardActions';

export const PREFIX = '@dailyReport/';

export const LOADING_USER_LIST = `${PREFIX}LOADING_USER_LIST`;
export const RECEIVED_USER_LIST = `${PREFIX}RECEIVED_USER_LIST`;
export const ERROR_USER_LIST = `${PREFIX}ERROR_USER_LIST`;

export const LOADING_DESK_LIST = `${PREFIX}LOADING_DESK_LIST`;
export const RECEIVED_DESK_LIST = `${PREFIX}RECEIVED_DESK_LIST`;
export const ERROR_DESK_LIST = `${PREFIX}ERROR_DESK_LIST`;

export const LOADING_DAILY_REPORT = `${PREFIX}LOADING_DAILY_REPORT`;
export const RECEIVED_DAILY_REPORT = `${PREFIX}RECEIVED_DAILY_REPORT`;
export const ERROR_DAILY_REPORT = `${PREFIX}ERROR_DAILY_REPORT`;
export const POPULATED_LIST = `${PREFIX}POPULATED_LIST`;

export const RESET_DAILY_REPORT = `${PREFIX}RESET_DAILY_REPORT`;
export const CONFIRMING_PAN = `${PREFIX}CONFIRMING_PAN`;
export const PAN_CONFIRMED = `${PREFIX}PAN_CONFIRMED`;
export const GET_TICKET_EMAIL_HISTORY_LOADING = `${PREFIX}GET_TICKET_EMAIL_HISTORY_LOADING`;

export const TICKET_EMAIL_HISTORY_RECEIVED = `${PREFIX}TICKET_EMAIL_HISTORY_RECEIVED`;
export const TICKET_EMAIL_HISTORY_RECEIVE_ERROR = `${PREFIX}TICKET_EMAIL_HISTORY_RECEIVE_ERROR`;
export const PAN_CONFIRM_ERROR = `${PREFIX}PAN_CONFIRM_ERROR`;
export const RESET_DAILY_REPORT_SEND_BY_EMAIL = `${PREFIX}RESET_DAILY_REPORT_SEND_BY_EMAIL`;
export const RESET_PAN_VERIFICATION_ERRORS = `${PREFIX}RESET_PAN_VERIFICATION_ERRORS`;

export const EMAIL_SENT_SUCCESS = `${PREFIX}EMAIL_SENT_SUCCESS`;
export const EMAIL_SENT_ERROR = `${PREFIX}EMAIL_SENT_ERROR`;
export const SENDING_TICKET_BY_EMAIL = `${PREFIX}SENDING_TICKET_BY_EMAIL`;

export const getUserList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_USER_LIST });

  const { tagID } = getState().tag;

  dispatch({ type: GET_USE_API_COUNT });

  return issuingAPI
    .get(`tag/${tagID}/user?super_admin=1`)
    .then((success) => {
      dispatch({ type: RECEIVED_USER_LIST, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_USER_LIST, payload: error });
      return error;
    });
};

export const getDeskList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_DESK_LIST });

  const { tagID } = getState().tag;

  dispatch({ type: GET_DESK_API_COUNT });

  return issuingAPI
    .get(`tag/${tagID}/desk`)
    .then((success) => {
      dispatch({ type: RECEIVED_DESK_LIST, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_DESK_LIST, payload: error });
      return error;
    });
};

export const getProductReport =
  (fromDate, toDate, selectedUser, selectedDesk) => (dispatch, getState) => {
    dispatch({ type: LOADING_DAILY_REPORT });

    const { tagID } = getState().tag;

    dispatch({ type: APP_COUNT });

    const params = {
      from_date: fromDate,
      to_date: toDate,
    };

    if (selectedUser !== -1) {
      Object.assign(params, { user_ids: selectedUser });
    }

    if (selectedDesk !== -1) {
      Object.assign(params, { desk_ids: selectedDesk });
    }

    if (selectedUser.length === 0) {
      Object.assign(params, { user_ids: [-1] });
    }
    if (selectedDesk.length === 0) {
      Object.assign(params, { desk_ids: [-1] });
    }

    return issuingAPI
      .get(`tag/${tagID}/transaction`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_DAILY_REPORT, payload: success.data });
        return success;
      })
      .catch((error) => {
        dispatch({ type: ERROR_DAILY_REPORT, payload: error });
        return error;
      });
  };

export const getCSV =
  (fromDate, toDate, selectedUser, selectedDesk, reportName) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT });

    const { tagID } = getState().tag;
    const params = {
      from_date: fromDate,
      to_date: toDate,
    };

    const headers = {
      Accept: 'text/csv',
    };

    const data = null;

    if (selectedUser !== -1) {
      Object.assign(params, { user_ids: selectedUser });
    }

    if (selectedDesk !== -1) {
      Object.assign(params, { desk_ids: selectedDesk });
    }

    if (selectedUser.length === 0) {
      Object.assign(params, { user_ids: [-1] });
    }
    if (selectedDesk.length === 0) {
      Object.assign(params, { desk_ids: [-1] });
    }

    const fileName = `${reportName}-${fromDate}-${toDate}`;

    return issuingAPI
      .get(`tag/${tagID}/transaction/stream`, { headers, params, data })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT });
        exportCSVFile(success.data, fileName);
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error });
        return error;
      });
  };

export const getExcel =
  (fromDate, toDate, selectedUser, selectedDesk, reportName) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_EXCEL_REPORT });

    const { tagID } = getState().tag;
    const params = {
      from_date: fromDate,
      to_date: toDate,
    };

    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const data = null;

    if (selectedUser !== -1) {
      Object.assign(params, { user_ids: selectedUser });
    }

    if (selectedDesk !== -1) {
      Object.assign(params, { desk_ids: selectedDesk });
    }

    const fileName = `${reportName}-${fromDate}-${toDate}`;

    return issuingAPI
      .get(`tag/${tagID}/transaction/stream`, {
        responseType: 'blob',
        headers,
        params,
        data,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_EXCEL_REPORT });
        exportExcelFile(success.data, fileName);
      })
      .catch((error) => {
        dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
        return error;
      });
  };

export const sendTicketByEmail =
  (email, ticketNumber, selectedLanguage) => (dispatch, getState) => {
    dispatch({ type: SENDING_TICKET_BY_EMAIL });

    const { tagID } = getState().tag;

    return issuingAPI
      .post(`/tag/${tagID}/ticket/${ticketNumber}/send-via-email`, {
        email,
        lang: selectedLanguage.value,
      })

      .then((response) => {
        dispatch({ type: EMAIL_SENT_SUCCESS });
        document.body.dispatchEvent(
          new Event('clear_email_load_card_ticket', {
            bubbles: true,
            cancelable: true,
          }),
        );
        setTimeout(function () {
          dispatch({ type: GET_TICKET_EMAIL_HISTORY_LOADING });
          dispatch(getTicketEmailHistory(tagID, ticketNumber));
        }, 3000);
      })
      .catch((error) => {
        dispatch({ type: EMAIL_SENT_ERROR, payload: error });
        document.body.dispatchEvent(
          new Event('clear_email_load_card_ticket', {
            bubbles: true,
            cancelable: true,
          }),
        );
        return error;
      });
  };

export const confirmPanByTicketNumber =
  (pan, ticketNumber) => (dispatch, getState) => {
    dispatch({ type: CONFIRMING_PAN });

    const { tagID } = getState().tag;

    return issuingAPI
      .post(`/tag/${tagID}/ticket/${ticketNumber}/verify-pan`, {
        pan,
      })

      .then((response) => {
        dispatch({ type: PAN_CONFIRMED, payload: response });
        dispatch({ type: GET_TICKET_EMAIL_HISTORY_LOADING });
        dispatch(getTicketEmailHistory(tagID, ticketNumber));
      })
      .catch((error) => {
        dispatch({ type: PAN_CONFIRM_ERROR, payload: error });
        return error;
      });
  };

export const getTicketEmailHistory = (tagID, ticketNumber) => (dispatch) => {
  return issuingAPI
    .get(`/tag/${tagID}/ticket/${ticketNumber}/sent-via-email`)
    .then((response) => {
      dispatch({ type: TICKET_EMAIL_HISTORY_RECEIVED, payload: response });
    })
    .catch((error) => {
      dispatch({ type: TICKET_EMAIL_HISTORY_RECEIVE_ERROR, payload: error });
      return error;
    });
};
