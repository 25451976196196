import React from 'react';
import { cn } from '../../utils';

function Card({ className, children, ...props }) {
  return (
    <div
      className={cn(
        'flex flex-col rounded-md bg-gfPeriwinkle p-4 space-y-2',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

function CardTitle({ className, children, ...props }) {
  return (
    <h3 className={cn('text-gfDarkBlue font-MulishBold', className)} {...props}>
      {children}
    </h3>
  );
}

function CardFooter({ className, children, ...props }) {
  return (
    <div
      {...props}
      className={cn(
        'flex flex-row justify-between items-center border-t border-gfLightBlue pt-2',
        className,
      )}
    >
      {children}
    </div>
  );
}

export { Card, CardTitle, CardFooter };
