import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { OPEN_TICKET_PRINT } from '../../../../actions/LoadCardActions';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import FailAlert from '../../../Alert/FailAlert';
import SuccessAlert from '../../../Alert/SuccessAlert';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import Receipt from './Receipt';
import { issuingAPI } from '../../../../services/Axios';

const isSendTicketViaEmailEnabled =
  window?.appConfig.REACT_APP_FEATURE_SEND_TICKET_VIA_EMAIL === 'true';

export default function OrderSummary({ ticket, onClose }) {
  const { t } = useTranslation();
  const [emailData, setEmailData] = useState('');
  const { selectedTag } = useSelector((state) => state.tag);
  const { selectedLanguage } = useSelector((state) => state.language);
  const componentRef = useRef();
  const printTicket = useReactToPrint({
    content: () => componentRef.current,
  });

  const sendEmail = () => {
    if (!ticket.ticket_number) {
      setEmailData({ ...emailData, error: err, status: 'error' });
    }

    setEmailData({ ...emailData, status: 'sending' });
    issuingAPI
      .post(
        `/tag/${selectedTag.id}/replacements/ticket/${ticket.ticket_number}/send-via-email`,
        {
          email: emailData.value,
          lang: selectedLanguage.value,
        },
      )
      .then(() => {
        setEmailData({ ...emailData, status: 'sent' });
      })
      .catch((err) => {
        setEmailData({ ...emailData, error: err, status: 'error' });
      });
  };

  window.addEventListener(OPEN_TICKET_PRINT, () => {
    printTicket();
  });

  const canSendReceiptByEmail = useMemo(() => {
    return (
      isSendTicketViaEmailEnabled &&
      checkLoggedInUserPermissions('send-ticket-via-email')
    );
  }, [isSendTicketViaEmailEnabled, checkLoggedInUserPermissions]);

  return (
    <>
      {ticket.status.status === 'loading' && (
        <div className="mx-auto py-7">
          <div className="py-7 mx-auto text-center">
            <div className={'flex justify-center'}>
              <ButtonLoading color={GF_DARK_BLUE} />
            </div>
            <div
              className={'text-center'}
            >{`${t('Getting Ticket infos')}...`}</div>
          </div>
        </div>
      )}
      {ticket.status.status === 'error' && (
        <div className="py-4 space-y-5">
          <FailAlert message={ticket.status.message} />
        </div>
      )}
      {!(ticket.error || ticket.loading) && (
        <div className="pb-4 space-y-5">
          {canSendReceiptByEmail && (
            <>
              <div className="flex flex-col justify-start">
                <hr className="h-1 w-full my-2"></hr>
                <div className="mb-1 text-left">
                  {t('Send the receipt by email')}
                </div>
                <div className="flex flex-row gap-5">
                  <input
                    value={emailData.value}
                    onChange={(event) =>
                      setEmailData({ ...emailData, value: event.target.value })
                    }
                    placeholder={t('type-in-email')}
                    autoComplete="false"
                    className="h-10 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                  <button
                    onClick={sendEmail}
                    className={
                      (emailData.status === 'sending' || emailData.value === ''
                        ? 'opacity-50 pointer-events-none '
                        : '') +
                      'border rounded  py-2 px-8 text-gfDarkBlue w-32 bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium'
                    }
                  >
                    {t(emailData.status === 'sending' ? 'Sending' : 'Send')}
                  </button>
                </div>
                <div className={'mt-2'}>
                  {emailData.status === 'sent' && (
                    <SuccessAlert
                      title="Success"
                      message={
                        t('Email-was-sent-successfully') + ' ' + emailData.value
                      }
                    />
                  )}
                  {emailData.status === 'error' && (
                    <FailAlert message={emailData.error} />
                  )}
                </div>
              </div>
              <hr className="h-1 w-full my-8"></hr>
            </>
          )}

          <div className="flex flex-row justify-between space-x-5">
            <button
              onClick={onClose}
              className="bg-gfCoral w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
            >
              {t('card-replacements.receipt.transfer-another-card')}
            </button>
            <button
              onClick={printTicket}
              className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
            >
              {t('Print')}
            </button>
          </div>
          <div className={'h-56 overflow-y-scroll'}>
            <Receipt ref={componentRef} ticket={ticket} />
          </div>
        </div>
      )}
    </>
  );
}
