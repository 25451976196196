import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import FailAlert from '../../../Alert/FailAlert';
import Receipt from './Receipt';
import DefaultModal from '../../../Modal/DefaultModal';
import SendByEmailForm from '../../../Modal/SendByEmailForm';
import PanVerification from '../../../Modal/PanVerification';

export default function SummaryModal({ ticket, onClose, isOpen }) {
  const { t } = useTranslation();
  const [isValidPAN, setIsValidPAN] = useState(false);
  const componentRef = useRef();
  const printTicket = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <DefaultModal
      title={t('gift-card-load-receipt')}
      isOpen={isOpen}
      onClose={() => {
        setIsValidPAN(false);
        onClose();
      }}
    >
      {ticket.status?.status === 'loading' && (
        <div className="mx-auto py-7">
          <div className="py-7 mx-auto text-center">
            <div className={'flex justify-center'}>
              <ButtonLoading color={GF_DARK_BLUE} />
            </div>
            <div
              className={'text-center'}
            >{`${t('Getting Ticket infos')}...`}</div>
          </div>
        </div>
      )}
      {ticket.status?.status === 'error' && (
        <div className="py-4 space-y-5">
          <FailAlert message={ticket.status.message} />
        </div>
      )}
      {!(ticket.error || ticket.loading) && (
        <div className="pb-4 space-y-5 mt-4">
          {!isValidPAN && (
            <PanVerification
              onSetIsValid={() => setIsValidPAN(true)}
              ticket_number={ticket.ticket_number}
              token={ticket.token ?? null}
            />
          )}

          {isValidPAN && <SendByEmailForm ticket={ticket} />}

          <hr className="h-1 w-full my-8"></hr>
          <div className="flex flex-row justify-between space-x-5">
            <button
              onClick={printTicket}
              className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
            >
              {t('Print')}
            </button>
          </div>
          <div className={'h-56 overflow-y-scroll'}>
            <Receipt ref={componentRef} ticket={ticket} />
          </div>
        </div>
      )}
    </DefaultModal>
  );
}
