/* eslint-disable import/no-anonymous-default-export */
import {
  ERROR_PERFORMANCE_REPORT,
  LOADING_PERFORMANCE_REPORT,
  RECEIVED_PERFORMANCE_REPORT,
  RESET_PERFORMANCE_REPORT,
} from '../../actions/ManagementPerformanceReportActions';

const initialState = {
  loadingPerformanceReport: false,
  receivedPerformanceReport: null,
  errorPerformanceReport: null,
  summaryPerformanceReport: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PERFORMANCE_REPORT:
      return {
        ...state,
        loadingPerformanceReport: true,
        receivedPerformanceReport: null,
        errorPerformanceReport: null,
        summaryPerformanceReport: null,
      };

    case RECEIVED_PERFORMANCE_REPORT:
      return {
        ...state,
        loadingPerformanceReport: false,
        receivedPerformanceReport: action.payload.data,
        errorPerformanceReport: null,
        summaryPerformanceReport: action.payload.summary,
      };

    case ERROR_PERFORMANCE_REPORT:
      return {
        ...state,
        loadingPerformanceReport: false,
        receivedPerformanceReport: null,
        errorPerformanceReport: action.payload,
        summaryPerformanceReport: null,
      };

    case RESET_PERFORMANCE_REPORT:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
