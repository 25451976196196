import { issuingAPI } from '../services/Axios';
import store from '../store/store';

const getChannelList = () => {
  const { selectedTag } = store.getState().tag;
  return issuingAPI.get(`tag/${selectedTag.id}/channel/all`);
};

export default {
  getChannelList,
};
