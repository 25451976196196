import codes from '../data/merchantCodes.json';
import { useTranslation } from 'react-i18next';

function findRangeForCode(value) {
  let start = 0;
  let end = codes.length - 1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    const code = codes[mid];

    if (value >= code.lower && value <= code.upper) {
      return code;
    }

    if (value < code.lower) {
      end = mid - 1;
    } else {
      start = mid + 1;
    }
  }

  return null;
}

function useMerchantCodes() {
  const { t, i18n } = useTranslation();

  function getDescriptionForCode(value) {
    const range = findRangeForCode(value);

    if (!range) {
      return null;
    }

    const translationKey = `merchant_codes.${range.lower}`;

    return i18n.exists(translationKey) ? t(translationKey) : null;
  }

  return {
    getDescriptionForCode,
  };
}

export { useMerchantCodes };
