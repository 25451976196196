/* eslint-disable import/no-anonymous-default-export */

import {
  LOADING_CARD_USAGE_MERCHANT,
  RECEIVED_CARD_USAGE_MERCHANT,
  ERROR_CARD_USAGE_MERCHANT,
  PAGINATE_CARD_USAGE_MERCHANT,
  RESET_CARD_USAGE_MERCHANT,
  LOADING_CARD_USAGE_MERCHANT_SUMMARY,
  RECEIVED_CARD_USAGE_MERCHANT_SUMMARY,
  ERROR_CARD_USAGE_MERCHANT_SUMMARY,
} from '../../actions/CardUsageAtMerchantReportActions';

const initialState = {
  loadingCardUsageMerchant: false,
  receivedCardUsageMerchant: null,
  errorCardUsageMerchant: null,
  metaCardUsageMerchant: null,

  loadingCardUsageMerchantSummary: false,
  receivedCardUsageMerchantSummary: null,
  errorCardUsageMerchantSummary: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_CARD_USAGE_MERCHANT:
      return {
        ...state,
        loadingCardUsageMerchant: true,
        receivedCardUsageMerchant: null,
        errorCardUsageMerchant: null,
        metaCardUsageMerchant: null,
      };

    case RECEIVED_CARD_USAGE_MERCHANT:
      return {
        ...state,
        loadingCardUsageMerchant: false,
        receivedCardUsageMerchant: action.payload.data,
        errorCardUsageMerchant: null,
        metaCardUsageMerchant: action.payload.meta,
      };

    case ERROR_CARD_USAGE_MERCHANT:
      return {
        ...state,
        loadingCardUsageMerchant: false,
        receivedCardUsageMerchant: null,
        errorCardUsageMerchant: action.payload,
        metaCardUsageMerchant: null,
      };

    case PAGINATE_CARD_USAGE_MERCHANT:
      return {
        ...state,
        loadingCardUsageMerchant: true,
      };

    case RESET_CARD_USAGE_MERCHANT:
      return {
        ...state,
        loadingCardUsageMerchant: false,
        receivedCardUsageMerchant: null,
        errorCardUsageMerchant: null,
        metaCardUsageMerchant: null,

        loadingCardUsageMerchantSummary: false,
        receivedCardUsageMerchantSummary: null,
        errorCardUsageMerchantSummary: null,
      };

    case LOADING_CARD_USAGE_MERCHANT_SUMMARY:
      return {
        ...state,
        loadingCardUsageMerchantSummary: true,
        receivedCardUsageMerchantSummary: null,
        errorCardUsageMerchantSummary: null,
      };

    case RECEIVED_CARD_USAGE_MERCHANT_SUMMARY:
      return {
        ...state,
        loadingCardUsageMerchantSummary: false,
        receivedCardUsageMerchantSummary: action.payload,
        errorCardUsageMerchantSummary: null,
      };

    case ERROR_CARD_USAGE_MERCHANT_SUMMARY:
      return {
        ...state,
        loadingCardUsageMerchantSummary: false,
        receivedCardUsageMerchantSummary: null,
        errorCardUsageMerchantSummary: action.payload,
      };

    default:
      return state;
  }
}
