import React from 'react';
import ClearIcon from '../../assets/svg/ClearIcon';

export default function InputWithClearButton({
  value,
  placeholder,
  onChange,
  onClear,
  onKeyUp,
  onFocus,
  inputRef,
}) {
  return (
    <div className="relative">
      {value !== '' && (
        <button
          onClick={onClear}
          className="focus:outline-none focus:shadow-outline"
          style={{ position: 'absolute', right: '5px', top: '9px' }}
        >
          <ClearIcon />
        </button>
      )}
      <input
        id="tokenInput"
        autoFocus
        onFocus={onFocus}
        ref={inputRef}
        onKeyUp={onKeyUp}
        value={value}
        maxLength="9"
        onChange={onChange}
        className="
                border
                text-center
                focus:outline-none
                text-gfDarkBlue
                text-xl
                border-gfPeriwinkle
                rounded
                placeholder-gfGrey
                px-4 py-2 w-full"
        placeholder={placeholder}
        autoComplete="off"
      />
    </div>
  );
}
