import React, { forwardRef } from 'react';
import Card from '../../enums/card.enum';
import { cn } from '../../utils';
import TextField from './TextField';

const TokenField = forwardRef(function ({ valid, invalid, ...props }, ref) {
  return (
    <TextField
      ref={ref}
      {...props}
      type="text"
      maxLength={Card.TOKEN_LENGTH}
      className={cn('focus:outline-none', {
        'text-kadozGreen': valid,
        'text-gfCoral border-gfCoral focus:border-gfCoral': invalid,
      })}
    />
  );
});

export default TokenField;
