import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import Table from '../../../Table';
import { RESET_APP_COUNT } from '../../../../actions/CommonActions';
import AlertHandler from '../../../Alert/AlertHandler';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';

export default function SettlementMvc({ listMVCPaymentMethodsHandler }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mvcTokenHandler = () => {
    dispatch({ type: RESET_APP_COUNT });
  };

  function PaymentMethod({ method }) {
    return (
      <Table.Row>
        <Table.Cell>
          <div className="flex underline text-gfLightBlue w-96 text-left px-4">
            {checkLoggedInUserPermissions('card-information') ? (
              <Link
                onClick={mvcTokenHandler}
                className="underline"
                to={`/gift-cards/card-information?token=${method.mvc_token}`}
              >
                {method.mvc_token}
              </Link>
            ) : (
              method.mvc_token
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney
            value={method.settlement_mvc_info?.min_balance_required}
          />
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={method.balance} />
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={method.settlement_mvc_info?.to_pay} />
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex justify-center items-center underline text-gfLightBlue">
              {checkLoggedInUserPermissions('mvc-list') ? (
                <Link
                  onClick={mvcTokenHandler}
                  className="underline"
                  to={`/mvc-management/transaction-overview/${method.mvc_token}`}
                >
                  {t('View-transactions')}
                </Link>
              ) : (
                '-'
              )}
            </div>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      <AlertHandler
        handler={listMVCPaymentMethodsHandler}
        messages={{
          empty: 'there-are-no-settlement-payment-methods-to-be-displayed',
        }}
      />
      {!!listMVCPaymentMethodsHandler?.data?.data?.length &&
        listMVCPaymentMethodsHandler.success && (
          <Table>
            <Table.Head>
              <tr>
                <Table.Header className={'w-100 text-left px-8'}>
                  {t('MVC')}
                </Table.Header>
                <Table.Header>{t('Minimum-Updated-Daily')}</Table.Header>
                <Table.Header>{t('Balance')}</Table.Header>
                <Table.Header>{t('To-Pay')}</Table.Header>
                <Table.Header>{t('Actions')}</Table.Header>
              </tr>
            </Table.Head>
            <Table.Body>
              {listMVCPaymentMethodsHandler?.data?.data?.map((method) => (
                <PaymentMethod key={method.id} method={method} />
              ))}
            </Table.Body>
          </Table>
        )}
    </>
  );
}
