import { useMemo } from 'react';

export function useOrdersGroupedByAmount({ order }) {
  if (!order) {
    return [];
  }

  const { orders = [] } = order;

  return useMemo(() => {
    const grouped = orders.reduce((acc, item) => {
      const { amount } = item;

      if (!acc[amount]) {
        acc[amount] = [];
      }
      acc[amount].push(item);

      return acc;
    }, {});

    return Object.keys(grouped).map((key) => {
      const amount = parseFloat(key);
      const quantity = grouped[key].length;
      return {
        amount,
        quantity,
        total: amount * quantity,
      };
    });
  }, [orders]);
}
