import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import FailAlert from '../../Alert/FailAlert';
import DefaultModal from '../../Modal/DefaultModal';

ReactModal.setAppElement('#root');

export default function SsoConfigClearConfirmation({
  isOpen,
  noOption,
  yesOption,
}) {
  const { t } = useTranslation();
  const { failClearingSsoConfig } = useSelector((state) => state.sso);

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={noOption}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
    >
      <header className={'border-gray-200 relative mb-5'}>
        <div className={'flex justify-between items-center'}>
          <h1 className={'uppercase font-bold text-md'}>
            {t('sso.delete-configuration')}
          </h1>
        </div>
      </header>
      <div className="text-center">
        <div>{t('sso.you-delete-configuration')}</div>
        <div className="flex flex-row justify-center space-x-20 py-5">
          <button
            className="py-2 px-4 bg-gfCoral focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
            onClick={yesOption}
          >
            {t('Yes')}
          </button>
          <button
            className="py-2 px-4 bg-gfPeriwinkle focus:outline-none hover:bg-opacity-75 text-gfDarkBlue font-MulishBold rounded"
            onClick={noOption}
          >
            {t('No')}
          </button>
        </div>
        {failClearingSsoConfig && (
          <FailAlert message={failClearingSsoConfig.message} />
        )}
      </div>
    </DefaultModal>
  );
}
