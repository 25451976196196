import React from 'react';
import CurrencyFormat from 'react-currency-format';

function Money({
  value,
  className,
  currency,
  decimalScale = 2,
  renderText,
  ...props
}) {
  return (
    <CurrencyFormat
      decimalScale={decimalScale}
      fixedDecimalScale={true}
      thousandSeparator={true}
      value={value}
      displayType="text"
      suffix={` ${currency}`}
      className={className}
      data-testid={'amountAndCurrency'}
      {...props}
      renderText={renderText ? (value) => renderText(value, currency) : null}
    />
  );
}

export default Money;
