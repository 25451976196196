/* eslint-disable import/no-anonymous-default-export */
import {
  LOADING_MVC_PAYMENT_METHOD_LIST,
  RECEIVED_MVC_PAYMENT_METHOD_LIST,
  ERROR_MVC_PAYMENT_METHOD_LIST,
  CREATE_PAYMENT_METHOD,
  SUCCESS_CREATE_PAYMENT_METHOD,
  ERROR_CREATE_PAYMENT_METHOD,
  LOAD_MVC_CARD,
  SUCCESS_LOAD_MVC_CARD,
  ERROR_LOAD_MVC_CARD,
  UNLOAD_MVC_CARD,
  SUCCESS_UNLOAD_MVC_CARD,
  ERROR_UNLOAD_MVC_CARD,
  LOADING_MVC_PAYMENT_METHOD_FULL_LIST,
  CLOSE_LOAD_MODAL,
  CLOSE_UNLOAD_MODAL,
  RESET_CREATE_PM,
  LOAD_MVC_TRANSACTIONS,
  RECEIVED_MVC_TRANSACTIONS,
  ERROR_MVC_TRANSACTIONS,
  LOAD_LOGGED_IN_USER_MVC,
  RECEIVED_LOGGED_IN_USER_MVC,
  ERROR_LOGGED_IN_USER_MVC,
  LOAD_USER_MVC,
  RECEIVED_USER_MVC,
  ERROR_USER_MVC,
  ADDING_MVC_TO_USER,
  SUCCESS_ADD_MVC,
  FAIL_MVC_ADD,
  RESET_ADD_MVC,
} from '../actions/MVCManagementAction';

const initialState = {
  loadingMVCPaymentMethods: false,
  listMVCPaymentMethods: null,
  errorMVCPaymentMethods: null,
  metaMVCPaymentMethods: null,

  loadingCreatePaymentMethod: false,
  successCreatPaymentMethod: null,
  errorCreatePaymentMethod: null,

  loadingLoadCard: false,
  successLoadCard: null,
  errorLoadCard: null,

  loadingUnLoadCard: false,
  successUnLoadCard: null,
  errorUnLoadCard: null,

  loadingMVCTransactionList: false,
  receivedMVCTransactionList: null,
  errorMVCTransactionList: null,
  metaMVCTransactions: null,

  loadingLoggedInUserMVC: false,
  receivedLoggedInUserMVC: [],
  errorLoggedInUserMVC: null,

  loadingUserMVC: false,
  receivedUserMVC: [],
  errorUserMVC: null,

  addingMVC: false,
  successAdding: null,
  failAdding: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_ADD_MVC:
      return {
        ...state,
        addingMVC: false,
        successAdding: null,
        failAdding: null,
        receivedUserMVC: [],
      };

    case ADDING_MVC_TO_USER:
      return {
        ...state,
        addingMVC: true,
        successAdding: null,
        failAdding: null,
      };

    case SUCCESS_ADD_MVC:
      return {
        ...state,
        addingMVC: false,
        successAdding: action.payload,
        failAdding: null,
      };

    case FAIL_MVC_ADD:
      return {
        ...state,
        addingMVC: false,
        successAdding: null,
        failAdding: action.payload,
      };

    case LOAD_USER_MVC:
      return {
        ...state,
        loadingUserMVC: true,
        receivedUserMVC: null,
        errorUserMVC: null,
      };

    case RECEIVED_USER_MVC:
      return {
        ...state,
        loadingUserMVC: false,
        receivedUserMVC: action.payload.data,
        errorUserMVC: null,
      };

    case ERROR_USER_MVC:
      return {
        ...state,
        loadingUserMVC: false,
        receivedUserMVC: null,
        errorUserMVC: action.payload,
      };

    case LOAD_LOGGED_IN_USER_MVC:
      return {
        ...state,
        loadingLoggedInUserMVC: true,
        receivedLoggedInUserMVC: null,
        errorLoggedInUserMVC: null,
      };

    case RECEIVED_LOGGED_IN_USER_MVC:
      const temp = action.payload.data;

      temp.forEach((mvc) => {
        mvc.isChecked = false;
        // mvc.disabled = true;
      });
      return {
        ...state,
        loadingLoggedInUserMVC: false,
        receivedLoggedInUserMVC: temp,
        errorLoggedInUserMVC: null,
      };

    case ERROR_LOGGED_IN_USER_MVC:
      return {
        ...state,
        loadingLoggedInUserMVC: false,
        receivedLoggedInUserMVC: null,
        errorLoggedInUserMVC: action.payload,
      };

    case LOADING_MVC_PAYMENT_METHOD_LIST:
      return {
        ...state,
        loadingMVCPaymentMethods: true,
      };

    case LOADING_MVC_PAYMENT_METHOD_FULL_LIST:
      return {
        ...state,
        loadingMVCPaymentMethods: true,
        listMVCPaymentMethods: null,
        errorMVCPaymentMethods: null,
      };

    case RECEIVED_MVC_PAYMENT_METHOD_LIST:
      return {
        ...state,
        loadingMVCPaymentMethods: false,
        listMVCPaymentMethods: action.payload.data,
        errorMVCPaymentMethods: null,
        metaMVCPaymentMethods: action.payload.meta,
      };

    case ERROR_MVC_PAYMENT_METHOD_LIST:
      return {
        ...state,
        loadingMVCPaymentMethods: false,
        listMVCPaymentMethods: null,
        errorMVCPaymentMethods: action.payload,
      };

    case CREATE_PAYMENT_METHOD:
      return {
        ...state,
        loadingCreatePaymentMethod: true,
        successCreatPaymentMethod: null,
        errorCreatePaymentMethod: null,
      };

    case SUCCESS_CREATE_PAYMENT_METHOD:
      return {
        ...state,
        loadingCreatePaymentMethod: false,
        successCreatPaymentMethod: action.payload,
        errorCreatePaymentMethod: null,
      };

    case ERROR_CREATE_PAYMENT_METHOD:
      return {
        ...state,
        loadingCreatePaymentMethod: false,
        successCreatPaymentMethod: null,
        errorCreatePaymentMethod: action.payload,
      };

    case LOAD_MVC_CARD:
      return {
        ...state,
        loadingLoadCard: true,
        successLoadCard: null,
        errorLoadCard: null,
      };

    case SUCCESS_LOAD_MVC_CARD:
      return {
        ...state,
        loadingLoadCard: false,
        successLoadCard: action.payload,
        errorLoadCard: null,
      };

    case ERROR_LOAD_MVC_CARD:
      return {
        ...state,
        loadingLoadCard: false,
        successLoadCard: null,
        errorLoadCard: action.payload,
      };

    case UNLOAD_MVC_CARD:
      return {
        ...state,
        loadingUnLoadCard: true,
        successUnLoadCard: null,
        errorUnLoadCard: null,
      };

    case SUCCESS_UNLOAD_MVC_CARD:
      return {
        ...state,
        loadingUnLoadCard: false,
        successUnLoadCard: action.payload,
        errorUnLoadCard: null,
      };

    case ERROR_UNLOAD_MVC_CARD:
      return {
        ...state,
        loadingUnLoadCard: false,
        successUnLoadCard: null,
        errorUnLoadCard: action.payload,
      };

    case CLOSE_LOAD_MODAL:
      return {
        ...state,
        loadingLoadCard: false,
        successLoadCard: null,
        errorLoadCard: null,
      };

    case CLOSE_UNLOAD_MODAL:
      return {
        ...state,
        loadingUnLoadCard: false,
        successUnLoadCard: null,
        errorUnLoadCard: null,
      };

    case RESET_CREATE_PM:
      return {
        ...state,
        loadingCreatePaymentMethod: false,
        successCreatPaymentMethod: null,
        errorCreatePaymentMethod: null,
      };

    case LOAD_MVC_TRANSACTIONS:
      return {
        ...state,
        loadingMVCTransactionList: true,
        receivedMVCTransactionList: null,
        errorMVCTransactionList: null,
      };

    case RECEIVED_MVC_TRANSACTIONS:
      return {
        ...state,
        loadingMVCTransactionList: false,
        receivedMVCTransactionList: action.payload.data,
        errorMVCTransactionList: null,
        metaMVCTransactions: action.payload.meta,
      };

    case ERROR_MVC_TRANSACTIONS:
      return {
        ...state,
        loadingMVCTransactionList: false,
        receivedMVCTransactionList: null,
        errorMVCTransactionList: action.payload,
      };

    default:
      return state;
  }
}
