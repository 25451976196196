/* eslint-disable import/no-anonymous-default-export */

import {
  ERROR_MANAGE_ORDERS,
  LOADING_MANAGE_ORDERS,
  MANAGE_ORDERS_OPERATION_ID,
  RECEIVED_MANAGE_ORDERS,
  RESET_MANAGE_ORDERS,
  CHANGING_ORDER_STATUS,
  CHANGED_ORDER_STATUS,
  ERROR_CHANGING_ORDER_STATUS,
  MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID,
  RESET_CHANGING_ORDER_STATUS,
  ORDER_PREPARING,
  ORDER_PREPARED,
  ORDER_PREPARING_ERROR,
  ORDER_PREPARE_RESET,
} from '../../actions/ManageOrdersActions';
import { generateOperationID, randomString } from '../../utils/Helper';

const initialState = {
  loadingManageOrders: false,
  receivedManageOrders: null,
  errorManageOrders: null,
  meta: null,

  changingOrderStatus: false,
  successOrderStatus: false,
  failOrderStatus: null,

  showActivationOrderResponseView: false,
  loadingActivationOrder: false,
  activatingOrder: false,
  successActivateOrder: null,
  failActivateOrder: null,

  changingOrderStatus: false,
  changedOrderStatus: null,
  errorChangingOrder: null,

  orderPreparing: false,
  orderPrepared: null,
  orderPreparingError: null,

  manageOrderChangeStatusOperationID: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_MANAGE_ORDERS:
      return {
        ...state,
        loadingManageOrders: true,
      };

    case RECEIVED_MANAGE_ORDERS:
      return {
        ...state,
        loadingManageOrders: false,
        receivedManageOrders: action.payload.data,
        errorManageOrders: null,
        meta: action.payload.meta,
      };

    case ERROR_MANAGE_ORDERS:
      return {
        ...state,
        loadingManageOrders: false,
        receivedManageOrders: null,
        errorManageOrders: action.payload,
        meta: null,
      };

    case MANAGE_ORDERS_OPERATION_ID: {
      return {
        ...state,
      };
    }

    case MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID:
      return {
        ...state,
        manageOrderChangeStatusOperationID: generateOperationID(),
      };

    case CHANGING_ORDER_STATUS:
      return {
        ...state,
        changingOrderStatus: true,
        changedOrderStatus: null,
        errorChangingOrder: null,
      };

    case CHANGED_ORDER_STATUS:
      return {
        ...state,
        changingOrderStatus: false,
        changedOrderStatus: action.payload,
        errorChangingOrder: null,
      };

    case ERROR_CHANGING_ORDER_STATUS:
      return {
        ...state,
        changingOrderStatus: false,
        changedOrderStatus: null,
        errorChangingOrder: action.payload,
      };

    case RESET_CHANGING_ORDER_STATUS:
      return {
        ...state,
        changingOrderStatus: false,
        changedOrderStatus: null,
        errorChangingOrder: null,
      };

    case ORDER_PREPARING:
      return {
        ...state,
        orderPreparing: true,
        orderPrepared: null,
        orderPreparingError: null,
      };

    case ORDER_PREPARED:
      return {
        ...state,
        orderPreparing: false,
        orderPrepared: action.payload,
        orderPreparingError: null,
      };

    case ORDER_PREPARING_ERROR:
      return {
        ...state,
        orderPreparing: false,
        orderPrepared: null,
        orderPreparingError: action.payload,
      };

    case ORDER_PREPARE_RESET:
      return {
        ...state,
        orderPreparing: false,
        orderPrepared: null,
        orderPreparingError: null,
      };

    case RESET_MANAGE_ORDERS:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
