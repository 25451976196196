import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Userpilot } from 'userpilot';
import ReactDatePicker from 'react-datepicker';
import Money from '../../Money';
import {
  getManageOrdersByDeliveryMethod,
  MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID,
  RESET_MANAGE_ORDERS,
  RESET_CHANGING_ORDER_STATUS,
  ORDER_PREPARE_RESET,
} from '../../../actions/ManageOrdersActions';
import { TAG_CHANGED } from '../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../actions/TerminalActions';
import ClearIcon from '../../../assets/svg/ClearIcon';
import SearchIcon from '../../../assets/svg/SearchIcon';
import FailAlert from '../../Alert/FailAlert';
import MessageAlert from '../../Alert/MessageAlert';
import ValidationErrors from '../../Alert/ValidationErrors';
import DropdownIndicator from '../../Dropdown/Components/DropdownIndicator';
import SalesStatus from '../Reports/OnlineSales/SalesStatus';
import Container from '../../UIComponents/Container';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_PERIWINKLE,
} from '../../../utils/Constants';
import ChevronUp from '../../../assets/svg/ChevronUp';
import ChevronDown from '../../../assets/svg/ChevronDown';
import Table from '../../Table';
import OrderDetailsModal from '../../Modal/OrderDetailsModal';
import CardDetailsModal from '../../Modal/CardDetailsModal';
import OrderStatusEnum from '../../../enums/orderStatus.enum';
import orderStatusEnum from '../../../enums/orderStatus.enum';
import ConfirmationDeclineOrder from './ConfirmationDeclineOrder';
import LoadingIndicator from '../../Dropdown/Components/LoadingIndicator';
import OrderStatus from '../../../enums/orderStatus.enum';
import ConfirmationPaidOrder from './ConfirmationPaidOrder';
import { checkLoggedInUserPermissions } from '../../../utils/Helper';
import { INITIAL_LOAD_CARD } from '../../../actions/LoadCardActions';
import { RESET_VALIDATION_ERRORS } from '../../../actions/CommonActions';

const customStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    borderColor: GF_PERIWINKLE,
    borderRadius: '4px',
    minHeight: '40px',
    paddingBottom: '2px',
    color: GF_DARK_BLUE,
    cursor: 'pointer',
    fontWeight: '600',
    maxWidth: '100%',

    '&:hover': {
      background: GF_PERIWINKLE,
      color: GF_DARK_BLUE,
    },

    '&:hover div': {
      color: GF_DARK_BLUE,
    },

    '&:focus': {
      borderColor: GF_PERIWINKLE,
      background: GF_PERIWINKLE,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? GF_DARK_BLUE : '#FFFFFF',
    color: state.isSelected ? '#FFFFFF' : GF_DARK_BLUE,
    '&:hover': {
      background: GF_CORAL,
      color: '#FFF',
    },
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: '100%',
    color: GF_DARK_BLUE,
    zIndex: '9999',
  }),

  singleValue: (provided) => ({
    ...provided,
    color: GF_DARK_BLUE,
    textTransform: 'capitalize',
  }),

  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
};

function OrderRow({ order, currency, callback, isExpandedByDefault }) {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isPaidOrder, setIsPaidOrder] = useState(false);
  const [globalRef, setGlobalRef] = useState(null);
  const { changingOrderStatus } = useSelector((state) => state.manageOrders);
  const canUpdateOrderStatus = useMemo(
    () => checkLoggedInUserPermissions('update-order-status'),
    [checkLoggedInUserPermissions],
  );

  const orderDeclineStatus = [
    { value: OrderStatus.PAID, label: `${t('paid').toUpperCase()}` },
    { value: OrderStatus.DECLINE, label: `${t('decline').toUpperCase()}` },
  ];

  function changeOrderStatusOnchange(event, orderRef) {
    setStatus(event);
    setGlobalRef(orderRef);
    if (event.value === OrderStatus.DECLINE) {
      setIsOpen(!isOpen);
    } else {
      setIsPaidOrder(!isPaidOrder);
    }
  }

  function declineClose() {
    dispatch({ type: RESET_CHANGING_ORDER_STATUS });
    setIsOpen(!isOpen);
    setStatus(null);
  }

  function onPaidCloserHandler() {
    dispatch({ type: RESET_CHANGING_ORDER_STATUS });
    setIsPaidOrder(!isPaidOrder);
    setStatus(null);
  }

  const updateOrderChange = () => {
    dispatch(
      getManageOrdersByDeliveryMethod('', 1, null, null, OrderStatusEnum.ALL),
    );
  };

  return (
    <>
      <Table.Row data-testid={order.reference} className="font-MulishRegular">
        <Table.Cell data-testid="globalOrderRef">{order.reference}</Table.Cell>

        <Table.Cell data-testid="date">{order.ordered_at}</Table.Cell>

        <Table.Cell data-testid="orderRef">
          <span>-</span>
        </Table.Cell>

        <Table.Cell data-testid="customer">
          {`${order.purchaser.first_name} ${order.purchaser.last_name}`}
        </Table.Cell>

        <Table.Cell data-testid="status">
          {order.status === OrderStatus.AWAITING_PAYMENT ? (
            <div className="flex flex-col">
              {canUpdateOrderStatus ? (
                <Select
                  key={order.reference}
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  id="statusDropdown"
                  className="w-48"
                  styles={customStyles}
                  value={status}
                  getOptionLabel={(option) => `${option.label}`}
                  onChange={(e) => {
                    changeOrderStatusOnchange(e, order.reference);
                  }}
                  placeholder={
                    <div className="uppercase text-gfDarkBlue font-MulishRegular">
                      {t('awaiting-payment')}
                    </div>
                  }
                  options={orderDeclineStatus}
                  isSearchable={false}
                  isLoading={changingOrderStatus}
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator,
                    LoadingIndicator,
                  }}
                />
              ) : (
                <SalesStatus status={order.status} />
              )}
            </div>
          ) : (
            <SalesStatus status={order.display_status} />
          )}
        </Table.Cell>

        <Table.Cell data-testid="amount">
          <Money value={order.amount} currency={currency} />
        </Table.Cell>

        {order.orders.length === 0 && <Table.Cell></Table.Cell>}

        {order.orders.length > 0 && (
          <Table.Cell data-testid="action" className={'flex items-start'}>
            <button
              className={'mr-2'}
              onClick={() => callback({ action: 'setOrder', payload: order })}
            >
              {t('View Details')}
            </button>
            <button
              className="focus:outline-none"
              id={order.reference}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? <ChevronUp /> : <ChevronDown />}
            </button>
          </Table.Cell>
        )}
      </Table.Row>

      {isExpanded &&
        order.orders.map((childOrder) => (
          <Table.Row
            data-testid={childOrder.reference}
            key={childOrder.reference}
            style={{ backgroundColor: '#f4f4f4' }}
          >
            <Table.Cell data-testid="globalOrderRef">
              {order.reference}
            </Table.Cell>
            <Table.Cell data-testid="date">{order.ordered_at}</Table.Cell>
            <Table.Cell data-testid="orderRef">
              {childOrder.reference}
            </Table.Cell>
            <Table.Cell data-testid="customer">
              {`${order.purchaser.first_name} ${order.purchaser.last_name}`}
            </Table.Cell>
            <Table.Cell data-testid="status">
              <SalesStatus status={childOrder.display_status} />
            </Table.Cell>
            <Table.Cell data-testid="amount">
              <Money value={childOrder.amount} currency={currency} />
            </Table.Cell>
            <Table.Cell
              data-testid="action"
              className={'truncate cursor-pointer flex items-center'}
            >
              <span
                onClick={() =>
                  callback({
                    action: 'setCard',
                    payload: childOrder,
                  })
                }
              >
                {' '}
                {t(
                  childOrder.status === OrderStatusEnum.PAID ||
                    childOrder.status === OrderStatusEnum.PARTIALLY_PICKED_UP
                    ? 'Prepare Card'
                    : 'View Details',
                )}
              </span>
            </Table.Cell>
          </Table.Row>
        ))}

      <ConfirmationDeclineOrder
        isOpen={isOpen}
        closeHandler={declineClose}
        globalRef={globalRef}
        onSuccess={updateOrderChange}
        contentLabel="orderDeclined"
      />
      <ConfirmationPaidOrder
        isOpen={isPaidOrder}
        closeHandler={onPaidCloserHandler}
        globalRef={globalRef}
        onSuccess={updateOrderChange}
        contentLabel="orderPaid"
      />
    </>
  );
}

export default function ManageOrders() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageRef = useRef(null);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const { loadingManageOrders, receivedManageOrders, errorManageOrders, meta } =
    useSelector((state) => state.manageOrders);

  const { selectedTag } = useSelector((state) => state.tag);
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const [status, setStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [formattedFromDate, setFormattedFromDate] = useState(null);
  const [formattedToDate, setFormattedToDate] = useState(null);

  const { appCount, validationErrors } = useSelector(
    (state) => state.commonReducer,
  );

  const { selectedLanguage } = useSelector((state) => state.language);

  const DPCustomInput = forwardRef(
    ({ value, onClick, placeholderText }, ref) => (
      <input
        ref={ref}
        value={value}
        onClick={onClick}
        readOnly={true}
        placeholder={placeholderText}
        className="border cursor-pointer text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 h-10 focus:outline-none"
      />
    ),
  );

  const orderStatus = [
    { value: orderStatusEnum.ALL, label: `${t('all').toUpperCase()}` },
    {
      value: orderStatusEnum.AWAITING_PAYMENT,
      label: `${t('awaiting-payment').toUpperCase()}`,
    },
    {
      value: orderStatusEnum.TO_PICK_UP,
      label: `${t('to-pick-up').toUpperCase()}`,
    },
    {
      value: orderStatusEnum.PICKED_UP,
      label: `${t('picked-up').toUpperCase()}`,
    },
    { value: orderStatusEnum.DECLINE, label: `${t('decline').toUpperCase()}` },
  ];
  useEffect(() => {
    Userpilot.reload();
    setSearchKeyWord('');
    if (appCount === 0) {
      dispatch({ type: RESET_MANAGE_ORDERS });
      dispatch({ type: MANAGE_ORDERS_CHANGE_STATUS_OPERATION_ID });
      dispatch(
        getManageOrdersByDeliveryMethod(
          searchKeyWord,
          selectedPageNumber,
          formattedFromDate,
          formattedToDate,
          status?.value,
        ),
      );
    }

    window.addEventListener(TAG_CHANGED, () => {});

    window.addEventListener(TERMINAL_CHANGED, () => {});

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const isSearching = useMemo(() => searchKeyWord.length >= 1, [searchKeyWord]);
  const isExpandedByDefault = useMemo(() => {
    if (isSearching === false) {
      return false;
    }
    if (receivedManageOrders === null) {
      return false;
    }

    if (
      receivedManageOrders?.length > 1 ||
      receivedManageOrders?.length === 0
    ) {
      return false;
    }

    const [order] = receivedManageOrders;

    return order.orders.length === 1;
  }, [isSearching, receivedManageOrders]);

  const handleStatusChanged = () => {
    dispatch(
      getManageOrdersByDeliveryMethod(
        searchKeyWord,
        selectedPageNumber,
        formattedFromDate,
        formattedToDate,
        status?.value,
      ),
    );
  };

  const searchOnChangeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const handlePagination = (event) => {
    setSelectedPageNumber(event.selected + 1);
    dispatch(
      getManageOrdersByDeliveryMethod(
        searchKeyWord,
        event.selected + 1,
        formattedFromDate,
        formattedToDate,
        status?.value,
      ),
    );
  };

  const fromDateOnChanged = (date) => {
    const formattedDate = formatISO(date, { representation: 'date' });
    setFromDate(date);
    setFormattedFromDate(formattedDate);
    dispatch(
      getManageOrdersByDeliveryMethod(
        searchKeyWord,
        selectedPageNumber,
        formattedDate,
        formattedToDate,
        status?.value,
      ),
    );
  };

  const toDateOnChanged = (date) => {
    const formattedDate = formatISO(date, { representation: 'date' });
    setToDate(date);
    setFormattedToDate(formattedDate);
    dispatch(
      getManageOrdersByDeliveryMethod(
        searchKeyWord,
        selectedPageNumber,
        formattedFromDate,
        formattedDate,
        status?.value,
      ),
    );
  };

  const statusChanged = (newStatus) => {
    setStatus(newStatus);
    dispatch(
      getManageOrdersByDeliveryMethod(
        searchKeyWord,
        selectedPageNumber,
        formattedFromDate,
        formattedToDate,
        newStatus.value,
      ),
    );
  };

  const clearSearchBox = () => {
    setSearchKeyWord('');
    dispatch(
      getManageOrdersByDeliveryMethod(
        '',
        selectedPageNumber,
        formattedFromDate,
        formattedToDate,
      ),
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchHandler();
    }
  };

  const searchHandler = () => {
    dispatch(
      getManageOrdersByDeliveryMethod(
        searchKeyWord,
        selectedPageNumber,
        formattedFromDate,
        formattedToDate,
      ),
    );
  };

  const updateOrdersAfterPrepare = () => {
    dispatch(
      getManageOrdersByDeliveryMethod(
        searchKeyWord,
        selectedPageNumber,
        formattedFromDate,
        formattedToDate,
        status?.value,
      ),
    );
  };

  return (
    <Container
      title={t('manage-orders.all-orders')}
      loading={loadingManageOrders}
    >
      <div className="flex flex-col w-full mx-auto justify-start space-y-6">
        <div className="flex flex-col md:flex-row gap-10">
          <div className="flex flex-row gap-3">
            <div className="relative">
              {searchKeyWord !== '' && (
                <button
                  onClick={clearSearchBox}
                  className="focus:outline-none focus:shadow-outline"
                  style={{ position: 'absolute', right: '5px', top: '10px' }}
                >
                  <ClearIcon />
                </button>
              )}
              <input
                id="searchInput"
                value={searchKeyWord}
                onChange={searchOnChangeHandler}
                className="h-10 text-lg w-full placeholder-gfGrey  rounded border border-gfPeriwinkle focus:border-gfPeriwinkle pl-10 focus:outline-none"
                placeholder={`${t('Search')}...`}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>

            <button
              id="searchButton"
              onClick={searchHandler}
              className="border  flex flex-row rounded justify-center items-center  bg-gfCoral hover:bg-opacity-75 font-MulishBold  px-4 py-2 focus:outline-none"
            >
              <SearchIcon />
            </button>
          </div>

          <div className="flex flex-col">
            <ReactDatePicker
              id="fromDateInput"
              showMonthDropdown
              showYearDropdown
              dateFormat="dd/MM/yyyy"
              customInput={
                <DPCustomInput placeholderText={t('From date (optional)')} />
              }
              selected={fromDate}
              onChange={fromDateOnChanged}
              locale={selectedLanguage.value}
            />
          </div>
          <div className="flex flex-col">
            <ReactDatePicker
              id="toDateInput"
              showMonthDropdown
              showYearDropdown
              dateFormat="dd/MM/yyyy"
              selected={toDate}
              onChange={toDateOnChanged}
              customInput={
                <DPCustomInput placeholderText={t('To date (optional)')} />
              }
              locale={selectedLanguage.value}
            />
          </div>

          <Select
            id="statusDropdown"
            className="w-96"
            styles={customStyles}
            value={status ?? orderStatus[0]}
            getOptionLabel={(option) => `${option.label}`}
            onChange={statusChanged}
            placeholder={false}
            options={orderStatus}
            isSearchable={false}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator,
            }}
          />
        </div>

        {meta?.total > window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
          <div className="flex flex-col md:flex-row md:justify-end w-full">
            <div>
              <ReactPaginate
                ref={pageRef}
                previousLabel={'prev'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  meta.total /
                  window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                }
                marginPagesDisplayed={4}
                pageRangeDisplayed={5}
                onPageChange={handlePagination}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        )}

        {receivedManageOrders?.length === 0 && (
          <div className="mt-10">
            <MessageAlert message={t('There-are-no-orders-to-display')} />
          </div>
        )}

        {errorManageOrders && (
          <div className="mt-10">
            {errorManageOrders && (
              <FailAlert message={errorManageOrders.message} />
            )}
          </div>
        )}

        {validationErrors && (
          <div className="mt-10">
            <ValidationErrors {...validationErrors} />
          </div>
        )}

        {meta !== null && receivedManageOrders?.length !== 0 && (
          <div className=" overflow-x-auto sm:overflow-x-auto md:overflow-x-auto rounded border ">
            <div className="align-middle inline-block min-w-full  ">
              <div className="shadow overflow-hidden  ">
                <div className="flex flex-row justify-end items-center border-b w-full px-4 py-2 text-xl font-MulishBold  text-gfDarkBlue bg-gfPeriwinkle bg-opacity-50">{`${
                  meta.from
                } - ${meta.to} ${t('of')} ${meta.total} ${t('results')}`}</div>
                <Table>
                  <Table.Head>
                    <tr>
                      <Table.Header>{t('global-order-ref')}</Table.Header>
                      <Table.Header>{t('Date')}</Table.Header>
                      <Table.Header>{t('Order-Ref')}</Table.Header>
                      <Table.Header>{t('Customer')}</Table.Header>
                      <Table.Header>{t('Status')}</Table.Header>
                      <Table.Header>{t('Amount')}</Table.Header>
                      <Table.Header>{t('Action')}</Table.Header>
                    </tr>
                  </Table.Head>

                  <Table.Body>
                    {receivedManageOrders.map((item) => (
                      <OrderRow
                        key={item.reference}
                        order={item}
                        onStatusChanged={handleStatusChanged}
                        currency={selectedTag.currency}
                        isExpandedByDefault={isExpandedByDefault}
                        callback={({ action, payload }) => {
                          if (action === 'setOrder') {
                            setSelectedOrderDetails(payload);
                          } else if (action === 'setCard') {
                            setSelectedCardDetails(payload);
                          }
                        }}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        )}
      </div>
      <OrderDetailsModal
        isOpen={!!selectedOrderDetails}
        onClose={() => {
          dispatch({ type: RESET_CHANGING_ORDER_STATUS });
          dispatch({ type: RESET_VALIDATION_ERRORS });
          setSelectedOrderDetails(null);
        }}
        contentLabel="orderDetails"
        orderDetails={selectedOrderDetails}
        onPrepare={updateOrdersAfterPrepare}
      />

      <CardDetailsModal
        isOpen={!!selectedCardDetails}
        onClose={() => {
          dispatch({ type: ORDER_PREPARE_RESET });
          dispatch({ type: RESET_VALIDATION_ERRORS });
          dispatch({ type: INITIAL_LOAD_CARD });
          setSelectedCardDetails(null);
        }}
        contentLabel="cardDetails"
        cardDetails={selectedCardDetails}
        onPrepare={updateOrdersAfterPrepare}
      />
    </Container>
  );
}
