import {
  enGB,
  de,
  es,
  fr,
  hu,
  pt,
  ro,
  nl,
  sk,
  it,
  fi,
  pl,
  cs,
  lt,
  hr,
  sr,
} from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

export const SupportedLanguage = [
  { value: 'cs', label: 'CS', locale: cs },
  { value: 'de', label: 'DE', locale: de },
  { value: 'en', label: 'EN', locale: enGB },
  { value: 'es', label: 'ES', locale: es },
  { value: 'fi', label: 'FI', locale: fi },
  { value: 'fr', label: 'FR', locale: fr },
  { value: 'hu', label: 'HU', locale: hu },
  { value: 'hr', label: 'HR', locale: hr },
  { value: 'it', label: 'IT', locale: it },
  { value: 'lt', label: 'LT', locale: lt },
  { value: 'nl', label: 'NL', locale: nl },
  { value: 'pl', label: 'PL', locale: pl },
  { value: 'pt', label: 'PT', locale: pt },
  { value: 'ro', label: 'RO', locale: ro },
  { value: 'sk', label: 'SK', locale: sk },
  { value: 'sr', label: 'SR', locale: sr },
];

export const getCalenderLocale = (selected) => {
  return registerLocale(selected.value, selected.locale);
};
