import { authServerAPI } from '../services/Axios';
import { useSelector } from 'react-redux';

export const PREFIX = '@sso/';

export const LOADING_SSO_PROVIDER = `${PREFIX}LOADING_SSO_PROVIDER`;
export const RECEIVED_SSO_PROVIDER = `${PREFIX}RECEIVED_CLIENT`;
export const ERROR_SSO_PROVIDER = `${PREFIX}ERROR_SSO_PROVIDER`;
export const SELECTED_SSO_PROVIDER = `${PREFIX}SELECTED_SSO_PROVIDER`;

export const getSsoProviders = () => (dispatch, getState) => {
  dispatch({ type: LOADING_SSO_PROVIDER });
  const { tagID } = getState().tag;

  return authServerAPI
    .get(`/api/sso/service-provider/${tagID}`)
    .then((response) => {
      dispatch({ type: RECEIVED_SSO_PROVIDER, payload: response.data });
      dispatch(getSsoSettings());
    })
    .catch((error) => {
      dispatch({ type: ERROR_SSO_PROVIDER, payload: error });
    });
};

export const LOADING_SSO_CONFIGURATION = `${PREFIX}LOADING_SSO_CONFIGURATION`;
export const RECEIVED_SSO_CONFIGURATION = `${PREFIX}RECEIVED_SSO_CONFIGURATION`;
export const ERROR_SSO_CONFIGURATION = `${PREFIX}ERROR_SSO_CONFIGURATION`;

export const getSsoSettings = () => (dispatch, getState) => {
  dispatch({ type: LOADING_SSO_CONFIGURATION });

  const { tagID } = getState().tag;

  if (tagID !== null) {
    return authServerAPI
      .get(
        `/api/sso/settings/${window?.appConfig.REACT_APP_AUTH_CLIENT_ID}/${tagID}`,
      )
      .then((response) => {
        dispatch({ type: RECEIVED_SSO_CONFIGURATION, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: ERROR_SSO_CONFIGURATION, payload: error });
        return error;
      });
  } else {
    return null;
  }
};

export const UPDATING_SSO_CONFIGURATION = `${PREFIX}UPDATING_SSO_CONFIGURATION`;
export const UPDATED_SSO_CONFIGURATION = `${PREFIX}UPDATED_SSO_CONFIGURATION`;
export const ERROR_UPDATED_SSO_CONFIGURATION = `${PREFIX}ERROR_UPDATED_SSO_CONFIGURATION`;
export const RESET_UPDATING_SSO_CONFIGURATION = `${PREFIX}RESET_UPDATING_SSO_CONFIGURATION`;

export const updateSsoClient =
  (editedSsoConfig, history, ssoProvider, ssoSetting) =>
  (dispatch, getState) => {
    dispatch({ type: UPDATING_SSO_CONFIGURATION });
    const { selectedTag } = getState().tag;

    const data = {
      oauth_client_id: window?.appConfig.REACT_APP_AUTH_CLIENT_ID,
      sso_service_provider_id: ssoProvider.id,
      tag_id: selectedTag.id,
      tag_name: ssoSetting?.tagName ?? getSlugTag(selectedTag.name),
      provider_metadata: {},
    };

    const formFields = ssoProvider.formFields;
    formFields.map((field) => {
      data.provider_metadata[field.name] = editedSsoConfig[field.name];
    });

    if (editedSsoConfig) {
      if (ssoSetting?.id) {
        authServerAPI
          .patch(`/api/sso/settings/${ssoSetting.id}`, { ...data })
          .then((response) => {
            dispatch({
              type: UPDATED_SSO_CONFIGURATION,
              payload: response.data,
              sso_provider_name: ssoProvider.name,
            });
            dispatch(getSsoSettings());
          })
          .catch((error) => {
            dispatch({
              type: ERROR_UPDATED_SSO_CONFIGURATION,
              payload: error,
              sso_provider_name: ssoProvider.name,
            });
            setTimeout(function () {
              dispatch({ type: RESET_UPDATING_SSO_CONFIGURATION });
            }, 2000);
            return error;
          });
      } else {
        authServerAPI
          .post(
            `/api/sso/settings/${window?.appConfig.REACT_APP_AUTH_CLIENT_ID}/${selectedTag.id}`,
            { ...data },
          )
          .then((success) => {
            dispatch({
              type: UPDATED_SSO_CONFIGURATION,
              payload: success.data,
              sso_provider_name: ssoProvider.name,
            });
            dispatch(getSsoSettings());
          })
          .catch((error) => {
            dispatch({
              type: ERROR_UPDATED_SSO_CONFIGURATION,
              payload: error,
              sso_provider_name: ssoProvider.name,
            });
            setTimeout(function () {
              dispatch({ type: RESET_UPDATING_SSO_CONFIGURATION });
            }, 2000);
            return error;
          });
      }
    }
  };

export const CLEARING_SSO_CONFIGURATION = `${PREFIX}CLEARING_SSO_CONFIGURATION`;
export const CLEARED_SSO_CONFIGURATION = `${PREFIX}CLEARED_SSO_CONFIGURATION`;
export const ERROR_CLEARED_SSO_CONFIGURATION = `${PREFIX}ERROR_CLEARED_SSO_CONFIGURATION`;
export const RESET_CLEARING_SSO_CONFIGURATION = `${PREFIX}RESET_CLEARING_SSO_CONFIGURATION`;

export const deleteSsoSetting =
  (ssoSettingId, history) => (dispatch, getState) => {
    dispatch({ type: CLEARING_SSO_CONFIGURATION });

    const data = {
      oauth_client_id: window?.appConfig.REACT_APP_AUTH_CLIENT_ID,
    };

    return authServerAPI
      .delete(`/api/sso/settings/${ssoSettingId}`, { ...data })
      .then((response) => {
        dispatch({ type: CLEARED_SSO_CONFIGURATION, payload: response.data });
        dispatch(getSsoSettings());
      })
      .catch((error) => {
        dispatch({ type: ERROR_CLEARED_SSO_CONFIGURATION, payload: error });
        setTimeout(function () {
          dispatch({ type: RESET_CLEARING_SSO_CONFIGURATION });
        }, 2000);
      });
  };

export const getSsoConnectionUrl = (tagSsoName) => {
  return `https://${window.location.host}/sso/login/${tagSsoName}`;
};
export const getSlugTag = (tagName) => {
  tagName = tagName.replace(/^\s+|\s+$/g, ''); // trim
  tagName = tagName.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñçč·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuuncc------';
  for (var i = 0, l = from.length; i < l; i++) {
    tagName = tagName.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  tagName = tagName
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return tagName;
};

export const getSsoCallbackUrl = (ssoProviderName, ssoTagName) => {
  return `${window?.appConfig.REACT_APP_AUTH_SERVER_API}/sso/callback/${ssoProviderName}/${getSlugTag(ssoTagName)}`;
};
