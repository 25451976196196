import React from 'react';

export default function SuccessAlert({ message, title }) {
  return (
    <div className="rounded border border-green-700 bg-green-100 px-4 py-2">
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-green-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="space-y-2">
          {title && (
            <h3 className="text-sm font-MulishBold text-left text-green-800">
              {title}
            </h3>
          )}
          {message && (
            <div className="text-sm text-green-700">
              <p>{message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
