/* eslint-disable import/no-anonymous-default-export */
import {
  GENERATE_MVC_INVOICE,
  SUCCESS_GENERATE_MVC_INVOICE,
  ERROR_GENERATE_MVC_INVOICE,
  RESET_MVC_LOAD,
} from '../actions/MVCLoadAction';

const initialState = {
  loadingGenerateMVCInvoice: false,
  errorGenerateMVCInvoice: null,
  successGenerateMVCInvoice: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GENERATE_MVC_INVOICE:
      return {
        ...state,
        loadingGenerateMVCInvoice: true,
        errorGenerateMVCInvoice: null,
        successGenerateMVCInvoice: null,
      };

    case SUCCESS_GENERATE_MVC_INVOICE:
      return {
        ...state,
        loadingGenerateMVCInvoice: false,
        errorGenerateMVCInvoice: null,
        successGenerateMVCInvoice: action.payload,
      };

    case ERROR_GENERATE_MVC_INVOICE:
      return {
        ...state,
        loadingGenerateMVCInvoice: false,
        errorGenerateMVCInvoice: action.payload,
        successGenerateMVCInvoice: null,
      };

    case RESET_MVC_LOAD:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
