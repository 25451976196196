/* eslint-disable import/no-anonymous-default-export */
import { enGB } from 'date-fns/locale';
import { SELECTED_LANGUAGE } from '../actions/LanguageAction';
import { SupportedLanguage } from '../utils/SupportedLanguages';

const initialState = {
  selectedLanguage: { value: 'en', label: 'EN', locale: enGB },
  languages: SupportedLanguage,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: state.languages.find(
          (lang) => lang.value === action.payload,
        ),
      };

    default:
      return state;
  }
}
