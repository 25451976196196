import React, { useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import Logo from '../../../../assets/svg/Logo';
import { randomString } from '../../../../utils/Helper';

ReactModal.setAppElement('#root');

export default function Print({
  modalVisibility,
  closeHandler,
  transferDetails,
}) {
  const { selectedTag, tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  const componentRef = useRef();
  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const AdditionalProducts = (product) => {
    const rows = [];

    for (let i = 0; i < product.quantity; i++) {
      rows.push(
        <div className="mb-2">
          <div className="flex flex-row justify-start item-center">
            <span className="font-MulishBold">{`${t('Name')}: `}</span>
            <span className="mx-2">{product.product.name}</span>
          </div>
          <div className="flex flex-row justify-start item-center">
            <span className="font-MulishBold">{`${t('price')}: `}</span>
            <div className="flex justify-start ml-2">
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={product.product.price}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span className="ml-2">{tagCurrency}</span>
            </div>
          </div>
          <div className="flex flex-row justify-start item-center">
            <span className="font-MulishBold">{`${t('Included-Tax')}: `}</span>
            <div className="flex justify-start ml-2">
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={product.product.tax}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span className="ml-2">{tagCurrency}</span>
            </div>
          </div>
        </div>,
      );
    }
    return rows;
  };

  return (
    <ReactModal
      isOpen={modalVisibility}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col max-w-xl mx-auto bg-gray-100">
        <div className="flex justify-between p-5">
          <button
            className="focus:outline-none bg-gfCoral text-base rounded font-MulishBold hover:bg-opacity-75 px-4 py-1"
            onClick={printReceipt}
          >
            {t('Print')}
          </button>
          <button onClick={() => closeHandler(!modalVisibility)}>
            <CloseIcon />
          </button>
        </div>
        <div
          ref={componentRef}
          style={{
            maxWidth: '165px',
            margin: 'auto',
            backgroundColor: 'white',
            color: 'black',
            fontSize: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '30px',
            }}
          >
            <img
              style={{ maxWidth: '100px' }}
              src={`${window?.appConfig.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
              alt="logo"
            />
          </div>

          <div
            style={{
              textAlign: 'left',
              fontSize: '16px',
              paddingTop: '30px',
              paddingBottom: '30px',
              fontWeight: 'bolder',
            }}
          >
            {selectedTag.name}
          </div>

          {transferDetails.user && transferDetails.user !== null && (
            <div
              style={{
                display: 'flex',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{`${t('User')}: `}</span>
              <span style={{ marginLeft: '4px' }}>{`${String(
                transferDetails.user.first_name.charAt(0),
              ).toUpperCase()} ${String(transferDetails.user.last_name.charAt(0)).toUpperCase()}`}</span>
            </div>
          )}

          <div
            style={{ display: 'flex', paddingTop: '3px', paddingBottom: '3px' }}
          >
            <span
              style={{ fontWeight: 'bold' }}
            >{`${t('Transaction')}: `}</span>
            <span style={{ marginLeft: '4px' }}>
              {transferDetails.ticket_number}
            </span>
          </div>

          {transferDetails.payments &&
            transferDetails.payments.length !== 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '3px',
                  paddingBottom: '3px',
                }}
              >
                <div className="flex flex-row">
                  <span
                    style={{ fontWeight: 'bold' }}
                  >{`${t('Payment-methods')}: `}</span>
                </div>
                {transferDetails.payments.map((payment) => (
                  <div className="flex flex-row" key={payment.id}>
                    <span className="">{payment.name}</span>
                    <span> (</span>
                    <div className="flex justify-start">
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={payment.total_amount}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                    </div>
                    <span>)</span>
                  </div>
                ))}
              </div>
            )}

          {transferDetails.channel && (
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  marginRight: '4px',
                }}
              >{`${t('Channel')}: `}</span>
              <span
                style={{ textTransform: 'uppercase', display: 'inline-block' }}
              >
                {transferDetails.channel.name}
              </span>
            </div>
          )}

          {transferDetails.desk && (
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  marginRight: '4px',
                }}
              >{`${t('Desk')}: `}</span>
              <span
                style={{ textTransform: 'uppercase', display: 'inline-block' }}
              >
                {transferDetails.desk.name}
              </span>
            </div>
          )}

          <div
            style={{
              textAlign: 'left',
              paddingTop: '3px',
              paddingBottom: '3px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                marginRight: '4px',
              }}
            >{`${t('Date-of-Purchase')}: `}</span>
            <span style={{ display: 'inline-block' }}>
              {transferDetails.transaction_date_time}
            </span>
          </div>

          {transferDetails.entries && transferDetails.entries.length !== 0 && (
            <div>
              <div
                style={{
                  textAlign: 'left',
                  paddingTop: '20px',
                  paddingBottom: '10px',
                  fontWeight: 'bolder',
                  fontSize: '13px',
                }}
              >
                {`${t('Gift-Cards')}`}
              </div>

              {transferDetails.entries.map((entry) => (
                <div
                  style={{ paddingTop: '3px', paddingBottom: '3px' }}
                  key={entry.token}
                >
                  <div className="flex ">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Token')}: `}</span>
                    <span style={{ marginLeft: '4px' }}>{entry.token}</span>
                  </div>
                  <div className="flex ">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Amount')}: `}</span>
                    <div style={{ marginLeft: '4px' }}>
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={entry.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                    </div>
                  </div>
                  <div className="flex ">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Expiry-date')}: `}</span>
                    <span style={{ marginLeft: '4px' }}>
                      {entry.expiry_date}
                    </span>
                  </div>
                </div>
              ))}

              <div style={{ paddingLeft: '12px', marginTop: '10px' }}>
                <div className="flex ">
                  <span
                    style={{ fontWeight: 'bold' }}
                  >{`${t('Cards')}: `}</span>
                  <span style={{ marginLeft: '4px' }}>
                    {transferDetails.no_of_entries}
                  </span>
                </div>
                <div className="flex flex-row">
                  <span
                    style={{ fontWeight: 'bold' }}
                  >{`${t('Amount')}: `}</span>
                  <div style={{ marginLeft: '4px' }}>
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={transferDetails.entries.reduce((sum, item) => {
                        sum += item.amount;
                        return sum;
                      }, 0)}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {transferDetails.additional_products &&
            transferDetails.additional_products.length !== 0 && (
              <div className="" key={randomString(2)}>
                <div
                  style={{
                    textAlign: 'left',
                    paddingTop: '20px',
                    paddingBottom: '10px',
                    fontWeight: 'bolder',
                    fontSize: '13px',
                  }}
                >{`${t('Additional-products')}`}</div>
                {transferDetails.additional_products.map((product) =>
                  AdditionalProducts(product),
                )}

                <div style={{ paddingLeft: '12px' }}>
                  <div className="flex flex-row items-center">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Products')}: `}</span>
                    <span style={{ marginLeft: '4px' }}>
                      {transferDetails.total_product_count}
                    </span>
                  </div>
                  <div className="flex flex-row items-center">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Amount')}: `}</span>
                    <div className="ml-1">
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={transferDetails.total_product_price}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center ">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Included-Tax')}: `}</span>
                    <div style={{ marginLeft: '4px' }}>
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={transferDetails.total_product_tax}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className="">
            <div
              style={{
                textAlign: 'left',
                paddingTop: '20px',
                paddingBottom: '10px',
                fontWeight: 'bolder',
                fontSize: '13px',
              }}
            >{`${t('Order-Recap')}`}</div>
            <div>
              <div className="flex flex-row items-center">
                <span style={{ fontWeight: 'bold' }}>{`${t('Cards')}: `}</span>
                <span style={{ marginLeft: '4px' }}>
                  {transferDetails.no_of_entries}
                </span>
              </div>
              {transferDetails.additional_products &&
                transferDetails.additional_products.length !== 0 && (
                  <div className="flex flex-row items-center">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Products')}: `}</span>
                    <span style={{ marginLeft: '4px' }}>
                      {transferDetails.total_product_count}
                    </span>
                  </div>
                )}
              <div className="flex flex-row items-center">
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${t('Total-amount')}: `}</span>
                <div className="flex flex-row ml-1">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={transferDetails.total_amount}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                  <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${t('Included-Tax')}:`}</span>
                {transferDetails.additional_products && (
                  <div className="flex flex-row ml-1">
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={transferDetails.total_product_tax}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {transferDetails.fields && transferDetails.fields.length !== 0 && (
            <div>
              <div
                style={{
                  textAlign: 'left',
                  paddingTop: '20px',
                  paddingBottom: '10px',
                  fontWeight: 'bolder',
                  fontSize: '13px',
                }}
              >{`${t('User-Information')}`}</div>
              <div>
                {transferDetails.fields.map((field) => (
                  <div className="flex flex-row">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${field.form_field.label}: `}</span>
                    <span style={{ marginLeft: '4px' }}>{field.value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div style={{ textAlign: 'center', padding: '30px 0px' }}>
            <div>{selectedTag.name}</div>
            <div className="text-center">{`${selectedTag.address}, ${selectedTag.zip}, ${selectedTag.city}, ${selectedTag.country_code}`}</div>
            <div style={{ width: '155px', wordBreak: 'break-all' }}>
              {selectedTag.support_email}
            </div>
            <div>{selectedTag.support_phone}</div>
            <div>{selectedTag.website}</div>
          </div>

          <div
            style={{
              textAlign: 'center',
              paddingBottom: '30px',
            }}
          >
            {selectedTag.ticket_footer}
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
