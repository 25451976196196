/* eslint-disable import/no-anonymous-default-export */
import {
  ERROR_FEES_ONLINE,
  LOADING_FEES_ONLINE,
  RECEIVED_FEES_ONLINE,
  PAGINATE_FEES_ONLINE_LIST,
  RESET_FEES_REPORT,
} from '../../actions/FeesReportActions';
import { paginate } from '../../utils/Helper';

const PAGINATE_FIRST_PAGE = 1;

const initialState = {
  loadingFeesReport: false,
  receivingFeesReport: null,
  errorFeesReport: null,
  populatedList: null,
  feesReportSummary: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FEES_ONLINE:
      return {
        ...state,
        loadingFeesReport: true,
        receivingFeesReport: null,
        errorFeesReport: null,
        feesReportSummary: null,
      };

    case RECEIVED_FEES_ONLINE:
      return {
        ...state,
        loadingFeesReport: false,
        receivingFeesReport: action.payload.orders,
        feesReportSummary: action.payload.summary,
        errorFeesReport: null,
        populatedList: paginate(
          action.payload.orders,
          PAGINATE_FIRST_PAGE,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      };

    case ERROR_FEES_ONLINE:
      return {
        ...state,
        loadingFeesReport: false,
        receivingFeesReport: null,
        errorFeesReport: action.payload,
        feesReportSummary: null,
      };

    case PAGINATE_FEES_ONLINE_LIST:
      return {
        ...state,
        populatedList: paginate(
          state.receivingFeesReport,
          action.payload,
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
        ),
      };

    case RESET_FEES_REPORT:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
