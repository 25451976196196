import { useTranslation } from 'react-i18next';
import Carrier from '../../../enums/carrier.enum';
import useAppConfig, { features } from '../../../hooks/useAppConfig';

export default function CarrierInformation({ data }) {
  const { isFeatureEnabled } = useAppConfig();
  const { t } = useTranslation();

  return (
    isFeatureEnabled(features.carriersInOrderSales) &&
    [Carrier.TO_PICK_UP, Carrier.HOME_DELIVERY].includes(
      data?.home_delivery,
    ) && (
      <div className="flex flex-row items-start justify-start">
        <span className="w-40 text-left font-MulishBold">{`${t('Delivery-method')} :`}</span>
        <div className="flex flex-col text-left">
          <span>
            {t(
              data.home_delivery === Carrier.TO_PICK_UP
                ? 'Pick-up'
                : 'Home-delivery',
            )}
            {data?.carrier_name ? ` (${data.carrier_name})` : ''}
          </span>
        </div>
      </div>
    )
  );
}
