import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import ButtonLoading from '../../assets/svg/ButtonLoading';
import { issuingAPI } from '../../services/Axios';
import { GF_DARK_BLUE } from '../../utils/Constants';
import FailAlert from '../Alert/FailAlert';
import { cn } from '../../utils';
import { Form, FormControl, FormField, FormItem } from '../Form';
import { cardAPI } from '../../api';

export default function PanVerification({
  onSetIsValid,
  ticket_number = null,
  token = null,
}) {
  const { t } = useTranslation();
  const { selectedTag } = useSelector((state) => state.tag);

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      pan: '',
    },
  });

  const confirmPan = () => {
    onSetIsValid(true);
  };

  const validatePan = async (pan) => {
    try {
      const { data } = await (token
        ? confirmPanByToken(pan)
        : confirmPanByTicket(pan));

      return data.status === 'valid' ? true : t('pan-is-not-valid');
    } catch (e) {
      return t('pan-is-not-valid');
    }
  };

  const confirmPanByTicket = (pan) => {
    return issuingAPI.post(
      `/tag/${selectedTag.id}/ticket/${ticket_number}/verify-pan`,
      {
        pan,
      },
    );
  };

  const confirmPanByToken = (pan) => {
    return cardAPI.confirmPanByToken({ pan, token });
  };

  const pan = methods.watch('pan');

  const panHasValidFormat = useMemo(() => {
    const regex = /^\d{4}$/;
    return regex.test(pan);
  }, [pan]);

  return (
    <>
      <div className="text-left mb-1">{t('enter-last-pan-of-the-card')}</div>
      <Form {...methods}>
        <div className="flex flex-row gap-5 mb-4">
          <FormField
            name="pan"
            defaultValue={''}
            rules={{
              required: t('Required'),
              validate: async (value) => await validatePan(value),
            }}
            render={(field) => (
              <FormItem className={'w-full'}>
                <FormControl>
                  <input
                    {...field}
                    placeholder={t('type-the-4-last-digit')}
                    autoComplete="false"
                    onKeyDown={() => methods.clearErrors()}
                    maxLength={4}
                    className="w-full h-10 focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <button
            onClick={methods.handleSubmit(confirmPan)}
            className={cn(
              'border rounded w-32 p-2 text-gfDarkBlue bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium',
              !panHasValidFormat ||
                methods.formState.isValidating ||
                methods.formState.isSubmitting
                ? 'opacity-50 pointer-events-none'
                : '',
            )}
          >
            {methods.formState.isValidating ||
              (methods.formState.isSubmitting && (
                <ButtonLoading color={GF_DARK_BLUE} />
              ))}
            {t('Confirm')}
          </button>
        </div>
        {methods.formState.errors.pan && !methods.formState.isValid && (
          <FailAlert message={methods.formState.errors.pan.message} />
        )}
      </Form>
    </>
  );
}
