import { issuingAPI } from '../services/Axios';
import { exportCSVFile } from '../utils/Helper';
import {
  APP_COUNT,
  ERROR_CSV_REPORT,
  GET_DESK_API_COUNT,
  GET_USE_API_COUNT,
  LOADING_CSV_REPORT,
  SUCCESS_CSV_REPORT,
} from './CommonActions';

export const PREFIX = '@dailyReport/';

export const LOADING_USER_LIST = `${PREFIX}LOADING_USER_LIST`;
export const RECEIVED_USER_LIST = `${PREFIX}RECEIVED_USER_LIST`;
export const ERROR_USER_LIST = `${PREFIX}ERROR_USER_LIST`;

export const LOADING_DESK_LIST = `${PREFIX}LOADING_DESK_LIST`;
export const RECEIVED_DESK_LIST = `${PREFIX}RECEIVED_DESK_LIST`;
export const ERROR_DESK_LIST = `${PREFIX}ERROR_DESK_LIST`;

export const LOADING_DAILY_REPORT = `${PREFIX}LOADING_DAILY_REPORT`;
export const RECEIVED_DAILY_REPORT = `${PREFIX}RECEIVED_DAILY_REPORT`;
export const ERROR_DAILY_REPORT = `${PREFIX}ERROR_DAILY_REPORT`;
export const POPULATED_LIST = `${PREFIX}POPULATED_LIST`;

export const RESET_DAILY_REPORT = `${PREFIX}RESET_DAILY_REPORT`;

export const getUserList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_USER_LIST });

  const { tagID } = getState().tag;

  dispatch({ type: GET_USE_API_COUNT });

  return issuingAPI
    .get(`tag/${tagID}/user`)
    .then((success) => {
      dispatch({ type: RECEIVED_USER_LIST, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_USER_LIST, payload: error });
      return error;
    });
};

export const getDeskList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_DESK_LIST });

  const { tagID } = getState().tag;

  dispatch({ type: GET_DESK_API_COUNT });

  return issuingAPI
    .get(`tag/${tagID}/desk`)
    .then((success) => {
      dispatch({ type: RECEIVED_DESK_LIST, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_DESK_LIST, payload: error });
      return error;
    });
};

export const getProductReport =
  (fromDate, toDate, selectedUser, selectedDesk) => (dispatch, getState) => {
    dispatch({ type: LOADING_DAILY_REPORT });

    const { tagID } = getState().tag;

    dispatch({ type: APP_COUNT });

    const params = {
      from_date: fromDate,
      to_date: toDate,
    };

    if (selectedUser !== -1) {
      Object.assign(params, { user_ids: [selectedUser] });
    }

    if (selectedDesk !== -1) {
      Object.assign(params, { desk_ids: [selectedDesk] });
    }

    return issuingAPI
      .get(`tag/${tagID}/transaction`, { params })
      .then((success) => {
        dispatch({ type: RECEIVED_DAILY_REPORT, payload: success.data });
        return success;
      })
      .catch((error) => {
        dispatch({ type: ERROR_DAILY_REPORT, payload: error });
        return error;
      });
  };

export const getCSV =
  (fromDate, toDate, selectedUser, selectedDesk, reportName) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_CSV_REPORT });

    const { tagID } = getState().tag;
    const params = {
      from_date: fromDate,
      to_date: toDate,
    };

    const headers = {
      Accept: 'text/csv',
    };

    const data = null;

    if (selectedUser !== -1) {
      Object.assign(params, { user_ids: [selectedUser] });
    }

    if (selectedDesk !== -1) {
      Object.assign(params, { desk_ids: [selectedDesk] });
    }

    const fileName = `${reportName}-${fromDate}-${toDate}`;

    return issuingAPI
      .get(`tag/${tagID}/transaction/stream`, { headers, params, data })
      .then((success) => {
        dispatch({ type: SUCCESS_CSV_REPORT });
        exportCSVFile(success.data, fileName);
      })
      .catch((error) => {
        dispatch({ type: ERROR_CSV_REPORT, payload: error });
        return error;
      });
  };
