import React from 'react';
import { useSelector } from 'react-redux';
import Money from './Money';

function ShoppingCenterMoney({
  value,
  className,
  currency = null,
  decimalScale = 2,
  ...props
}) {
  let localCurrency = currency;

  if (!currency) {
    const { tagCurrency } = useSelector((state) => state.tag);
    localCurrency = tagCurrency;
  }
  return (
    <Money
      value={value}
      className={className}
      currency={localCurrency}
      decimalScale={decimalScale}
      {...props}
    />
  );
}

export default ShoppingCenterMoney;
