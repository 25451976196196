import { issuingAPI } from '../services/Axios';
import { exportCSVFile, exportExcelFile } from '../utils/Helper';
import { ERROR_CSV_REPORT } from './CardOrderActions';
import {
  APP_COUNT,
  LOADING_CSV_REPORT,
  LOADING_EXCEL_REPORT,
  SUCCESS_EXCEL_REPORT,
  SUCCESS_CSV_REPORT,
  ERROR_EXCEL_REPORT,
} from './CommonActions';

export const PREFIX = '@cardStock/';

export const LOADING_CARD_STOCK = `${PREFIX}LOADING_CARD_STOCK`;
export const RECEIVED_CARD_STOCK = `${PREFIX}RECEIVED_CARD_STOCK`;
export const ERROR_CARD_STOCK = `${PREFIX}ERROR_CARD_STOCK`;

export const POPULATED_STOCK_TODAY_LIST = `${PREFIX}POPULATED_STOCK_TODAY_LIST`;
export const POPULATED_STOCK_THREE_MONTHS_LIST = `${PREFIX}POPULATED_STOCK_THREE_MONTHS_LIST`;
export const POPULATED_STOCK_SIX_MONTHS_LIST = `${PREFIX}POPULATED_STOCK_SIX_MONTHS_LIST`;
export const POPULATED_STOCK_YEAR_LIST = `${PREFIX}POPULATED_STOCK_YEAR_LIST`;
export const POPULATED_STOCK_EXPIRED_LIST = `${PREFIX}POPULATED_STOCK_EXPIRED_LIST`;

export const getCardStock = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CARD_STOCK });

  const { tagID } = getState().tag;

  dispatch({ type: APP_COUNT });

  return issuingAPI
    .get(`/tag/${tagID}/card/stock`)
    .then((success) => {
      dispatch({ type: RECEIVED_CARD_STOCK, payload: success.data });
    })
    .catch((error) => {
      dispatch({ type: ERROR_CARD_STOCK, payload: error });
      return error;
    });
};

export const getExcel = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT });

  const { tagID } = getState().tag;
  const fileName = `Card Stock Report`;

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  return issuingAPI
    .get(`tag/${tagID}/card/stock/stream`, { responseType: 'blob', headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT });
      exportExcelFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error });
      return error;
    });
};

export const getExpiredCardsCSV = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'text/csv',
  };

  const fileName = `Card-Stock-Expired-Cards`;

  return issuingAPI
    .get(`tag/${tagID}/card/expired-stock/stream`, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT });
      exportCSVFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error });
      return error;
    });
};

export const getExpiredCardsExcel = () => (dispatch, getState) => {
  dispatch({ type: LOADING_EXCEL_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const fileName = `Card-Stock-Expired-Cards`;

  return issuingAPI
    .get(`tag/${tagID}/card/expired-stock/stream`, {
      responseType: 'blob',
      headers,
    })
    .then((success) => {
      dispatch({ type: SUCCESS_EXCEL_REPORT });
      exportExcelFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
      return error;
    });
};

// {{host}}/tag/{{tag_id}}/card/today-stock/stream
export const getTodaysStockCSV = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'text/csv',
  };

  const fileName = `Card-Stock-Today`;

  return issuingAPI
    .get(`tag/${tagID}/card/today-stock/stream`, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT });
      exportCSVFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error });
      return error;
    });
};

export const getTodayStockExcel = () => (dispatch, getState) => {
  dispatch({ type: LOADING_EXCEL_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const fileName = `Card-Stock-Today`;

  return issuingAPI
    .get(`tag/${tagID}/card/today-stock/stream`, {
      responseType: 'blob',
      headers,
    })
    .then((success) => {
      dispatch({ type: ERROR_EXCEL_REPORT });
      exportExcelFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
      return error;
    });
};

// {{host}}/tag/{{tag_id}}/card/three-months-stock/stream
export const getThreeMonthsStockCSV = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'text/csv',
  };

  const fileName = `Card-Stock-Three-Months`;

  return issuingAPI
    .get(`tag/${tagID}/card/three-months-stock/stream`, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT });
      exportCSVFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error });
      return error;
    });
};

export const getThreeMonthsStockExcel = () => (dispatch, getState) => {
  dispatch({ type: LOADING_EXCEL_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const fileName = `Card-Stock-Three-Months`;

  return issuingAPI
    .get(`tag/${tagID}/card/three-months-stock/stream`, {
      responseType: 'blob',
      headers,
    })
    .then((success) => {
      dispatch({ type: SUCCESS_EXCEL_REPORT });
      exportExcelFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
      return error;
    });
};

// {{host}}/tag/{{tag_id}}/card/six-months-stock/stream
export const getSixMonthsStockCSV = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'text/csv',
  };

  const fileName = `Card-Stock-Six-Months`;

  return issuingAPI
    .get(`tag/${tagID}/card/six-months-stock/stream`, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT });
      exportCSVFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error });
      return error;
    });
};

export const getSixMonthsStockExcel = () => (dispatch, getState) => {
  dispatch({ type: LOADING_EXCEL_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const fileName = `Card-Stock-Six-Months`;

  return issuingAPI
    .get(`tag/${tagID}/card/six-months-stock/stream`, {
      responseType: 'blob',
      headers,
    })
    .then((success) => {
      dispatch({ type: SUCCESS_EXCEL_REPORT });
      exportExcelFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
      return error;
    });
};

// {{host}}/tag/{{tag_id}}/card/year-stock/stream
export const getyearStockCSV = () => (dispatch, getState) => {
  dispatch({ type: LOADING_CSV_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'text/csv',
  };

  const fileName = `Card-Stock-Year`;

  return issuingAPI
    .get(`tag/${tagID}/card/year-stock/stream`, { headers })
    .then((success) => {
      dispatch({ type: SUCCESS_CSV_REPORT });
      exportCSVFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_CSV_REPORT, payload: error });
      return error;
    });
};

export const getYearStockExcel = () => (dispatch, getState) => {
  dispatch({ type: LOADING_EXCEL_REPORT });

  const { tagID } = getState().tag;

  const headers = {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const fileName = `Card-Stock-Year`;

  return issuingAPI
    .get(`tag/${tagID}/card/year-stock/stream`, {
      responseType: 'blob',
      headers,
    })
    .then((success) => {
      dispatch({ type: SUCCESS_EXCEL_REPORT });
      exportExcelFile(success.data, fileName);
    })
    .catch((error) => {
      dispatch({ type: ERROR_EXCEL_REPORT, payload: error });
      return error;
    });
};
