import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SettlementMvc from './SettlementMVC';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import MVCViewList from '../../MVC/MVCViewList';
import PageTitle from '../../../PageTitle';
import useApiHook from '../../../../hooks/useAPI';
import reportAPI from '../../../../api/reportAPI';

export default function MVCs() {
  const { t } = useTranslation();

  const [listMVCPaymentMethodsHandler, getMVCPaymentMethodsFullList] =
    useApiHook(reportAPI.getMVCPaymentMethodsFullList);

  useEffect(() => {
    getMVCPaymentMethodsFullList({ show_settlement_mvc: true });
  }, []);

  return (
    <TabItemContainer
      title={t('reports.finance.mvcs.title')}
      description={t('reports.finance.mvcs.description')}
    >
      {!!listMVCPaymentMethodsHandler?.data?.data?.length &&
        listMVCPaymentMethodsHandler.success && (
          <>
            <PageTitle title={t('reports.finance.mvcs.settlement.title')} />
            <SettlementMvc
              title={'Settlement MVC'}
              listMVCPaymentMethodsHandler={listMVCPaymentMethodsHandler}
            />
            <div className={'h-4'} />
          </>
        )}
      <PageTitle title={t('reports.finance.mvcs.mvcs.title')} />
      <MVCViewList />
    </TabItemContainer>
  );
}
