import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Userpilot } from 'userpilot';
import { TAG_CHANGED } from '../../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions';
import ClearIcon from '../../../../assets/svg/ClearIcon';
import SearchIcon from '../../../../assets/svg/SearchIcon';
import Container from '../../../UIComponents/Container';
import Table from '../../../UIComponents/Table';
import EditShop from './EditShop';
import {
  getManageShopListPagination,
  getShopSign,
  RESET_MANAGE_SHOP_LIST,
  RESET_UPDATE,
} from '../../../../actions/ManageShopActions';
import FailAlert from '../../../Alert/FailAlert';

export default function ManageShops() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [keyword, setKeyword] = useState('');
  const [isOpenEdit, setOpenEdit] = useState(false);
  const [data, setData] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const { appCount } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    Userpilot.reload();

    if (appCount === 0) {
      dispatch({ type: RESET_MANAGE_SHOP_LIST });
      dispatch(getShopSign());
    }

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_MANAGE_SHOP_LIST });
      dispatch(getShopSign());
    });

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_MANAGE_SHOP_LIST });
      dispatch(getShopSign());
    });

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const {
    loadingShopSign,
    loadingShopCategory,
    loadingManageShopList,
    manageShopList,
    manageShopListMeta,
    errorShopSign,
    errorShopCategory,
    errorManageShopList,
  } = useSelector((state) => state.manageShops);

  const clearShopSearchHandler = () => {
    setKeyword('');
    dispatch(getManageShopListPagination('', 1));
  };

  const shopSearchHandler = (event) => {
    event.persist();
    setKeyword(event.target.value);
    dispatch(getManageShopListPagination(event.target.value, selectedPage));
  };

  const editRow = (event, shop) => {
    setData(shop);
    setOpenEdit(true);
  };

  const editCloseHandler = () => {
    setOpenEdit(false);
    dispatch({ type: RESET_UPDATE });
  };

  const paginationHandler = (event) => {
    setSelectedPage(event.selected + 1);
    dispatch(getManageShopListPagination(keyword, event.selected + 1));
  };

  const successHandler = () => {
    dispatch({ type: RESET_UPDATE });
    dispatch(getManageShopListPagination(keyword, selectedPage));
  };

  return (
    <Container
      title={t('manage-shops')}
      loading={loadingShopSign || loadingShopCategory || loadingManageShopList}
    >
      <div className="grid grid-cols-1 md:grid-cols-4 gap-12 py-5">
        <div>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <button className="focus:outline-none focus:shadow-outline">
                <SearchIcon />
              </button>
            </span>
            {keyword !== '' && (
              <button
                onClick={clearShopSearchHandler}
                className="focus:outline-none focus:shadow-outline"
                style={{ position: 'absolute', right: '5px', top: '9px' }}
              >
                <ClearIcon />
              </button>
            )}
            <input
              id="searchInput"
              value={keyword}
              onChange={shopSearchHandler}
              className="h-10 text-lg w-full placeholder-gfGrey rounded border border-gfPeriwinkle focus:border-gfPeriwinkle pl-10 focus:outline-none"
              placeholder={`${t('Search')}...`}
              autoComplete="off"
            />
          </div>
        </div>
      </div>

      <div>
        {(errorShopSign || errorShopCategory || errorManageShopList) && (
          <FailAlert
            message={
              errorShopSign.message ||
              errorShopCategory.message ||
              errorManageShopList.message
            }
          />
        )}
      </div>

      {manageShopListMeta &&
        manageShopListMeta.total >
          window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
          <div className="w-full flex flex-row justify-end">
            <div>
              <ReactPaginate
                previousLabel={'prev'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  manageShopListMeta.total /
                  window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                }
                marginPagesDisplayed={4}
                pageRangeDisplayed={5}
                onPageChange={paginationHandler}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        )}

      {manageShopList && manageShopList.length !== 0 && (
        <Table>
          <thead className="bg-gfGrey font-MulishBlack text-sm">
            <tr>
              <th
                scope="col"
                className="px-4 py-3  text-left  uppercase tracking-wider"
              >
                {t('Shop-name')}
              </th>
              <th
                scope="col"
                className="py-3 px-10  text-left  uppercase tracking-wider"
              >
                {t('Alias')}
              </th>
              {/* <th scope="col" className="px-10 py-3  text-left   uppercase tracking-wider">
                                {t("Global-Brand")}
                            </th>
                            <th scope="col" className="px-10 py-3  text-left   uppercase tracking-wider">
                                {t("Merchant-Category")}
                            </th> */}
              <th
                scope="col"
                className="px-10 py-3  text-center   uppercase tracking-wider"
              >
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gfPeriwinkle text-sm">
            {manageShopList &&
              manageShopList.length !== 0 &&
              manageShopList.map((shop, index) => (
                <tr key={shop.id} data-testid={`shop_${index}`}>
                  <td
                    className="px-4 py-4 whitespace-nowrap text-left"
                    data-testid="shopName"
                  >
                    {shop.name}
                  </td>

                  <td
                    className="px-10 py-4 whitespace-nowrap text-left"
                    data-testid="alias"
                  >
                    <div>{shop.alias ? shop.alias : '-'}</div>
                  </td>

                  {/* <td className="px-10 py-4 whitespace-nowrap text-left">
                                        <div>{shop.shop_sign !== null ? shop.shop_sign.name : "-"}</div>
                                    </td>

                                    <td className="px-10 py-4 whitespace-nowrap text-left">
                                        <div>{shop.shop_category !== null ? shop.shop_category.name : "-"}</div>
                                    </td> */}
                  <td
                    className="px-10 py-4 whitespace-nowrap flex justify-center text-center"
                    data-testid="action"
                  >
                    <button
                      onClick={(event) => editRow(event, shop)}
                      className="rounded bg-gfLightBlue text-white py-2 px-4 font-MulishBold focus:outline-none hover:underline "
                    >
                      {t('Edit')}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <EditShop
        isOpen={isOpenEdit}
        successHandler={successHandler}
        closeHandler={editCloseHandler}
        data={data}
        alias={data.name}
      />
    </Container>
  );
}
