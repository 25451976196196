import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import Tooltip from 'react-simple-tooltip';
import Table from '../../../../Table';
import { checkLoggedInUserPermissions } from '../../../../../utils/Helper';
import { GF_DARK_BLUE, GF_PERIWINKLE } from '../../../../../utils/Constants';

function CardTransaction({ trx }) {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { cardInformation } = useSelector((state) => state.cardInformation);
  const trxClass = `${trx.status === 'declined' ? 'text-gfCoral' : trx.amount > 0 ? 'text-gfRobinBlue' : 'text-gfDarkBlue'} flex justify-start`;
  return (
    <Table.Row key={trx.id}>
      <Table.Cell>
        <div className={`${trxClass} items-center`}>{trx.date}</div>
      </Table.Cell>
      {cardInformation && !cardInformation.is_mvc && (
        <Table.Cell>
          <div className={`${trxClass} items-center`}>{trx.terminal_id}</div>
        </Table.Cell>
      )}
      <Table.Cell className="px-2">
        {cardInformation && cardInformation.is_mvc ? (
          <div className={`${trxClass} items-center`}>{trx.description}</div>
        ) : (
          <div className={`${trxClass} items-center`}>
            {checkLoggedInUserPermissions('list-of-shops') ? (
              <Link
                className="underline"
                to={`/shops/shop-list?keyword=${trx.merchant_name}`}
              >
                {trx.merchant_name}
              </Link>
            ) : (
              trx.merchant_name
            )}
          </div>
        )}
      </Table.Cell>
      {cardInformation && !cardInformation.is_mvc && (
        <Table.Cell className="px-2">
          {trx.status === 'declined' && trx.note !== '' ? (
            <div className={`${trxClass} z-50 font-MulishBold `}>
              <Tooltip
                content={trx.note}
                customCss={`
                    white-space: nowrap;
                    z-index: 9999;
                `}
                zIndex={9999}
                background="#FFF"
                border={GF_PERIWINKLE}
                color={GF_DARK_BLUE}
                fontFamily="inherit"
                radius={4}
                padding={10}
                placement="top"
              >
                <div
                  className={`${trxClass} cursor-help items-center font-MulishBold uppercase`}
                >
                  {trx.status}
                </div>
              </Tooltip>
            </div>
          ) : (
            <div
              className={`${trxClass} items-center font-MulishBold uppercase`}
            >
              {trx.status}
            </div>
          )}
        </Table.Cell>
      )}
      <Table.Cell>
        <div
          className={`${trx.status === 'declined' ? 'text-gfCoral' : trx.amount > 0 ? 'text-gfRobinBlue' : 'text-gfLightBlue'} flex justify-end font-MulishBold`}
        >
          <CurrencyFormat
            decimalScale={2}
            fixedDecimalScale={true}
            value={trx.amount}
            displayType={'text'}
            thousandSeparator={true}
          />
          <span className="ml-2">{` ${tagCurrency}`}</span>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

export default function ListCardTransactions() {
  const { cardTransactionInfo } = useSelector((state) => state.cardInformation);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col my-3 text-sm">
      <Table>
        <Table.Head>
          <tr>
            <Table.Header className="px-4">{t('Date')}</Table.Header>
            <Table.Header>TID</Table.Header>
            <Table.Header>{t('Merchant')}</Table.Header>
            <Table.Header className="px-2">{t('Status')}</Table.Header>
            <Table.Header className="px-4 text-right">
              {t('Amount')}
            </Table.Header>
          </tr>
        </Table.Head>

        <Table.Body>
          {cardTransactionInfo.map((trx) => (
            <CardTransaction trx={trx} />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
