import { issuingAPI } from '../services/Axios';

export const FETCH_CARRIERS = 'FETCH_CARRIERS';
export const FETCH_CARRIERS_SUCCESS = 'FETCH_CARRIERS_SUCCESS';
export const FETCH_CARRIERS_ERROR = 'FETCH_CARRIERS_ERROR';

export const fetchCarriers = (customerType) => (dispatch, getState) => {
  const { tagID } = getState().tag;
  dispatch({ type: FETCH_CARRIERS });

  return issuingAPI
    .get(`/tag/${tagID}/online-sale/${customerType}/carriers`)
    .then((success) => {
      dispatch({ type: FETCH_CARRIERS_SUCCESS, payload: success.data });
    })
    .catch((error) => {
      dispatch({ type: FETCH_CARRIERS_ERROR, payload: error });
    });
};
