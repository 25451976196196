import { issuingAPI } from '../services/Axios';
import { APP_COUNT, RESET_APP_COUNT } from './CommonActions';

export const PREFIX = '@shoppingCenterBalance/';

export const LOADING_SHOPPING_CENTER_BALANCE = `${PREFIX}LOADING_SHOPPING_CENTER_BALANCE`;
export const RECEIVED_SHOPPING_CENTER_BALANCE = `${PREFIX}RECEIVED_SHOPPING_CENTER_BALANCE`;
export const ERROR_SHOPPING_CENTER_BALANCE = `${PREFIX}ERROR_SHOPPING_CENTER_BALANCE`;

export const getShoppingCardBalance = () => (dispatch, getState, history) => {
  dispatch({ type: LOADING_SHOPPING_CENTER_BALANCE });

  dispatch({ type: APP_COUNT });

  const { tagID, tagCardBrands } = getState().tag;

  return issuingAPI
    .get(`/tag/${tagID}/shop/card-brand/${tagCardBrands[0]}/balance`)
    .then((response) => {
      dispatch({
        type: RECEIVED_SHOPPING_CENTER_BALANCE,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: ERROR_SHOPPING_CENTER_BALANCE, payload: error });
      return error;
    });
};
