import React from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_LIGHT_BLUE,
  GF_PERIWINKLE,
} from '../../../../utils/Constants';
import { useSelector } from 'react-redux';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';
import { format, isValid } from 'date-fns';

export default function Chart({ data, chartType, intervalFormat }) {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();
  const { selectedLanguage } = useSelector((state) => state.language);
  const dateTickFormatter = (tickItem) => {
    if (intervalFormat === 'year' && isValid(Date.parse(tickItem))) {
      return format(Date.parse(tickItem), 'yyyy');
    } else if (intervalFormat === 'month' && isValid(Date.parse(tickItem))) {
      return format(Date.parse(tickItem), 'MM-yyyy');
    }

    return tickItem.replace('Week', t('Week'));
  };
  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active && label !== undefined && payload.length) {
      return (
        <div className="flex flex-col p-4 border bg-white rounded border-gfDarkSand font-MulishBold text-sm space-y-2 shadow-md">
          {format !== 'week' ? <div>{label}</div> : <div>{label}</div>}

          {(chartType === 'cards-sold' || chartType === 'both') && (
            <div className="">
              <span className="text-gfCoral">{`${t('Cards-Sold')}: ${
                payload[0].payload.cardsSoldAmount
              }`}</span>
            </div>
          )}

          {(chartType === 'amounts-loaded' || chartType === 'both') && (
            <div className="flex flex-row">
              <span className="text-gfLightBlue mr-1">{`${t('Amounts-loaded')}: `}</span>
              <div className="flex justify-start text-gfLightBlue">
                <ShoppingCenterMoney
                  value={payload[0].payload.cardsSoldValue}
                />
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const MoneyTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g>
        <text
          className="text-sm font-MulishBold text-right"
          x={x}
          y={y}
          dy={5}
          fill={GF_LIGHT_BLUE}
        >
          {parseFloat(payload?.value).toFixed(2)}
        </text>

        <text
          className="text-sm font-MulishBold text-right"
          x={x}
          y={y + 15}
          dy={5}
          fill={GF_LIGHT_BLUE}
        >
          {tagCurrency}
        </text>
      </g>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        height: '500px',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data ?? []}>
          <CartesianGrid stroke={GF_PERIWINKLE} />
          <Tooltip content={CustomizedTooltip} />
          <XAxis
            dataKey={'date'}
            tickFormatter={dateTickFormatter}
            stroke={GF_DARK_BLUE}
            angle={90}
            minTickGap={-200}
            padding={{ left: 20, right: 20, top: 20, bottom: 20 }}
            tick={{ fontSize: 12 }}
            dy={40}
            height={100}
          ></XAxis>
          {['amounts-loaded', 'both'].includes(chartType) && (
            <YAxis
              yAxisId="right"
              dataKey="cardsSoldValue"
              orientation="right"
              stroke={GF_DARK_BLUE}
              tick={MoneyTick}
              width={70}
            >
              <Label
                className="font-MulishBold"
                angle={-90}
                position="insideRight"
              >
                {t('Amount')}
              </Label>
            </YAxis>
          )}
          {['cards-sold', 'both'].includes(chartType) && (
            <YAxis yAxisId="left" dataKey={'cardsSoldAmount'}>
              <Label
                className="font-MulishBold"
                angle={-90}
                position="insideLeft"
              >
                {t('Cards')}
              </Label>
            </YAxis>
          )}
          {['cards-sold', 'both'].includes(chartType) && (
            <Line
              yAxisId="left"
              stroke={GF_CORAL}
              strokeWidth={2}
              dataKey={'cardsSoldAmount'}
            />
          )}
          {['amounts-loaded', 'both'].includes(chartType) && (
            <Line
              yAxisId="right"
              stroke={GF_DARK_BLUE}
              strokeWidth={2}
              dataKey={'cardsSoldValue'}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
