import { issuingAPI } from '../services/Axios';
import store from '../store/store';

const sendReceiptByEmail = async ({ email, ticket_number }) => {
  const { selectedLanguage } = store.getState().language;
  const { selectedTag } = store.getState().tag;

  return issuingAPI.post(
    `/tag/${selectedTag.id}/ticket/${ticket_number}/send-via-email`,
    {
      email: email,
      lang: selectedLanguage.value,
    },
  );
};

export default {
  sendReceiptByEmail,
};
