import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { useFieldArray } from 'react-hook-form';
import DropDownStyle from '../../../Dropdown/Components/DropDownStyle';
import { FormControl, FormField, FormItem, FormMessage } from '../../../Form';
import TextField from '../../../Input/TextField';

function AdditionalProductRow({ name, options, onRemove }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap space-x-3">
      <FormField
        name={`${name}.product`}
        defaultValue={''}
        rules={{ required: t('Required') }}
        render={(field) => (
          <FormItem>
            <FormControl>
              <Select
                className="w-48"
                inputRef={field.ref}
                onChange={(value) => {
                  field.onChange(value.value);
                }}
                styles={DropDownStyle((option) => option)}
                options={options}
                isSearchable={true}
                components={{
                  IndicatorSeparator: null,
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name={`${name}.quantity`}
        defaultValue={''}
        rules={{
          required: t('Required'),
          min: {
            value: 0,
            message: t('validation.min.numeric', {
              attribute: t('quantity'),
              min: 0,
            }),
          },
        }}
        render={(field) => (
          <FormItem>
            <FormControl>
              <TextField
                {...field}
                placeholder={t('quantity')}
                type="number"
                min={0}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <button
        type="button"
        onClick={onRemove}
        className="text-gray-300 hover:text-gray-900"
      >
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}

export default function AdditionalProductsField() {
  const { t } = useTranslation();
  const { additionalProducts } = useSelector((state) => state.loadCard);
  const { fields, append, remove } = useFieldArray({
    name: 'additionalProducts',
  });

  const options = useMemo(() => {
    return (additionalProducts || []).map((product) => ({
      label: `${product.name} - ${product.total_price} ${product.currency}`,
      value: product.id,
    }));
  }, [additionalProducts]);

  function onAddProduct() {
    append({ product: '', quantity: '' });
  }

  function onRemoveProduct(index) {
    remove(index);
  }

  return (
    <div className="flex flex-col space-y-4">
      {fields.map((field, index) => (
        <AdditionalProductRow
          key={field.id}
          name={`additionalProducts.${index}`}
          options={options}
          onRemove={() => onRemoveProduct(index)}
        />
      ))}
      <div>
        <button
          onClick={onAddProduct}
          id="adProductButton"
          className="bg-gfPeriwinkle border border-gfPeriwinkle text-gfDarkBlue font-MulishBold rounded text-sm py-2 px-3 hover:bg-white focus:outline-none"
          type="button"
        >
          {t('Add-a-product')}
        </button>
      </div>
    </div>
  );
}
