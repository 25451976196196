import { issuingAPI } from '../services/Axios';
import { APP_COUNT } from './CommonActions';

export const PREFIX = '@desksList/';

export const LOADING_DESKS_LIST = `${PREFIX}LOADING_DESKS_LIST`;
export const RECEIVED_DESKS_LIST = `${PREFIX}RECEIVED_DESKS_LIST`;
export const ERROR_DESKS_LIST = `${PREFIX}ERROR_DESKS_LIST`;
export const RESET_DESKS_LIST = `${PREFIX}RESET_DESKS_LIST`;

export const getDesksList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_DESKS_LIST });

  const { tagID } = getState().tag;

  dispatch({ type: APP_COUNT });

  return issuingAPI
    .get(`tag/${tagID}/desk`)
    .then((success) => {
      dispatch({ type: RECEIVED_DESKS_LIST, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_DESKS_LIST, payload: error });
      return error;
    });
};
