/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { getMVCPaymentMethodsFullList } from '../../../actions/MVCManagementAction';
import { TAG_CHANGED } from '../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../actions/TerminalActions';
import { checkLoggedInUserPermissions } from '../../../utils/Helper';
import FailAlert from '../../Alert/FailAlert';
import MessageAlert from '../../Alert/MessageAlert';
import Container from '../../UIComponents/Container';
import Table from '../../UIComponents/Table';
import GenerateMVCInvoiceRequest from './GenerateMVCInvoiceRequest';
import { RESET_APP_COUNT } from '../../../actions/CommonActions';
import { RESET_SEARCH_TYPE } from '../../../actions/CardInformationActions';

export default function MVCLoad() {
  const dispatch = useDispatch();
  const { appCount } = useSelector((state) => state.commonReducer);
  const { selectedTag } = useSelector((state) => state.tag);

  useEffect(() => {
    Userpilot.reload();

    if (appCount === 0) {
      dispatch(getMVCPaymentMethodsFullList());
    }

    window.addEventListener(TAG_CHANGED, () => {
      dispatch(getMVCPaymentMethodsFullList());
    });

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch(getMVCPaymentMethodsFullList());
    });

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const {
    loadingMVCPaymentMethods,
    listMVCPaymentMethods,
    metaMVCPaymentMethods,
    errorMVCPaymentMethods,
  } = useSelector((state) => state.mvc);
  const { tagCurrency } = useSelector((state) => state.tag);

  const { t } = useTranslation();
  const mvcTokenHandler = () => {
    dispatch({ type: RESET_APP_COUNT });
    dispatch({ type: RESET_SEARCH_TYPE });
  };

  const [
    generateInvoiceRequestVisibility,
    setGenerateInvoiceRequestVisibility,
  ] = useState(false);
  const [mvcToken, setMvcToken] = useState('');

  const generateInvoiceRequest = (mvcToken, currency) => {
    setGenerateInvoiceRequestVisibility(true);
    setMvcToken(mvcToken);
  };

  const generateInvoiceRequestClose = () => {
    setGenerateInvoiceRequestVisibility(false);
  };

  return (
    <Container loading={loadingMVCPaymentMethods} title={t('mvc-load')}>
      <div className="">
        {errorMVCPaymentMethods && (
          <FailAlert message={errorMVCPaymentMethods.message} />
        )}
        {listMVCPaymentMethods && listMVCPaymentMethods.length === 0 && (
          <MessageAlert message={t('There-are-no-MVC-to-display')} />
        )}

        <div>
          {listMVCPaymentMethods && listMVCPaymentMethods.length !== 0 && (
            <Table>
              <thead className="bg-gfGrey text-gfDarkBlue font-MulishBlack text-xs">
                <tr>
                  <th
                    scope="col"
                    className="py-3 text-center uppercase tracking-wider"
                  >
                    {t('MVC-Token')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-center uppercase tracking-wider"
                  >
                    {t('Name')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-center uppercase tracking-wider"
                  >
                    {t('Balance')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-center uppercase tracking-wider"
                  >
                    {t('Actions')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gfPeriwinkle">
                {listMVCPaymentMethods.map(
                  (method) =>
                    !method.requires_transhandler &&
                    !method.is_correction_mvc &&
                    !method.is_temp && (
                      <tr key={method.id}>
                        <td className="px-4 py-4 whitespace-nowrap">
                          <div className="flex justify-center items-center underline text-gfLightBlue">
                            {checkLoggedInUserPermissions(
                              'card-information',
                            ) ? (
                              <Link
                                onClick={mvcTokenHandler}
                                className="underline"
                                to={`/gift-cards/card-information?token=${method.mvc_token}`}
                              >
                                {method.mvc_token}
                              </Link>
                            ) : (
                              method.mvc_token
                            )}
                          </div>
                        </td>
                        <td className="px-2 py-4 text-center whitespace-nowrap">
                          {method.name}
                        </td>
                        <td className="px-6 py-4 w-48 whitespace-nowrap">
                          <div className="flex justify-center text-gfLightBlue font-MulishBold">
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={method.balance}
                              displayType={'text'}
                              thousandSeparator={true}
                            />
                            <span className="ml-2">{` ${tagCurrency}`}</span>
                          </div>
                        </td>
                        <td className="px-2 py-4 text-sm ">
                          <div className="flex flex-col md:flex-row justify-between">
                            <div className="flex justify-center items-center">
                              <button
                                onClick={(event) =>
                                  generateInvoiceRequest(
                                    method.mvc_token,
                                    tagCurrency,
                                  )
                                }
                                type="submit"
                                className={`w-42 flex flex-row border rounded px-4 py-2 ml-2 justify-center font-MulishBold focus:outline-none hover:bg-opacity-75 bg-gfCoral cursor-pointer`}
                              >
                                <span>{t('invoice-request')}</span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <div>
        <GenerateMVCInvoiceRequest
          modalVisibility={generateInvoiceRequestVisibility}
          closeHandler={generateInvoiceRequestClose}
          mvcToken={mvcToken}
          selectedTag={selectedTag}
        />
      </div>
    </Container>
  );
}
