import { issuingAPI } from '../services/Axios';
import store from '../store/store';

const sendReceiptByEmail = async ({ email, token }) => {
  const { selectedLanguage } = store.getState().language;
  const { selectedTag } = store.getState().tag;

  return issuingAPI.post(
    `/tag/${selectedTag.id}/card/${token}/send-via-email`,
    {
      email: email,
      lang: selectedLanguage.value,
    },
  );
};

const confirmPanByToken = ({ pan, token }) => {
  const { selectedTag } = store.getState().tag;
  return issuingAPI.post(`/tag/${selectedTag.id}/card/${token}/verify-pan`, {
    pan,
  });
};

export default {
  sendReceiptByEmail,
  confirmPanByToken,
};
