import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCalenderLocale } from './SupportedLanguages';

export default function I18nProvider({ children }) {
  const { selectedLanguage } = useSelector((state) => state.language);

  useEffect(() => {
    getCalenderLocale(selectedLanguage);
  }, [selectedLanguage, getCalenderLocale]);

  return <>{children}</>;
}
