import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMerchants } from './useMerchants';
import { useSelector } from 'react-redux';
import { issuingAPI } from '../services/Axios';
import useApiHook from './useAPI';

const colors = [
  { hue: 217, saturation: 91 }, // #00adb5
  { hue: 360, saturation: 10 }, // #766161
  { hue: 195, saturation: 95 }, // #02475e
  { hue: 24, saturation: 100 }, // #ff6701
  { hue: 169, saturation: 54 }, // #206a5d
];
function generateDistinctColor(index, lightnessSteps = 20) {
  const baseColor = colors[index % colors.length];
  const lightness = 50 + (index % lightnessSteps) * (30 / lightnessSteps);

  return `hsl(${baseColor.hue}, ${baseColor.saturation}%, ${lightness}%)`;
}

const useMerchantSales = () => {
  const { tagCardBrands } = useSelector((state) => state.tag);
  const fetchSales = useCallback(
    async (params) => {
      return await issuingAPI.get(`card-brand/${tagCardBrands}/merchant-sale`, {
        params,
      });
    },
    [tagCardBrands],
  );

  const [result, fetch] = useApiHook(fetchSales);

  return {
    fetch,
    isLoading: result.loading,
    error: result.error,
    isFetched: result.success === true,
    reset: result.reset,
  };
};

function useCardUsageSummary(params) {
  const [summary, setSummary] = useState([]);
  const { tagID } = useSelector((state) => state.tag);
  const {
    fetch: fetchMerchants,
    isFetched: hasFetchedMerchants,
    isLoading: isLoadingMerchants,
    merchants: merchantsData,
    error: merchantsError,
  } = useMerchants({ enabled: false });

  const {
    fetch: fetchSales,
    isLoading: isLoadingSales,
    error: salesError,
    isFetched: hasFetchedSales,
    reset: resetSales,
  } = useMerchantSales();

  const fetchMerchantsForShoppingCentre = useCallback(
    (params) => {
      return fetchMerchants({
        ...params,
        tag_id: tagID,
      });
    },
    [fetchMerchants, tagID],
  );

  const fetchSummary = useCallback(
    async (params) => {
      try {
        const response = await fetchSales({
          ...params,
          tag_id: tagID,
        });

        // @TODO: handle the colors
        setSummary(response.data.data);
      } catch (error) {
        // Ignore?
      }
    },
    [fetchSales, tagID],
  );

  const merchants = useMemo(
    () =>
      merchantsData.map((merchant, index) => ({
        ...merchant,
        color: generateDistinctColor(index),
      })),
    [merchantsData],
  );

  useEffect(() => {
    // @TODO: handle filtering merchant list by date (based on sales that happened in that date range).
    fetchMerchantsForShoppingCentre();
  }, [fetchMerchantsForShoppingCentre, params]);

  return {
    isLoading: isLoadingMerchants || isLoadingSales,
    error: salesError || merchantsError,
    merchants,
    fetchMerchants: fetchMerchantsForShoppingCentre,
    hasFetchedMerchants,
    hasMerchants: useMemo(() => merchants.length > 0, [merchants]),
    fetchSummary,
    summary,
    hasFetchedSummary: hasFetchedSales,
    clearSummary: () => {
      resetSales();
      setSummary([]);
    },
  };
}

export { useCardUsageSummary };
