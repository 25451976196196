import React from 'react';
import { cn } from '../../utils';

function RadioGroup({ children, className }) {
  return <div className={cn('flex', className)}>{children}</div>;
}

function RadioGroupButton({
  name,
  value,
  selected,
  onChange,
  children,
  className,
}) {
  return (
    <label
      className={cn(
        'flex cursor-pointer text-center px-4 py-2 bg-gfPeriwinkle overflow-ellipsis first:rounded-l-md last:rounded-r-md',
        { 'bg-gfDarkBlue text-white': selected },
        className,
      )}
    >
      <input
        name={name}
        type="radio"
        value={value}
        className="sr-only"
        onChange={(event) => onChange(event.target.value)}
      />
      <span>{children}</span>
    </label>
  );
}

export { RadioGroup, RadioGroupButton };
