import { forwardRef, useMemo } from 'react';
import { cn } from '../../utils';
import { GF_DARK_BLUE } from '../../utils/Constants';
import ButtonLoading from '../../assets/svg/ButtonLoading';

const Button = forwardRef(
  (
    { children, variant, size = 'default', className, loading, ...props },
    ref,
  ) => {
    const isLink = useMemo(
      () => ['link', 'link-destructive'].includes(variant),
      [variant],
    );

    return (
      <button
        ref={ref}
        className={cn(
          'flex justify-center items-center rounded font-MulishBold hover:text-opacity-75 focus:outline-none disabled:opacity-75',
          {
            'text-gfLightBlue hover:underline': variant === 'link',
            'text-gfCoral hover:underline': variant === 'link-destructive',
            'bg-gfCoral rounded hover:bg-opacity-75': variant === 'primary',
            'bg-gfPeriwinkle rounded hover:bg-opacity-75':
              variant === 'secondary',
            'px-4 py-2': size === 'default' && !isLink,
          },
        )}
        {...props}
      >
        {loading && <ButtonLoading color={GF_DARK_BLUE} />}
        {children}
      </button>
    );
  },
);

export { Button };
