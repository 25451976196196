/* eslint-disable import/no-anonymous-default-export */
import {
  ERROR_FROM_MVC,
  ERROR_TO_MVC,
  ERROR_TRANSFER_MVC,
  LOADING_FROM_MVC,
  LOADING_TO_MVC,
  RECEIVED_FROM_MVC,
  RECEIVED_TO_MVC,
  RESET_ALL_MVC_MVC_TRANSFER,
  RESET_MVC_MVC_TRANSFER,
  SUCCESS_TRANSFER_MVC,
  TRANSFERRING_MVC,
  LOADING_MVC_TRANSFER_HISTORY,
  RECEIVED_MVC_TRANSFER_HISTORY,
  ERROR_MVC_TRANSFER_HISTORY,
  MVC_TRANSFER_OPERATION_ID,
} from '../actions/MVCToMVCTransferActions';
import { randomString } from '../utils/Helper';

const initialState = {
  loadingFromMVC: false,
  receivedFromMVC: null,
  errorFromMVC: null,

  loadingToMVC: false,
  receivedToMVC: null,
  errorToMVC: null,

  transferringMVC: false,
  successTransfer: null,
  failTransfer: null,

  loadingMVCTransHistory: false,
  receivedMVCTransHistory: null,
  errorMVCTransHistory: null,
  metaMVCTansHistory: null,
  summaryMVCTansHistory: null,

  mvcTransferOperationID: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FROM_MVC:
      return {
        ...state,
        loadingFromMVC: true,
        errorFromMVC: null,
      };

    case RECEIVED_FROM_MVC:
      return {
        ...state,
        loadingFromMVC: false,
        receivedFromMVC: action.payload,
        errorFromMVC: null,
      };

    case ERROR_FROM_MVC:
      return {
        ...state,
        loadingFromMVC: false,
        receivedFromMVC: null,
        errorFromMVC: action.payload,
      };

    case LOADING_TO_MVC:
      return {
        ...state,
        loadingToMVC: true,
        errorToMVC: null,
      };

    case RECEIVED_TO_MVC:
      return {
        ...state,
        loadingToMVC: false,
        receivedToMVC: action.payload,
        errorToMVC: null,
      };

    case ERROR_TO_MVC:
      return {
        ...state,
        loadingToMVC: false,
        receivedToMVC: null,
        errorToMVC: action.payload,
      };

    case MVC_TRANSFER_OPERATION_ID:
      return {
        ...state,
        mvcTransferOperationID: `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`,
      };

    case TRANSFERRING_MVC:
      return {
        ...state,
        transferringMVC: true,
        successTransfer: null,
        failTransfer: null,
      };

    case SUCCESS_TRANSFER_MVC:
      return {
        ...state,
        transferringMVC: false,
        successTransfer: action.payload,
        failTransfer: null,
      };

    case ERROR_TRANSFER_MVC:
      return {
        ...state,
        transferringMVC: false,
        successTransfer: null,
        failTransfer: action.payload,
      };

    case RESET_MVC_MVC_TRANSFER:
      return {
        ...state,
        transferringMVC: false,
        successTransfer: null,
        failTransfer: null,
      };

    case RESET_ALL_MVC_MVC_TRANSFER:
      return {
        ...state,
        ...initialState,
      };

    case LOADING_MVC_TRANSFER_HISTORY:
      return {
        ...state,
        loadingMVCTransHistory: true,
        receivedMVCTransHistory: null,
        errorMVCTransHistory: null,
        metaMVCTansHistory: null,
      };

    case RECEIVED_MVC_TRANSFER_HISTORY:
      return {
        ...state,
        loadingMVCTransHistory: false,
        receivedMVCTransHistory: action.payload.data,
        errorMVCTransHistory: null,
        metaMVCTansHistory: action.payload.meta,
        summaryMVCTansHistory: action.payload.summary,
      };

    case ERROR_MVC_TRANSFER_HISTORY:
      return {
        ...state,
        loadingMVCTransHistory: false,
        receivedMVCTransHistory: null,
        errorMVCTransHistory: action.payload,
        metaMVCTansHistory: null,
      };

    default:
      return state;
  }
}
