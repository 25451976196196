import {
  ADDING_USER,
  ERROR_USER_LIST,
  ERROR_USER_PERMISSIONS,
  FAIL_ADDING_USER,
  LOADING_USER_LIST,
  LOADING_USER_PERMISSIONS,
  RECEIVED_USER_LIST,
  RECEIVED_USER_PERMISSIONS,
  SUCCESS_ADDING_USER,
  SUCCESS_ADDING_USER_SSO,
  RESET_ADDING_USER,
  DELETING_USER,
  SUCCESS_DELETING_USER,
  FAIL_DELETING_USER,
  ACTIVATE_DEACTIVATE_USER,
  SUCCESS_ACTIVATE_DEACTIVATE_USER,
  FAIL_ACTIVATE_DEACTIVATE_USER,
  RESET_ACTIVATE_DEACTIVATE_USER,
  RESET_DELETING_USER,
  EDITING_USER,
  SUCCESS_EDITING_USER,
  FAIL_EDITING_USER,
  RESET_EDITING_USER,
  OPEN_USER,
  LOADING_USER,
  RECEIVED_USER,
  ERROR_USER,
  LOADING_USER_ORGANIZATION_PERMISSION,
  RECEIVED_USER_ORGANIZATION_PERMISSION,
  ERROR_USER_ORGANIZATION_PERMISSION,
  ADDING_USER_ORGANIZATION_PERMISSION,
  SUCCESS_USER_ORGANIZATION_PERMISSION,
  FAIL_USER_ORGANIZATION_PERMISSION,
  LOADING_LOGIN_USER_ORGANIZATION_PERMISSION,
  RECEIVED_LOGIN_USER_ORGANIZATION_PERMISSION,
  ERROR_LOGIN_USER_ORGANIZATION_PERMISSION,
  LOADING_USER_BY_EMAIL,
  RECEIVED_USER_BY_EMAIL,
  ERROR_USER_BY_EMAIL,
  RESET_USER_BY_EMAIL,
} from '../actions/Admin/UserManagement/UserManagementActions';

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  loadingUserList: false,
  receivedUserList: null,
  errorUserList: null,

  loadingUser: false,
  receivedUser: null,
  errorUser: null,

  loadingUserByEmail: false,
  receivedUserByEmail: null,
  errorUserByEmail: null,

  loadingUserPermissions: false,
  receivedUserPermissions: [],
  errorUserPermissions: null,

  addingUser: false,
  successAddingUser: null,
  failAddingUser: null,

  deletingUser: false,
  successDeleteUser: null,
  failDeleteUser: null,

  activateDeactivateUser: false,
  successActivateDeactivateUser: null,
  failActivateDeactivateUser: null,

  editingUser: false,
  successEditingUser: null,
  failEditingUser: null,

  openUser: false,

  loadingUserOrganizationPermissions: false,
  receivedUserOrganizationPermissions: null,
  errorUserOrganizationPermissions: null,

  addingUserOrganizationPermissions: false,
  successUserOrganizationPermissions: null,
  failUserOrganizationPermissions: null,

  loadingShoppingCenters: false,
  receivedShoppingCenters: null,
  errorShoppingCenters: null,
  selectedShoppingCenter: null,

  loadingLoggedInUserPermissions: false,
  receivedLoggedInUserPermissions: null,
  errorLoggedInUserPermissions: null,

  ssoEnabled: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_USER_LIST:
      return {
        ...state,
        loadingUserList: true,
        receivedUserList: null,
        errorUserList: null,
      };

    case RECEIVED_USER_LIST:
      return {
        ...state,
        loadingUserList: false,
        receivedUserList: action.payload,
        errorUserList: null,
      };

    case ERROR_USER_LIST:
      return {
        ...state,
        loadingUserList: false,
        receivedUserList: null,
        errorUserList: action.payload,
      };

    case LOADING_USER_PERMISSIONS:
      return {
        ...state,
        loadingUserPermissions: true,
        receivedUserPermissions: [],
        errorUserPermissions: null,
      };

    case RECEIVED_USER_PERMISSIONS:
      return {
        ...state,
        loadingUserPermissions: false,
        receivedUserPermissions: action.payload,
        errorUserPermissions: null,
      };

    case ERROR_USER_PERMISSIONS:
      return {
        ...state,
        loadingUserPermissions: false,
        receivedUserPermissions: [],
        errorUserPermissions: action.payload,
      };

    case ADDING_USER:
      return {
        ...state,
        addingUser: true,
        successAddingUser: null,
        failAddingUser: null,
      };

    case SUCCESS_ADDING_USER:
      return {
        ...state,
        addingUser: false,
        successAddingUser: action.payload,
        failAddingUser: null,
      };

    case SUCCESS_ADDING_USER_SSO:
      return {
        ...state,
        addingUser: false,
        successAddingUser: action.payload,
        failAddingUser: null,
        ssoEnabled: true,
      };

    case FAIL_ADDING_USER:
      return {
        ...state,
        addingUser: false,
        successAddingUser: null,
        failAddingUser: action.payload,
      };

    case RESET_ADDING_USER:
      return {
        ...state,
        addingUser: false,
        successAddingUser: null,
        failAddingUser: null,
      };

    case DELETING_USER:
      return {
        ...state,
        deletingUser: true,
        successDeleteUser: null,
        failDeleteUser: null,
      };

    case SUCCESS_DELETING_USER:
      return {
        ...state,
        deletingUser: false,
        successDeleteUser: action.payload,
        failDeleteUser: null,
      };

    case FAIL_DELETING_USER:
      return {
        ...state,
        deletingUser: false,
        successDeleteUser: null,
        failDeleteUser: action.payload,
      };

    case RESET_DELETING_USER:
      return {
        ...state,
        deletingUser: false,
        successDeleteUser: null,
        failDeleteUser: null,
      };

    case ACTIVATE_DEACTIVATE_USER:
      return {
        ...state,
        activateDeactivateUser: true,
        successActivateDeactivateUser: null,
        failActivateDeactivateUser: null,
      };

    case SUCCESS_ACTIVATE_DEACTIVATE_USER:
      return {
        ...state,
        activateDeactivateUser: false,
        successActivateDeactivateUser: action.payload,
        failActivateDeactivateUser: null,
      };

    case FAIL_ACTIVATE_DEACTIVATE_USER:
      return {
        ...state,
        activateDeactivateUser: false,
        successActivateDeactivateUser: null,
        failActivateDeactivateUser: action.payload,
      };

    case RESET_ACTIVATE_DEACTIVATE_USER:
      return {
        ...state,
        activateDeactivateUser: false,
        successActivateDeactivateUser: null,
        failActivateDeactivateUser: null,
      };

    case EDITING_USER:
      return {
        ...state,
        editingUser: true,
        successEditingUser: null,
        failEditingUser: null,
      };

    case SUCCESS_EDITING_USER:
      return {
        ...state,
        editingUser: false,
        successEditingUser: action.payload,
        failEditingUser: null,
      };

    case FAIL_EDITING_USER:
      return {
        ...state,
        editingUser: false,
        successEditingUser: null,
        failEditingUser: action.payload,
      };

    case RESET_EDITING_USER:
      return {
        ...state,
        editingUser: false,
        successEditingUser: null,
        failEditingUser: null,
      };

    case OPEN_USER:
      return {
        ...state,
        openUser: action.payload,
      };

    case LOADING_USER:
      return {
        ...state,
        loadingUser: true,
        receivedUser: null,
        errorUser: null,
      };

    case RECEIVED_USER:
      return {
        ...state,
        loadingUser: false,
        receivedUser: action.payload,
        errorUser: null,
      };

    case ERROR_USER:
      return {
        ...state,
        loadingUser: false,
        receivedUser: null,
        errorUser: action.payload,
      };

    case LOADING_USER_BY_EMAIL:
      return {
        ...state,
        loadingUserByEmail: true,
        receivedUserByEmail: null,
        errorUserByEmail: null,
      };

    case RECEIVED_USER_BY_EMAIL:
      return {
        ...state,
        loadingUserByEmail: false,
        receivedUserByEmail: action.payload,
        errorUserByEmail: null,
      };

    case ERROR_USER_BY_EMAIL:
      return {
        ...state,
        loadingUserByEmail: false,
        receivedUserByEmail: null,
        errorUserByEmail: action.payload,
      };

    case RESET_USER_BY_EMAIL:
      return {
        ...state,
        loadingUserByEmail: false,
        receivedUserByEmail: null,
        errorUserByEmail: null,
      };

    case LOADING_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        loadingUserOrganizationPermissions: true,
        receivedUserOrganizationPermissions: null,
        errorUserOrganizationPermissions: null,
      };

    case RECEIVED_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        loadingUserOrganizationPermissions: false,
        receivedUserOrganizationPermissions: action.payload,
        errorUserOrganizationPermissions: null,
      };

    case ERROR_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        loadingUserOrganizationPermissions: false,
        receivedUserOrganizationPermissions: null,
        errorUserOrganizationPermissions: action.payload,
      };

    case ADDING_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        addingUserOrganizationPermissions: true,
        successUserOrganizationPermissions: null,
        failUserOrganizationPermissions: null,
      };

    case SUCCESS_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        addingUserOrganizationPermissions: false,
        successUserOrganizationPermissions: action.payload,
        failUserOrganizationPermissions: null,
      };

    case FAIL_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        addingUserOrganizationPermissions: false,
        successUserOrganizationPermissions: null,
        failUserOrganizationPermissions: action.payload,
      };

    case LOADING_LOGIN_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        loadingLoggedInUserPermissions: true,
        receivedLoggedInUserPermissions: null,
        errorLoggedInUserPermissions: null,
      };

    case RECEIVED_LOGIN_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        loadingLoggedInUserPermissions: false,
        receivedLoggedInUserPermissions: action.payload,
        errorLoggedInUserPermissions: null,
      };

    case ERROR_LOGIN_USER_ORGANIZATION_PERMISSION:
      return {
        ...state,
        loadingLoggedInUserPermissions: false,
        receivedLoggedInUserPermissions: null,
        errorLoggedInUserPermissions: action.payload,
      };

    default:
      return state;
  }
}
