import React, { useMemo } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_PERIWINKLE,
} from '../../../utils/Constants';
import DropdownIndicator from '../Components/DropdownIndicator';

export default function SearchCardTypeDropdown(props) {
  const { t } = useTranslation();
  const { onChangeHandler, id, searchCardTypesList } = props;

  const options = useMemo(
    () => searchCardTypesList ?? [],
    [searchCardTypesList, t],
  );
  const { selectedSearchType } = useSelector((state) => state.cardInformation);

  const customStyles = (value) => ({
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      padding: '0.5rem 0rem 0.5rem 0rem',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      fontSize: '16px',
      verticalAlign: 'middle',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: GF_PERIWINKLE,
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.id === value.id
          ? GF_DARK_BLUE
          : state.isFocused
            ? GF_CORAL
            : 'white',
      color:
        state.isSelected && state.data.id === value.id ? '#FFF' : GF_DARK_BLUE,
      '&:hover': {
        background: GF_CORAL,
        color: '#FFF',
      },
    }),

    menuList: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
    }),

    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      fontSize: '18px',
    }),
  });

  return (
    <div data-testid={id}>
      <Select
        data-id={id}
        id={id}
        defaultValue={searchCardTypesList[0]}
        value={selectedSearchType ?? searchCardTypesList[0]}
        onChange={onChangeHandler}
        styles={customStyles(selectedSearchType ?? searchCardTypesList[0])}
        options={options}
        getOptionLabel={(option) => `${option.name}`}
        isSearchable={false}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
      />
    </div>
  );
}
