import { issuingAPI } from '../services/Axios';

export const PREFIX = '@notifications/';

export const LOADING_NOTIFICATIONS = `${PREFIX}LOADING_NOTIFICATIONS`;
export const RECEIVED_NOTIFICATIONS = `${PREFIX}RECEIVED_NOTIFICATIONS`;
export const ERROR_NOTIFICATIONS = `${PREFIX}ERROR_NOTIFICATIONS`;

export const SENDING_NOTIFICATION_SEEN = `${PREFIX}SENDING_NOTIFICATION_SEEN`;
export const SUCCESS_NOTIFICATION_SEEN = `${PREFIX}SUCCESS_NOTIFICATION_SEEN`;
export const FAIL_NOTIFICATION_SEEN = `${PREFIX}FAIL_NOTIFICATION_SEEN`;

export const getNotificationList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_NOTIFICATIONS });

  const { tagID } = getState().tag;

  return issuingAPI
    .get(`tag/${tagID}/notification`)
    .then((success) => {
      dispatch({ type: RECEIVED_NOTIFICATIONS, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_NOTIFICATIONS, payload: error });
      return error;
    });
};

export const setNotificationSeen = (notificationID) => (dispatch, getState) => {
  dispatch({ type: SENDING_NOTIFICATION_SEEN });

  const { tagID } = getState().tag;

  return issuingAPI
    .post(`tag/${tagID}/notification/${notificationID}`)
    .then((success) => {
      dispatch({ type: SUCCESS_NOTIFICATION_SEEN, payload: notificationID });
      return success;
    })
    .catch((error) => {
      dispatch({ type: FAIL_NOTIFICATION_SEEN, payload: error });
      return error;
    });
};

export const DELETING_NOTIFICATION = `${PREFIX}DELETING_NOTIFICATION`;
export const DELETING_SUCCESS_NOTIFICATION = `${PREFIX}DELETING_NOTIFICATION`;
export const DELETING_FAIL_NOTIFICATION = `${PREFIX}DELETING_NOTIFICATION`;

export const deleteNotification = (notificationID) => (dispatch, getState) => {
  dispatch({ type: DELETING_NOTIFICATION });

  const { tagID } = getState().tag;

  return issuingAPI
    .delete(`tag/${tagID}/notification/${notificationID}`)
    .then((success) => {
      dispatch(getNotificationList());
      dispatch({
        type: DELETING_SUCCESS_NOTIFICATION,
        payload: notificationID,
      });
      return success;
    })
    .catch((error) => {
      dispatch({ type: DELETING_FAIL_NOTIFICATION, payload: error });
      return error;
    });
};
