import React from 'react';
import { cn } from '../../utils';

export function Step({ children, title, required = false, isLast }) {
  return (
    <div
      className={cn('relative pb-4 pl-10', {
        'border-l border-gfDarkBlue': !isLast,
      })}
    >
      <span
        className={
          'step rounded-full bg-gfDarkBlue flex items-center justify-center absolute left-0 top-0 w-8 h-8 text-white -translate-x-1/2 transform'
        }
      />
      {title && (
        <h2 className="mb-2">
          <span>{title}</span>
          {required && <span className="text-gfCoral">*</span>}
        </h2>
      )}
      {children}
    </div>
  );
}

export function Steps({ className, children }) {
  return <div className={cn('steps', className)}>{children}</div>;
}
