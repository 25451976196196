import React, { forwardRef, useCallback } from 'react';

const Radio = ({ label, id, onClick, checked, name }) => {
  return (
    <div className="flex items-center cursor-pointer">
      <input
        defaultChecked={checked}
        id={id}
        onClick={onClick}
        name={name}
        type="radio"
        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
      />
      <label
        htmlFor={id}
        className="ml-3 block text-sm font-medium leading-6 text-gray-900 cursor-pointer"
      >
        {label}
      </label>
    </div>
  );
};

const RadioField = forwardRef(function ({ options, value, onChange }, ref) {
  const onInput = (index) => {
    onChange(options[index].value);
  };

  const isChecked = useCallback(
    (optionValue) => {
      if (typeof optionValue === 'object' && !Array.isArray(optionValue)) {
        return optionValue.value === value?.value;
      }

      return optionValue === value;
    },
    [value],
  );

  return options.map((option, index) => (
    <Radio
      checked={isChecked(option.value)}
      key={index}
      id={index}
      onClick={() => onInput(index)}
      {...option}
    />
  ));
});

export default RadioField;
