/* eslint-disable import/no-anonymous-default-export */

import {
  ERROR_NOTIFICATIONS,
  FAIL_NOTIFICATION_SEEN,
  LOADING_NOTIFICATIONS,
  RECEIVED_NOTIFICATIONS,
  SENDING_NOTIFICATION_SEEN,
  SET_NOTIFICATION_COUNT,
  SUCCESS_NOTIFICATION_SEEN,
} from '../actions/NotificationActions';

const initialState = {
  notificationCount: 0,

  loadingNotifications: false,
  receivedNotifications: null,
  errorNotifications: null,

  sendingSeen: false,
  successSeen: null,
  failSeen: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: true,
        receivedNotifications: null,
        errorNotifications: null,
        notificationCount: 0,
      };

    case RECEIVED_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: false,
        receivedNotifications: action.payload.data,
        notificationCount: action.payload.count,
        errorNotifications: null,
      };

    case ERROR_NOTIFICATIONS:
      return {
        ...state,
        loadingNotifications: false,
        receivedNotifications: null,
        errorNotifications: action.payload,
        notificationCount: 0,
      };

    case SENDING_NOTIFICATION_SEEN:
      return {
        ...state,
        sendingSeen: true,
        successSeen: null,
        failSeen: null,
      };

    case SUCCESS_NOTIFICATION_SEEN:
      const ID = action.payload;
      const changedList = state.receivedNotifications.map((notification) => {
        if (notification.id === ID) {
          notification.seen = 'seen';
          return notification;
        }
        return notification;
      });

      let counter = 0;
      for (let i = 0; i < state.receivedNotifications.length; i++) {
        if (state.receivedNotifications[i].seen === 'unseen') counter++;
      }

      return {
        ...state,
        sendingSeen: false,
        notificationCount: counter,
        receivedNotifications: changedList,
        successSeen: ID,
        failSeen: null,
      };

    case FAIL_NOTIFICATION_SEEN:
      return {
        ...state,
        sendingSeen: false,
        successSeen: null,
        failSeen: action.payload,
      };

    default:
      return state;
  }
}
