import React from 'react';
import { cn } from '../../utils';

const ModalSubTitle = ({ children, className, ...props }) => {
  return (
    <h2
      className={cn(
        'font-bold border-b border-gfDarkBlue pb-0.5 inline text-lg',
        className,
      )}
      {...props}
    >
      {children}
    </h2>
  );
};

export { ModalSubTitle };
