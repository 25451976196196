import { lowerCase, upperCase } from 'lodash';
import PhoneInput from '@simpleview/react-phone-input-2';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Userpilot } from 'userpilot';
import Button from '../../../UIComponents/Button';
import {
  CHANGE_PHONE_NUMBER,
  CHANGE_SEARCH_TYPE,
  getCardInformation,
  getCardsListByPhoneNumber,
  RESET_CARD_INFO,
  RESET_SEARCH_CARD_LIST,
  RESET_SEARCH_TYPE,
  SET_FOCUS_SELECTED,
} from '../../../../actions/CardInformationActions';
import InputWithClearButton from '../../../UIComponents/InputWithClearButton';
import SearchCardTypeDropdown from '../../../Dropdown/Components/SearchCardTypeDropdown';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import { RESET_APP_COUNT } from '../../../../actions/CommonActions';
import { PHONE_COUNTRIES } from '../../../../utils/PhoneCountriesRegex';
import FailAlert from '../../../Alert/FailAlert';
import useAppConfig, { features } from '../../../../hooks/useAppConfig';

export default function SearchCard({
  setSelectedCard,
  requiredValidation,
  setRequiredValidation,
  setBackToList,
  searchLocation,
  history,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAppConfig();
  const [cardNumber, setCardNumber] = useState('');
  const { appCount } = useSelector((state) => state.commonReducer);
  const {
    loadingCardInformation,
    loadListSearchCardByPhone,
    selectedSearchType,
    cardPhoneNumber,
    selectedPhoneNumberCountry,
    errorCardInformation,
  } = useSelector((state) => state.cardInformation);
  const inputRef = useRef(null);

  useEffect(() => {
    Userpilot.reload();
    if (appCount === 0) {
      dispatch({ type: RESET_CARD_INFO });
      dispatch({ type: RESET_SEARCH_CARD_LIST });
      dispatch({ type: RESET_SEARCH_TYPE });
    }

    window.addEventListener(SET_FOCUS_SELECTED, () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(0, 9);
      }
    });

    const params = new URLSearchParams(searchLocation);
    setBackToList(params.get('isBackToList'));

    const token = params.get('token');
    if (token && selectedSearchType.id === 0) {
      setSelectedCard(true);
      setCardNumber(token);
      if (appCount === 0) dispatch(getCardInformation(token));
    }
  }, []);

  const onFocusHandler = (e) => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, 9);
    }
  };

  const onChangeHandlerSearchType = (searchType) => {
    dispatch({ type: RESET_CARD_INFO });
    dispatch({ type: CHANGE_SEARCH_TYPE, payload: searchType });
    dispatch({ type: CHANGE_PHONE_NUMBER, payload: '' });
  };

  const phoneNumberIsValid = useMemo(() => {
    const correspondingCountry = PHONE_COUNTRIES.find(
      (countryCode) =>
        countryCode.iso2 === selectedPhoneNumberCountry?.toUpperCase(),
    );
    if (correspondingCountry && cardPhoneNumber) {
      if (
        !new RegExp(correspondingCountry.validation).test(
          `+${cardPhoneNumber}`?.replace(/ /g, ''),
        )
      ) {
        return t('card_information.search.results.enter_valid_phone_number');
      }
      return true;
    }
    return t('card_information.search.results.enter_valid_phone_number');
  }, [cardPhoneNumber, selectedPhoneNumberCountry, PHONE_COUNTRIES]);

  const onKeyUpHandlerPhone = (event) => {
    event.preventDefault();
    setSelectedCard(false);
    if (phoneNumberIsValid !== true) {
      return false;
    }
    dispatch(getCardsListByPhoneNumber(cardPhoneNumber));
    setRequiredValidation(false);
    return true;
  };

  const loadCardHandler = () => {
    if (selectedSearchType.id === 0 && cardNumber.length !== 0) {
      dispatch({ type: RESET_APP_COUNT });
      history.push({
        pathname: '/gift-cards/card-information',
        search: `?token=${cardNumber}`,
      });
      setRequiredValidation(false);
      setSelectedCard(true);
    } else if (selectedSearchType.id === 1 && cardPhoneNumber?.length !== 0) {
      dispatch(getCardsListByPhoneNumber(cardPhoneNumber));
      setSelectedCard(false);
      setRequiredValidation(false);
    } else {
      setRequiredValidation(true);
    }
  };
  const onKeyUpHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      loadCardHandler();
    }
  };
  const onChangeCardNumber = (e) => {
    setRequiredValidation(false);
    setCardNumber(e.target.value);
  };

  const onClearCardNumber = () => {
    setCardNumber('');
    dispatch({ type: RESET_CARD_INFO });
  };

  const countryCodeInputStyle = {
    border: 'none',
    color: '#2d3748',
    padding: '0.5rem 0.5rem 0.5rem 3rem',
    width: '100%',
    fontSize: '20px',
    lineHeight: '1.75rem',
    fontFamily: 'MulishRegular',
  };
  const countryCodeButtonStyle = {
    border: 'none',
    color: '#2d3748',
    margin: 0,
  };

  const searchCardTypesList = [
    {
      id: 0,
      name: t('Token'),
      value: 'token',
    },
  ];

  if (
    isFeatureEnabled(features.searchCardByPhone) &&
    checkLoggedInUserPermissions('search-card-by-phone')
  ) {
    searchCardTypesList.push({
      id: 1,
      name: t('Phone'),
      value: 'phone',
    });
  }

  const onlyCountries = useMemo(() => {
    return PHONE_COUNTRIES.map((country) => {
      return lowerCase(country.iso2);
    });
  }, [PHONE_COUNTRIES]);

  const localization = useMemo(() => {
    const mapped = {};
    onlyCountries.forEach((country) => {
      if (country !== '') {
        mapped[country] = t(`card_information.countries.${country}`);
      }
    });
    return mapped;
  }, [t]);

  return (
    <>
      <div className="grid grid-cols-4 md:grid-cols-5 gap-1 md:max-w-xl mx-auto auto-rows-max">
        <div className="col-span-1">
          {isFeatureEnabled(features.searchCardByPhone) &&
            checkLoggedInUserPermissions('search-card-by-phone') && (
              <SearchCardTypeDropdown
                value={selectedSearchType?.id}
                onChangeHandler={onChangeHandlerSearchType}
                data-testid={'searchCardTypeListDropdownID'}
                id={'searchCardTypeListDropdownID'}
                searchCardTypesList={searchCardTypesList}
              />
            )}
        </div>
        <div className="col-span-3 mr-4 h-100">
          {selectedSearchType?.id === 0 && (
            <InputWithClearButton
              value={cardNumber}
              onKeyUp={(event) => onKeyUpHandler(event)}
              onChange={onChangeCardNumber}
              onClear={onClearCardNumber}
              placeholder={upperCase(t(selectedSearchType.value))}
              inputRef={inputRef}
              onFocus={onFocusHandler}
            />
          )}
          {selectedSearchType?.id === 1 && (
            <PhoneInput
              containerClass="border
                        text-center
                        focus:outline-none
                        text-gfDarkBlue
                        text-xl
                        border-gfPeriwinkle
                        rounded
                        placeholder-gfGrey
                        w-full"
              inputClass="no-border"
              inputStyle={countryCodeInputStyle}
              buttonStyle={countryCodeButtonStyle}
              country={'ae'}
              isValid={(value, country) => {
                if (value.slice(country.dialCode.length).length > 0)
                  return phoneNumberIsValid;
                return true;
              }}
              onlyCountries={onlyCountries}
              localization={localization}
              enableSearch={true}
              onEnterKeyPress={onKeyUpHandlerPhone}
              value={cardPhoneNumber}
              onChange={(phoneNumber, data) => {
                dispatch({
                  type: CHANGE_PHONE_NUMBER,
                  payload: {
                    phone_number: phoneNumber,
                    country_code: data?.countryCode,
                  },
                });
                dispatch({ type: RESET_SEARCH_CARD_LIST });
              }}
            />
          )}
        </div>
        <Button
          id={'cardInfoButton'}
          loading={loadingCardInformation || loadListSearchCardByPhone}
          label={t('Search')}
          disabled={
            loadingCardInformation ||
            loadListSearchCardByPhone ||
            (selectedSearchType.id === 1 && phoneNumberIsValid !== true)
          }
          classAddsName="disabled:bg-opacity-50"
          onClick={loadCardHandler}
        />
      </div>
      <div className="mt-10">
        {errorCardInformation && selectedSearchType.id === 0 && (
          <FailAlert message={errorCardInformation.message} />
        )}
        {selectedSearchType.id === 0 && requiredValidation && (
          <FailAlert message={t('Please enter card number')} />
        )}
        {selectedSearchType.id === 1 && requiredValidation && (
          <FailAlert message={t('enter-phone-number')} />
        )}
      </div>
    </>
  );
}
