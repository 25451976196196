/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from '../../../UIComponents/Container';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import PrintPreview from './PrintPreview';
import BlockUnblockCards from './BlockUnblockCards';
import '@simpleview/react-phone-input-2/lib/bootstrap.css';
import CardTransactions from './CardTransactions/CardTransactions';
import CardInformationDetails from './CardInformationDetails';
import SearchCard from './SearchCard';
import CardSearchByPhone from './CardsSearch/CardSearchByPhone';
import useAppConfig, { features } from '../../../../hooks/useAppConfig';

export default function CardInformation(props) {
  const [visibility, setVisibility] = useState(false);
  const [cardNumber] = useState('');
  const [selectedCard, setSelectedCard] = useState(false);
  const [cardObject, setCardObject] = useState({});
  const [requiredValidation, setRequiredValidation] = useState(false);
  const [isBackToList, setBackToList] = useState(false);
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAppConfig();
  const { cardInformation, errorCardInformation, loadingCardInformation } =
    useSelector((state) => state.cardInformation);

  const searchCardTypesList = [
    {
      id: 0,
      name: t('Token'),
      value: 'token',
    },
  ];
  if (
    isFeatureEnabled(features.searchCardByPhone) &&
    checkLoggedInUserPermissions('search-card-by-phone')
  ) {
    searchCardTypesList.push({
      id: 1,
      name: t('Phone'),
      value: 'phone',
    });
  }

  const [printPreviewVisibility, setPrintPreviewVisibility] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setCardObject((prev) => Object.assign(prev, cardInformation));
    setVisibility(!visibility);
    document.body.classList.toggle('modal-active');
  };

  const closeModalHandler = (e) => {
    setCardObject({});
    setVisibility(false);
  };

  const printPreview = () => {
    setPrintPreviewVisibility(!printPreviewVisibility);
  };

  const printPreviewClose = () => {
    setPrintPreviewVisibility(!printPreviewVisibility);
  };

  return (
    <Container title={t('card-information')}>
      <div>
        <div>
          <SearchCard
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
            requiredValidation={requiredValidation}
            setRequiredValidation={setRequiredValidation}
            setBackToList={setBackToList}
            searchLocation={props.location.search}
            history={props.history}
          />
          <div className={'rounded border'}>
            {selectedCard && cardInformation && (
              <CardInformationDetails
                isBackToList={isBackToList}
                openModal={openModal}
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                searchCardTypesList={searchCardTypesList}
              />
            )}
            {!selectedCard && (
              <CardSearchByPhone
                searchCardTypesList={searchCardTypesList}
                setSelectedCard={setSelectedCard}
              />
            )}
          </div>
          {selectedCard && !errorCardInformation && !loadingCardInformation && (
            <CardTransactions
              selectedCard={selectedCard}
              printPreview={printPreview}
              cardNumber={cardNumber}
              cardInformation={cardInformation}
            />
          )}
        </div>
        <PrintPreview
          modalVisibility={printPreviewVisibility}
          closeHandler={printPreviewClose}
        />
        <BlockUnblockCards
          modalVisibility={visibility}
          closeHandler={closeModalHandler}
          cardObject={cardObject}
        />
      </div>
    </Container>
  );
}
