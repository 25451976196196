import React, { useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import CloseIcon from '../../../../assets/svg/CloseIcon';

ReactModal.setAppElement('#root');

export default function PrintOne({
  modalVisibility,
  closeHandler,
  transferDetails,
  token,
}) {
  const { t } = useTranslation();
  const componentRef = useRef();
  const { selectedTag, tagCurrency } = useSelector((state) => state.tag);

  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const filterOne =
    transferDetails.entries &&
    transferDetails.entries.length !== 0 &&
    transferDetails.entries.filter((item) => item.token === token);

  return (
    <ReactModal
      isOpen={modalVisibility}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col max-w-xl mx-auto bg-gray-100">
        <div className="flex justify-between p-5">
          <button
            className="focus:outline-none bg-gfCoral text-base rounded font-MulishBold hover:bg-opacity-75 px-4 py-1"
            onClick={printReceipt}
          >
            {t('Print')}
          </button>
          <button onClick={() => closeHandler(!modalVisibility)}>
            <CloseIcon />
          </button>
        </div>
        <div
          ref={componentRef}
          style={{
            maxWidth: '165px',
            margin: 'auto',
            backgroundColor: 'white',
            color: 'black',
            fontSize: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '30px',
            }}
          >
            <img
              style={{ maxWidth: '100px' }}
              src={`${window?.appConfig.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
              alt="logo"
            />
          </div>

          <div
            style={{
              textAlign: 'left',
              fontSize: '16px',
              paddingTop: '30px',
              paddingBottom: '30px',
              fontWeight: 'bolder',
            }}
          >
            {selectedTag.name}
          </div>

          {transferDetails.user && transferDetails.user !== null && (
            <div
              style={{
                display: 'flex',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{`${t('User')}: `}</span>
              <span style={{ marginLeft: '4px' }}>{`${String(
                transferDetails.user.first_name.charAt(0),
              ).toUpperCase()} ${String(transferDetails.user.last_name.charAt(0)).toUpperCase()}`}</span>
            </div>
          )}

          <div
            style={{ display: 'flex', paddingTop: '3px', paddingBottom: '3px' }}
          >
            <span
              style={{ fontWeight: 'bold' }}
            >{`${t('Transaction')}: `}</span>
            <span style={{ marginLeft: '4px' }}>
              {transferDetails.ticket_number}
            </span>
          </div>

          {transferDetails.channel && (
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  marginRight: '4px',
                }}
              >{`${t('Channel')}: `}</span>
              <span
                style={{ textTransform: 'uppercase', display: 'inline-block' }}
              >
                {transferDetails.channel.name}
              </span>
            </div>
          )}

          {transferDetails.desk && (
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  marginRight: '4px',
                }}
              >{`${t('Desk')}: `}</span>
              <span
                style={{ textTransform: 'uppercase', display: 'inline-block' }}
              >
                {transferDetails.desk.name}
              </span>
            </div>
          )}

          <div
            style={{
              textAlign: 'left',
              paddingTop: '3px',
              paddingBottom: '3px',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                marginRight: '4px',
              }}
            >{`${t('Date-of-Purchase')}: `}</span>
            <span style={{ display: 'inline-block' }}>
              {transferDetails.transaction_date_time}
            </span>
          </div>

          {filterOne && filterOne.length !== 0 && (
            <div>
              {filterOne.map((entry) => (
                <div
                  style={{ paddingTop: '3px', paddingBottom: '3px' }}
                  key={entry.token}
                >
                  <div className="flex ">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Token')}: `}</span>
                    <span style={{ marginLeft: '4px' }}>{entry.token}</span>
                  </div>
                  <div className="flex ">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Amount')}: `}</span>
                    <div style={{ marginLeft: '4px' }}>
                      <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={entry.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                      <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
                    </div>
                  </div>
                  <div className="flex ">
                    <span
                      style={{ fontWeight: 'bold' }}
                    >{`${t('Expiry-date')}: `}</span>
                    <span style={{ marginLeft: '4px' }}>
                      {entry.expiry_date}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div
            style={{
              textAlign: 'center',
              paddingBottom: '30px',
              paddingTop: '20px',
            }}
          >
            {selectedTag.ticket_footer}
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
