import React from 'react';
import { GF_LIGHT_BLUE, GF_PERIWINKLE } from '../../../utils/Constants';

export default function DropdownIndicator() {
  return (
    <svg
      width="32"
      height="8"
      viewBox="10 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0625 7.625L0.28125 2.84375C0.117188 2.67969 0.0351562 2.48047 0.0351562 2.24609C0.0351562 2.01172 0.117188 1.8125 0.28125 1.64844L1.08984 0.875C1.25391 0.710937 1.45312 0.628906 1.6875 0.628906C1.92188 0.628906 2.12109 0.710937 2.28516 0.875L5.66016 4.25L9.03516 0.875C9.19922 0.710937 9.39844 0.628906 9.63281 0.628906C9.86719 0.628906 10.0664 0.710937 10.2305 0.875L11.0391 1.64844C11.2031 1.8125 11.2852 2.01172 11.2852 2.24609C11.2852 2.48047 11.2031 2.67969 11.0391 2.84375L6.25781 7.625C6.09375 7.78906 5.89453 7.87109 5.66016 7.87109C5.42578 7.87109 5.22656 7.78906 5.0625 7.625Z"
        fill={GF_LIGHT_BLUE}
      />
    </svg>
  );
}
